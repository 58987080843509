<template>
  <div>
    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <!-- Product Job Notes START -->
    <JobDetailViewSection
      :headline="$t('notes')"
      :border="!productOptionsJobNotes.length"
      :text="job.order.product.jobNotes[0]['description']"
      v-if="job.order.product.jobNotes && job.order.product.jobNotes.length">
    </JobDetailViewSection>
    <!-- Product Job Notes END -->

    <!-- Product Option Job Notes START -->
    <div v-if="productOptionsJobNotes && productOptionsJobNotes.length">
      <JobDetailViewSection
        :border="index === (productOptionsJobNotes.length-1)"
        :text="productOptionJobNote"
        v-for="(productOptionJobNote, index) in productOptionsJobNotes" :key="'option-job-note-' + index">
      </JobDetailViewSection>
    </div>
    <!-- Product Option Job Notes END -->

    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD START -->
    <JobDetailViewSection
      :border="false"
      :headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      :download-action="downloadAction"
      :downloads="translationSourceFiles"
      :remove-action="null"
      :remove-enabled="removeEnabled"
      :admin="admin"
      :table="jobOrderQuantity"
      v-if="translationSourceFiles">
    </JobDetailViewSection>
    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD END -->

    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM START -->
    <JobDetailViewLinguist
      :job="job"
      :admin="admin"
      :action-buttons="actionButtons"
      :linguist="linguist"
      :upload-action="uploadTranslatedFiles"
      :handle-file-upload-change="handleFileUploadChange"
      :upload-translation-file-label="'upload_translation_here'"
      :uploaded-files-label="uploadedFilesLabel"
      :download-action="downloadAction"
      :uploaded-correction-files-label="'corrections_to_download'"
      :translation-files="translationFiles"
      :job-in-progress="job.status === jobStatus.REALIZATION"
      :remove-action="null"/>
    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM END -->
  </div>
</template>
<script>
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import JobDetailViewUploadSection from 'lingoking-workbench/src/components/jobdetailview/JobDetailViewUploadSection.vue'
import JobDetailViewLinguist from "lingoking-workbench/src/components/jobdetailview/JobDetailViewLinguist.vue";

import {OrderStatus} from "lingoking-workbench/src/enums";

import JobStatus from "lingoking-workbench/src/enums/JobStatus";

export default {
  name: 'Translator',
  components: {
    JobDetailViewLinguist,
    JobDetailViewSection,
    JobDetailViewUploadSection
  },

  props: {
    admin: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object
    },
    linguist: {
      type: Object
    },
    actionButtons: {
      type: Object
    },
    handleFileUploadChange: {
      type: Function
    },
    downloadAction: {
      type: Function
    },
    uploadAction: {
      type: Function,
    },
    getTranslationFiles: {
      type: Function
    },
    deliveryDate: {
      type: String
    },
    uploadedFilesLabel: {
      type: String,
      default: 'your_uploaded_translation'
    },
    jobOrderQuantity: {
      type: Array
    },
    translationSourceFiles: {
      type: Array
    },
    productOptionsJobNotes: {
      type: Array
    }
  },

  computed: {
    deadlines() {
      let deadlines =  [{'head': 'due_on', 'data': this.deliveryDate}]

      if(this.proofreadingDeliveryDate) {
        deadlines.push({
          'head': 'deadline_proofreading',
          'data': this.proofreadingDeliveryDate,
          'hide': false
        })
      }

      if(this.finalRevisionDeliveryDate) {
        deadlines.push(        {
          'head': 'deadline_final_revision',
          'data': this.finalRevisionDeliveryDate,
          'hide': false
        })
      }

      return deadlines
    },

    finalRevisionJob() {
      return this.job.order.jobs.find(job => job.jobDefinition && job.jobDefinition.type === 'final-revision')
    },

    finalRevisionDeliveryDate() {
      if (!this.finalRevisionJob)
        return null

      return this.$moment(this.finalRevisionJob.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    proofreadingJob() {
      return this.job.order.jobs.find(job => job.jobDefinition && job.jobDefinition.type === 'corrector')
    },

    proofreadingDeliveryDate() {
      if (!this.proofreadingJob)
        return null

      return this.$moment(this.proofreadingJob.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    removeEnabled() {
      return this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length > 1
    },

    targetFilesDownload() {
      return this.job.order.status === this.orderStatus.ORDER_DELIVERED.schema
    },

    translationFiles() {
      return this.getTranslationFiles('target-file', null)
    },

    // TODO: check
    downloads() {
      if (this.job && this.job.user) {
        if (this.job.status !== JobStatus.FINISHED) {
          let translationSourceFiles = []

          if (this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length) { // if there are more files
            let self = this

            this.job.order.translationSourceFiles.forEach(function (translationSourceFile, i) {
              let downloadFile = {
                downloadLink: self.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
                linkText: translationSourceFile.name,
                downloadAction: true,
                resourceId: translationSourceFile['@id'],
                type: 'source-file',
                name: translationSourceFile.name,
                created_at: translationSourceFile.createdAt,
                scheduledDeletionDate: translationSourceFile.scheduledDeletionDate,
                status: translationSourceFile.status,
              }

              if (!i) {
                downloadFile['downloadLabel'] = 'documents_to_translate'
              }

              translationSourceFiles.push(downloadFile)
            })
            // only one file
          } else {
            translationSourceFiles = [{
              downloadLabel: 'document_to_translate',
              downloadLink: this.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
              linkText: this.job.order.translationSourceFile.name,
              downloadAction: true,
              resourceId: this.job.order.translationSourceFile['@id'],
              type: 'source-file',
              name: this.job.order.translationSourceFile.name,
              created_at: this.job.order.translationSourceFile.createdAt,
              scheduledDeletionDate: this.job.order.translationSourceFile.scheduledDeletionDate,
              status: this.job.order.translationSourceFile.status,
            }]
          }

          return translationSourceFiles
        }

        return []
      }
    },
  },

  methods: {
    uploadTranslatedFiles: function () {
      this.uploadAction('translationTargetFiles[]')
    },
  },

  data() {
    return {
      jobStatus: JobStatus,
      orderStatus: OrderStatus
    }
  },
}
</script>
