import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const upload = ({ commit }, values) => {
  commit(types.ADDITIONAL_COPY_CREATE_SET_ERROR, '')
  commit(types.ADDITIONAL_COPY_CREATE_TOGGLE_LOADING)
  return fetch('additional_copy_files', {
    method: 'POST',
    data: values.fileUploadForm
  })
      .then((response) => {
        commit(types.ADDITIONAL_COPY_CREATE_TOGGLE_LOADING)
        return response
      })
      .then((data) => {
        commit(types.ADDITIONAL_COPY_CREATE_SET_CREATED, data)
      })
      .catch((e) => {
        commit(types.ADDITIONAL_COPY_CREATE_TOGGLE_LOADING)
        commit(types.ADDITIONAL_COPY_CREATE_SET_ERROR, e.message)
      })
}

export const reset = ({ commit }) => {
  commit(types.ADDITIONAL_COPY_CREATE_RESET)
}
