export default {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  german: 'German',
  english: 'English',
  spanish: 'Spanish',
  language: 'Language',
  save: 'Save',
  confirm: 'Confirm',
  cancel: 'Cancel',
  close: 'Close',
  delete: 'Delete',
  error: 'Error',
  sorry: 'Sorry!',
  yes: 'yes',
  no: 'no',
  clarify_with_customer: 'Clarify with the customer',
  clarification: 'Clarification',
  clarification_from_qm: 'Feedback from lingoking',
  select_from_list: 'Please select a {item} from the list',
  settings_language: 'Language settings',
  settings_language_tooltip: 'Language used in your emails',
  notifications: 'Notifications',
  settings_notifications: 'Notification Settings',
  settings_notifications_tooltip: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  newsletter: 'Newsletter',
  success: 'Thank you!',
  settings_update_success: 'Your setting were saved successfully',
  try_again: 'Please try again.',
  welcome: 'Welcome',
  your_language: 'Your language',
  filter_by: 'Filter by',
  clear_all: 'Clear All',
  error_occurred: 'An error occurred.',
  invoice: 'Invoice | Invoices',
  credit_note: 'Credit note | Credit notes',
  name: 'Name',
  service: 'Service',
  receipt_number: 'Receipt number',
  creation_date: 'Creation date',
  ccn_number: 'CCN number',
  ccn_amount: 'Credit note amount',
  account_not_enabled: 'Account not enabled. Contact support for info.',
  customer: 'Customer',
  lsp: 'Linguist',
  pm: 'lingoking Support',
  project_manager: 'Project Manager',
  invalid_data: 'Please check the form for invalid data',
  select_timezone: 'Select a timezone.',
  timezone: 'Timezone',
  your_timezone: 'Your timezone',
  register_as_lsp: 'Register as a service provider',
  register_as_customer: 'Register as a customer',
  generate_shipment_sheet_pdf: 'Generate shipment sheet pdf',
  are_you_sure_confirm_job_execution: 'Are you sure you want to confirm the job execution?',

  // Registration Pages
  registration_page_title: 'Register now/Registration',
  login_now: 'Sign in now',
  account_already: 'Do you already have an account?',
  register_header: 'Get started in just a few steps',
  register_subheader: 'Register now as LSP and use our offers!',
  register_subtitle_error: 'We kindly ask you to check your entered data again and try again. Thank you very much!',
  business_customer: 'Business customer',
  private_customer: 'Private customer',
  first_name: 'First name',
  first_name_required: 'First name is required',
  first_name_too_short: 'First name is too short',
  last_name: 'Last name',
  last_name_required: 'Last name is required',
  last_name_too_short: 'Last name is too short',
  repeat_email: 'Repeat email address',
  repeat_email_required: 'The field "Repeat email address" is required',
  repeat_password: 'Repeat password',
  repeat_password_required: 'The field "Repeat password" is required',
  repeat_password_not_identical: 'Repeat password not identical with password',
  opt_out: 'I am not interested in receiving news or updates regarding the lingoking products and services.',
  opt_out_privacy: 'Privacy policy',
  privacy_link: '/en/general/privacy-policy',
  terms_of_use_p1: 'By clicking on "complete registration" I agree to the ',
  terms_of_use_p2: 'of lingoking.',
  terms_of_use: 'terms of use',
  terms_of_use_lsp_dashboard: 'Terms of use',
  of_lingoking: 'of Lingoking',
  terms_of_use_link: '/en/general/terms-and-conditions',
  help_link: '/en/general/help-customers',
  help_linkLSP: '/en/general/help-linguists',
  gtc_linkLSP: '/en/terms-and-conditions',
  register_complete: 'Complete registration',
  required: 'is required',
  too_short: 'is too short',
  login_problem_classic1: 'Having trouble logging in?',
  login_problem_classic2: 'Our website is currently under construction - you can find your old orders here: ',
  login_problem_classic3: 'For any questions do not hesitate to contact us in the chat!',

  login_page_title: 'Log in now',
  no_account: 'You don\'t have an account yet?',
  register_now: 'Register now',
  login_header: 'LSP Log in now',
  login_subheader: 'Enter your credentials in the fields.',
  your_email: 'Your email address',
  password: 'Password',
  email: 'Email',
  email_required: 'Email is required',
  email_valid: 'The format of the email is not valid',
  password_required: 'Password is required',
  min_length_error: 'This value is too short. It should have {number} characters or more.',
  password_min_length_error: 'This password is too short. It should have {number} characters or more.',
  forgot_password: 'Forgot your password?',
  login: 'Login',
  profile_updated: 'Your profile has been updated.',
  favorites_updated: 'Your favorite language service providers have been updated',
  customized_service_title: 'Customized Services',
  customized_service: 'There customized services have been developed for you exclusively.',
  customized_service_company_title: 'Customized Services for your Company',
  customized_service_company: 'There customized services have been developed for your company exclusively.',
  invalid_captcha: 'invalid captcha',

  // Registration LSP
  lsp_registration_page_title:  'LSP Registration',
  welcome_title: 'Welcome to lingoking - complete your profile in four easy steps',
  welcome_description: 'Thank you for your interest in working with lingoking. Please take a few minutes to complete your profile and registration. Here you can find out how to submit a successful application and learn about the technical registration requirements (browser, browser settings). You can complete your registration in four easy steps. Please enter your contact details, your professional skills and qualifications as well as the respective supporting documents. Please note that you can only accept jobs once your skills have been accredited by lingoking. Registration checklist The following documents are required for registration:',
  currenct_contact_details: 'Current contact details',
  skills: 'Skills (types of language services, languages, areas of expertise)',
  proof_of_qualification: 'Proof of qualification, certification and accreditation, e.g. translation or interpretation certificates (with final grade), customer testimonials and other supporting documents that verify your experience in translating and interpreting, as well as proof of membership in relevant associations.',
  photo_optional: 'Photo (optional)',
  account_details: 'Account details',
  contact_details: '1. Your Contact Details',
  contact: 'Contact',
  phone_description: 'Your phone number',
  phone: 'Telephone number',
  mobile_phone: 'Mobile phone number',
  mobile_phone_description: 'Your mobile phone number',
  fax_number: 'Fax number',
  fax_number_description: 'Your fax number',
  skills_title: 'Skills',
  skills_placeholder: 'Add your skills',
  please_note: 'Please note',
  skill_warning: 'Your skills must first be accredited by lingoking before you are offered suitable jobs.',
  your_skills: '2. Your skills',
  skills_desc: 'As lingoking language service provider, you have the opportunity to work as a translator, an interpreter or a telephone interpreter. Please specify the language services you wish to offer. You can add more details in the next steps. You can specify one or several skills.',
  native_language: 'Native Language',
  native_languages: 'Native Languages',
  native_language_desc1: 'Please select your native language.',
  native_language_desc2: 'If you have more than one native language, please specify.',
  native_language_placeholder: 'Search and add native languages.',
  native_language_error: 'At least one native language is required.',
  application_documents: '3. Your application documents',
  curriculum_vitae: 'Curriculum vitae',
  upload_curriculum_vitae: 'Please upload your curriculum vitae.',
  documents_allowed: 'Only documents allowed (msword, ms-excel, ms-powerpoint, .txt, .pdf, .jpeg, .png).',
  select_files: 'Select files',
  select_file: 'Select file',
  delete_file_success: 'File successfully deleted.',
  upload_file_success: 'File successfully uploaded.',
  only_jpg: 'only .jpg and .png files. 500kb max file size.',
  max_file_upload_size_mb: 'MB max file size.',
  references: 'Reference / testimonials',
  upload_references: 'Please upload at least one reference or testimonial.',
  profile_summary: '4. Your profile summary',
  profile_photo: 'Profile photo',
  profile_photo_desc: 'You are able to enhance your public profile by uploading a profile picture, however this is optional. Please note that the profile picture you enter will be visible in your public profile.',
  profile_photo_desc2: 'Important: In order to submit your registration to lingoking please scroll to the bottom of the page and click the \'complete registration\' button. Please also keep in mind the Data Protection Declaration as well as our TOC. Write something about yourself for the customer to see. Please do not enter any data such as your full name, e-mail address or telephone number that could identify you personally.',
  bank_account: '5. Bank account details, tax number and VAT ID',
  account: 'Account',
  payment_data: 'With your payment details, you can accept jobs but these fields must be completed by the time the job is signed off by the customer. If you reside in Germany, you will see a VAT ID field alongside the tax number. In this case only complete one of the two fields.',
  enter_payment_data: 'Please enter your payment details.',
  payment_method: 'Payment Method',
  payment_not_successfully_created: 'Payment has not been created successfully.',
  paypal_address: 'PayPal email address',
  tax_number: 'Tax Number',
  vat_id: 'Vat ID',
  vat_id_invalid: 'This is not a valid VAT identification number',
  account_holder_name: 'Name of the account holder',
  vat_id_required: 'Mandatory field for EU companies outside Germany',
  search_invoices:'Search invoices',

  // Reset password pages
  password_token_reset_page_title: 'Reset token Password',
  reset_password: 'Reset password',
  enter_email: 'Enter your email address',
  email_unvalid: 'Email is invalid',
  password_reset_success: 'If you are a valid user on our platform, you will receive an email to reset your password.',
  reset_successful: 'Reset successful',
  enter_new_password: 'Enter your new password.',
  your_new_password: 'Your new password',
  save_password: 'Save password',
  reset_user_notfound: 'Your email address is incorrect. Please check again.',

  // Alerts
  tracking_code_sent: 'Tracking Code successfully sent',
  tracking_code_saved: 'Tracking Code successfully saved',
  job_reject_success: 'The job request was successfully rejected.',
  job_accept_success: 'The job request was successfully accepted.',
  link_invalid: 'The requested Link is invalid or has expired.',
  job_reject_fail: 'Job request could not be rejected',
  email_not_confirmed: 'You have not confirmed your email address, yet!',
  confirmation_successful: 'Confirmation successful',
  registration_successful: 'Registration successful',
  email_confirmation_label: 'Please click here to confirm',
  rejected_job_request: 'Nice of you to swing by! You have already rejected this job. If you have any questions, please feel free to contact us at support@lingoking.com',

  // Empty Dashboard
  first_order: 'Currently there are no orders in this section',
  empty_help1: 'If you want to place an order, click on "New Order" in the upper left corner. Do you have further questions? Visit our ',
  no_results_found: 'No results found!',
  empty_help2: 'or visit our ',
  empty_help_video: 'video',
  no_orders: 'You do not have any orders yet!',
  empty_jobs: 'Currently there are no jobs in this section',
  empty_help_lsp_1: 'Questions about project management at lingoking? Browse through our ',
  empty_help_lsp_2: 'or contact us, we are looking forward to hearing from you!',

  // Dashboard
  recent_orders: 'Recent Orders',
  show_all: 'Show all',
  dashboard: 'Dashboard',
  recently_updated_jobs: 'Recently updated jobs',

  // Profile
  customer_type: 'Are you a business or private customer?',
  customer_salutation: 'Salutation',
  customer_salutation_desc: 'Please enter a form of address.',
  customer_salutation_mrs: 'Mrs',
  customer_salutation_mr: 'Mr',
  customer_billing_address: 'Please enter your billing address.',
  billing_address: 'Billing address',
  change_billing_address: 'Change billing address',
  change_delivery_address: 'Change delivery address',
  billing_address_label: 'Please enter your billing address.',
  address_street_number: 'Street, number',
  address_street: 'Street',
  number: 'Number',
  address_zip_code: 'ZIP code',
  address_city: 'City',
  address_country: 'Country',
  address_suffix: 'Address Suffix',
  choose_address_country: 'Select a country.',
  favorites: 'Favorites',
  favorite_linguists: 'Favorite Linguists',
  favorites_desc: 'Please select your favorite language service providers here.',
  favorites_desc2: 'If you have one or more favorite linguist, please specify.',
  favorites_placeholder: 'Search and add favorite linguists.',
  unsubscribe_feedback_email: 'You have successfully unsubscribed from our evaluation emails',
  subscribe_feedback_email: 'Subscribe Feedback Email',

  // Auftragsverarbeitung
  request: 'Request',
  accept: 'Accept',
  reject: 'Reject',
  reject_to_lsp: 'Reject to LSP',
  reject_translation_desc: 'This rejects the translation and initiates a correction of the translation. Please let us know where we can improve the translation.',
  refusal_reason: 'Reason for refusal',
  reject_translation: 'Reject translation',
  accept_translation: 'Accept translation',
  accept_translation_desc_physical: 'You hereby accept the translation and arrange for the immediate dispatch of your documents.',
  accept_translation_desc_mail: 'You hereby accept the translation.',
  lsp_search: 'We are recruiting a service provider for you',
  tracking_code: 'Tracking code',
  track_shipment: 'Track shipment',
  select_carrier: 'Please select a shipping method',
  enter_tracking_code: 'Please enter tracking code',

  // Job Detail View Section
  requirements: 'Requirements',
  notes: 'Notes',
  customer_notes: 'Customer message',
  deadline: 'Deadline',
  deadline_proofreading: 'Revised translation available for final review on:',
  deadline_final_revision: 'Delivery of final reviewed file to the customer:',
  deadline_translation: 'Translation available for proofreading on:',
  due_on: 'Due on:',
  document_to_translate: 'Document to be translated',
  documents_to_translate: 'Documents to be translated',
  file_was_deleted_on: 'File was deleted on {date}',
  file_will_be_deleted_on: 'File will be deleted on {date}',
  address_info: 'The complete address is activated after order acceptance',
  source_language: 'Source language',
  target_language: 'Target language',
  target_languages: 'Target languages',
  upload_documents: 'Upload document(s)',
  upload_document: 'Upload document',
  translation_was_accepted: 'Your submitted translation has been accepted by the customer',
  translation_was_not_accepted: 'Your submitted translation has not yet been accepted by the customer',
  personal_contact_person: 'Your personal contact',
  customer_address: 'Customer address',
  your_translation_to_download: 'Download your translation',
  your_uploaded_translation: 'Your uploaded translation',
  your_uploaded_translation_to_download: 'Your uploaded translation to download',
  translation_to_correct: 'Translation to be corrected',
  your_uploaded_correction: 'Your uploaded correction',
  corrections_to_download: 'Download corrections',
  upload_translation_here: 'Upload your translation here',
  upload_correction_here: 'Upload correction here',
  upload_corrected_translation: 'Upload corrected translation here',
  upload_correction_success: 'Correction has been uploaded successfully.',
  upload_translation_success: 'Translation has been uploaded successfully.',
  upload_success: 'Translation for job {job} has been uploaded successfully.',
  accepted_translation_success: 'Thank you very much! You have successfully accepted this translation.',
  upload_translation_rejected: 'Translation has been rejected successfully.',
  translated_file_not_uploaded: 'Translated file not uploaded, yet!',
  your_fee: 'Your fee',
  fee: 'Fee',
  final_fee: 'Final Fee',
  amount: 'Amount',
  translate_from: 'Your translation is being processed by',
  translated_from: 'Your translation was processed by',
  for_your_information: 'For your information:',
  release_translation: 'Submit translation',
  release_correction: 'Submit correction',
  upload_correction: 'Upload correction',
  target_file: 'Target file',
  release: 'Submit',
  current_order: 'Current order',
  download_translation: 'Download translation',
  download_translation_to_be_corrected: 'Download translation to be corrected',
  job_cancelled: 'Job cancelled',
  job_cancelled_warning: 'This order has been cancelled. Unfortunately, you will therefore no longer have access to the job information.',
  cet: 'CET',
  job_progress: 'Job In Progress',
  job_done: 'Job Done',
  job_rejected: 'Job Rejected',
  current_job: 'Current Job',

  // List view:
  order_from: 'Commissioned by',
  after_order_copy_to: 'After order send copy of invoice to',
  download_offer: 'Download offer',
  internal_process: 'Internal Process',

  // CompanyForm
  company_legend: 'lingoking for businesses',
  company: 'Company',
  company_details: 'Company details',
  company_name: 'Company name',
  company_name_required: 'Company name required',
  company_name_too_short: 'Company name is too short',
  company_phone: 'Phone',
  position_in_company: 'Position in the company',
  phone_is_too_short: 'Phone number is too short',
  select_industry: 'Please select an industry',
  small_company_label: "I am a 'Kleinunternehmer' (small business). My income at lingoking is exempt from the sales tax based on section 19 of the German UStG.",
  industry: 'Industry',
  vat_number: 'VAT number',
  vat_required: 'VAT required',
  url_too_short: 'URL is too short',

  // Shopping Cart
  select_customer: 'Select customer',
  pay_safely_with: 'Pay securely with',
  estimated_delivery_date: 'Delivery expected within 3-5 working days',
  gtc_and_privacy: 'GTC und Privacy Policy',
  shipping_options: 'Delivery options',
  accept_gtc: 'I hereby confirm that I have read and understood the General Terms and Conditions and the Privacy Policy, both of which I accept.',
  continue_to_payment: 'Checkout',
  continue_shopping: 'Continue Shopping',
  cart_overview: 'Cart overview',
  order_overview: 'Order overview',
  cart: 'Cart',
  overview: 'Overview',
  login_tab: 'Your data',
  continue: 'continue',
  data_successfully_saved: 'Your data has been successfully saved. You will receive e-mails to confirm your account and verify your password.',
  please_enter_data: 'Please enter your data',
  please_uplod_file: 'Please upload the source file(s) now. You will then receive an e-mail confirming receipt of the order.',
  back: 'Back',
  enter_login_data: 'Please enter your login data',
  find_right_product: 'Not found the right product yet?',
  help_you: 'We will help you',
  contact_lingoking: 'Contact lingoking',
  cart_empty: 'Your cart is empty.',
  to_products: 'Go to products',
  choose_option: 'To continue shopping, please select one of the following options',
  order_product_now: 'Order a translation now',
  go_to_login: 'Go to the login area',
  ask_help: 'Questions? We would like to help you!',
  go_to_products: 'Go to products',
  link_login: 'Login',
  link_contact: 'Contact',
  new_customer: 'New customer',
  order_as_guest: 'Order as guest',
  problems: 'Problems',
  file_upload: 'File Upload',
  submit_order: 'Submit order',
  submit_order_info: 'In a few minutes you will receive a confirmation via email that your data has been received successfully.',
  remove_product: 'Remove product',
  show_details: 'Show details',
  hide_details: 'Hide details',
  source_language_placeholder: 'Search and add a source language.',
  target_languages_placeholder: 'Search and add a target languages.',
  source_language_missing: 'source language missing',
  target_language_missing: 'target language missing',
  number_of_pages_missing: 'number of pages is required',
  number_of_words_missing: 'number of words is required',
  number_of_fixed_items_missing: 'Quantity is required',
  number_of_pages: 'Number of pages',
  number_of_words: 'Number of words',
  number_of_fixed_items: 'Quantity',
  number_of_hours: 'Number of hours',
  number_of_days: 'Number of days',
  number_of_half_days: 'Number of half days',
  number_of_half_hour: 'Half hours',
  how_many: 'How many',
  pages: 'pages',
  words: 'words',
  page: 'page',
  word: 'word',
  day: 'day',
  days: 'days',
  hour: 'hour',
  hours: 'hours',
  half_day: 'half day',
  half_days: 'half days',
  half_hour: 'half hour',
  half_hours: 'half hours',
  to_translate: 'would you like to have translated?',
  how_many_to_translate: 'How many {pagesOrWords} would you like to have translated?',
  interpretation_price_unit_select_headline: 'For how many {priceUnit} do you need an interpreter?',
  start_price_calculation: 'Calculate the price of your translation now!',
  total_amount_net: 'Total net amount',
  total_amount: 'Total amount',
  payment: 'Payment',
  choose_payment_method: 'Choose your payment method',
  confirmation_and_upload: 'Confirmation and data upload',
  net_amount: 'Net amount',
  vat_abbr: 'VAT',
  login_successful: 'Your login was successful.',
  no_parcel_delivery: 'Parcel Delivery is not available for your country!',
  order_number: 'Order number',
  show_dashboard: 'Show Dashboard',
  not_authorized_title: 'Customized Service',
  not_authorized: 'This customized service was created for one of our customers exclusively. Please contact the lingoking team for more information about this service.',
  additional_copy: 'Additional copy',
  additional_copies: 'Additional copies',
  additional_data: 'Additional data',
  additional_digital_files: 'Additional Digital File(s)',
  additional_files_required: '* At least one Additional Digital File is required.',
  cart_item_remove_cancel: 'Cancel',
  cart_item_remove_confirm: 'Delete',
  cart_item_remove_message: 'Are you sure you want to delete this product from your shopping cart?',
  pay_upon_invoice: 'Pay Upon Invoice',
  pay_upon_invoice_description: 'lingoking postpaid payment exclusively for our business customers.',
  order_reference: 'Your Order Reference',
  pay_upon_optional_field: "optional, will be shown on your invoice",
  discount: 'Discount',
  discount_code: 'Discount Code',
  discount_apply: 'Apply',
  discount_apply_label: 'Do you have a discount code?',
  discount_remove: 'Remove',
  discount_message: 'If you have a discount code, enter it below:',
  discount_already_applied: 'Discount already applied! If you want to use a new discount, remove the previous one.',
  discount_already_applied_summary: 'Discount (already applied)',
  discount_not_valid: 'Discount not found or inactive. Please check the code and try again.',
  discount_usage_reached: 'The maximum number of uses for this discount code has been reached.',
  discount_not_applicable: 'This discount code does not apply to any of the products in your shopping cart.',
  discount_min_value: 'To apply this discount you must have a minimum net price of {value} €.',

  // Product
  price_calculation: 'Price calculation',
  your_order: 'Your order',
  translation_from: 'Translation from',
  calculate_price: 'Calculate price',
  calculated_price: 'Calculated price',
  price_edited_at: 'Price edited at',
  summary: 'Summary',
  assign_name: 'Assign a name to your order',
  product_name: 'Product name',
  recommended: 'Recommended',
  order_name: 'Order name',
  order_date: 'Order date',
  order_name_placeholder: 'Order 01',
  max_chars: 'max. 50 characters',
  upload_after_payment: 'You can upload the document after payment',
  message_to_us: 'Your message to us (optional)',
  add_to_cart: 'Add to cart',
  search_add_topic: 'Search and add a topic',
  search_add_country: 'Search and add a country',
  search_add_timezone: 'Search and add a timezone',
  start_date: 'Start Date',
  end_date: 'End Date',
  date: 'Date',
  order_nr: 'Order-Nr',
  state: 'State',
  not_confirmed_yet: 'not confirmed yet',

  // Chat
  chat_deactivated: 'The chat has been deactivated.',
  chat_deactivated_info: 'If you have further questions, the lingoking team is happy to help! Simply contact us via our Messenger, by clicking the blue button on the bottom right of this page. We will get back to you asap!',
  your_message: 'Your message',
  no_messages: 'There are no messages.',
  private_message: 'Private message',
  private_message_to: 'Private message to',
  write_a_message: 'Please write a message.',

  thanks_for_order: 'Thank you for your order!',
  order: 'Order',
  job: 'Job',
  order_created: 'Your order has been created. Please upload the required files.',
  payment_cancelled: 'We are sorry! Please choose another payment method.',
  order_completed_info: 'In your personal account you can view all details of your orders as well as manage other settings.',

  // CMS Navigation
  user_administration: 'User administration',
  settings: 'Settings',
  logout: 'Logout',
  profile: 'Profile',
  help: 'Help',
  intranet: 'Intranet',
  messages: 'Messages',
  message: 'Message',

  new_order: 'New Order',
  completed_orders: 'Completed Orders',
  offers: 'Offers',
  bills: 'Bills',
  quick_access: 'Order now',
  delivery_date: 'Delivery Date',
  delivery_date_customer: 'Upload of the translation by',
  price: 'Price',
  total_price: 'Total Price',
  margin: 'Margin',

  first_and_last_name: 'First and last name',
  street_and_number: 'Street & street number',
  postal_code_and_city: 'ZIP code & city',

  recent_jobs: 'Recent Jobs',
  completed_jobs: 'Completed Jobs',
  job_requests: 'Job Requests',
  another_linguist_already_accepted_job: 'Oh no! Looks like the job has just been assigned to someone else. Sorry for that. Surely it will work next time!',
  you_already_accepted_job: 'You have already accepted this job',
  payments: 'Payments',

  // ccn
  searchCcn: 'Search credit notes',

  // customer order states labels
  order_state_processing: 'Processing',
  order_state_check: 'Please check',
  order_state_shipment_pending: 'Shipment',
  order_state_done: 'Done',
  order_state_cancelled: 'Cancelled',
  order_state_delivered: 'Order Delivered',
  order_state_transit: 'Order In Transit',

  // lsp job states labels
  lsp_job_state_translation_processing: 'Please translate',
  lsp_job_state_corrections_pending: 'Waiting for corrections',
  lsp_job_state_customer_approval: 'Waiting for Approval',
  lsp_job_state_qm_approval: 'Waiting for Approval',
  lsp_job_state_acceptance: 'Waiting for acceptance',
  lsp_job_state_review: 'Please review',
  lsp_job_state_shipment_pending: 'Shipment necessary',
  lsp_job_state_done: 'Done',
  lsp_job_state_translation_pending: 'Waiting for translation',
  lsp_job_state_correction_processing: 'Start proofreading',
  lsp_job_state_approval_pending: 'Waiting for Approval',
  lsp_job_state_order_cancelled: 'Job cancelled',
  lsp_job_state_pending: 'Waiting for Approval',
  lsp_job_state_cancelled: 'Job cancelled',

  // info
  lsp_qm_info: 'This order includes additional quality management by the lingoking team. This means that your translation is not delivered to the customer immediately and automatically after uploading, but is first checked by the lingoking project management team. In case of adjustment requests or need for clarification, the PM team will contact you, not the customer. If you have any questions about this, please contact us directly via the messenger at the bottom right of this page. Do not use the chat to contact lingoking, as these notifications are visible to customers.',

  // countries
  country_germany: 'Germany',
  country_switzerland: 'Switzerland',
  country_austria: 'Austria',

  // Status
  status_pending : 'Status: Pending.',
  status_rejected: 'Status: Translation rejected.',
  status_shipment_required: 'Status: Shipment required.',
  status_uploaded: 'Status: Translation uploaded.',
  status_correction_uploaded: 'Status: Correction uploaded.',

  //Status SubTitle
  waiting_corrections_uploads: 'Please wait until corrections are uploaded.',
  waiting_shipment: 'Waiting for shipment.',
  upload_translation_correction: 'Please update your translation with the corrections.',

  translation_rejected_pm_not_satisfied: 'Upload corrected translation here. The translation was rejected for the following reason: The lingoking project management has rejected your translation after checking it, as adjustments or changes are necessary.',
  translation_rejected_wait_for_instructions: 'Wait for instructions from lingoking project management.',

  // Status Content
  rejection_reasons: '<br /><p class="bx--inline-notification__title">You have rejected the translation with the following reason:</p>',
  rejection_reasons_lsp: '<br /><p class="bx--inline-notification__title">The translation has been rejected with the following reason:</p>',
  rejection_reason: 'Rejection Reason',
  previous_rejection_reasons: 'Previous Rejection Reasons',

  // PaymentData validation
  bic_required: 'BIC is required',
  bic_invalid: 'BIC is invalid',
  iban_invalid: 'IBAN is invalid',
  iban_required: 'IBAN is required',

  // Pagination
  items_per_page: 'Items per page:',
  of: 'of',
  items: 'items',
  sort_by: 'Sort by',
  group_by: 'Group by',

  no_spaces: 'no spaces',
  //BA
  error_leitweg_id: 'Leitweg-ID is not valid',

  // Dunning process
  invoicing_phase: 'invoicing phase',
  reminder1: 'first reminder',
  reminder2: 'second reminder',
  collection_company: 'debt collector',
  lawyer: 'lawyer',
  dunning_status_waiting_description: 'starting state for all invoices; invoice is not yet delivered and sent to customer',
  dunning_status_ongoing_description: 'invoice was sent to customer; payment is in process of collection',
  dunning_status_completed_description: 'payment has successfully been collected',
  dunning_status_unsuccessful_description: 'payment has not been successfully collected and is marked as lost (at any dunning level)',
  dunning_status_canceled_description: 'payment demand has been cancelled (e.g. due to cancelled invoice)',
  dunning_status_paused_description: 'payment demand has been paused',


  // Cancel Invoice Reason
  cancel_invoice_bad_service:
    `Hello {salutation} {lastName},<br />` +
    `we regret deeply that you are not satisfied with our service. ` +
    `We will of course at your request cancel the order with the number {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
    `Please find attached the cancellation of the invoice {invoiceNumber}. ` +
    `If you have already paid the order amount, it will be refunded immediately.<br />` +
    `If you still have any questions or suggestions, please do not hesitate to contact us.<br />` +
    `Kind regards <br/> Your lingoking team`,

  cancel_invoice_no_linguist_recruited:
    `Hello {salutation} {lastName},<br />` +
    `we regret to inform you that we have to cancel the order with the number {orderNumber}.<br />` +
    `Our translators for the requested language combination are currently working to capacity and therefore cannot accept any further translations.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
    `Please find attached the cancellation of the invoice {invoiceNumber}. If you have already paid the order amount, it will be refunded immediately.<br />` +
    `We are very sorry for the inconvenience caused.<br />` +
    `If you have any questions, please feel free to contact us at any time.<br />` +
    `Kind regards <br/> Your lingoking team`,

  cancel_invoice_no_need:
    `Hello {salutation} {lastName},<br />` +
    `thank you very much for your order at lingoking. We deeply regret that you have decided otherwise. At your request we will cancel the order with the number {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
    `Please find attached the cancellation of the invoice {invoiceNumber}. If you have already paid the order amount, it will be refunded immediately.<br />` +
    `If you do need our service in the future, we look forward to welcoming you back at lingoking.<br />` +
    `Kind regards <br/> Your lingoking team<br />`,

  cancel_invoice_wrong_order:
      `Hello {salutation} {lastName},<br />` +
      `After careful examination of your order we noticed that the length/type of the uploaded document(s) does not correspond to the criteria of the booked service. Therefore unfortunately we will cancel the order with the number {orderNumber}.<br />` +
      `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
      `Please find attached the cancellation of the invoice {invoiceNumber}.  If you have already paid the order amount, it will be refunded immediately.<br />` +
      `You are welcome to book the correct service now. If you have any questions about this, we will be very happy to help you.<br />` +
      `Kind regards <br/> Your lingoking team<br />`,

  cancel_invoice_other:
    `Hello {salutation} {lastName},<br />` +
    `we regret to inform you that we have to cancel the order with the number {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
    `Please find attached the cancellation of the invoice {invoiceNumber}. If you have already paid the order amount, it will be refunded immediately.<br />` +
    `We are very sorry for the inconvenience caused.<br />` +
    `If you have any questions, please feel free to contact us at any time.<br />` +
    `Kind regards <br/> Your lingoking team<br />`,

  cancel_invoice_solved_internally:
    `Hello {salutation} {lastName},<br />` +
    `you have informed us that you have already solved the translation process internally.<br />` +
    `To our regret we therefore cancel the order with the number {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">View Order</a><br />` +
    `Please find attached the cancellation of the invoice {invoiceNumber}. If you have already paid the order amount, it will be refunded immediately.<br />` +
    `If you do need our service in the future, we look forward to welcoming you back at lingoking<br />` +
    `Please do not hesitate to contact us if you have any questions.<br />` +
    `Kind regards <br/>  Your lingoking team<br />`
};
