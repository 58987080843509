<template>
    <layout-front>
        <div class="lk-login lk-container--inner" >
            <div class="lk-login__header bx--row">
                <div class="bx--col-lg-4">
                </div>
                <div class="bx--col-lg-8 lk-login__header-content lk-alignment--right">
                    <router-link to="register" class="lk-login__button-label">{{$t('no_account')}}</router-link>
                    <router-link to="register" class="bx--btn bx--btn--tertiary bx--btn--sm lk-login__button">{{$t('register_now')}}</router-link>
                    <LanguageSelector></LanguageSelector>
                </div>
            </div>

            <Loading
                v-if="checkIsLoading || isLoading"
                class="alert alert-info"
                role="status">
            </Loading>

            <div class="lk-login__content lk-login__content--bigmargintop bx--row" v-if="token">
                <div class="bx--col-md-12 bx--col-lg-12 bx--col-xl-10 bx--col-xxl-5 lk-alignment--center lk-margin--center">
                    <h1 class="lk-login__headline">{{$t('reset_password')}}</h1>
                    <h2 class="lk-login__sub-headline">{{$t('enter_new_password')}}</h2>

                    <InlineNotification
                        v-if="alert && alert.message"
                        notificationClass="bx--inline-notification bx--inline-notification--error"
                        kind="error"
                        role="alert"
                        icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                        :title="$t(alert.message)"
                        :subtitle="$t('try_again')"
                        :closeButton="true"
                    />


                    <form class="lk-login-form" @submit.prevent="handleSubmit">
                        <div  class="bx--form-item bx--text-input-wrapper">
                            <input
                              id="text-input-password"
                              type="password"
                              name="password"
                              :placeholder="$t('password')"
                              class="bx--text-input lk-login-form__field"
                              v-model="$v.password.$model"
                              :data-invalid="$v.password.$error"
                            >
                            <label for="text-input-password" class="bx--label lk-login-form__label">{{$t('your_new_password')}}</label>
                            <div v-if="!$v.password.required" class="bx--form-requirement">{{$t('password_required')}}</div>
                            <div v-else-if="!$v.password.minLength" class="bx--form-requirement">{{$t('password_min_length_error', { number: $v.password.$params.minLength.min })}}</div>
                        </div>
                        <div  class="bx--form-item bx--text-input-wrapper">
                            <input
                              id="text-input-password-repeat"
                              type="password"
                              name="passwordRepeat"
                              class="bx--text-input lk-login-form__field"
                              :placeholder="$t('repeat_password')"
                              v-model="$v.passwordRepeat.$model"
                              :data-invalid="$v.passwordRepeat.$error"
                            >
                            <label for="text-input-password-repeat" class="bx--label lk-login-form__label">{{ $t('repeat_password') }}</label>
                            <div class="bx--form-requirement"
                                  v-if="!$v.passwordRepeat.passwordSameAs">
                              {{$t('repeat_password_not_identical')}}
                            </div>
                        </div>
                        <div class="bx--form-item">
                            <button class="bx--btn bx--btn--primary lk-login-form__submit" type="submit" :disabled="isLoading">{{ $t('save_password') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </layout-front>
</template>

<script>
  import 'lingoking-workbench/src/components/login/login.scss'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { default as validations } from '../store/modules/password/reset/validations';
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import LayoutFront from './layouts/LayoutFront.vue'
  import LanguageSelector from "lingoking-workbench/src/components/languageselector/LanguageSelector.vue";

  export default {
    data () {
      return {
        password: null,
        passwordRepeat: null,
        token: null
      }
    },

    computed: {
      ...mapGetters({
        isLoading: 'password/reset/isLoading',
        error: 'password/reset/error',
        created: 'password/reset/created',
        violations: 'password/reset/violations',
        checkSuccess: 'password/token/success',
        checkIsLoading: 'password/token/isLoading'
      }),
      ...mapState({
        alert: state => state.alert
      }),
      errors() {
        return this.violations || {}
      }
    },
    beforeDestroy() {
      this.reset()
    },

    methods: {
      ...mapActions({
        resetCreate: 'password/reset/create',
        resetReset: 'password/reset/reset',
        alertSuccess: 'alert/success',
        alertError: 'alert/error',
        checkToken: 'password/token/check'
      }),
      handleSubmit (e) {
        const { password } = this;
        const token = this.$route.params.token;

        // run validation at least once
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.resetCreate({ password , token})
        }
      },
      reset() {
        this.resetReset()
      }
    },

    watch: {
      // eslint-disable-next-line object-shorthand,func-names
      created: function (created) {
        if (!this.created) {
          return
        }
        this.$router.push({ name: 'Login' })
        setTimeout(() => {
          // display success message after route change completes
          this.alertSuccess('reset_successful', { root: true })
        })
      },
      checkSuccess: function (checkedToken) {
        if(!checkedToken || !checkedToken.token) {
          this.$router.push({ name: 'Login' })

          setTimeout(() => {
            this.alertError('link_invalid', {root: true})
          })
        }
        else {
          this.token = this.$route.params.token
        }
      }
    },

    validations() {
      return validations
    },

    components: {
      LayoutFront,
      InlineNotification,
      Loading,
      LanguageSelector
    },

    created () {
      if(this.$route.params.token) {
        const token = this.$route.params.token
        this.checkToken(token)
      }
    },
  };
</script>
