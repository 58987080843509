<template>
  <div class="bx--row">
    <div class="lk-form-desc bx--col-md-3" v-if="config.projectManager.showLabel">
      {{ $t('project_manager') }}
    </div>
    <div class="bx--col-md-2" style="padding-top: 0.8em" v-if="config.projectManager.showNoPmCheckBox" >
      <input
        id="checkbox-no-specific-pm"
        name="no-specific-pm"
        type="checkbox"
        class="bx--checkbox"
        @change="$emit('toggle-no-pm')"
        :checked="noProjectManager"
        :value="noProjectManager"
      />
      <label for="checkbox-no-specific-pm" class="bx--checkbox-label">No specific PM</label>
    </div>
    <div :class="config.projectManager.showSaveCancelButton ? 'bx--col-md-4' : 'bx--col-md-8 bx--form-item bx--select' ">
      <multiselect
        ref="pms"
        @input="updateProjectManagerField"
        label="email"
        id="multi-select-project-manager"
        track-by="id"
        :value="selectedProjectManager"
        :options="projectManagers || []"
        :hideSelected="false"
        :multiple="false"
        :required="false"
        :taggable="false"
        :show-labels="false"
        :placeholder="$t('project_manager')"
        :tag-placeholder="$t('project_manager')"
        :disabled="this.noProjectManager"
        @open="onOpen"
        @close="isOpen = false"
        @search-change="onSearchChange"
      ></multiselect>
    </div>
    <div class="bx-col-md-1 bx--offset-md-1" v-if="config.projectManager.showSaveCancelButton">
      <button @click="save" class="bx--btn bx--btn--primary">{{ $t('save') }}</button>
      <button @click="$emit('toggle-edit')" class="bx--btn bx--btn--danger">{{ $t('cancel')}}</button>
    </div>
  </div>
</template>
<script>
  import './projectmanagerlist.scss'
  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'ProjectManagerList',
    components: {
      Multiselect
    },
    props: {
      config: Object,
      item: Object, // This is the object that have a pm inside
      noProjectManager: Boolean,
      selectedProjectManager: null,
      required: Boolean,
      updateField: Function,
      toggleEditing: Function,
      save: Function, //The method that will update the object with the new pm in database
      language: String,  // TODO: make this component language configurable
    },
    computed: {
      ...mapGetters({
        projectManagers: 'projectManager/projectManagerList'
      })
    },
    methods: {
      updateProjectManagerField(value) {
        // In case of using vuex we need to use a mutation to change the state.
        // So we ask for a updateField method that should be a mapped mutation
        if (this.updateField) {
          this.updateField(value)

        }
      },
      onOpen() {
        if (this.firstTimeOpen) {
          this.firstTimeOpen = false
          this.searchForProjectManagers()
        }
        this.$refs.pms.updateSearch(this.search)
        setTimeout(() => {
          this.isOpen = true
        }, 30)
      },
      onSearchChange(text) {
        if (this.isOpen) {
          this.search = text
          setTimeout(() => {
            if (text === this.search) {
              this.searchForProjectManagers(text)
            }
          }, 400)
        }
      },
      searchForProjectManagers: function (searchValue) {
        this.getProjectManagerUsers(searchValue)
      },
      ...mapActions({
        getProjectManagerUsers: 'projectManager/getProjectManagerUsers'
      }),
    },
    created() {
      //TODO: refactor this to return the object instead just run a function to fill the state
      this.getProjectManagerUsers()
    },
    mounted() {
      //
    },
    data() {
      return {
        firstTimeOpen: true,
        isOpen: false,
        search: '',
      }
    }
  }

</script>

