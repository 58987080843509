<template>
  <layout-default>
    <Loading v-if="isLoading"/>

    <div>
      <div class="bx--row">
        <div class="bx--col-md-4">
          <h1 class="lk-service-backend__headline">{{ $t('bills') }}</h1>
        </div>
        <div class="bx--offset-md-4 bx--col-md-4">
          <div class="lk--fe--searchbar">
            <div class="bx--search bx--search--sm bx--search--light"
                 data-search role="search">
              <button
                @click="searchItems"
                class="bx--btn bx--btn--ghost bx--btn--sm bx--search__button lk-carbon-icon lk-carbon-icon-search lk-finance-customer-invoices_search-icon">
              </button>
              <input
                class="bx--search-input" id="search__input "
                :placeholder="$t('search_invoices')"
                role="search"
                type="text"
                v-model="searchString" v-on:keyup.enter="searchItems">
            </div>
          </div>
        </div>
      </div>
      <div class="bx--row">

        <div class="bx--col-xs-12 bx--col-md-2">
          <div class="lk-list-view">
            <CheckboxFilter
              @filter="filter"
              :filter-data="filterData"
            />
          </div>
        </div>
        <div class="bx--col-xs-12 bx--col-md-10">
          <FeTable
            :hide-header="totalItems === 0"
            :columns="columns"
            :items="items"
            @sort="sort($event)"
          >
            <template slot="downloadButton" slot-scope="items">
              <JwtDownloadButton
                :btn-class="['bx--table-expand-v2__button', 'lk-data-table-expand__button']"
                :download-url="renderDownloadUrl(items.item)"
                :download-name="renderDownloadName(items.item)">
                <template>
                  <span class="lk-carbon-icon lk-carbon-icon-download-rounded lk-carbon-icon-2point5x"></span>
                </template>
              </JwtDownloadButton>
            </template>
          </FeTable>

          <pagination
            class="lk-container--layer-raised lk-pagination--borderless"
            v-if="totalItems>itemsPerPage"
            :cur-page="currentPage"
            :current-page="currentPage"
            :current-page-change-parent="currentPageChange"
            :get-page="goToPage"
            :items-length="items.length"
            :items-per-page="itemsPerPage"
            :items-per-page-change-parent="itemsPerPageChange"
            :total-items="totalItems"
            :total-pages="totalPages"
            :view="view">
          </pagination>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import LayoutDefault from '../../../layouts/LayoutDefault.vue';
  import CheckboxFilter from 'lingoking-workbench/src/components/checkboxfilter/CheckboxFilter.vue';
  import Pagination from 'lingoking-workbench/src/components/datatable/Pagination.vue';
  import FeTable from "lingoking-workbench/src/components/fetable/FeTable.vue";
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import {ENTRYPOINT} from './../../../../config/entrypoint';
  import JwtDownloadButton
    from 'lingoking-workbench/src/components/jwtdownloadbutton/JwtDownloadButton.vue';
  import {InvoiceStatus} from 'lingoking-workbench/src/enums/index.js';
  import {userService} from "../../../../_services";
// TODO no errors displayed?
  export default {
    name: "List",

    components: {
      LayoutDefault,
      CheckboxFilter,
      Pagination,
      FeTable,
      Loading,
      JwtDownloadButton
    },

    computed: {
      ...mapState({
        //
      }),
      ...mapGetters({
        invoices: 'finance/customerInvoiceList/items',
        currentPage: 'finance/customerInvoiceList/currentPage',
        totalItems: 'finance/customerInvoiceList/totalItems',
        view: 'finance/customerInvoiceList/view',
        items: 'finance/customerInvoiceList/items',
        isLoading: 'finance/customerInvoiceList/isLoading',
      }),

      totalPages() {
        return this.totalItems < this.itemsPerPage ? 1 : Math.ceil(this.totalItems / this.itemsPerPage).toFixed(0);
      }
    },

    created() {
      this.getInvoices(this.invoicesUrl + this.defaultSortParam + '&itemsPerPage=' + this.itemsPerPage + '&page=' + this.curPage);
    },

    data() {
      return {
        apiUrl: ENTRYPOINT,
        invoicesUrl: '/invoices?groups[]=invoice:customer-list&groups[]=timestampable:created&parentInvoice[exists]=false&paymentStatus[exists]=true&partOfCollectiveInvoice=0&orderStatus='+InvoiceStatus.ORDER_DELIVERED.schema+'&',
        defaultSortParam: 'orderBy[createdAt]=desc&',
        itemsPerPage: 10,
        curPage: 1,
        searchString: '',
        searchFields: ['name'],
        columns: [
          {
            label: 'name',
            sortable: true,
            index: 'name',
            header: true
          },
          {
            label: 'order_number',
            sortable: true,
            index: 'confirmationNumber',
            renderFcn: this.renderConfirmationNumber
          },
          {
            label: 'order_date',
            sortable: true,
            index: 'createdAt',
            renderFcn: this.renderDate
          },
          {
            label: 'price',
            sortable: true,
            index: 'price',
            renderFcn: this.renderPrice
          },
          {
            label: '',
            sortable: false,
            index: 'download',
            icon: 'lk-carbon-icon-download-rounded lk-carbon-icon-2point5x',
            custom: true,
            columnSlotName: 'downloadButton'
          }

        ],
        filterData: [
          {
            id: 'filter-cancelled',
            label: 'order_state_cancelled',
            value: 'cancelled=true'
          },
          {
            id: 'filter-order-delivered',
            label: 'order_state_delivered',
            value: 'orderDelivered=orderDelivered'
          },
          {
            id: 'filter-order-in-transit',
            label: 'order_state_transit',
            value: 'orderInTransit=orderInTransit'
          }
        ],
        sortByIndex: '',
        sortDirection: '',
        searchBy: [
          'name',
        ],
        emptyDashboard: true
      }
    },

    methods: {
      ...mapActions({
        getInvoices: 'finance/customerInvoiceList/default',
      }),

      searchItems() {
        this.requestInvoices(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      currentPageChange(newCurrentPage) {
        if (newCurrentPage) {
          this.curPage = newCurrentPage;
        }
        this.requestInvoices(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      goToPage(page) {
        if (page) {
          const user = userService.getUserData();
          const userId = user.id;
          page = page.replace('customers/' + userId + '/', '');
          this.getInvoices(page);
          if (this.items.length < this.itemsPerPage) {
            this.curPage = 1;
          }
        }
      },

      itemsPerPageChange(newItemsPerPage) {
        if (newItemsPerPage) {
          this.itemsPerPage = newItemsPerPage;
        }
        if (this.items.length < this.itemsPerPage) {
          this.curPage = 1
        }
        this.requestInvoices(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection);
      },

      filter(data) {
        let search = '';
        data.forEach(function (item) {
          search += item + "&"
        });
        this.getInvoices(this.invoicesUrl + search + 'itemsPerPage=' + this.itemsPerPage);
      },
      renderConfirmationNumber(item) {
        const confirmationNumber = item.confirmationNumber ? item.confirmationNumber : '';
        return "Best. Nr.: " + confirmationNumber;
      },

      renderPrice(item) {
        return item.totalPrice + " " + item.priceCurrency;
      },

      renderDate(item, field) {
        return this.$moment(item[field]).format("DD.MM.YYYY");
      },

      renderDownloadUrl(item) {
        return '/invoices/' + item.id + '/download_pdf';
      },
      renderDownloadName(item) {
        const timestamp = new Date().getTime();
        return `invoice_${item.name}_${timestamp}.pdf`;
      },

      sort({sortDirection, sortByIndex}) {
        this.sortByIndex = sortByIndex;
        this.sortDirection = sortDirection;
        this.requestInvoices(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      requestInvoices(term, page, itemsPerPage, sortByIndex, sortDirection) {

        if (term !== null && term !== '') {
          let generateUrl = '';
          this.searchBy.forEach(function (item) {
            generateUrl += item + '=' + term + "&"
          });
          term = generateUrl;
        }

        if (sortByIndex !== null && sortDirection !== null && sortByIndex !== '' && sortDirection !== '') {
          sortByIndex = 'orderBy[' + sortByIndex + ']=' + sortDirection + '&';
        } else {
          sortByIndex = this.defaultSortParam;
        }

        if (itemsPerPage !== null) {
          itemsPerPage = 'itemsPerPage=' + itemsPerPage + '&';
        }

        if (page !== null) {
          page = 'page=' + page;
        }

        let generateUrl = this.invoicesUrl + term + sortByIndex + itemsPerPage + page;
        this.getInvoices(generateUrl);
      },
    },

    watch: {
      invoices: function (items) {
        this.emptyDashboard = !(items && items.length);
      }
    },
  }
</script>
