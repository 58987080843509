const JobStatus = {
  DRAFT: 'draft',
  RECRUITING: 'recruiting',
  QUEUED: 'queued',
  REALIZATION: 'realization',
  FINISHED: 'finished',
  COLLECTED: 'collected',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
}

export default JobStatus
