<template>
  <ul>
    <li :class="['lk-data-table-section', row.status ? 'lk-data-table-section--'+ row.status : '']"
        :key="'lk-data-table-section-' + index" v-for="(item, index) in items">
      <div class="bx--row">
        <div class="bx--col-xs-6 bx--col-sm-6 bx--col-md-8 bx--col-lg-6 bx--col-xl-4" v-if="item">
          <!-- Radio option defines the headline of thi section -->
          <table class="lk-data-table-section__table" v-for="orderProductOption in item" v-if="orderProductOption['productOption']['type'] === productOptionFormType.RADIO.type">
            <tr>
              <th class="lk-data-table-section__headline">
                {{ orderProductOption.productOption.label }}
              </th>
            </tr>
          </table>
          <!-- TRANSLATION SECTION WITH LANGUAGE OPTIONS START -->
          <table class="lk-data-table-section__table"
                 v-if="row.sourceLanguage
                       && row.targetLanguages
                       && row.targetLanguages.length
                       && index === productOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_TRANSLATION">
            <tr>
              <th>{{ $t('source_language')}}</th>
              <td class="lk-data-table-section__table-source">{{ row.sourceLanguage.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('target_languages')}}</th>
              <td>
                  <ul class="lk-data-table__col-list">
                    <li class="lk-data-table__col-list__item lk-data-table__col-list__item--link" v-for="target in row.purchaseOrderNumbers">
<!--                      <a class="lk-data-table__col-list__link" :href="target.link" v-if="target.link">-->
                        <span :class="target.icon" v-if="target.icon"></span>
                        <span v-if="target.text">{{ target.text }}</span>
<!--                      </a>-->
                      <span :class="target.icon" v-else-if="target.icon"></span>
                      <span class="lk-data-table__col-list__badge" :class="target.badge" v-if="target.badge && canShowBadge">
                          {{ target.badgeText ? target.badgeText : '' }}
                        </span>
                    </li>
                  </ul>
              </td>
            </tr>
          </table>
          <!-- TRANSLATION SECTION WITH LANGUAGE OPTIONS END -->
          <table class="lk-data-table-section__table"
                 v-else-if="index === productOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_DELIVERY">
            <tr v-if="row.deliveryDate">
              <th>{{ $t('delivery_date_customer') }}</th>
              <td>{{ $moment(row.deliveryDate).format("DD.MM.YYYY") }}</td>
            </tr>
            <tr v-if="row.physicalDelivery && row.trackingCode">
              <th>Tracking-Code</th>
              <td>{{ row.trackingCode }}</td>
            </tr>
          </table>
          <!-- Other options of this category type -->
          <table class="lk-data-table-section__table">
            <tr v-for="orderProductOption in item" v-if="orderProductOption['productOption']['type'] !== productOptionFormType.RADIO.type">
              <th>{{ orderProductOption.productOption.label ? orderProductOption.productOption.label : ''}}</th>
              <td>
                {{ getProductOptionValue(orderProductOption) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </li>
    <li class="lk-data-table-section" v-if="row.chargedWith || row.sendCopyTo || row.downloadFileUrl">
      <div class="bx--row">
        <div class="bx--col-md-4">
          <span class="lk-text__state" v-if="row.chargedWith">
            <span class="lk-text__state--pending">{{ $t('order_from') }}:</span>
            {{ row.chargedWith }}
          </span>
        </div>
        <div class="bx--col-md-5">
          <span class="lk-text__state" v-if="row.sendCopyTo">
            <span class="lk-text__state--pending">{{ $t('after_order_copy_to') }}:</span>
            {{ row.sendCopyTo }}
          </span>
        </div>
        <div class="bx--col-md-3">
          <a :href="row.downloadFileUrl" v-if="row.downloadFileUrl">
            {{ $t('download_offer') }}
            <span class="lk-carbon-icon lk-carbon-icon-download lk-button-icon lk-button-icon--right"></span>
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
  import {ProductOptionCategoryType} from "../../enums";
  import ProductOptionFormType from "../../enums/ProductOptionFormType";

  export default {
    name: 'ProductOptionList',
    components: {},
    props: {
      row: {
        type: Object,
        required: true
      },
      items: {
        type: Object,
        required: true
      },
      canShowBadge: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      //TODO duplicated code (see ProductOrderSummary.vue)
      //TODO other form types
      getProductOptionValue (option) {
        if(option['productOption']['type'] === ProductOptionFormType.CHECKBOX.type) {
          return (/true/i).test(option['productOptionValue']) ? this.$t('yes') : this.$t('no')
        }
        else if(option['productOption']['type'] === ProductOptionFormType.DATE_TIME_INPUT.type) {
          return this.$moment(option['productOptionValue']).format("DD.MM.YYYY, HH:mm")
        }

        return option['productOptionValue']
      }
    },
    data() {
      return {
        productOptionCategoryType: ProductOptionCategoryType,
        productOptionFormType: ProductOptionFormType
      }
    }
  }
</script>
