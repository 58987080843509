<template>
  <div>
    <InlineNotification
      :closeButton="false"
      :title="$t(statusText)"
      :subtitle="$t(statusSubTitle)"
      :icon="statusIcon ? statusIcon : 'lk-carbon-icon lk-carbon-icon-close--glyph'"
      :kind="status"
      :notificationClass="'bx--inline-notification bx--inline-notification--' + status"
      role="alert"
      v-if="status && statusText">
      <template slot="content" v-if="statusContent">
        <div>
          <div class="bx--inline-notification__subtitle" v-if="statusContent === 'tracking-code'">
            <a href="#" v-scroll-to="{ el: '#tracking-code' }">
              {{ $t('enter_tracking_code') }}
            </a>
          </div>
          <div class="bx--inline-notification__content" v-html="$t(statusContent)" v-else></div>
        </div>
      </template>
    </InlineNotification>
    <InlineNotification
      v-if="showClarification"
      :closeButton="false"
      :title="$t(statusText)"
      icon="lk-carbon-icon lk-carbon-icon-warning--glyph"
      kind="warning"
      role="warning"
      notification-class="bx--inline-notification bx--inline-notification--warning"
    >
      <template slot="content">
        <div class="bx--inline-notification__content" v-html="$t('clarification_from_qm') + '<p>' + lastRejectionReason.reason + '</p>'">
        </div>
      </template>
    </InlineNotification>
  </div>
</template>

<script>
  import InlineNotification from "../../notification/InlineNotification.vue";
  import JobStatus from "../../../enums/JobStatus"
  import JobTypes from "../../../enums/JobType";
  import { OrderStatus } from '../../../enums';

  export default {
    name: 'JobDetailViewHeaderStatus',
    components: {
      InlineNotification,
    },
    props: {
      job: {
        type: Object
      }
    },

    data () {
      return {
        statusText: '',
        status: '',
        statusIcon: '',
        statusSubTitle: '',
        statusContent: ''
      }
    },

    created () {
      this.setStatus()
    },

    computed: {
      childJobInProgress () {
        if(this.job.childJobs && this.job.childJobs.length) {
          let childJobInProgress = this.job.childJobs.find(function (childJob) {
            return childJob['state'] === JobStatus.REALIZATION
          })

          if(childJobInProgress) {
            return true
          }
        }
        return false
      },

      showClarification () {
        let clarificationRejectionReason = this.job.rejectionReasons.find(rejectionReason => !rejectionReason.solved && rejectionReason.type === 'clarify');

        return clarificationRejectionReason
          && this.job.order.status === OrderStatus.ORDER_PROCESSING.schema
          && this.job.jobDefinition.type === JobTypes.JOB_TYPE_CUSTOMER_APPROVAL
      },

      lastRejectionReason () {
        let rejectionReasons = this.job.order.rejectionReasons
        return rejectionReasons.length ? rejectionReasons[rejectionReasons.length - 1] : null
      }
    },

    methods: {
      // sets the according status if customer approval job:
      setCustomerStatus: function () {
        // Translation Pending
        if(this.job.status === JobStatus.QUEUED) {
          this.statusText =  'status_pending'
          this.status = 'warning'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-spinner'
          this.statusSubTitle = 'translated_file_not_uploaded'
        }

        // Translation Rejected
        let currentRejectionReason = this.job.createdRejectionReasons.find(rejectionReason => !rejectionReason.solved);

        if(currentRejectionReason) {
          this.statusText = 'status_rejected'
          this.status = 'error'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-warning--glyph'
          this.statusSubTitle = 'waiting_corrections_uploads'
          this.statusContent = this.$t('rejection_reasons') + '<p>' + currentRejectionReason.reason + '</p>'
        }

        // Shipment
        let shipment = this.job.order.jobs.find(job => job instanceof Object && job.jobDefinition.type === JobTypes.JOB_TYPE_SHIPMENT)

        if (shipment && shipment.status === JobStatus.REALIZATION) {
          this.statusText =  'status_pending'
          this.status = 'warning'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-spinner'
          this.statusSubTitle = 'waiting_shipment'
        }
      },

      // sets the according status for translation job
      setTranslatorStatus: function () {
        let currentJob = this.job;
        let qmJob = this.job.order.jobs.find(job => job instanceof Object && job.jobDefinition.type === JobTypes.JOB_TYPE_QM)

        // REALIZATION
        if(currentJob.status === JobStatus.REALIZATION) {
          // Rejection
          let currentRejectionReason = currentJob.rejectionReasons.find(rejectionReason => !rejectionReason.solved);

          if (currentRejectionReason) {
            this.statusText = 'status_rejected'
            this.status = 'error'
            this.statusIcon = 'lk-carbon-icon lk-carbon-icon-warning--glyph'
            this.statusSubTitle = 'upload_corrected_translation'
            this.statusContent = this.$t('rejection_reasons_lsp') + '<p>' + currentRejectionReason.reason + '</p>'

            if(currentRejectionReason.originJob.jobDefinition.type === JobTypes.JOB_TYPE_QM) {
              this.statusSubTitle = 'translation_rejected_pm_not_satisfied'
            }

            return;
          }

          // Child Job In Progress
          if(currentJob.jobDefinition.type === JobTypes.JOB_FINAL_REVISION) {
            this.statusText =  'status_correction_uploaded'
            this.status = 'error'
            this.statusIcon = 'lk-carbon-icon lk-carbon-icon-warning--glyph'
            this.statusSubTitle = 'upload_translation_correction'
            if(this.job.order.rejectReason) {
              this.statusContent = this.$t('rejection_reasons_lsp') + '<p>' + this.job.order.rejectReason + '</p>'
            }
          }

          if(currentJob.jobDefinition.type === JobTypes.JOB_TYPE_SHIPMENT) {
            this.statusText =  'status_shipment_required'
            this.status = 'warning'
            this.statusIcon = 'lk-carbon-icon lk-carbon-icon-warning--glyph'
            this.statusContent = 'tracking_code'
          }
        }

        // FINISHED
        if (currentJob.status === JobStatus.FINISHED && this.job.order.status === OrderStatus.ORDER_PROCESSING.schema) {
          this.statusText =  'status_uploaded'
          this.status = 'warning'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-spinner'

          let currentJob = this.job.order.jobs.find(job => job instanceof Object && job.status === JobStatus.REALIZATION)
          let rejection = this.job.order.rejectionReasons.find(rejectionReason => !rejectionReason.solved);

          if(currentJob) {
            switch (currentJob.jobDefinition.type) {
              case JobTypes.JOB_TYPE_CUSTOMER_APPROVAL:
                this.statusSubTitle = 'lsp_job_state_approval_pending';
                break;
              case JobTypes.JOB_TYPE_CORRECTOR:
                this.statusSubTitle = 'lsp_job_state_corrections_pending';
                break;
              case JobTypes.JOB_TYPE_QM:
                this.statusSubTitle = 'lsp_job_state_acceptance';

                if(rejection) {
                  this.statusSubTitle = 'translation_rejected_wait_for_instructions'
                }
                break;
            }
          }
        }
      },

      // sets the according status for corrector job
      setCorrectorStatus: function () {
        // Pending, waiting for translation
        if (this.job.user && this.job.status === JobStatus.QUEUED) {
          this.statusText =  'status_pending'
          this.status = 'warning'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-spinner'
          this.statusSubTitle = 'translated_file_not_uploaded'
        }
      },

      setQMStatus: function () {
        // Pending, waiting for translation
        if (this.job.user && this.job.status === JobStatus.QUEUED) {
          this.statusText =  'status_pending'
          this.status = 'warning'
          this.statusIcon = 'lk-carbon-icon lk-carbon-icon-spinner'
          this.statusSubTitle = 'translated_file_not_uploaded'
        }
      },

      setStatus: function () {
        if (this.job) {
          switch (this.job.jobDefinition.type) {
            case JobTypes.JOB_TYPE_TRANSLATOR:
            case JobTypes.JOB_FINAL_REVISION:
            case JobTypes.JOB_FINAL_REVISION_QM:
            case JobTypes.JOB_TYPE_SHIPMENT:
              this.setTranslatorStatus()
              break;
            case JobTypes.JOB_TYPE_CORRECTOR:
              this.setCorrectorStatus()
              break;
            case JobTypes.JOB_TYPE_QM:
              this.setQMStatus()
              break;
            case JobTypes.JOB_TYPE_CUSTOMER_APPROVAL:
              this.setCustomerStatus()
              break;
            default:
              break;
          }
        }
      }
    },

    watch: {
      job() {
        this.setStatus()
      }
    }
  }
</script>
