import router from "../index";


export const isActive = function (route) {
  if(!route.name){
    return false;
  }

  if(route.name === router.currentRoute.name){
    return true;
  }

  const subNav = route.children || [];
  return subNav.filter(this.isActive).length > 0;

}
