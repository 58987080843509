import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({ commit }, values) => {
  commit(types.PASSWORD_TOKEN_CREATE_SET_ERROR, '')
  commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)

  const formData = new FormData();
  formData.append("email", values.email);

  return fetch('reset_password', { method: 'POST', data: formData, config: { headers: {'Content-Type': 'multipart/form-data' }} })
    .then((response) => {
      commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)

      return response
    })
    .then(() => {
      commit(types.PASSWORD_TOKEN_CREATE_SET_CREATED, true)
    })
    .catch((e) => {
      commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.PASSWORD_TOKEN_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.PASSWORD_TOKEN_CREATE_SET_ERROR, e.errors.message)
        return
      }

      commit(types.PASSWORD_TOKEN_CREATE_SET_ERROR, e.message)
    })
}

export const check = ({ commit }, token) => {
  commit(types.PASSWORD_TOKEN_CREATE_SET_ERROR, '')
  commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)

  return fetch('reset_password/validate_token/' + token, { method: 'GET' })
    .then((response) => {
      commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)
      return response
    })
    .then((data) => {
      commit(types.PASSWORD_TOKEN_CHECK_SUCCESS, data)
    })
    .catch((e) => {
      commit(types.PASSWORD_TOKEN_CHECK_SUCCESS, e.message)
      commit(types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING)
      commit(types.PASSWORD_TOKEN_CREATE_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.PASSWORD_TOKEN_CREATE_RESET, true)
}
