import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '../components/LoginPage'
import RegistrationPage from '../components/RegistrationPage'
import RegistrationLspPage from '../components/RegistrationLspPage'
import PasswordResetTokenPage from '../components/PasswordResetTokenPage'
import PasswordResetPage from '../components/PasswordResetPage'
import Dashboard from '../components/Dashboard'
import Settings from '../components/settings/Settings';
import SettingsLsp from '../components/settings/SettingsLsp';
import UnsubscribeFeedbackEmailPage from '../components/settings/UnsubscribeFeedbackEmailPage';
import {store} from '../store';
import accountCustomerRoutes from './account'
import lspRoutes from './linguist'
import invoiceRoutes from './invoice'
import creditNotesRoutes from './creditNotes'
import downloadRoutes from './download'
import offerRoutes from './offer'
import jobRoutes from './job'
import orderRoutes from './order'
import {i18n} from '../plugins/i18n';
import {jwtService, userService} from '../_services'

Vue.use(Router);

const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de';
const pageTitleSuffix = ' | Lingoking';

const baseRoutes = [
  {
    name: 'Home',
    path: '/',
    component: Dashboard,
    meta: {
      bodyClass: 'lk-body--dashboard'
    }
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    meta: {
      bodyClass: 'lk-body--dashboard'
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    meta: {
      bodyClass: 'lk-body--login',
      title: 'login_page_title'
    }
  },
  // {
  //   name: 'Register',
  //   path: '/register',
  //   component: RegistrationPage,
  //   meta: {
  //     bodyClass: 'lk-body--registration',
  //     title: 'registration_page_title'
  //   }
  // },
  {
    name: 'Register',
    path: '/register',
    component: RegistrationLspPage,
    meta: {
      bodyClass: 'lk-body--registration-lsp',
      title: 'lsp_registration_page_title'
    }
  },
  {
    name: 'PasswordResetToken',
    path: '/password-token',
    component: PasswordResetTokenPage,
    meta: {
      bodyClass: 'lk-body--password-token',
      title: 'password_token_reset_page_title'
    }
  },
  {
    name: 'PasswordReset',
    path: '/password-reset/:token',
    component: PasswordResetPage,
    meta: {
      bodyClass: 'lk-body--password-reset',
      title: 'password_reset'
    }
  },

  // processed directly
  {
    name: 'EmailConfirmation',
    path: '/email-confirmation/:token'
  },
  {
    name: 'Logout',
    path: '/logout'
  },
  {
    label: 'Einstellungen',
    name: 'Settings',
    path: '/settings',
    component: Settings
  },
  {
    label: 'Einstellungen',
    name: 'SettingsLSP',
    path: '/settings-lsp',
    component: SettingsLsp
  },
  {
    name: 'UnsubscribeFeedbackEmailPage',
    path: '/unsubscribe-feedback-email',
    component: UnsubscribeFeedbackEmailPage,
    meta: {
      title: 'Unsubscribe Feedback Email | Lingoking'
    }
  },
  // otherwise redirect to home
  {
    path: '*',
    redirect: '/'
  }
];

export const router = new Router({
  mode: 'history',
  routes: [
    ...baseRoutes,
    ...accountCustomerRoutes,
    ...lspRoutes,
    ...invoiceRoutes,
    ...offerRoutes,
    ...jobRoutes,
    ...orderRoutes,
    ...creditNotesRoutes,
    ...downloadRoutes
  ]
});


router.beforeEach((to, from, next) => {
  const user = userService.getUserData()
  const loggedIn = user !== null;
  const locale = store.state.locale.locale ? store.state.locale.locale : 'de';

  // const abilities = defineAbilitiesFor(user)
  const publicPages = ['Login', 'Register', 'RegisterLsp', 'PasswordResetToken', 'PasswordReset'];

  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.matched.some(record => record.meta.requiresAuth) || !publicPages.includes(to.name);

  if(to.path === '/login' && loggedIn ) {
    return router.push({path: '/dashboard'})
  }

  i18n.locale = locale
  document.title = to.meta.title ? i18n.t(to.meta.title) + pageTitleSuffix : 'lingoking - Dashboard'

  // logout
  if (to.name === 'Logout') {
    store.dispatch('account/logout').then(() => {
      return router.push('/login'); // go back to login
    })
  }

  // email confirmation
  if (to.name === 'EmailConfirmation') {
    // cannot ask for user, maybe he is not logged in
    store.dispatch('account/confirmation', to.params.token);

  }

  // if loggedin and user is active then check for the next condition
  if (loggedIn && user.active) {
    return next();
  }

  // redirect to account setup if user hasn't activated account
  if (loggedIn && user.active === false && to.name !== 'AccountSetup'&& to.name !== 'AccountSetupLSP') {
    if (user.roles.includes('ROLE_LSP')) {
      return next({name: 'AccountSetupLSP'});
    } else if (user.roles.includes('ROLE_CUSTOMER')) {
      return next({name: 'AccountSetup'});
    }   
  } 
  
  if (authRequired) {
    if (to.meta.customerAuth && loggedIn && !user.roles.includes('ROLE_CUSTOMER')) {
      jwtService.clearToken()
      next('/login')
    } else if (to.meta.lspAuth && loggedIn && !user.roles.includes('ROLE_LSP')) {
      jwtService.clearToken()
      next('/login');
    }
  }

  if (authRequired && !loggedIn) {
    let redirectPath = '?redirectTo=' + decodeURIComponent(window.location.pathname);
    return router.push('/login' + redirectPath)
  }
  next();
});


export default router;
