<template>
  <div
    class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom">
    <div class="bx--row">
      <div class="bx--col-xs-12 bx--col-md-12">
        <h2 class="lk-form__step-headline">{{ $t('bank_account') }}</h2>

        <fieldset>
          <legend>
            {{ $t('account') }}
            <Tooltip
              :hover="true"
              :visible="false"
              direction="left"
              uid="lk-how-to-tip-payment">
              <template slot="label">
              </template>
              <template slot="trigger">
                <span
                  class="lk-button-icon lk-button-icon--left lk-how-to-tip__icon lk-carbon-icon lk-carbon-icon-info--glyph"></span>
              </template>
              <template slot="content">
                <p>{{ config.legend_content }}</p>
              </template>
            </Tooltip>
          </legend>

          <p class="lk-form__desc-text">
            {{ $t('payment_data') }}
          </p>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3">
              {{ $t('enter_payment_data') }}
            </div>
            <div class="bx--col-md-9">
              <div class="bx--row">
                <div class="bx--col-md-3">
                  <div class="bx--form-item bx--select">
                    <label class="bx--label" for="user-payment-method">{{ $t('payment_method') }}</label>
                    <select
                      :data-invalid="isInvalid('paymentMethod')"
                      @input="handleUpdatePaymentMethod($event.target.value)"
                      class="bx--select-input"
                      id="user-payment-method"
                      v-if="paymentMethods"
                      v-model="item.paymentMethod">
                      <option v-bind:value="option" v-for="option in paymentMethods">
                        {{ option }}
                      </option>
                    </select>
                    <svg class="bx--select__arrow" height="5" viewBox="0 0 10 5" width="10">
                      <path d="M0 0l5 4.998L10 0z" fill-rule="evenodd"/>
                    </svg>
                    <div class="bx--form-requirement" v-if="!$v.item.paymentMethod.required">
                      {{ $t('payment_method') }} {{ $t('required') }}
                    </div>
                    <div class="bx--form-requirement" v-if="!$v.item.paymentMethod.inEnum">
                      {{ $t('payment_method') }} {{ $t('required') }}
                    </div>
                    <div class="bx--form-requirement" v-if="getViolation('paymentMethod')">
                      {{ getViolation('paymentMethod') }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item && item.paymentMethod">
                <div class="bx--row" v-if="item.paymentMethod === paymentMethods.PAYMENT_METHOD_PAYPAL">
                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('paypalAddress') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'paypalAddress')"
                        :placeholder="$t('paypal_address')"
                        :value="item.paymentData.paypalAddress"
                        @input="handleUpdatePaymentData('paypalAddress', !!$event.target.value ? $event.target.value : null)"
                        id="paymentdata_paypalAddress"
                        name="paymentdata_paypalAddress"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_paypalAddress">{{ $t('paypal_address') }} *</label>
                      <div class="bx--form-requirement" v-if="!$v.item.paymentData.paypalAddress.required">
                        {{ $t('paypal_address') }} {{ $t('required') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bx--row" v-if="item.paymentMethod === paymentMethods.PAYMENT_METHOD_BANK_TRANSFER">
                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('accountHolder') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'accountHolder')"
                        :placeholder="$t('account_holder_name')"
                        :value="item.paymentData.accountHolder"
                        @input="handleUpdatePaymentData('accountHolder', $event.target.value)"
                        id="paymentdata_accountHolder"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_accountHolder">{{ $t('account_holder_name') }} *</label>
                      <div class="bx--form-requirement" v-if="$v.item.paymentData.accountHolder.$error">
                        {{config.account_holder_required}}
                      </div>
                    </div>
                  </div>

                  <div class="bx--col-md-6"></div>

                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('iban') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'iban')"
                        :placeholder="config.iban_palceholder"
                        :value="item.paymentData.iban"
                        @input="handleUpdatePaymentData('iban', !!$event.target.value ? $event.target.value : null)"
                        id="paymentdata_iban"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_iban">IBAN *  <span style="color:gray">( {{$t('no_spaces')}} )</span></label>

                      <div class="bx--form-requirement" v-if="!$v.item.paymentData.iban.required">
                        {{ $t('iban_required') }}
                      </div>
                      <div class="bx--form-requirement" v-if="!$v.item.paymentData.iban.isValidIBAN">
                        {{ $t('iban_invalid') }}
                      </div>
                    </div>
                  </div>

                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('bic') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'bic')"
                        :placeholder="config.bic_placeholder"
                        :value="item.paymentData.bic"
                        @input="handleUpdatePaymentData('bic', $event.target.value)"
                        id="paymentdata_bic"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_bic">{{ config.bic_label}} *</label>
                      <div class="bx--form-requirement" v-if="!$v.item.paymentData.bic.required">
                        {{ $t('bic_required') }}
                      </div>
                      <div class="bx--form-requirement" v-if="!$v.item.paymentData.bic.isValidBIC">
                        {{ $t('bic_invalid') }}
                      </div>
                    </div>
                  </div>

                </div>


                <div class="bx--row">
                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('taxNumber') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'taxNumber')"
                        :placeholder="$t('tax_number')"
                        :value="item.paymentData.taxNumber"
                        @input="handleUpdateTaxNumber($event.target.value)"
                        id="paymentdata_taxNumber"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_taxNumber">{{ $t('tax_number') }} <span v-if="item.paymentMethod === paymentMethods.PAYMENT_METHOD_PAYPAL">*</span></label>
                      <div class="bx--form-requirement" v-if="$v.item.paymentData.taxNumber.$error">
                        {{ $t('tax_number') }} {{ $t('required') }}
                      </div>
                    </div>
                  </div>

                  <div class="bx--col-md-6">
                    <div class="bx--form-item bx--text-input-wrapper">
                      <input
                        :class="['bx--text-input', 'form-control', isInvalid('vatId') ? 'is-invalid' : '']"
                        :data-invalid="isInvalid('paymentData', 'vatId')"
                        :placeholder="$t('vat_id')"
                        :value="item.paymentData.vatId"
                        @input="handleUpdateVatId($event.target.value)"
                        id="paymentdata_vatId"
                        type="text">
                      <label
                        class="bx--label"
                        for="paymentdata_vatId">{{ $t('vat_id') }}</label>
                      <div class="bx--form-requirement" v-if="$v.item.paymentData.vatId.$error">
                        {{ $t('vat_id') }} {{ $t('required') }}
                      </div>
                    </div>
                  </div>

                  <!-- SmallCompany -->
                  <div class="bx--col-md-12">
                    <div class="bx--form-item bx--checkbox-wrapper bx--checkbox-wrapper--no-margin">
                      <input
                        id="smallcompany"
                        name="smallCompany"
                        type="checkbox"
                        class="bx--checkbox"
                        :disabled="disabledSmallCompany"
                        :checked="item.paymentData.smallCompany"
                        :value="item.paymentData.smallCompany"
                        :data-invalid="isInvalid('paymentData', 'smallCompany')"
                        @change="handleUpdatePaymentData('smallCompany', !item.paymentData.smallCompany)"
                      />
                      <label for="smallcompany" class="bx--checkbox-label lk-login__checkbox-label lk-alignment--left">
                        {{ $t('small_company_label') }}
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
  import '../form/form.scss'

  import Tooltip from './../tooltip/Tooltip.vue'

  export default {
    name: 'PaymentForm',
    components: {
      Tooltip
    },
    props: {
      defaultConfig: {
        type: Object,
        required: true
      },

      $v: {
        type: Object,
        required: true
      },

      item: {
        type: Object,
        required: true
      },

      paymentMethods: {
        type: Object,
        required: true
      },

      getViolation: {
        type: Function,
        required: true
      },

      isInvalid: {
        type: Function,
        required: true
      },

      handleUpdatePaymentData: {
        type: Function,
        required: true
      },

      handleUpdatePaymentMethod: {
        type: Function,
        required: true
      },

      subtitle: {
        type: String
      },

      canDisableSmallCompanyByCountry: {
        type: Boolean,
        required: false,
      }
    },

    computed: {
      config() {
        return this.formConfig[this.formConfig.lang]
      },
      currentSubtitle() {
        return this.subtitle ? this.subtitle : this.formConfig[this.formConfig.lang].subtitle;
      },
      disabledSmallCompany() {
        if (this.item.paymentData.vatId) {
          return true;
        }
        if (this.canDisableSmallCompanyByCountry) {
          return this.item.billingAddress.addressCountry != 'DE';
        }
        return false;
      }
    },

    data() {
      return {
        formConfig: {
          //TODO translations should be handled with vue translations!
          en: {
            title: "Bank account details, tax number and VAT ID",
            subtitle: "5.",
            legend_label: 'Account',
            legend_content: 'Ohne Angabe Ihrer Bankverbindung kann Ihr Auftrag dem Kunden nicht zur Abnahme freigegeben werden.',
            description: 'With your payment details, you can accept jobs but these fields must be completed by the time the job is signed off by the customer. If you reside in Germany, you will see a VAT ID field alongside the tax number. In this case only complete one of the two fields.',
            payment_details: 'Please enter your payment details.',
            paypal_address: 'The paypal address',
            paypal_label: 'Paypal address',
            paypal_required_label: 'Paypal address is required',
            iban_palceholder: 'IBAN',
            bic_placeholder: 'BIC',
            bic_label: 'BIC',
            tax_number: 'Tax Number',
            tax_number_placeholder: 'Tax Number',
            tax_number_required: 'Tax Number is required',
            name_of_account_holder: 'The name of account holder',
            name_of_holder: 'Name of account holder',
            account_holder_required: 'Account holder is required',
            vat_id: 'Vat ID',
            vat_id_placeholder: 'Vat ID',
            vat_id_required: 'Vat ID is required',
          },
          de: {},
          lang: 'en'
        }
      }
    },

    methods: {
      canSetSmallCompanyToTrue(item) {
        const addressCountry = item.billingAddress.addressCountry;
        const vatId = item.paymentData.vatId;

        // if vat ID is set, small company is not possible
        if (vatId) {
          return false;
        }

        // Only lsp from germany can enable small company
        if (this.canDisableSmallCompanyByCountry && addressCountry !== 'DE') {
          return false;
        }
        
        return true;
      },

      handleUpdateTaxNumber(taxNumber) {
        this.handleUpdatePaymentData('taxNumber', !!taxNumber ? taxNumber : null);

        if (!taxNumber) {
          return;
        }

        const smallCompany = this.$v.item.$model.paymentData.smallCompany;

        const canSetSmallCompanyToTrue = this.canSetSmallCompanyToTrue(this.$v.item.$model);

        if (canSetSmallCompanyToTrue && !smallCompany) {
          this.handleUpdatePaymentData('smallCompany', true);
        }
      },

      handleUpdateVatId(vatId) {
        this.handleUpdatePaymentData('vatId', !!vatId ? vatId : null)
      }
    },

    watch: {
      "$v.item.billingAddress.addressCountry": function () {
        const smallCompany = this.$v.item.$model.paymentData.smallCompany;

        const canSetSmallCompanyToTrue = this.canSetSmallCompanyToTrue(this.$v.item.$model);

        if (!canSetSmallCompanyToTrue && smallCompany) {
          this.handleUpdatePaymentData('smallCompany', false);
        }
      }
    },
  }
</script>
