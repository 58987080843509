export const RESET = 'LINGUIST_UPDATE_RESET'
export const SET_ERROR = 'LINGUIST_UPDATE_SET_ERROR'
export const SET_RETRIEVED = 'LINGUIST_UPDATE_SET_RETRIEVED'
export const SET_UPDATED = 'LINGUIST_UPDATE_SET_UPDATED'
export const SET_VIOLATIONS = 'LINGUIST_UPDATE_SET_VIOLATIONS'
export const TOGGLE_LOADING = 'LINGUIST_UPDATE_TOGGLE_LOADING'
export const UPDATE_RETRIEVED = 'LINGUIST_UPDATE_UPDATE_RETRIEVED'
export const TOGGLE__FILE_UPLOAD_LOADING = 'LINGUIST_TOGGLE__FILE_UPLOAD_LOADING'
export const SET_UPLOAD_ERROR = 'LINGUIST_SET_UPLOAD_ERROR'
export const SET_UPLOAD_VIOLATIONS = 'LINGUIST_SET_UPLOAD_VIOLATIONS'
export const SET_UPLOAD_UPDATED = 'LINGUIST_SET_UPLOAD_UPDATED'
