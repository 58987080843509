<template>
  <div class="lk-chat">
    <div class="lk-chat__container" id="chat-container">
      <ul>
        <li v-for="message in chatMessages">
          <div class="bx--grid lk-chat__item">
            <div class="bx--row lk-chat__row" :class="'lk-chat__row--' + message.type ">
              <div class="bx--col-md-6 lk-chat__header" :class="['lk-chat__header--' + message.type, active ? 'lk-chat__header--active' : 'lk-chat__header--inactive']" @click="receiver = message.type == 'reply' ? message.from : null">
                <div class="lk-chat__icon" :class="['lk-chat__icon--' + message.from.type, 'lk-chat__icon--' + message.type]">
                  {{ iconContent(message) }}
                </div>

                <div class="lk-chat__author" :title="fullName(message.from)">
                  {{ fullName(message.from) }}
                  <p v-if="userType(message) === 'customer'">
                    {{ $t('customer') }}
                  </p>
                  <p v-else-if="userType(message) === 'pm'">
                    {{ $t('pm') }}
                  </p>
                  <p v-else>
                    {{ $t('lsp') }}
                  </p>
                  <div class="lk-chat__time">
                    {{ formatDate(message) }}
                  </div>
                </div>

              </div>
              <div class="bx--col-md-6 lk-chat__private-wrapper">
                <div v-if="message.private" class="lk-chat__private" :class="'lk-chat__private--' + message.type">
                  <span v-if="message.type === 'reply'">{{ $t('private_message') }}</span>
                  <span v-else>An: {{message.receiver.firstName }} {{ message.receiver.lastName}}</span>
                  </div>
                  <button v-else-if="admin" class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm" @click="makePrivate(message.id)" type="button">Make private</button>
              </div>
            </div>
            <div class="lk-chat__message" :class="['lk-chat__message--' + message.from.type, 'lk-chat__message--' + message.type]" v-html="formatMessage(message)">
            </div>
          </div>
        </li>
        <li v-if="!messages || !messages.length" >
          <div class="bx--grid lk-chat__item">
            <div class="lk-chat__no-messages">{{ $t('no_messages') }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="lk-chat__form" v-if="active">
      <div v-if="receiver" class="bx--grid lk-chat__private-message">
        {{ $t('private_message_to') }} {{ receiver.firstName }} {{ receiver.lastName }}
        <button @click="receiver = null" data-notification-btn class="bx--inline-notification__close-button" type="button" aria-label="close">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;" xmlns="http://www.w3.org/2000/svg" class="bx--inline-notification__close-icon" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true"><path d="M12 4.7L11.3 4 8 7.3 4.7 4 4 4.7 7.3 8 4 11.3 4.7 12 8 8.7 11.3 12 12 11.3 8.7 8z"></path></svg>
        </button>      
      </div>
      <label class="bx--visually-hidden" for="chat-textarea">{{ $t('your_message') }}</label>
      <textarea id="chat-textarea" class="lk-chat__text bx--text-area" :placeholder="$t('write_a_message')" v-model="message"></textarea>
      <button class="lk-chat__submit bx--btn bx--btn--primary" @click="postMessage" type="button">Okay</button>
    </div>
    <div v-else data-notification class="lk-chat__inactive bx--toast-notification bx--toast-notification--warning" role="alert">
      <div class="bx--toast-notification__details">
        <h3 class="bx--toast-notification__title">{{ $t('chat_deactivated') }}</h3>
        <p class="bx--toast-notification__subtitle">{{ $t('chat_deactivated_info') }}</p>
      </div>

    </div>
    <div v-show="users && users.length > 0 && active" class="chat-users">
      <p>Send private message to:</p>
      <ul class="chat-users-list">
        <li 
          v-for="user in users"
          :key="user.id"
          :class="(receiver ? receiver.id : null) == user.id ? 'active': ''"
          @click="receiver = ((receiver ? receiver.id : null) == user.id ? null : user)"
        >
          {{ fullName(user) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
  .lk-chat {
    background: #FFF;

    .chat-users {
      padding: 4px;
      &-list {
        display: flex;
        flex-wrap: wrap;
        li {
          border: 1px solid #ddd;
          font-size: 11px;
          color: #152935;
          font-weight: bold;
          padding: 3px 6px;
          border-radius: 6px;
          margin-right: 4px;
          &:hover, &.active {
            cursor: pointer;
            background: #f0f0f0;
          }
        }
      }
    }

    p {
      font-size: .5rem;
    }

    &__container {
      overflow-y: auto;
      max-height: 450px;
      padding-bottom: 24px;
    }
    &__item {
      margin-top: 24px;
    }
    &__row {
      &--reply {
        flex-direction: row-reverse;
      }
    }
    &__header {
      &--reply {
        direction: rtl;
        cursor: pointer;
      }

      &--inactive {
        cursor: inherit;
      }
    }
    &__icon {
      height: 32px;
      width: 32px;
      text-align: center;
      border-radius: 50%;
      background: #000;
      color: #FFF;
      line-height: 32px;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-right: 8px;

      &--corrector {
        background: red;
      }

      &--translator {
        background: blue;
      }

      &--stuff {
        background: purple;
      }

      &--reply {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    &__author {
      font-size: 12px;
      color: #152935;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 44px);
      direction: ltr;
    }
    &__time {
      color: #8897a2;
      font-weight: normal;
    }
    &__private-wrapper {
      display: flex;
    }
    &__private {
      font-size: 12px;
      color: #152935;
      margin-left: auto;
      align-self: center;
    }
    &__private--reply {
      margin-left: 0;
    }
    &__no-messages {
      margin-top: 12px;
      background: #F4F7FB;
      padding: 8px;
      color: #152935;
      font-size: 14px;
      line-height: 24px;
      position: relative;
    }
    &__message {
      margin-top: 12px;
      background: #F4F7FB;
      padding: 8px;
      color: #152935;
      font-size: 14px;
      line-height: 24px;
      position: relative;

      &:after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #F4F7FB;
        border-width: 8px;
        margin-left: -8px;
      }

      &--reply:after {
        left: auto;
        right: 10%;
      }
    }

    &__form {
      position: relative;
    }

    &__submit {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }

    &__text {
      padding-right: 88px;
    }

    &__inactive {
      width: 100%;
    }
    &__private-message {
      font-size: 12px;
      color: #1F9DD9;
      padding-bottom: 4px;
    }
  }

  /* ~ emoji */
  .joypixels {
    width: 16px;
    height: 16px;
  }

  .lk-chat__private-wrapper {
    display: flex;
    align-items: center;
  }
</style>

<script>
    import {bus} from "../../util/bus.js"
    import TimeAgo from 'javascript-time-ago';
    import de from 'javascript-time-ago/locale/de';
    import escapeGoat from 'escape-goat'
    import Autolinker from 'autolinker'
    const fmt = require('msgdown');
    import emojiToolkit from 'emoji-toolkit';

    export default {
        name: 'JobDetailViewChat',
        props: {
            messages: {
                type: Array
            },
            users: {
                type: Array
            },
            active: {
                type: Boolean
            },
            admin: {
                type: Boolean
            },
            updateReceiverMethod: {
              type: Function,
              required: false
            }
        },
        data() {
            return {
                chatMessages: this.messages,
                message: "",
                receiver: null
            }
        },
        watch: {
          messages: {
            handler(newMessages) {
              this.chatMessages = [...newMessages];
            },
            deep: true
          }
        },
        created: function() {
            TimeAgo.addLocale(de)
        },
        updated: function () {
            // ~ scroll down if there are new messages
            this.$nextTick(function () {
                const chatContainer = this.$el.querySelector("#chat-container");
                chatContainer.scrollTop = chatContainer.scrollHeight;
            })
        },
        methods: {
            postMessage() {
               if (this.message.trim() === "") return;
               bus.$emit("postMessage", this.message, this.receiver);
               this.message = "";
            },
            formatDate(message) {
                const timeAgo = new TimeAgo('de-DE');
                const msgDate = new Date(message.createdAt * 1000);
                return timeAgo.format(msgDate);
            },
            fullName(from) {
                const lastName = from.lastName;
                const strLastName = lastName.split("");
                return from.firstName + " " + strLastName[0] + ".";
            },
            userType(message) {
              const user_type = message.from.type;
              if(user_type === 'customer') {
                return 'customer'
              } 
              if(user_type === 'staff') {
                return 'pm'
              }

              return 'lsp';
            },
            iconContent(message) {
                return message.from.firstName.charAt(0) + message.from.lastName.charAt(0)
            },
            formatMessage(message) {
                const escaped = escapeGoat.htmlEscape(message.message);
                return Autolinker.link(emojiToolkit.toImage(fmt(escaped)), {
                    className: 'chatLink',
                    truncate: {length: 50, location: 'smart'}
                });
            },

            makePrivate(messageId) {
              this.updateReceiverMethod(messageId)
                .then(updatedMessages => {
                  this.chatMessages = [...updatedMessages];
                })
                .catch(error => {
                  console.error('Error making message private:', error);
                });
            },
        }
    }
</script>
