<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.download();
  },
  computed: {
    mediaData() {
      let query = this.$router.history.current.query;
      return {
        resourceId: query.resourceId,
        name: query.name,
      };
    },
  },
  methods: {
    ...mapActions({
      downloadMediaObject: "mediaobject/show/download",
    }),

    download() {
      if (this.mediaData.resourceId && this.mediaData.name) {
        this.downloadMediaObject(this.mediaData);
      }
    },
  },
};
</script>