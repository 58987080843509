<template>
  <div>
    <Loading v-if="invoicesIsLoading || messagesIsLoading"/>
    <div class="bx--row" v-if="emptyDashboard">
      <div class="bx--col-md-12">
        <div class="lk-empty-page-content-wrapper">
          <div class="lk-empty-page-content">
            <div class="lk-empty-page-content__image-wrapper">
              <img src="../../assets/images/lk-empty-dashboard.png" alt="Lingoking Empty Dashboard"
                   class="lk-empty-page-content__image"/>
            </div>
            <div class="lk-empty-page-content__teaser">
              <h2 class="lk-empty-page-content__headline">
                {{ $t('first_order') }}
              </h2>
              <p class="lk-empty-page-content__text">
                {{ $t('empty_help1') }} <a :href="shop_url + $t('help_link')"
                                           class="lk-empty-page-content__link">FAQ</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!invoicesIsLoading && !messagesIsLoading">
        <h1 class="lk-service-backend__headline">Dashboard</h1>
      </div>

      <div class="bx--col-xl-6" v-if="invoices && invoices.length">
        <div class="lk-service-backend__dashboard-item">
          <div class="lk-service-backend__dashboard-headline-wrapper">
            <span class="lk-carbon-icon lk-carbon-icon-contact lk-service-backend__dashboard-icon"></span>
            <h3 class="lk-service-backend__dashboard-headline">{{ $t('recent_orders') }}</h3>
          </div>
          <ul class="lk-service-backend__dashboard-list">
            <li class="lk-service-backend__dashboard-list-item lk-service-backend__dashboard-list-item--no-message"
                v-for="invoice in invoices" :key="invoice.id">
              <router-link
                :to="{name: 'InvoiceShow', params: { id: invoice['@id'] , locale: locale.locale}}">
                <div class="lk-service-backend__dashboard-second-row">
                  <p class="lk-service-backend__dashboard-list-link">
                    {{ invoice.confirmationNumber }} - {{ invoice.firstOrderName }} {{ invoice.referencesOrders && invoice.referencesOrders.length > 1 ? '(...)' : '' }}
                  </p>
                  <div class="lk-service-backend__dashboard-link-wrapper">
                    <span class="lk-service-backend__dashboard-list-charges" v-if="invoice.totalPaymentDue">
                      {{ invoice.totalPaymentDue }}
                    </span>
                    <span class="lk-carbon-icon lk-carbon-icon-chevron--right lk-service-backend__dashboard-list-icon"></span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          <router-link
            :to="{name: 'InvoiceList'}"
            :title="$t('show_all')"
            class="bx--btn bx--btn--secondary bx--btn--sm lk-service-backend__dashboard-item-button">
            {{ $t('show_all') }}
          </router-link>
        </div>
      </div>

      <div class="bx--col-xl-6" v-if="messages && messages.length">
        <div class="lk-service-backend__dashboard-item">
          <div class="lk-service-backend__dashboard-headline-wrapper">
            <span class="lk-carbon-icon lk-carbon-icon-contact lk-service-backend__dashboard-icon"></span>
            <h3 class="lk-service-backend__dashboard-headline">{{ $t('messages') }}</h3>
          </div>
          <ul class="lk-service-backend__dashboard-list">
            <li class="lk-service-backend__dashboard-list-item lk-service-backend__dashboard-list-item--no-message"
                v-for="(message, index) in messages" :key="index">
              <router-link
                :to="{name: 'OrderShow', params:{id: '/orders/' + message.orderId}}">
                <div class="lk-service-backend__dashboard-second-row">
                  <p class="lk-service-backend__dashboard-list-link">
                    {{ message.confirmationNumber + ' - ' + message.orderName }}</p>
                  <div class="lk-service-backend__dashboard-link-wrapper">
                    <span
                      class="lk-carbon-icon lk-carbon-icon-chevron--right lk-service-backend__dashboard-list-icon"></span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import {mapState, mapGetters, mapActions} from 'vuex'
import LayoutDefault from '../layouts/LayoutDefault.vue'
import InlineLoading from 'lingoking-workbench/src/components/loading/LoadingInline.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import {SHOP_URL} from '../../config/entrypoint'

export default {
  computed: {
    ...mapState({
      account: state => state.account,
      locale: state => state.locale
    }),
    ...mapGetters({
      invoiceError: 'invoice/list/error',
      invoices: 'invoice/list/items',
      invoicesIsLoading: 'invoice/list/isLoading',
      invoicesView: 'invoice/list/view',
      messages: 'message/messages',
      messagesIsLoading: 'message/isLoading'
    }),
  },
  created() {
    const url = '/invoices?page=1&itemsPerPage=3&orderStatus=http://schema.org/OrderProcessing&groups[]=invoice:customer-dashboard'
    this.getInvoices(url) // get invoices
    this.getAllMessages(this.account.user.id)
  },
  components: {
    Loading,
    LayoutDefault,
    InlineLoading
  },
  data() {
    return {
      emptyDashboard: false,
      shop_url: SHOP_URL,
    }
  },

  watch: {
    invoices: function (items) {
      this.emptyDashboard = !(items && items.length)
    }
  },

  methods: {
    ...mapActions({
      getInvoices: 'invoice/list/default',
      getAllMessages: 'message/getAllCustomerMessages'
    }),
  }
};
</script>
