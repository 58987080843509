<template>
  <layout-front>
    <div class="lk-login lk-container--inner">
      <div class="lk-login__header bx--row">
        <div class="bx--col-lg-4">
          <!--<router-link :to="{ name: 'Register' }" class="bx--btn bx--btn--primary" type="button">
            TODO: TRANSLATION
            {{$t('register_as_customer')}}
          </router-link>-->
        </div>
        <div class="bx--col-lg-8 lk-login__header-content lk-alignment--right">
          <router-link :to="{ name: 'Login' }" class="lk-login__button-label">
            {{$t('account_already')}}
          </router-link>
          <router-link :to="{ name: 'Login' }" class="bx--btn bx--btn--tertiary bx--btn--sm lk-login__button">
            {{$t('login_now')}}
          </router-link>
          <LanguageSelector></LanguageSelector>
        </div>
      </div>
      <div class="lk-login__content bx--row">
        <div class="bx--col-md-12 bx--col-lg-12 bx--col-xl-10 bx--col-xxl-5 lk-alignment--center lk-margin--center">
          <h1 class="lk-login__headline">{{$t('register_header')}}</h1>
          <h2 class="lk-login__sub-headline">{{$t('register_subheader')}}</h2>

          <Loading
            v-if="isLoading"
            class="alert alert-info"
            role="status">
          </Loading>

          <InlineNotification
            v-if="alert && alert.message && alert.type === 'alert-danger'"
            notificationClass="bx--inline-notification bx--inline-notification--error"
            kind="error"
            role="alert"
            icon="lk-carbon-icon lk-carbon-icon-close--glyph"
            :title="alert.message"
            :subtitle="$t('register_subtitle_error')"
            :closeButton="true"
          />

          <RegistrationLspPage
            :$v="$v"
            :handleSubmit="handleSubmit"
            :getViolation="getViolation"
            :isLoading="isLoading"
            :optOut="optOut"
            @optOut="updateOptOut"
            :linkShop="linkShop"
          >
            <template #extra>
              <vue-friendly-captcha
                :key="captchaKey"
                :sitekey="sitekey"
                :puzzleEndpoint="puzzleEndpoint"
                style="width:100%;"
                @done="saveRecaptchaToken"
              />
            </template>
          </RegistrationLspPage>
        </div>
      </div>
    </div>
  </layout-front>
</template>

<script>
  import 'regenerator-runtime/runtime'
  import 'lingoking-workbench/src/components/login/login.scss'
  import 'lingoking-workbench/src/components/form/form.scss'
  import { mapState, mapActions} from 'vuex'
  import {email, required, minLength, maxLength, sameAs, helpers} from 'vuelidate/lib/validators'
  import {inArray} from './../validators'
  import LayoutFront from './layouts/LayoutFront.vue'
  import UserTypes from 'lingoking-workbench/src/enums/UserTypes'
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import RegistrationLspPage from "lingoking-workbench/src/components/registration/lsp/RegistrationLspPage";
  import LanguageSelector from "lingoking-workbench/src/components/languageselector/LanguageSelector.vue";
  import VueFriendlyCaptcha from '@somushq/vue-friendly-captcha';
  import { SHOP_URL, FRIENDLY_CAPTCHA_SITEKEY, FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT } from '../config/entrypoint';

  export default {

    data() {
      return {
        userTypes: UserTypes,
        userType: UserTypes.TYPE_LINGUIST,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordRepeat: '',
        optOut: false,
        linkShop: SHOP_URL,
        sitekey: FRIENDLY_CAPTCHA_SITEKEY,
        puzzleEndpoint: FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT,
        captchaKey: 'captchaKey',
        recaptchaToken: null
      }
    },

    validations: {
      userType: {
        required,
        inEnum: inArray(Object.values(UserTypes))
      },
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email,
        checkViolations(value) {
          if (value === '') return true;
          return !this.getViolation('email')
        }
      },
      password: {
        required,
        minLength: minLength(8)
      },
      passwordRepeat: {
        passwordSameAs: sameAs('password')
      }
    },

    computed: {
      ...mapState('account', ['status', 'violations', 'isLoading', 'created']),
      ...mapState({
        alert: state => state.alert
      }),
      errors() {
        return this.violations || {}
      }
    },

    watch: {
      // eslint-disable-next-line object-shorthand,func-names
      created: function (created) {
        // no idea why the var created is not set/passed
        // use this.created instead works but be aware of race conditions
        if (!this.created) {
          return
        }

        this.$router.push({name: 'Login', params: {email: created['email']}});

        setTimeout(() => {
          // display success message after route change completes
          this.registerAlertSuccess()
        })
      },

      errors(errors) {
        if (Object.keys(errors).length) {
          this.resetRecaptcha()
        }
      },
    },

    beforeDestroy() {
      this.reset()
    },

    methods: {
      ...mapActions('account', ['register', 'registerReset', 'registerAlertSuccess', 'registerAlertError']),
      resetRecaptcha() {
        this.captchaKey = 'recaptcha' + Date.now()
        this.recaptchaToken = null
      },
      saveRecaptchaToken(solution) {
        this.recaptchaToken = solution
      },
      async handleSubmit() {
        const {userType, firstName, lastName, email, password, optOut} = this;

        // reset violations (come from api)
        this.registerReset();

        // be sure to run validation at least once
        this.$v.$touch();

        if (!this.$v.$invalid) {

          if (!this.recaptchaToken) {
            this.registerAlertError(this.$t('invalid_captcha'));

            return;
          }

          this.register({
            user: {userType, firstName, lastName, email, password, optOut, recaptchaToken: this.recaptchaToken},
            options: {path: 'linguists'}
          });
        }
      },
      getError(key) {
        return Object.keys(this.errors).length > 0 && this.errors[key]
      },
      getViolation(key) {
        return Object.keys(this.errors).length > 0 && this.errors[key]
      },
      isInvalid(key) {
        return this.$v[key].error || this.getError(key)
      },
      reset() {
        this.registerReset()
      },
      updateOptOut(value) {
        this.optOut = value;
      }
    },

    components: {
      LayoutFront,
      Loading,
      InlineNotification,
      RegistrationLspPage,
      LanguageSelector,
      VueFriendlyCaptcha
    }
  }
</script>
