import fetch from '../../../../../utils/fetch';
import * as types from './mutationTypes';
import {userService} from "../../../../../_services";

const getItems = ({commit}, page = '/invoices') => {
  commit(types.toggleLoading);
  const user = userService.getUserData()
  if (user &&  user.id) {
    page = '/customers/' + user.id + page;
    fetch(page)

      .then((data) => {
        commit(types.toggleLoading);
        commit(types.setItems, data['hydra:member']);
        commit(types.setViews, data['hydra:view']);
        commit(types.setTotalItems, data['hydra:totalItems']);

        if (data['hydra:view'] && data['hydra:view']['@id']) {
          const pageParams = new URLSearchParams(data['hydra:view']['@id']);
          const currentPage = pageParams.get('page');
          if (currentPage) {
            commit(types.setCurrentPage, currentPage)
          }
        }
      })
      .catch((e) => {
        commit(types.toggleLoading);
        commit(types.setError, e.message);
      });
  }
};

export default getItems
