export default {
  de: 'Deutsch',
  en: 'Englisch',
  es: 'Spanisch',
  german: 'Deutsch',
  english: 'Englisch',
  spanish: 'Spanisch',
  language: 'Sprache',
  save: 'Speichern',
  confirm: 'Bestätigen',
  cancel: 'Abbrechen',
  close: 'Schließen',
  delete: 'Löschen',
  error: 'Fehler',
  sorry: 'Entschuldigung!',
  yes: 'ja',
  no: 'nein',
  clarification_from_qm: 'Rückmeldung von lingoking',
  select_from_list: 'Bitte {item} aus der Liste auswählen',
  settings_language: 'Spracheinstellungen',
  settings_language_tooltip: 'Sprache, die in Ihren E-Mails verwendet wird',
  notifications: 'Benachrichtigungen',
  settings_notifications: 'Benachrichtigungseinstellungen',
  settings_notifications_tooltip: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  newsletter: 'Newsletter',
  success: 'Vielen Dank!',
  settings_update_success: 'Ihre Einstellungen wurden erfolgreich gespeichert',
  try_again: 'Bitte versuchen Sie es nochmal später.',
  welcome: 'Willkommen',
  your_language: 'Ihre Sprache',
  filter_by: 'Filtern nach',
  clear_all: 'Alle entfernen',
  error_occurred: 'Ups. Hier ist wohl etwas schiefgelaufen.',
  invoice: 'Rechnung | Rechnungen',
  credit_note: 'Gutschrift | Gutschriften',
  name: 'Name',
  service: 'Service',
  receipt_number: 'Auftragnummer',
  creation_date: 'Erstellungsdatum',
  ccn_number: 'CCN number',
  ccn_amount: 'Gutschrift',
  account_not_enabled: 'Account ist gesperrt. Bitte kontaktieren Sie den Support.',
  customer: 'Kunde',
  lsp: 'Linguist',
  pm: 'lingoking Support',
  project_manager: 'Projektmanager',
  invalid_data: 'Bitte prüfen Sie das Formular auf ungültige Daten',
  select_timezone: 'Bitte wählen Sie eine Zeitzone aus.',
  timezone: 'Zeitzone',
  your_timezone: 'Ihre Zeitzone',
  register_as_lsp: 'Als Dienstleister registrieren',
  register_as_customer: 'Als Kunde registrieren',
  generate_shipment_sheet_pdf: 'Lieferschein generieren (pdf)',
  are_you_sure_confirm_job_execution: 'Sind Sie sicher, dass Sie die Ausführung des Jobs bestätigen wollen?',

  // Registration Pages
  registration_page_title: 'Jetzt registrieren/Registrierung',
  login_now: 'Jetzt anmelden',
  account_already: 'Besitzen Sie bereits einen Account?',
  register_header: 'In wenigen Schritten loslegen',
  register_subheader: 'Registrieren Sie sich jetzt als LSP und nutzen Sie unsere Angebote!',
  register_subtitle_error: 'Wir bitten Sie ihre eingegebenen Daten erneut zu prüfen und es erneut zu Versuchen. Vielen Dank!',
  business_customer: 'Geschäftskunde',
  private_customer: 'Privatkunde',
  first_name: 'Vorname',
  first_name_required: 'Vorname wird benötigt',
  first_name_too_short: 'Vorname zu kurz',
  last_name: 'Nachname',
  last_name_required: 'Nachname wird benötigt',
  last_name_too_short: 'Nachname zu kurz',
  repeat_email: 'E-Mail-Adresse wiederholen',
  repeat_email_required: 'Das Feld „E-Mail-Adresse wiederholen“ muss ausgefüllt sein',
  repeat_password: 'Passwort wiederholen',
  repeat_password_required: 'Das Feld „Passwort wiederholen“ muss ausgefüllt sein',
  repeat_password_not_identical: 'Passwort wiederholen nicht identisch mit Passwort',
  opt_out: 'Ich bin nicht an Neuerungen zu lingoking-Produkten und -Services interessiert.',
  opt_out_privacy: 'Datenschutzrichtlinien',
  privacy_link: '/de/allgemeines/datenschutz',
  terms_of_use_p1: 'Durch Klicken auf „Registrierung abschließen“ stimme ich den ',
  terms_of_use_p2: 'von lingoking zu.',
  terms_of_use: 'Nutzungsbedingungen',
  terms_of_use_lsp_dashboard: 'Nutzungsbedingungen',
  of_lingoking: 'von Lingoking',
  terms_of_use_link: '/de/allgemeines/agb',
  help_link: '/de/allgemeines/hilfe-kunden',
  help_linkLSP: '/de/allgemeines/hilfe-dienstleister',
  gtc_linkLSP: '/de/agb',
  register_complete: 'Registrierung abschließen',
  required: 'wird benötigt',
  too_short: 'ist zu kurz',
  login_problem_classic1: 'Schwierigkeiten beim Log-in?',
  login_problem_classic2: 'Wir bauen gerade um - Ihre alten Aufträge finden Sie unter ',
  login_problem_classic3: 'Bei Fragen schreiben Sie uns gerne im Chat!',

  login_page_title: 'Jetzt einloggen',
  no_account: 'Noch keinen Account?',
  register_now: 'Jetzt Registrieren',
  login_header: 'LSP Jetzt anmelden',
  login_subheader: 'Geben Sie Ihre Anmeldedaten in den Feldern ein.',
  your_email: 'E-Mail-Adresse',
  password: 'Passwort',
  email: 'E-Mail',
  email_required: 'E-Mail wird benötigt',
  email_valid: 'Das Format der E-Mail-Adresse ist ungültig',
  password_required: 'Passwort wird benötigt',
  min_length_error: 'Dieser Wert ist zu kurz. Es sollte {number} Zeichen oder mehr haben.',
  password_min_length_error: 'Dieses Passwort ist zu kurz. Es sollte {number} Zeichen oder mehr haben.',
  forgot_password: 'Passwort vergessen?',
  login: 'Einloggen',
  profile_updated: 'Dein Profil wurde aktualisiert.',
  favorites_updated: 'Ihre Wunsch-Dienstleister wurden aktualisiert.',
  customized_service_title: 'Individuelle Lösung',
  customized_service: 'Diese individuellen Services haben wir speziell für Sie entwickelt.',
  customized_service_company_title: 'Individuelle Lösungen Ihrer Firma',
  customized_service_company: 'Die folgenden Services haben wir individuell für Ihre Firma entwickelt.',
  invalid_captcha: 'Ungültiges captcha',

  // Registration LSP
  lsp_registration_page_title:  'LSP Registrierung',
  welcome_title: 'Willkommen bei lingoking - Vervollständigen Sie Ihr Profil in vier einfachen Schritten',
  welcome_description: 'Vielen Dank für Ihr Interesse an einer Zusammenarbeit mit lingoking. Bitte nehmen Sie sich ein paar Minuten Zeit, um Ihr Profil und Ihre Registrierung zu vervollständigen. Hier erfahren Sie, wie Sie eine erfolgreiche Bewerbung einreichen können und welche technischen Voraussetzungen für die Registrierung bestehen (Browser, Browsereinstellungen). Sie können Ihre Registrierung in vier einfachen Schritten abschließen. Bitte geben Sie Ihre Kontaktdaten, Ihre beruflichen Fähigkeiten und Qualifikationen sowie die entsprechenden Unterlagen an. Bitte beachten Sie, dass Sie Aufträge erst dann annehmen können, nachdem Ihre Kompetenzen geprüft wurden. Registrierungs-Checkliste Für die Registrierung sind folgende Dokumente erforderlich:',
  currenct_contact_details: 'Aktuelle Kontaktinformationen',
  skills: 'Kompetenzen (Arten von Sprachdienstleistungen, Sprachen, Fachgebiete)',
  proof_of_qualification: 'Qualifizierungs-, Zertifizierungs- und Akkreditierungsnachweis, z.B. Übersetzungs- oder Dolmetscherzertifikate (mit Endnote), Kundenreferenzen und andere Unterlagen, die Ihre Erfahrung im Übersetzen und Dolmetschen belegen, sowie Nachweise für Mitgliedschaften in entsprechenden Verbänden.',
  photo_optional: 'Foto (optional)',
  account_details: 'Kontoinformationen',
  contact_details: '1. Ihre Kontoinformationen',
  contact: 'Kontakt',
  phone_description: 'Ihre Telefonnummer',
  phone: 'Telefonnummer',
  mobile_phone: 'Mobilfunknummer',
  mobile_phone_description: 'Ihre Mobilfunknummer',
  fax_number: 'Faxnummer',
  fax_number_description: 'Ihre Faxnummer',
  skills_title: 'Kompetenzen',
  skills_placeholder: 'Fügen Sie Ihre Kompetenzen hinzu',
  please_note: 'Bitte beachten Sie',
  skill_warning: 'Ihre Kompetenzen müssen zuerst von lingoking geprüft werden, bevor Ihnen ein geeigneter Auftrag angeboten wird.',
  your_skills: '2. Ihre Kompetenzen',
  skills_desc: 'Als lingoking Sprachdienstleister haben Sie die Möglichkeit, als Übersetzer, Dolmetscher oder Telefondolmetscher zu arbeiten. Bitte geben Sie an, welche Sprachdienstleistungen Sie anbieten möchten. In den nächsten Schritten können Sie weitere Details hinzufügen. Sie können eine oder mehrere Kompetenzen angeben.',
  native_language: 'Muttersprache',
  native_languages: 'Muttersprachen',
  native_language_desc1: 'Bitte wählen Sie Ihre Muttersprache aus.',
  native_language_desc2: 'Bitte angeben, wenn Sie mehr als eine Muttersprache haben.',
  native_language_placeholder: 'Muttersprachen suchen und hinzufügen.',
  native_language_error: 'Es wird mindestens eine Muttersprache benötigt.',
  application_documents: '3. Ihre Bewerbungsunterlagen',
  curriculum_vitae: 'Lebenslauf',
  upload_curriculum_vitae: 'Bitte laden Sie Ihren Lebenslauf hoch.',
  documents_allowed: 'Nur Dokumente erlaubt (msword, ms-excel, ms-powerpoint, .txt, .pdf, .jpeg, .png).',
  select_files: 'Dateien auswählen',
  select_file: 'Datei auswählen',
  delete_file_success: 'Datei wurde erfolgreich gelöscht.',
  upload_file_success: 'Datei wurde erfolgreich hochgeladen.',
  only_jpg: 'nur .jpg und .png Dateien. 500kb max Dateigröße.',
  max_file_upload_size_mb: 'MB max Dateigröße.',
  references: 'Referenzen / Zeugnisse',
  upload_references: 'Bitte laden Sie mindestens eine Referenz oder ein Zeugnis hoch.',
  profile_summary: '4. Ihre Profil-Zusammenfassung',
  profile_photo: 'Profilbild',
  profile_photo_desc: 'Sie können Ihr öffentliches Profil durch das Hochladen eines Profilbildes erweitern, dies ist jedoch optional. Bitte beachten Sie, dass das von Ihnen hochgeladene Profilbild in Ihrem öffentlichen Profil sichtbar ist.',
  profile_photo_desc2: 'Wichtig: Um Ihre Anmeldung an lingoking zu übermitteln, scrollen Sie bitte zum Ende der Seite und klicken Sie auf die Schaltfläche "Registrierung abschließen". Bitte beachten Sie auch die Datenschutzerklärung sowie unsere AGB. Schreiben Sie gerne etwas über sich selbst und teilen es so den Kunden mit. Bitte geben Sie keine Daten wie Ihren vollständigen Namen, Ihre E-Mail-Adresse oder Telefonnummer ein, die Sie persönlich identifizieren könnten.',
  bank_account: '5. Bankdaten, Steuernummer und Umsatzsteuer-ID',
  account: 'Konto',
  payment_data: 'Nach Eingabe Ihrer Zahlungsdaten können Sie Aufträge annehmen. Bitte beachten Sie, dass diese Felder bis zur Freigabe des Auftrags durch den Kunden ausgefüllt sein müssen. Wenn Sie in Deutschland wohnhaft sind, sehen Sie neben der Steuernummer ein Feld für die Umsatzsteuer-ID. In diesem Fall füllen Sie nur eines der beiden Felder aus.',
  enter_payment_data: 'Bitte geben Sie Ihre Zahlungsdaten ein.',
  payment_method: 'Zahlungsmethode',
  payment_not_successfully_created: 'Zahlung wurde nicht erfolgreich erstellt.',
  paypal_address: 'PayPal E-Mail Adresse',
  tax_number: 'Steuernummer',
  vat_id: 'Umsatzsteuer-ID',
  vat_id_invalid: 'Dies ist keine gültige Umsatzsteuer-Identifikationsnummer',
  account_holder_name: 'Name des Kontoinhabers',
  vat_id_required: 'Pflichtfeld für EU-Unternehmen außerhalb Deutschlands',
  search_invoices:'Rechnungen  durchsuchen',

  // Reset password pages
  password_token_reset_page_title: 'Token-Passwort zurücksetzen',
  reset_password: 'Passwort zurücksetzen',
  enter_email: 'Geben Sie Ihre E-Mail-Adresse ein',
  email_unvalid: 'E-Mail ist ungültig',
  password_reset_success: 'Wenn Sie ein gültiger Benutzer auf unserer Plattform sind, erhalten Sie eine E-Mail zum Zurücksetzen Ihres Passworts.',
  reset_successful: 'Zurücksetzen erfolgreich',
  enter_new_password: 'Geben Sie ein neues Passwort ein.',
  your_new_password: 'Ihr neues Passwort',
  save_password: 'Passwort speichern',
  reset_user_notfound: 'Das Passwort kann nicht geändert werden, da kein Benutzer mit dieser E-Mail-Adresse existiert.',

  // Alerts
  tracking_code_sent: 'Sendungsnummer erfolgreich versendet',
  tracking_code_saved: 'Sendungsnummer erfolgreich gespeichert',
  job_reject_success: 'Die Auftragsanfrage wurde erfolgreich abgelehnt.',
  job_accept_success: 'Die Auftragsanfrage wurde erfolgreich angenommen.',
  link_invalid: 'Der angeforderte Link ist ungültig oder abgelaufen.',
  job_reject_fail: 'Die Auftragsanfrage konnte nicht abgelehnt werden',
  email_not_confirmed: 'Sie habe Ihre E-Mail-Adresse noch nicht bestätigt!',
  confirmation_successful: 'Bestätigung erfolgreich',
  registration_successful: 'Registrierung erfolgreich',
  email_confirmation_label: 'Bitte, klicken Sie hier zur Bestätigung',
  rejected_job_request: 'Schön, dass Sie vorbeischauen! Sie haben diesen Auftrag bereits abgelehnt. Bei Rückfragen können Sie sich gerne an support@lingoking.com wenden',


  // Empty Dashboard
  first_order: 'Aktuell befinden sich in diesem Bereich keine Bestellungen',
  empty_help1: 'Wenn Sie einen Auftrag buchen möchten, klicken Sie oben links auf „Neue Bestellung“. Sie haben weitere Fragen? Besuchen Sie unsere ',
  no_results_found: 'Keine Ergebnisse gefunden!',
  empty_help2: 'an oder besuchen Sie unsere ',
  empty_help_video: 'Video',
  no_orders: 'Sie haben noch keine Aufträge!',
  empty_jobs: 'Aktuell befinden sich in diesem Bereich keine Aufträge',
  empty_help_lsp_1: 'Fragen rund um die Projektabwicklung bei lingoking? Stöbern Sie durch unsere ',
  empty_help_lsp_2: 'oder kontaktieren Sie uns, wir freuen uns auf Sie!',

  // Dashboard
  recent_orders: 'Aktuelle Bestellungen',
  show_all: 'Alle anzeigen',
  dashboard: 'Dashboard',
  recently_updated_jobs: 'Zuletzt aktualisierte Aufträge',

  // Profile
  customer_type: 'Sind Sie Geschäfts- oder Privatkunde?',
  customer_salutation: 'Anrede',
  customer_salutation_desc: 'Bitte geben sie eine Anredeform an.',
  customer_salutation_mrs: 'Frau',
  customer_salutation_mr: 'Herr',
  customer_billing_address: 'Bitte geben Sie Ihre Rechnungsadresse an.',
  billing_address: 'Rechnungsadresse',
  change_billing_address: 'Rechnungsadresse ändern',
  change_delivery_address: 'Lieferadresse ändern',
  billing_address_label: 'Bitte geben Sie Ihre Rechnungsadresse an.',
  address_street_number: 'Straße, Hausnummer',
  number: 'Hausnummer',
  address_street: 'Straße',
  address_zip_code: 'Postleitzahl',
  address_city: 'Stadt',
  address_country: 'Land',
  address_suffix: 'Adresszusatz',
  choose_address_country: 'Wählen Sie ein Land.',
  favorites: 'Favoriten',
  favorite_linguists: 'Wunsch-Dienstleister',
  favorites_desc: 'Wählen Sie bitte hier Ihre gewünschten Dienstleister.',
  favorites_desc2: 'Bitte genau angeben, falls Sie einen oder mehrere Wunsch-Dienstleister haben.',
  favorites_placeholder: 'Wunsch-Dienstleister suchen und hinzufügen',
  unsubscribe_feedback_email: 'Sie haben sich erfolgreich von unseren Bewertungsmails abgemeldet',
  subscribe_feedback_email: 'Bewertungsmails Anmelden',

  // Auftragsverarbeitung
  request: 'Anfrage',
  accept: 'Annehmen',
  reject: 'Ablehnen',
  reject_translation_desc: 'Hiermit lehnen Sie die Übersetzung ab und veranlassen eine Korrektur der Übersetzung. Bitte teilen Sie uns mit, an welcher Stelle wir die Übersetzung verbessern können.',
  refusal_reason: 'Ablehnungsgrund',
  reject_translation: 'Übersetzung ablehnen',
  accept_translation: 'Übersetzung akzeptieren',
  accept_translation_desc_physical: 'Hiermit akzeptieren Sie die Übersetzung und veranlassen den sofortigen Versand Ihrer Unterlagen.',
  accept_translation_desc_mail: 'Hiermit akzeptieren Sie die Übersetzung.',
  lsp_search: 'Ein Dienstleister wird für Sie rekrutiert',
  tracking_code: 'Sendungsnummer',
  track_shipment: 'Sendung verfolgen',
  select_carrier: 'Bitte wählen Sie eine Versandart',
  enter_tracking_code: 'Sendungsnummer eingeben',

  // Job Detail View Section
  requirements: 'Anforderungen',
  notes: 'Anmerkungen',
  customer_notes: 'Kundennachricht',
  deadline: 'Abgabetermin',
  deadline_proofreading: 'Korrekturen zur finalen Überprüfung verfügbar ab:',
  deadline_final_revision: 'Lieferung der finalen Datei an den Kunden:',
  deadline_translation: 'Übersetzung zur Korrektur verfügbar ab:',
  due_on: 'Abgabe am',
  document_to_translate: 'Zu übersetzendes Dokument',
  documents_to_translate: 'Zu übersetzende Dokumente',
  address_info: 'Die vollständige Adresse für den Versand wird nach der Annahme durch den Kunden angezeigt.',
  source_language: 'Ausgangssprache',
  target_language: 'Zielsprache',
  target_languages: 'Zielsprachen',
  upload_documents: 'Dokument(e) hochladen',
  upload_document: 'Dokument hochladen',
  translation_was_accepted: 'Ihre freigegebene Übersetzung wurde vom Kunden akzeptiert.',
  translation_was_not_accepted: 'Ihre freigegebene Übersetzung wurde vom Kunden noch nicht akzeptiert.',
  file_was_deleted_on: 'Datei wurde am {date} gelöscht',
  file_will_be_deleted_on: 'Die Datei wird am {date} gelöscht',
  personal_contact_person: 'Ihr persönlicher Ansprechpartner',
  customer_address: 'Kundenanschrift',
  your_translation_to_download: 'Ihre Übersetzung zum Download',
  your_uploaded_translation: 'Ihre hochgeladene Übersetzung',
  your_uploaded_translation_to_download: 'Ihre hochgeladene Übersetzung zum Download',
  translation_to_correct: 'Zu korrigierende Übersetzung',
  your_uploaded_correction: 'Ihre hochgeladene Korrektur',
  corrections_to_download: 'Korrekturen zum Download',
  upload_translation_here: 'Ihre Übersetzung hier hochladen',
  upload_correction_here: 'Korrektur hier hochladen',
  upload_corrected_translation: 'Korrigierte Übersetzung hier hochladen',
  upload_correction_success: 'Korrektur wurde erfolgreich hochgeladen.',
  upload_translation_success: 'Übersetzung wurde erfolgreich hochgeladen.',
  upload_success: 'Die Übersetzung für den Job {job} wurde erfolgreich hochgeladen.',
  accepted_translation_success : 'Sie haben diese Übersetzung erfolgreich abgenommen.',
  upload_translation_rejected: 'Übersetzung wurde erfolgreich abgelehnt.',
  translated_file_not_uploaded: 'Die Übersetzung wurde noch nicht hochgeladen',
  your_fee: 'Ihr Honorar',
  fee: 'Honorar',
  final_fee: 'Endgültige Honorar',
  amount: 'Umfang',
  translated_from: 'Ihre Übersetzung wurde bearbeitet von',
  translate_from: 'Ihre Übersetzung wird bearbeitet von',
  for_your_information: 'Zur Kenntnis:',
  release_translation: 'Übersetzung freigeben',
  release_correction: 'Korrektur freigeben',
  upload_correction: 'Korrektur hochladen',
  target_file: 'Zieldatei',
  release: 'Freigeben',
  current_order: 'Aktueller Auftrag',
  download_translation: 'Übersetzung herunterladen',
  download_translation_to_be_corrected: 'Zu korrigierende Übersetzung herunterladen',
  job_cancelled: 'Auftrag storniert',
  job_cancelled_warning: 'Dieser Auftrag wurde storniert. Sie haben daher leider keinen Zugriff auf die Auftragsinformationen!',
  cet: 'MEZ',
  job_progress: 'Job in Bearbeitung',
  job_done: 'Job beendet',
  job_rejected: 'Job wurde abgelehnt',
  current_job: 'Aktueller Job',

  // List view
  order_from: 'Beauftragt durch',
  after_order_copy_to: 'Nach Bestellung Rechnungskopie senden an',
  download_offer: 'Angebot herunterladen',
  internal_process: 'Interner Prozess',

  // CompanyForm
  company_legend: 'lingoking für Unternehmen',
  company: 'Firma',
  company_details: 'Firmendetails',
  company_name: 'Firmenname',
  company_name_required: 'Firmenname wird benötigt',
  company_name_too_short: 'Firmenname ist zu kurz',
  company_phone: 'Telefon',
  position_in_company: 'Position im Unternehmen',
  phone_is_too_short: 'Telefonnummer ist zu kurz',
  select_industry: 'Bitte wählen Sie eine Branche aus',
  small_company_label: 'Ich bin Kleinunternehmer. Mein Verdienst bei lingoking ist aufgrund der Kleinunternehmerregelung von der Umsatzsteuer befreit.',
  industry: 'Branche',
  vat_number: 'USt-Id-Nr.',
  vat_required: 'USt-Id-Nr. wird benötigt',
  url_too_short: 'URL ist zu kurz',

  // Shopping Cart
  select_customer: 'Wählen Sie einen Kunden',
  pay_safely_with: 'Sicher bezahlen mit',
  estimated_delivery_date: 'Lieferung voraussichtlich innerhalb von 3-5 Werktagen',
  gtc_and_privacy: 'AGB und Datenschutz',
  shipping_options: 'Versandoptionen',
  accept_gtc: 'Ich bestätige hiermit, dass ich die Allgemeinen Geschäftsbedingungen sowie die Datenschutzerklärung gelesen habe und akzeptiere.',
  continue_to_payment: 'Zur Kasse',
  continue_shopping: 'Weiter einkaufen',
  cart_overview: 'Warenkorb Übersicht',
  order_overview: 'Bestellübersicht',
  cart: 'Warenkorb',
  overview: 'Übersicht',
  login_tab: 'Ihre Daten',
  continue: 'weiter',
  data_successfully_saved: 'Ihre Daten wurden erfolgreich gespeichert. Wir haben Ihnen soeben eine Verifizierungs-Mail geschickt. Bitte folgen Sie den Anweisungen, um Ihr Konto zu aktivieren.',
  please_enter_data: 'Bitte geben Sie Ihre Daten ein',
  please_uplod_file: 'Laden Sie die Ausgangsdatei(en) jetzt hoch. Im Anschluss erhalten Sie per E-Mail eine Bestätigung über den Auftragseingang.',
  back: 'Zurück',
  enter_login_data: 'Bitte geben Sie Ihre Logindaten ein',
  find_right_product: 'Noch nicht das richtige Produkt gefunden?',
  help_you: 'Wir helfen Ihnen weiter',
  contact_lingoking: 'lingoking kontaktieren',
  cart_empty: 'Ihr Warenkorb ist leer.',
  order_product_now: 'Jetzt Übersetzung bestellen',
  go_to_login: 'Zum Login-Bereich',
  ask_help: 'Fragen? Wir helfen ihnen gern!',
  go_to_products: 'Zu den Produkten',
  link_login: 'Anmelden',
  link_contact: 'Kontakt',
  choose_option: 'Sind Sie bereits Kunde?',
  new_customer: 'Neukunde',
  order_as_guest: 'Bestellen als Gast',
  problems: 'Probleme',
  file_upload: 'Datei Upload',
  submit_order: 'Auftrag absenden',
  submit_order_info: 'Sie bekommen in wenigen Minuten eine Bestätigung via E-Mail, dass Ihre Daten erfolgreich eingegangen sind.',
  remove_product: 'Produkt entfernen',
  show_details: 'Details anzeigen',
  hide_details: 'Details ausblenden',
  source_language_placeholder: 'Ausgangssprache suchen und hinzufügen',
  target_languages_placeholder: 'Zielsprachen suchen und hinzufügen',
  source_language_missing: 'Bitte geben Sie die Ausgangssprache an',
  target_language_missing: 'Bitte geben Sie die Zielsprache an',
  number_of_pages_missing: 'Bitte geben Sie die Seitenanzahl an',
  number_of_words_missing: 'Bitte geben Sie die Wortanzahl an',
  number_of_fixed_items_missing: 'Bitte geben Sie die Menge an',
  number_of_pages: 'Seitenanzahl',
  number_of_words: 'Wortanzahl',
  number_of_fixed_items: 'Menge',
  number_of_hours: 'Stundenanzahl',
  number_of_days: 'Tage',
  number_of_half_days: 'Halbe Tage',
  number_of_half_hour: 'Halbe Stunden',
  how_many: 'Wie viele',
  pages: 'Seiten',
  words: 'Wörter',
  page: 'Seite',
  word: 'Wort',
  day: 'Tag',
  days: 'Tage',
  hour: 'Stunde',
  hours: 'Stunden',
  half_day: 'halber Tag',
  half_days: 'halbe Tage',
  half_hour: 'halbe Stunde',
  half_hours: 'halbe Stunden',
  to_translate: 'möchten Sie übersetzen lassen?',
  how_many_to_translate: 'Wie viele {pagesOrWords} möchten Sie übersetzen lassen?',
  interpretation_price_unit_select_headline: 'Für wie viele {priceUnit} benötigen Sie einen Dolmetscher?',
  start_price_calculation: 'Berechnen Sie jetzt den Preis für Ihre Übersetzung!',
  total_amount_net: 'Gesamtsumme netto',
  total_amount: 'Gesamtsumme',
  payment: 'Bezahlung',
  choose_payment_method: 'Wählen Sie Ihre Bezahlart',
  confirmation_and_upload: 'Bestätigung und Datenupload',
  net_amount: 'Nettobetrag',
  vat_abbr: 'MwSt.',
  login_successful: 'Sie wurden erfolgreich eingeloggt.',
  no_parcel_delivery: 'Paketlieferung ist für Ihr Land leider nicht verfügbar!',
  order_number: 'Auftragsnummer',
  show_dashboard: 'Dashboard anzeigen',
  not_authorized_title: 'Individueller Service',
  not_authorized: 'Diese individuelle Lösung haben wir speziell für einen unserer Kunden entwickelt und kann nur von diesem gebucht werden. Für weitere Infos zu diesem Service kontaktieren Sie bitte das lingoking Team.',
  additional_copy: 'Zusätzliche Kopie',
  additional_copies: 'Zusätzliche Kopien',
  additional_data: 'Zusätzliche Angaben',
  additional_digital_files: 'Zusätzliche digitale Datei(en)',
  additional_files_required: '* Mindestens eine zusätzliche digitale Datei ist erforderlich.',
  cart_item_remove_cancel: 'Behalten',
  cart_item_remove_confirm: 'Löschen',
  cart_item_remove_message: 'Sind Sie sicher, dass Sie dieses Produkt entfernen wollen?',
  pay_upon_invoice: 'Auf Rechnung bezahlen',
  pay_upon_invoice_description: 'Ausschließlich für Geschäftskunden',
  order_reference: 'Ihre Auftragsreferenz',
  pay_upon_optional_field: "optional, erscheint auf Ihrer Rechnung",
  discount: 'Rabatt',
  discount_code: 'Rabattcode',
  discount_apply: 'Anwenden',
  discount_apply_label: 'Haben Sie einen Rabattcode?',
  discount_remove: 'Entfernen',
  discount_message: 'Wenn Sie einen Rabattcode haben, geben Sie diesen unten ein:',
  discount_already_applied: 'Rabatt bereits angewendet! Wenn Sie einen neuen Rabatt verwenden möchten, entfernen Sie den vorherigen.',
  discount_already_applied_summary: 'Rabatt (bereits abgezogen)',
  discount_not_valid: 'Rabatt nicht gefunden oder inaktiv. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
  discount_usage_reached: 'Die maximale Anzahl von Verwendungen für diesen Rabattcode wurde erreicht.',
  discount_not_applicable: 'Dieser Rabattcode gilt nicht für Produkte in Ihrem Warenkorb.',
  discount_min_value: 'Um diesen Rabatt in Anspruch nehmen zu können, müssen Sie einen Mindestnettopreis von {value} € haben.',

  // Product
  price_calculation: 'Preisberechnung',
  your_order: 'Ihre Bestellung',
  translation_from: 'Übersetzung von',
  calculate_price: 'Preis berechnen',
  summary: 'Zusammenfassung',
  assign_name: 'Geben Sie Ihrem Auftrag einen Namen',
  product_name: 'Produktname',
  recommended: 'Empfohlen',
  order_name: 'Auftragsname',
  order_date: 'Bestelldatum',
  order_name_placeholder: 'Auftrag 01',
  max_chars: 'max. 50 Zeichen',
  upload_after_payment: 'Bitte beachten Sie: Das Hochladen Ihres zu übersetzenden Dokuments erfolgt erst nach der Bezahlung.',
  message_to_us: 'Ihre Nachricht an uns (optional)',
  add_to_cart: 'In den Warenkorb',
  search_add_topic: 'Thema suchen und hinzufügen',
  search_add_country: 'Land suchen und hinzufügen',
  search_add_timezone: 'Zeitzone suchen und hinzufügen',
  start_date: 'Startdatum',
  end_date: 'Enddatum',
  date: 'Datum',
  order_nr: 'Auftrags-Nr',
  state: 'Status',
  not_confirmed_yet: 'noch nicht bestätigt',

  // Chat
  chat_deactivated: 'Der Chat ist deaktiviert.',
  chat_deactivated_info: 'Falls Sie noch Fragen haben, steht Ihnen das lingoking Team gerne zur Verfügung! Um Kontakt aufzunehmen, klicken Sie auf den blauen Messenger-Button rechts unten auf dieser Seite. Wir melden uns umgehend zurück!',
  your_message: 'Ihre Nachricht',
  no_messages: 'Keine Nachrichten vorhanden.',
  private_message: 'Private Nachricht',
  private_message_to: 'Private Nachricht an',
  write_a_message: 'Verfassen Sie eine Nachricht.',

  thanks_for_order: 'Vielen Dank für Ihre Bestellung!',
  order: 'Bestellung',
  job: 'Aufträge',
  order_created: 'Ihre Bestellung ist bei uns eingegangen. Bitte laden Sie die benötigten Dateien hoch.',
  payment_cancelled: 'Es tut uns Leid! Ihre Bestellung wurde abgebrochen.',
  order_completed_info: 'In Ihrem Kundenkonto können Sie alle Details zu Ihren Bestellungen einsehen sowie weitere Einstellungen verwalten.',

  // CMS Navigation
  user_administration: 'Nutzerverwaltung',
  settings: 'Einstellungen',
  logout: 'Abmelden',
  profile: 'Profil',
  help: 'Hilfe',
  intranet: 'Intranet',
  messages: 'Nachrichten',
  message: 'Nachricht',

  new_order: 'Neue Bestellung',
  completed_orders: 'Erledigte Bestellungen',
  offers: 'Angebote',
  bills: 'Rechnungen',
  quick_access: 'Jetzt bestellen',
  delivery_date: 'Lieferdatum',
  delivery_date_customer: 'Upload der Übersetzung bis',
  price: 'Preis',
  total_price: 'Gesamtpreis',
  margin: 'Margen',

  first_and_last_name: 'Vor- und Nachname',
  street_and_number: 'Straße & Hausnummer',
  postal_code_and_city: 'PLZ & Ort',

  recent_jobs: 'Aktuelle Aufträge',
  completed_jobs: 'Erledigte Aufträge',
  job_requests: 'Anfragen',
  another_linguist_already_accepted_job: 'Oh nein! Der Auftrag wurde soeben anderweitig vergeben, tut uns leid! Sicherlich klappt es nächstes Mal!',
  you_already_accepted_job: 'Sie haben diesen Job bereits angenommen',
  payments: 'Payments',

  // ccn
  searchCcn: 'Gutschriften durchsuchen',

  // customer order states labels
  order_state_processing: 'in Arbeit',
  order_state_check: 'Bitte prüfen',
  order_state_shipment_pending: 'Versand',
  order_state_done: 'Fertig',
  order_state_cancelled: 'Storniert',
  order_state_delivered: 'Bestellung geliefert',
  order_state_transit: 'Bestellung im Transit',
  // lsp job states labels
  lsp_job_state_translation_processing: 'Bitte bearbeiten',
  lsp_job_state_corrections_pending: 'Auf Korrekturen warten',
  lsp_job_state_customer_approval: 'Auf Abnahme warten',
  lsp_job_state_qm_approval: 'Auf Abnahme warten',
  lsp_job_state_acceptance: 'Wartet auf Annahme',
  lsp_job_state_review: 'Bitte Überarbeiten',
  lsp_job_state_shipment_pending: 'Bitte Versenden',
  lsp_job_state_done: 'Fertig',
  lsp_job_state_translation_pending: 'Auf Übersetzung warten',
  lsp_job_state_correction_processing: 'Bitte Korrekturlesen',
  lsp_job_state_approval_pending: 'Auf Abnahme Warten',
  lsp_job_state_order_cancelled: 'Auftrag storniert',
  lsp_job_state_pending: 'Auf Abnahme Warten',
  lsp_job_state_cancelled: 'Auftrag storniert',

  // countries
  country_germany: 'Deutschland',
  country_switzerland: 'Schweiz',
  country_austria: 'Österreich',

  // info
  lsp_qm_info: 'Dieser Auftrag umfasst ein zusätzliches Qualitätsmanagement durch das lingoking Team. Das bedeutet, dass Ihre Übersetzung nach dem Upload nicht sofort und automatisch an den Kunden geliefert, sondern erst vom lingoking Projektmanagement geprüft wird. Bei Anpassungswünschen oder Klärungsbedarf meldet sich das PM-Team bei Ihnen, nicht der Kunde. Sollten Sie Rückfragen hierzu haben, kontaktieren Sie uns direkt über den Messenger rechts unten auf dieser Seite. Nutzen Sie für eine Kontaktaufnahme mit lingoking nicht den Chat, denn diese Benachrichtigungen sind für Kunden sichtbar.',

  // Status
  status_pending : 'Status: In Bearbeitung.',
  status_rejected: 'Status: Übersetzung abgelehnt.',
  status_shipment_required: 'Status: Versand erforderlich.',
  status_uploaded: 'Status: Übersetzung hochgeladen.',
  status_correction_uploaded: 'Status: Korrektur hochgeladen.',

  //Status SubTitle
  waiting_corrections_uploads: 'Bitte warten Sie, bis die Korrekturen hochgeladen sind.',
  waiting_shipment: 'Warten auf den Versand.',
  upload_translation_correction: 'Bitte aktualisieren Sie Ihre Übersetzung mit den Korrekturen.',

  translation_rejected_pm_not_satisfied: 'Korrigierte Übersetzung hier hochladen. Die Übersetzung wurde aus folgendem Grund abgelehnt: Das lingoking Projektmanagement hat Ihre Übersetzung nach eingehender Prüfung abgelehnt, da Anpassungen bzw. Änderungen notwendig sind.',
  translation_rejected_wait_for_instructions: 'Warten Sie auf Anweisungen vom lingoking Projektmanagement.',

  // Status Content
  rejection_reasons: '<br /><p class="bx--inline-notification__title">Sie haben die Übersetzung mit folgender Begründung abgelehnt:</p>',
  rejection_reasons_lsp: '<br /><p class="bx--inline-notification__title">Die Übersetzung wurde aus folgendem Grund abgelehnt:</p>',
  rejection_reason: 'Ablehnung Grund',
  previous_rejection_reasons: 'Bisherige Ablehnungsgründe',

  // PaymentData validation
  bic_required: 'BIC wird benötigt',
  bic_invalid: 'BIC ist ungültig',
  iban_invalid: 'IBAN ist ungültig',
  iban_required: 'IBAN wird benötigt',

  // Pagination
  items_per_page: 'Elemente pro Seite:',
  of: 'von',
  items: 'Elemente',
  sort_by: 'Sortieren nach',
  group_by: 'Gruppiere nach',

  no_spaces: 'keine Leerzeichen',
  //BA
  error_leitweg_id: 'Leitweg-ID is not valid',

  // Dunning process
  invoicing_phase: 'Rechnungsphase',
  reminder1: 'Erste Mahnung',
  reminder2: 'Zweite Mahnung',
  collection_company: 'Inkasso',
  lawyer: 'Gerichtlich',
  dunning_status_waiting_description: 'starting state for all invoices; invoice is not yet delivered and sent to customer',
  dunning_status_ongoing_description: 'invoice was sent to customer; payment is in process of collection',
  dunning_status_completed_description: 'payment has successfully been collected',
  dunning_status_unsuccessful_description: 'payment has not been successfully collected and is marked as lost (at any dunning level)',
  dunning_status_canceled_description: 'payment demand has been cancelled (e.g. due to cancelled invoice)',
  dunning_status_paused_description: 'payment demand has been paused',

  cancel_invoice_bad_service:
    `Guten Tag {salutation} {lastName},<br />` +
    `wir bedauern zutiefst, dass Sie mit unserem Service nicht zufrieden sind. ` +
    `Selbstverständlich stornieren wir auf Ihren Wunsch hin Ihren Auftrag mit der Nummer {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
    `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. ` +
    `Falls die Auftragssumme bereits bezahlt wurde, wird der Betrag umgehend zurückerstattet.<br />` +
    `Wenn Sie noch Fragen oder Anregungen haben, kommen Sie gerne auf uns zu.<br />` +
    `Beste Grüße Ihr lingoking-Team`,

  cancel_invoice_no_linguist_recruited:
    `Guten Tag {salutation} {lastName},<br />` +
    `wir bedauern, Ihnen mitteilen zu müssen, dass wir den Auftrag mit der Nummer {orderNumber} stornieren müssen.<br />` +
    `Unsere Übersetzer für die gewünschte Sprachkombination sind momentan ausgelastet und können daher keine weiteren Übersetzungen annehmen.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
    `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. Sollten Sie die Auftragssumme bereits bezahlt haben, wird der Betrag umgehend zurückerstattet.<br />` +
    `Wir entschuldigen uns sehr für die entstandenen Unannehmlichkeiten.<br />` +
    `Bei Fragen kommen Sie gerne jederzeit auf uns zu.<br />` +
    `Beste Grüße Ihr lingoking-Team`,

  cancel_invoice_no_need:
    `Guten Tag {salutation} {lastName},<br />` +
    `vielen Dank für Ihre Bestellung bei lingoking. Wir bedauern zutiefst, dass Sie sich doch anders entschieden haben. Auf Ihren Wunsch hin stornieren wir den Auftrag mit der Nummer {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
    `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. Falls die Auftragssumme bereits bezahlt wurde, wird der Betrag umgehend zurückerstattet.<br />` +
    `Sollten Sie unseren Service künftig doch benötigen, so freuen wir uns umso mehr, Sie wieder bei lingoking willkommen zu heißen.<br />` +
    `Beste Grüße Ihr lingoking - Team<br />`,

  cancel_invoice_wrong_order:
      `Guten Tag {salutation} {lastName},<br />` +
      `vielen Dank für Ihre Bestellung bei lingoking!<br />` +
      `Nach sorgfältiger Prüfung Ihres Auftrags mussten wir leider feststellen, dass die Art bzw. der Umfang der hochgeladenen Datei(en) nicht mit dem gebuchten Service übereinstimmt. Daher wird der Auftrag mit der Nummer {orderNumber} zu unserem Bedauern storniert.<br />` +
      `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
      `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. Falls die Auftragssumme bereits bezahlt wurde, wird der Betrag umgehend zurückerstattet.<br />` +
      `Gerne können Sie nun den korrekten Service buchen. Falls Sie Fragen dazu haben, helfen wir Ihnen sehr gerne weiter.<br />` +
      `Beste Grüße <br />` +
      `Ihr lingoking-Team`,

  cancel_invoice_other:
    `Guten Tag {salutation} {lastName},<br />` +
    `wir bedauern, Ihnen mitteilen zu müssen, dass wir den Auftrag mit der Nummer {orderNumber} stornieren müssen.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
    `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. Sollten Sie die Auftragssumme bereits bezahlt haben, wird der Betrag umgehend zurückerstattet.<br />` +
    `Wir entschuldigen uns sehr für die entstandenen Unannehmlichkeiten.<br />` +
    `Bei Fragen kommen Sie gerne jederzeit auf uns zu.<br />` +
    `Beste Grüße Ihr lingoking - Team<br />`,

  cancel_invoice_solved_internally:
    `Guten Tag {salutation} {lastName},<br />` +
    `wie Sie uns mitgeteilt haben, wurde dieser Auftrag Ihrerseits bereits intern gelöst. Auf Ihren Wunsch hin und zu unserem Bedauern stornieren wir daher den Auftrag mit der Nummer {orderNumber}.<br />` +
    `<a style="background-color: #1f9dd9; font-size: .875rem; font-weight: 700; padding: 10px; line-height: 50px; color: white; text-decoration: none;" href="{invoiceLink}">Zum Auftrag</a><br />` +
    `Anbei finden Sie die Stornierung der Rechnung {invoiceNumber}. Falls die Auftragssumme bereits bezahlt wurde, wird der Betrag umgehend zurückerstattet.<br />` +
    `Sollten Sie unseren Service künftig doch benötigen, so freuen wir uns umso mehr, Sie wieder bei lingoking willkommen zu heißen.<br />` +
    `Bei Fragen kommen Sie gerne auf uns zu.<br />` +
    `Beste Grüße Ihr lingoking - Team<br />`,

};
