import Download from '../components/download/Download'

export default [
    {
        name: 'DownloadFile',
        path: '/download-file',
        component: Download,
        meta: { authRequired: true }
    }
]
