<template>
  <layout-default>
    <Loading v-if="invoicesIsLoading" />
    <div class="bx--row" v-if="emptyDashboard">
      <div class="bx--col-md-12">
        <div class="lk-empty-page-content-wrapper">
          <div class="lk-empty-page-content">
            <div class="lk-empty-page-content__image-wrapper">
              <img
                src="../../assets/images/lk-empty-dashboard.png"
                alt="Lingoking Empty Dashboard"
                class="lk-empty-page-content__image"
              />
            </div>
            <div class="lk-empty-page-content__teaser">
              <h2 class="lk-empty-page-content__headline">
                {{ $t("first_order") }}
              </h2>
              <p class="lk-empty-page-content__text">
                <!-- {{$t('empty_help1')}} <a href="#" class="lk-empty-page-content__link">{{$t('empty_help_video')}}</a>
                                {{$t('empty_help2')}} <a href="#" class="lk-empty-page-content__link">FAQ</a> -->
                {{ $t("empty_help1") }}
                <a
                  :href="shop_url + $t('help_link')"
                  class="lk-empty-page-content__link"
                  >FAQ</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!invoicesIsLoading">
        <h1 class="lk-service-backend__headline">
          {{ completed ? $t("completed_orders") : $t("recent_orders") }}
        </h1>
        <ListView
          :data-table-items="dataTableItems"
          :data-table-head-items="dataTableHeadItems"
        ></ListView>
        <pagination
          class="lk-container--layer-raised lk-pagination--borderless"
          v-if="totalItems && totalItems > itemsPerPage"
          :cur-page="currentPage"
          :current-page="currentPage"
          :current-page-change-parent="currentPageChange"
          :get-page="goToPage"
          :items-length="totalItems"
          :items-per-page="itemsPerPage"
          :items-per-page-change-parent="itemsPerPageChange"
          :total-items="totalItems"
          :total-pages="totalPages"
          :view="invoicesView"
        >
        </pagination>
      </div>
    </div>
  </layout-default>
</template>

<script>
import "lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss";
import { mapState, mapGetters, mapActions } from "vuex";

import InlineLoading from "lingoking-workbench/src/components/loading/LoadingInline.vue";
import Loading from "lingoking-workbench/src/components/loading/Loading.vue";
import ListView from "lingoking-workbench/src/components/listview/ListView.vue";
import Pagination from "lingoking-workbench/src/components/datatable/Pagination.vue";
import { InvoiceStatus } from "lingoking-workbench/src/enums";
import { getInvoiceStatusLabel } from "lingoking-workbench/src/enums";

import LayoutDefault from "../layouts/LayoutDefault.vue";
import router from "../../router/index";
import { SHOP_URL } from "../../config/entrypoint";

export default {
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      account: (state) => state.account,
    }),

    ...mapGetters({
      invoiceError: "invoice/list/error",
      invoices: "invoice/list/items",
      invoicesIsLoading: "invoice/list/isLoading",
      invoicesView: "invoice/list/view",
      currentPage: "invoice/list/currentPage",
      totalItems: "invoice/list/totalItems",
      messages: "message/messages",
    }),

    totalPages() {
      return this.totalItems < this.itemsPerPage
        ? 1
        : Math.ceil(this.totalItems / this.itemsPerPage).toFixed(0);
    },

    messageInfo() {
      if (!this.messages) {
        return JSON.parse(atob(localStorage.getItem("messagesInfo")));
      }

      return this.messages;
    },

    dataTableItems() {
      if (this.invoices && this.invoices.length) {
        return this.prepareListView(this.invoices);
      }

      return [];
    },

    emptyDashboard() {
      return !this.invoices || this.invoices.length == 0;
    },

    dataTableHeadItems() {
      let dataTableHeadItems = [
        {
          label: "name",
          sortable: true,
          index: "name",
        },
        {
          label: "order_name",
          sortable: true,
          index: "orderName",
        },
        {
          label: "date",
          sortable: true,
          index: "date",
        },
        {
          label: "price",
          sortable: true,
          index: "price",
        },
        {
          label: "state",
          sortable: true,
          index: "status",
        },
        {
          label: "",
          sortable: false,
          index: "actions",
        },
      ];

      if (!this.completed) {
        dataTableHeadItems = dataTableHeadItems.filter(
          (headItem) => headItem.index !== "status"
        );
      }

      return dataTableHeadItems;
    },

    params() {
      const params = new URLSearchParams();

      params.append("groups[]", "invoice:customer-list");
      params.append("groups[]", "timestampable:created");

      params.append("itemsPerPage", this.itemsPerPage);
      params.append("page", this.curPage);

      const orderStatusList = this.completed
        ? [
            InvoiceStatus.ORDER_DELIVERED.schema,
            InvoiceStatus.ORDER_ABORTED.schema,
            InvoiceStatus.ORDER_CANCELLED.schema,
          ]
        : [InvoiceStatus.ORDER_PROCESSING.schema];
      orderStatusList.map((orderStatus) => {
        params.append("orderStatus[]", orderStatus);
      });

      if (this.completed) {
        params.append("collectiveInvoice", 0);
      }

      return params;
    },

    locale() {
      return localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : "de";
    },
  },

  components: {
    Loading,
    LayoutDefault,
    InlineLoading,
    ListView,
    Pagination,
  },

  mounted() {
    this.getAllMessages(this.account.user.id);
  },

  data() {
    return {
      itemsPerPage: 10,
      curPage: 1,
      shop_url: SHOP_URL,
    };
  },

  watch: {
    $route: {
      handler: function () {
        this.setFilter();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      getInvoices: "invoice/list/default",
      getAllMessages: "message/getAllCustomerMessages",
    }),

    fetchInvoices: function () {
      this.getInvoices("/invoices?" + this.params.toString());
    },

    prepareListView: function (items) {
      return items.map((item) => this.prepareListViewItem(item));
    },

    prepareListViewItem: function (item) {
      const locale = this.locale;

      let jobMessageCount = this.messageInfo.filter(
        (m) => m.confirmationNumber === item["confirmationNumber"]
      );

      const unreadMessageCount = jobMessageCount[0]
        ? jobMessageCount[0].unread
        : 0;
      const messageNotification = {
        type: "icon-link",
        icon: "lk-carbon-icon lk-carbon-icon-email",
        badge:
          "lk-data-table__col-list__badge--red lk-data-table__col-list__badge--icon",
        badgeText: unreadMessageCount,
      };

      const invoiceStatus = getInvoiceStatusLabel(item["orderStatus"], locale);
      const status = invoiceStatus
        ? invoiceStatus
        : item["customerOrderState"]
        ? this.$t("order_state_" + item["customerOrderState"].replace("-", "_"))
        : "not confirmed, yet";

      const actions = [];
      if (item["customerNotifications"]) {
        actions.push({
          type: "icon-link",
          icon: "lk-carbon-icon lk-carbon-icon-header--notification",
          badge:
            "lk-data-table__col-list__badge--red lk-data-table__col-list__badge--icon",
          badgeText: item["customerNotifications"],
        });
      }

      return {
        id: item["@id"],
        name: item["confirmationNumber"],
        orderName: item["firstOrderName"] ? item["firstOrderName"] : "",
        date: this.$moment(item["createdAt"]).format("DD.MM.YYYY, HH:mm"),
        price: parseFloat(item["totalPrice"]).toFixed(2) + " €",
        status,
        expandable: false,
        expanded: false,
        link: router.resolve({
          name: "InvoiceShow",
          params: { id: encodeURIComponent(item["@id"]) },
        }).href,
        actions,
        messageNotification,
      };
    },

    itemsPerPageChange(newItemsPerPage) {
      if (newItemsPerPage) {
        this.itemsPerPage = newItemsPerPage;
      }

      if (this.dataTableItems.length < this.itemsPerPage) {
        this.curPage = 1;
      }

      this.fetchInvoices();
    },

    currentPageChange(newCurrentPage) {
      if (newCurrentPage) {
        this.curPage = newCurrentPage;
      }

      this.fetchInvoices();
    },

    goToPage(page) {
      if (page) {
        this.getInvoices(page);

        if (this.dataTableItems.length < this.itemsPerPage) {
          this.curPage = 1;
        }
      }
    },

    setFilter() {
      this.curPage = 1;

      this.fetchInvoices();
    },
  },
};
</script>
