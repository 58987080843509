<template>
  <layout-default>
    <Loading v-if="isLoading || ordersLoading || translationFileIsLoading"/>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!isLoading && item">
        <h1 class="lk-service-backend__headline">{{ $t('order') }} {{ item.confirmationNumber }}</h1>
        <InlineNotification
          :closeButton="false"
          :subtitle="sendError"
          icon="lk-carbon-icon lk-carbon-icon-close--glyph"
          kind="error"
          notificationClass="bx--inline-notification bx--inline-notification--error"
          role="alert"
          :title="$t('error_occurred')"
          v-if="sendError"/>
        <InlineNotification
          :closeButton="true"
          icon="lk-carbon-icon lk-carbon-icon-checkmark"
          kind="success"
          notificationClass="bx--inline-notification bx--inline-notification--success"
          role="alert"
          :subtitle="$t('thanks_for_order')"
          :title="$t('success')"
          v-if="showSuccess"/>
        <div class="bx--row">
          <div class="bx--col-md-12">
            <div class="bx--row">
              <div class="bx--col-xl-4">
                <div class="lk-lsp-backend__accept-request">
                  <h3 class="lk-lsp-backend__accept-headline">{{ $t('your_order') }}</h3>
                  <p class="lk-lsp-backend__accept-name">
                    {{ $t('order_nr') }}.: {{ item.confirmationNumber }} <br/>
                    {{ $t('date') }}: {{ item.createdAt | moment("DD.MM.YYYY, HH:mm") }}<br/>
                    {{ $t('total_amount') }}: <strong>{{ item.totalPrice }} €</strong><br/>
                    {{ $t('state') }}: {{
                      sourceFileMissingOrders && sourceFileMissingOrders.length ?
                        $t('not_confirmed_yet') : invoiceStatus
                    }}
                  </p>
                </div>
              </div>
              <div class="bx--col-xl-8">
                <div class="lk-lsp-backend__accept-request">
                  <h5 class="lk-service-backend__headline">{{ $t('billing_address') }}</h5>
                  <p class="lk-lsp-backend__accept-name">
                    {{ item.billingAddress.name }}<br/>
                    <span v-if="item.billingAddress.companyName">{{ item.billingAddress.companyName }}<br/></span>
                    {{ item.billingAddress.streetAddress }}<br/>
                    {{ item.billingAddress.houseNumber }}<br/>
                    {{ item.billingAddress.postalCode }} {{ item.billingAddress.addressLocality }}<br/>
                  </p>
                </div>
              </div>
            </div>
            <ListView
              :data-table-head-items="dataTableHeadItems"
              :data-table-items="dataTableItems"
              v-if="dataTableItems && dataTableItems.length">
              <template v-slot:explandable-content="slotProps">
                <div
                  v-if="
                    slotProps.order &&
                    slotProps.order['orderedItems'] &&
                    slotProps.order['orderedItems'].length > 0
                  "
                >
                  <SubOrdersList :orders="prepareOrderedItems(slotProps.order['orderedItems'])" />
                </div>
              </template>
            </ListView>
            <TranslationSourceFileUpload
              section-class="lk-translation-file-upload__section"
              wrapper-class="lk-translation-file-upload---margin-top"
              :orders="sourceFileMissingOrders"
              :disable-send-files="disableSendFiles"
              :handle-file-upload="handleFileUpload"
              :order-file-change="orderFileChange"
              v-if="sourceFileMissingOrders && sourceFileMissingOrders.length">
            </TranslationSourceFileUpload>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import {mapActions, mapGetters, mapState} from 'vuex'
import LayoutDefault from '../layouts/LayoutDefault.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import ListView from 'lingoking-workbench/src/components/listview/ListView.vue'
import ProductOptionList from 'lingoking-workbench/src/components/listview/ProductOptionList.vue'
import TranslationSourceFileUpload from "lingoking-workbench/src/components/fileupload/TranslationSourceFileUpload.vue"
import InlineNotification from "lingoking-workbench/src/components/notification/InlineNotification.vue";

import JobTypes from "lingoking-workbench/src/enums/JobType";
import {getOrderStatusLabel} from "lingoking-workbench/src/enums/OrderStatus"
import {getInvoiceStatusLabel} from "lingoking-workbench/src/enums/InvoiceStatus"
import router from "../../router";
import SubOrdersList from "./SubOrdersList.vue";

export default {
  computed: {
    ...mapState({
      account: state => state.account
    }),
    ...mapGetters({
      item: 'invoice/show/retrieved',
      error: 'invoice/show/error',
      isLoading: 'invoice/show/isLoading',
      orderItems: 'invoice/show/retrievedOrders',
      ordersLoading: 'invoice/show/ordersLoading',
      jobs: 'job/list/items',
      translationFileIsLoading: 'translationfile/create/isLoading',
      translationFileError: 'translationfile/create/error',
      createdTranslationFile: 'translationfile/create/created',
      intercomSettings: 'intercom/allSettings',
    }),
    invoiceStatus() {
      let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de'
      return this.item ? getInvoiceStatusLabel(this.item.orderStatus, locale) : ''
    }
  },
  created() {
    this.retrieveAll()
  },
  components: {
    Loading,
    LayoutDefault,
    ListView,
    ProductOptionList,
    TranslationSourceFileUpload,
    InlineNotification,
    SubOrdersList
  },
  data() {
    return {
      dataTableHeadItems: [
        {
          label: 'product_name',
          sortable: true,
          index: 'name'
        },
        {
          label: 'order_name',
          sortable: true,
          index: 'orderName'
        },
        {
          label: 'target_languages',
          sortable: true,
          index: 'targetLanguages'
        },
        {
          label: 'message',
          sortable: true,
          index: 'messageShort',
        },
        {
          label: 'price',
          sortable: true,
          index: 'price'
        },
        {
          label: 'state',
          sortable: true,
          index: 'status'
        },
        {
          label: 'Actions',
          sortable: false,
          index: 'actions'
        }
      ],
      dataTableItems: [],
      disableSendFiles: true,
      ordersAndFiles: [],
      sourceFileMissingOrders: [],
      sendError: false,
      showSuccess: false
    }
  },
  watch: {
    item: function (item) {
      if (!item) {
        return
      }
      //register intercomSettings
      this.registerOrderNumber(this.item.confirmationNumber);
      this.registerOrderAmount(this.item.totalPrice + '€');
      window.Intercom('update', this.intercomSettings);
    },

    orderItems: function (items) {
      if (!items) {
        return
      }

      if (items.length) {
        this.dataTableItems = this.prepareListView(items);
      }
    },

    jobs: function (jobs) {
      if (!jobs) {
        return
      }
    },

    createdTranslationFile: function (created) {
      if (!created) {
        return
      }
      if (!this.translationFileError && !this.translationFileIsLoading) {
        this.showSuccess = true
        this.retrieveAll()
      }
    },
  },
  methods: {
    ...mapActions({
      retrieve: 'invoice/show/retrieve',
      retrieveOrders: 'invoice/show/retrieveOrders',
      retrieveJobs: 'job/list/default',
      createTranslationFile: 'translationfile/create/create',
      registerOrderNumber: 'intercom/registerOrderNumber',
      registerOrderAmount: 'intercom/registerOrderAmount',
    }),

    openLink (link, target) {
      window.open(link, target)
    },

    retrieveAll: function () {
      this.retrieve(decodeURIComponent(this.$route.params.id))
      this.retrieveOrders(decodeURIComponent(this.$route.params.id))
      this.retrieveJobs()
    },

    getLocale: function () {
      return localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de'
    },

    jobDetailViewLink: function (job) {
      return router.resolve({name: 'JobCustomerShow', params: {id: encodeURIComponent(job['@id'])}}).href;
    },

    orderDetailViewLink: function (order) {
      return router.resolve({name: 'OrderShow', params: {id: encodeURIComponent(order['@id'])}}).href;
    },

    getCustomerApprovalJobFromOrder: function (order) {
      if (!order.jobs || !order.jobs.length) {
        return null
      }
      return order.jobs.find(function (job) {
        return job.jobDefinition.type === JobTypes.JOB_TYPE_CUSTOMER_APPROVAL
      })
    },

    getJobPurchaseOrderNumbersFromOrder: function (order) {
      if (!order['status']) {
        return []
      }
      let jobPurchaseOrderNumbers = []
      const getJobNumber = order => {
        let job = this.getCustomerApprovalJobFromOrder(order)
        if (job) {
          jobPurchaseOrderNumbers.push({
            type: 'link',
            icon: '',
            link: this.jobDetailViewLink(job),
            text: order.targetLanguage.name,
            badgeText: '1',
            badge: 'lk-data-table__col-list__badge--red',
          })
        }
      }
      if (!order['orderedItems'].length) {
        getJobNumber(order)
      } else {
        order['orderedItems'].forEach(getJobNumber)
      }
      return jobPurchaseOrderNumbers
    },

    prepareOrderedItems: function (orderedItems) {
      return orderedItems.map(order => this.prepareItemView(order))
    },

    prepareItemView: function (order) {
      let itsParentOrder = !!order['orderedItems'] && order['orderedItems'].length > 0
      let orderLink = this.orderDetailViewLink(order)
      let jobPurchaseOrderNumbers = this.getJobPurchaseOrderNumbersFromOrder(order)
      let locale = this.getLocale()
      let actions = []
      if (!itsParentOrder) {
        actions = [
          {
            type: 'button-link',
            link: orderLink,
            text: 'details'
          }
        ]
      }
      return {
        id: order['id'],
        name: order['product']['name'],
        orderName: order['orderName'],
        messageShort: order['message'] ? this.$options.filters.truncate(order['message'], 50) : '',
        message: order['message'],
        status: getOrderStatusLabel(order['status'], locale) ? getOrderStatusLabel(order['status'], locale) : (order['customerOrderState'] ? this.$t('order_state_' + order['customerOrderState'].replace('-', '_')) : 'not confirmed, yet'),
        price: order['totalPrice'] ? parseFloat(order['totalPrice']).toFixed(2) + ' €' : '',
        purchaseOrderNumbers: jobPurchaseOrderNumbers,
        expandable: true,
        expanded: false,
        product: order['product'],
        items: order['options'],
        orderedItems: order['orderedItems'],
        deliveryDate: this.getDeliveryDate(order['jobs']),
        physicalDelivery: order['physicalDelivery'],
        trackingCode: order['trackingCode'],
        shipmentURL: order['shipmentURL'],
        sourceLanguage: order['sourceLanguage'],
        targetLanguages: this.getTargetLanguagesAsList(order),
        sourceFile: true,
        actions,
        $original: order
      }
    },

    prepareListView: function (orders) {
      let preparedOrders = []
      this.sourceFileMissingOrders = []

      orders.forEach(item => {
        if (item['parentOrder']) {
          return;
        }
        if (item['translationSourceFile'] || item['translationSourceFiles']) {
          preparedOrders.push(this.prepareItemView(item))
        } else {
          this.sourceFileMissingOrders.push(item)
        }
      })

      return preparedOrders
    },

    getDeliveryDate(jobs) {
      let lastJob = jobs.filter(job => {
          return job.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR
      })
      if(lastJob.length === 0) {
        lastJob = jobs.filter(job => {
          return job.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR
        })
      }
      let job = lastJob && lastJob[0] ? lastJob[0] : null
      return job ? job['deliveryDate'] : null
    },

    getTargetLanguagesAsList(order) {
      let itsSubOrder = !!order['parentOrder'];
      const targetLanguages = itsSubOrder ? [order['targetLanguage']] : order['targetLanguages'];
      return targetLanguages.map(language => language.name).join(', ')
    },

    orderFileChange(fieldName, fileList) {
      if (!fileList || !fileList.length) {
        delete this.ordersAndFiles[fieldName]
        this.disableSendFiles = true
        return
      }

      this.ordersAndFiles[fieldName] = []

      // append the files to a FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.ordersAndFiles[fieldName].push({
            file: fileList[x].file,
            filename: fileList[x].file.name,
            order: fieldName
          })
        })

      if (this.ordersAndFiles && Object.keys(this.ordersAndFiles).length === this.sourceFileMissingOrders.length) {
        // allow sending files
        this.disableSendFiles = false
      }
    },

    handleFileUpload() {
      if (this.ordersAndFiles && Object.keys(this.ordersAndFiles).length === this.sourceFileMissingOrders.length) {
        let fileUploadForm = new FormData()
        let ordersAndFiles = []
        for (let key in this.ordersAndFiles) {
          for (let translationFileKey in this.ordersAndFiles[key]) {
            fileUploadForm.append('file[]', this.ordersAndFiles[key][translationFileKey]['file'], (this.ordersAndFiles[key][translationFileKey]['name'] || this.ordersAndFiles[key][translationFileKey]['filename']))
            ordersAndFiles.push(this.ordersAndFiles[key][translationFileKey])
          }
        }
        fileUploadForm.append('orders', JSON.stringify(ordersAndFiles))
        this.createTranslationFile(fileUploadForm)
      } else {
        this.sendError = 'You need to upload for every order a single file!'
      }
    },
  }
};
</script>
