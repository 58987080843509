<template>
  <fieldset>
    <legend>{{ $t("settings_notifications") }}</legend>
    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3">
        <div class="lk-tooltip cv-interactive-tooltip">
          <Tooltip
            uid="lk-how-to-tip-timezone"
            direction="right"
            :hover="true"
            :visible="false"
          >
            <template slot="label">{{ $t("notifications") }}</template>
            <template slot="trigger">
              <span
                class="
                  lk-button-icon lk-button-icon--left
                  lk-how-to-tip__icon
                  lk-carbon-icon
                  lk-carbon-icon-info--glyph
                "
              ></span>
            </template>
            <template slot="content">
              <p>{{ $t("settings_notifications_tooltip") }}</p>
            </template>
          </Tooltip>
        </div>
      </div>
      <div class="bx--col-md-9">
        <div class="bx--row">
          <div class="bx--col-md-3 lk-checkbox-wrapper--margin-top">
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-newsletter"
                name="checkbox-newsletter"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.item.newsletter.isInvalid ? 'is-invalid' : '',
                ]"
                @change="handleUpdateField('newsletter', !item.newsletter)"
                :data-invalid="$v.item.newsletter.isInvalid"
                :checked="item.newsletter"
                :value="item.newsletter"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-newsletter"
              >
                {{ $t("newsletter") }}
              </label>
            </div>
          </div>
          <div class="bx--col-md-3 lk-checkbox-wrapper--margin-top">
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-notifications"
                name="checkbox-notifications"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.item.notificationEnabled.isInvalid ? 'is-invalid' : '',
                ]"
                @change="
                  handleUpdateField(
                    'notificationEnabled',
                    !item.notificationEnabled
                  )
                "
                :data-invalid="$v.item.notificationEnabled.isInvalid"
                :checked="item.notificationEnabled"
                :value="item.notificationEnabled"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-notifications"
              >
                {{ $t("email") }}
              </label>
            </div>
          </div>
          <div class="bx--col-md-6 lk-checkbox-wrapper--margin-top">
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-feedback"
                name="checkbox-feedback"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.item.subscribeFeedbackEmail.isInvalid ? 'is-invalid' : '',
                ]"
                @change="
                  handleUpdateField(
                    'subscribeFeedbackEmail',
                    !item.subscribeFeedbackEmail
                  )
                "
                :data-invalid="$v.item.subscribeFeedbackEmail.isInvalid"
                :checked="item.subscribeFeedbackEmail"
                :value="item.subscribeFeedbackEmail"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-feedback"
              >
                {{ $t("subscribe_feedback_email") }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Tooltip from "../../tooltip/Tooltip.vue";

export default {
  name: "CustomerNotificationSettingsForm",
  components: {
    Multiselect,
    Tooltip,
  },
  props: ["$v", "item", "handleUpdateField"],
};
</script>
