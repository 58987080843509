const InvoiceStatusOld = {
  ORDER_CANCELLED: {
    schema: 'http://schema.org/OrderCancelled',
    label_en: 'Cancelled',
    label_de: 'Storniert',
    label_es: 'Cancelado',
    status: 'error'
  },
  ORDER_PROCESSING: {
    schema: 'http://schema.org/OrderProcessing',
    label_en: 'Processing',
    label_de: 'Wird bearbeitet',
    label_es: 'Tratamiento',
    status: 'warning'
  },
  ORDER_DELIVERED: {
    schema: 'http://schema.org/OrderDelivered',
    label_en: 'Delivered',
    label_de: 'Geliefert',
    label_es: 'Entregado',
    status: 'done'
  },
  ORDER_IN_TRANSIT: {
    schema: 'http://schema.org/OrderInTransit',
    label_en: 'In transit',
    label_de: 'Im Transit',
    label_es: 'En tránsito',
    status: 'warning'
  },
  ORDER_PAYMENT_DUE: {
    schema: 'http://schema.org/OrderPaymentDue',
    label_en: 'Payment due',
    label_de: 'Zahlung fällig',
    label_es: 'Pago pendiente',
    status: 'warning'
  },
  ORDER_PICKUP_AVAILABLE: {
    schema: 'http://schema.org/OrderPickupAvailable',
    label_en: 'Pickup available',
    label_de: 'Abholung möglich',
    label_es: 'Recogida disponible',
    status: 'warning'
  },
  ORDER_PROBLEM: {
    schema: 'http://schema.org/OrderProblem',
    label_en: 'Problem',
    label_de: 'Problem',
    label_es: 'Problema',
    status: 'error'
  },
  ORDER_RETURNED: {
    schema: 'http://schema.org/OrderReturned',
    label_en: 'Returned',
    label_de: 'Ist zurückgekommen',
    label_es: 'Devuelto'
  },
  ORDER_MISSING_SOURCE_FILE: {
    label_en: 'Source File not uploaded',
    label_de: 'Ausgangsdatei nicht hochgeladen',
    label_es: 'Archivo fuente no cargado'
  },
  ORDER_ABORTED: {
    schema: 'http://schema.org/OrderAborted',
    label_en: 'Aborted',
    label_es: 'Abortado',
    label_de: 'Abgebrochen',
    status: 'error'
  },
  ORDER_QUOTE: {
    schema: 'http://schema.org/OrderQuote',
    label_en: 'Quote',
    label_es: 'Quote',
    label_de: 'Quote',
    status: 'warning'
  },
};

export default InvoiceStatusOld

export function getInvoiceStatusOld(orderStatus) {
  let status = ''

  Object.keys(InvoiceStatusOld).some(function (k) {
    if (InvoiceStatusOld[k]['schema'] === orderStatus) {
      status = InvoiceStatusOld[k]['status']
      return true;
    }
  });

  return status
}

export function getInvoiceStatusLabel(orderStatus, locale = 'en') {
  let label = orderStatus

  Object.keys(InvoiceStatusOld).some(function (k) {
    if (InvoiceStatusOld[k]['schema'] === orderStatus) {
      label = InvoiceStatusOld[k][`label_${locale}`]
      return true;
    }
  });

  return label
}
