<template>
  <fieldset>
    <legend>{{$t('settings_language')}}</legend>
    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3">
        <div class="lk-tooltip cv-interactive-tooltip">
          <Tooltip
            uid="lk-how-to-tip-timezone"
            direction="right"
            :hover="true"
            :visible="false">
            <template slot="label">{{$t('your_language')}}</template>
            <template slot="trigger">
                          <span
                            class="lk-button-icon lk-button-icon--left lk-how-to-tip__icon lk-carbon-icon lk-carbon-icon-info--glyph"></span>
            </template>
            <template slot="content">
              <p>{{$t('settings_language_tooltip')}}</p>
            </template>
          </Tooltip>
        </div>
      </div>
      <div class="bx--col-md-9">
        <div class="bx--form-item bx--select">
          <multiselect
            :data-invalid="$v.language.isInvalid"
            :class="[$v.language.isInvalid ? 'is-invalid' : '']"
            :hideSelected="true"
            id="multi-select-language"
            :multiple="false"
            :options="locales"
            track-by="id"
            label="label"
            :required="false"
            :taggable="false"
            v-model="selectedLanguage"
            :show-labels="false"
            :placeholder="$t('language')"
            :tag-placeholder="$t('language')"
          ></multiselect>
          <label class="bx--label" for="multi-select-language">{{$t('language')}}
            *</label>
          <div class="bx--form-requirement"
               v-if="!$v.language.isInvalid">
            {{$t('select_from_list', {item: $t('language')}) }}
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>

  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import Tooltip from 'lingoking-workbench/src/components/tooltip/Tooltip.vue'
  import {getLocales} from '../../utils/locales'

  export default {
    name: 'LanguageSettings',
    components: {
      Multiselect,
      Tooltip,
    },
    props: ['language', 'updateRetrieved', '$v'],
    data() {
      return {
        currentLanguage: null
      }
    },
    computed: {
      selectedLanguage: {
        get() {
          if (this.currentLanguage) {
            return this.currentLanguage;
          }
          const selectedL = this.locales.find(l => l['id'] == this.language);
          return selectedL;
        },
        set(language) {
          this.currentLanguage = language;
          this.updateRetrieved({'language': language.id})
        }
      },
      locales: {
        get() {
          const languages = getLocales();
          return languages.map(l => {
            return {'id': l, 'label': this.$t(l)}
          });
        }
      }
    }
  };
</script>
