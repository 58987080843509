export const ENTRYPOINT = window.lingokingConfig.API_URL
export const SHOP_URL = window.lingokingConfig.SHOP_URL.replace(/\/%.*?%/, '')
export const SHOP_SERVICE_LSP_URL = window.lingokingConfig.SHOP_SERVICE_URL.replace(/\/%.*?%/, '')
export const INTRANET_URL = 'https://sites.google.com/lingoking.com/lspintranet'
export const SHOP_BACKEND_URL = window.lingokingConfig.SHOP_BACKEND_URL
export const SENTRY_ENV = window.lingokingConfig.SENTRY_ENV
export const SENTRY_RELEASE = window.lingokingConfig.SENTRY_RELEASE
export const SENTRY_DSN = window.lingokingConfig.SENTRY_DSN_LSPAREA
export const SENTRY_SAMPLERATE = parseFloat(window.lingokingConfig.SENTRY_SAMPLERATE)
export const INTERCOM_APP_ID = window.lingokingConfig.INTERCOM_APP_ID
export const UPTAIN_ID = window.lingokingConfig.UPTAIN_ID
export const FRIENDLY_CAPTCHA_SITEKEY = window.lingokingConfig.FRIENDLY_CAPTCHA_SITEKEY
export const FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT = window.lingokingConfig.FRIENDLY_CAPTCHA_PUZZLE_ENDPOINT