<template>
  <ul
    :class="[
      'lk-languageselector bx--dropdown',
      { 'bx--dropdown--open': showDropdown },
    ]"
  >
    <li class="bx--dropdown-text" @click="toggleLanguageOptions()">
      <img
        class="lk-languageselector__image"
        :alt="$t(locale)"
        src="../../../assets/images/world.svg"
      />
      <span>{{ locale.toUpperCase() }}</span>
    </li>
    <li>
      <ul class="bx--dropdown-list">
        <li
          v-for="locale in locales"
          :key="locale"
          data-option=""
          data-value="all"
          class="bx--dropdown-item"
        >
          <a
            href="#"
            @click="setLocale(locale)"
            class="bx--dropdown-link"
          >
            {{ $t(locale) }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";
import { getShopServiceLocales } from "../../util";

import WorldIcon from "../../../assets/images/world.svg";

import "./languageselector.scss";

export default {
  name: "LanguageSelector",
  components: {
    WorldIcon
  },
  data() {
    return {
      worldIcon: WorldIcon,
      selectedLanguage: null,
      showDropdown: false,
      flags: {},
      locales: getShopServiceLocales(),
    };
  },
  created() {
    // Check if the "locale" key exists in localStorage
    const localeFromStorage = localStorage.getItem("locale");
    if (localeFromStorage) {
      // If "locale" exists, use it
      this.selectedLanguage = localeFromStorage;
    } else {
      // If "locale" doesn't exist, set a default language (e.g., "en") and use it
      const defaultLanguage = "en";
      localStorage.setItem("locale", defaultLanguage);
      this.selectedLanguage = defaultLanguage;
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale.locale,
    }),
  },
  methods: {
    setLocale(locale) {
      localStorage.setItem("locale", locale);
      this.selectedLanguage = locale;
      window.location.reload();
    },
    toggleLanguageOptions() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>
