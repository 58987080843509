import {MESSAGES_SET, TOGGLE_LOADING} from './mutations_types'

export default {
  [MESSAGES_SET](state, payload) {
    state.messages = payload
    localStorage.setItem('messagesInfo', btoa(JSON.stringify(payload)))
  },

  [TOGGLE_LOADING](state) {
    state.isLoading = !state.isLoading
  }
}
