<template>
    <fieldset>
        <legend>
            <span class="lk-product-icon lk-product-icon-onsite lk-button-icon--left"></span>
            {{ config.title }}
            <Tooltip
                    uid="lk-how-to-tip-text-translation-skills"
                    direction="left"
                    :hover="true"
                    :visible="false">
                <template slot="label">
                </template>
                <template slot="trigger">
                    <span class="lk-button-icon lk-button-icon--left lk-how-to-tip__icon lk-carbon-icon lk-carbon-icon-info--glyph"></span>
                </template>
                <template slot="content">
                    <p>{{ config.description }}</p>
                </template>
            </Tooltip>

        </legend>

        <SkillForm
            input-id-prefix="linguist_interpretation_skills"
            label="Interpretation skill"
            :remove-skill="removeInterpretationSkill"
            :$v="$v"
            :config="config.skillsForm"
            :showStatus="showStatus"
            :action-statuses="actionStatuses"
            :skills="interpretationSkills"
            :all-languages-options="allLanguagesOptions"
            :topic-items="topicItems"
            :update-skills="updateSkills"
            :skill-qualities="skillQualities"
            :skill-status-readonly="skillStatusReadonly">
        </SkillForm>

      <div class="bx--row">
        <div class="bx--col bx-col-md-6">
          <br />
          <button class="bx--btn bx--btn--primary lk-form__add-btn" type="button" @click="addInterpretationSkill()">
            {{ config.additionalSkills }}
            <svg class="bx--btn__icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path d="M7 7H4v2h3v3h2V9h3V7H9V4H7v3zm1 9A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" fill-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </fieldset>
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../../form/form.scss'
  import Multiselect from 'vue-multiselect'
  import SkillForm from './SkillForm.vue'
  import Tooltip from './../../tooltip/Tooltip.vue'
  import { SkillQualities} from './../../../enums';

  export default {
    name: 'InterpretationSkillsForm',
    components: {
      SkillForm,
      Multiselect,
      Tooltip
    },
    props: {
      config: {
        type: Object,
        required: true
      },
      actionStatuses: {
        type: Array,
        required: true
      },
      showStatus:{
        type: Boolean,
        default: false
      },
      $v: {
        type: Object,
        required: true
      },

      getViolation: {
        type: Function,
        required: true
      },

      allLanguagesOptions: {
        required: true
      },

      addInterpretationSkill: {
        type: Function,
        required: true
      },

      removeInterpretationSkill: {
        type: Function,
        required: true
      },

      selectedSkills: {
        required: true
      },

      skillOptions: {
        required: true
      },

      interpretationSkills: {
        required: true
      },

      topicItems: {
        required: true
      },

      updateSkills: {
        type: Function,
        required: true
      },

      skillStatusReadonly: {
        type: Boolean
      },
      
      showOnlyExpertiseArea: {
        type: Boolean,
        default: false
      },
    },

    computed: {
      skillQualities() {
        let skillQualities = [
          SkillQualities.BIDIRECTIONAL,
          SkillQualities.SIMULTANEOUS,
          SkillQualities.SWORN,
        ]
        if (this.showOnlyExpertiseArea) {
          skillQualities.push(SkillQualities.ONLY_EXPERTISE_AREA)
        }
        return skillQualities
      }
    }
  }
</script>
