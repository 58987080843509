<template>
  <div class="lk-list-view lk-fe-table">
    <div class="lk-data-table-container">
      <table class="lk-data-table">
        <thead>
        <tr v-if="!hideHeader">
          <th class="lk-data-table__col" v-for="label in columns">
            <button
              v-if="label.sortable"
              data-event="sort"
              @click="sort(label.index)"
              class="bx--table-sort-v2"
              :class="[activeClass, sortedClass]"
              :data-sort-by-index="label.index">
              <span class="bx--table-header-label">{{ $t(label.label) }}</span>
              <span
                v-if="label.index === sortedBy"
                class="bx--table-sort-v2__icon lk-table-sort__icon lk-carbon-icon lk-carbon-icon-caret--down"
                aria-label="Sort rows by this header in descending order">
              </span>
            </button>
            <button
              v-else
              class="bx--table-sort-v2">
              <span class="bx--table-header-label">{{ $t(label.label) }}</span>
            </button>
          </th>
        </tr>
        </thead>
        <tbody v-if="!items.length">
        <tr class="lk-data-table__row bx--parent-row-v2 lk-container--layer-raisedjh">
          <td :colspan="columns.length" class="lk-data-table__col">
            {{ $t('no_results_found') }}
          </td>
        </tr>
        </tbody>
        <tbody v-if="items.length">
        <tr v-for="item in items"
            class="lk-data-table__row bx--parent-row-v2 lk-container--layer-raised">
          <th v-if="data.header" class="lk-data-table__col" v-for="data in columns">
            <span v-if="data.renderFcn">{{data.renderFcn(item, data.index)}}</span>
            <span v-else>{{item[data.index]}}</span>
            <button
              v-if="data.action" class="bx--table-expand-v2__button lk-data-table-expand__button"
              @click="data.fcn(item)">
              <span class="lk-carbon-icon lk-carbon-icon-2x" :class="data.icon"></span>
            </button>
          </th>
          <td v-if="!data.header" class="lk-data-table__col" v-for="data in columns">
            <span v-if="data.renderFcn">{{data.renderFcn(item, data.index)}}</span>
            <span v-else>{{item[data.index]}}</span>
            <button
              v-if="data.action" class="bx--table-expand-v2__button lk-data-table-expand__button"
              @click="data.fcn(item)">
              <span class="lk-carbon-icon " :class="data.icon"></span>
            </button>
            <slot v-if="data.custom" :name="data.columnSlotName" :item="item"></slot>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import './fetable.scss';

  export default {
    name: "FeTable",
    props: {
      columns: {
        type: Array,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      hideHeader: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        sortedBy: 'name',
        sortDirection: 'asc',
        sortedClass: '',
        activeClass: '',
        tempSorted: '',
      }
    },
    methods: {
      sortClass(sortBy) {
        // For always starting DESC sort
        if (this.tempSorted !== sortBy) {
          this.sortDirection = "asc";
          this.tempSorted = sortBy;
        }

        this.sortedBy = sortBy;
        if (this.sortDirection === "asc") {
          this.sortedClass = "";
          this.activeClass = "bx--table-sort-v2--active";
          this.sortDirection = "desc";
        } else {
          this.sortedClass = "bx--table-sort-v2--ascending";
          this.activeClass = "bx--table-sort-v2--active";
          this.sortDirection = "asc";
        }
      },

      sort(sortByIndex) {
        this.sortClass(sortByIndex);
        this.$emit('sort', {sortDirection: this.sortDirection, sortByIndex});
      },
    }
  }
</script>
