<template>
  <form class="lk-form lk-login-form" @submit.prevent="handleSubmit">

    <div class="bx--row">
      <div class="bx--col-md-12">
        <!-- UserType -->
        <div class="bx--form-item">
          <div class="bx--radio-button-group">
            <input id="radio-button-user-type-private" class="bx--radio-button" type="radio"
                   :value="userTypes['TYPE_CUSTOMER_PRIVATE']" v-model="$v.userType.$model"
                   name="userType" tabindex="0"
                   :checked="userType == userTypes.TYPE_CUSTOMER_PRIVATE">
            <label for="radio-button-user-type-private" class="bx--radio-button__label">
              <span class="bx--radio-button__appearance"></span>
              {{$t('private_customer')}}
            </label>
            <input id="radio-button-user-type-business" class="bx--radio-button"
                   type="radio" :value="userTypes['TYPE_CUSTOMER_BUSINESS']"
                   v-model="$v.userType.$model" name="userType" tabindex="0"
                   :checked="userType == userTypes.TYPE_CUSTOMER_BUSINESS">
            <label for="radio-button-user-type-business" class="bx--radio-button__label">
              <span class="bx--radio-button__appearance"></span>
              {{$t('business_customer')}}
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- FirstName / LastName -->
    <div class="bx--row">
      <div class="bx--col-md-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            id="text-input-firstname"
            type="text"
            name="name"
            class="bx--text-input lk-form__field"
            :placeholder="$t('first_name')"
            v-model="$v.firstName.$model"
            v-bind:data-invalid="$v.firstName.$error"
          >
          <label for="text-input-firstname" class="bx--label lk-form__label">{{$t('first_name')}}</label>
          <div class="bx--form-requirement" v-if="!$v.firstName.required">{{$t('first_name_required')}}</div>
        </div>
      </div>
      <div class="bx--col-md-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            id="text-input-lastname"
            type="text"
            name="lastName"
            class="bx--text-input lk-form__field"
            :placeholder="$t('last_name')"
            v-model="$v.lastName.$model"
            v-bind:data-invalid="$v.lastName.$error"
          >
          <label for="text-input-lastname" class="bx--label lk-form__label">{{$t('last_name')}}</label>
          <div class="bx--form-requirement" v-if="!$v.lastName.required">{{$t('last_name_required')}}</div>
        </div>
      </div>
    </div>

    <!-- Email -->
    <div class="bx--row">
      <div class="bx--col-md-12">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            id="text-input-email"
            type="text"
            name="email"
            class="bx--text-input lk-form__field"
            :placeholder="$t('your_email')"
            v-model.trim="$v.email.$model"
            v-bind:data-invalid="$v.email.$error"
          >
          <label for="text-input-email" class="bx--label lk-form__label">
            {{$t('your_email')}}
          </label>
          <div class="bx--form-requirement" v-if="!$v.email.required">{{$t('email_required')}}</div>
          <div class="bx--form-requirement" v-if="!$v.email.email">{{$t('email_valid')}}</div>
          <div class="bx--form-requirement" v-if="!$v.email.checkViolations">{{ getViolation('email') }}</div>
        </div>
      </div>
    </div>

    <!-- Password & PasswordRepeat -->
    <div class="bx--row">
      <div class="bx--col-md-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            id="text-input-password"
            type="password"
            name="password"
            class="bx--text-input lk-form__field"
            :placeholder="$t('password')"
            v-model="$v.password.$model"
            v-bind:data-invalid="$v.password.$error"
          >
          <label for="text-input-password" class="bx--label lk-form__label">{{$t('password')}}</label>
          <div class="bx--form-requirement" v-if="!$v.password.required">{{$t('password_required')}}</div>
          <div class="bx--form-requirement" v-else-if="!$v.password.minLength">{{ $t('password_min_length_error', { number: $v.password.$params.minLength.min }) }}</div>
        </div>
      </div>
      <div class="bx--col-md-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            id="text-input-password-repeat"
            type="password"
            name="passwordRepeat"
            class="bx--text-input lk-form__field"
            :placeholder="$t('repeat_password')"
            v-model="$v.passwordRepeat.$model"
            v-bind:data-invalid="$v.passwordRepeat.$error"
          >
          <label for="text-input-password-repeat" class="bx--label lk-form__label">{{$t('repeat_password')}}</label>
          <div class="bx--form-requirement" v-if="!$v.password.required">
            {{$t('repeat_password_required')}}
          </div>
          <div class="bx--form-requirement" v-if="!$v.passwordRepeat.passwordSameAs">
            {{$t('repeat_password_not_identical')}}
          </div>
        </div>
      </div>
    </div>
    <!-- OptOut -->
    <div class="bx--form-item bx--checkbox-wrapper bx--checkbox-wrapper--no-margin">
      <input id="checkbox-opt-out"
             class="bx--checkbox"
             type="checkbox"
             value="optout"
             @change="optOutFunction"
             name="optOut">
      <label for="checkbox-opt-out" class="bx--checkbox-label lk-register__checkbox-label lk-login__checkbox-label lk-alignment--left">
        {{$t('opt_out')}}
        <a :href="linkShop + $t('privacy_link')" target="_blank" class="lk-form__link">{{$t('opt_out_privacy')}}</a>
      </label>
    </div>

    <!-- Additional Label Text -->
    <div class="bx--form-item bx--checkbox-wrapper">
      <label
        class="bx--checkbox-label lk-form__checkbox-label--nocheck lk-login__checkbox-label lk-alignment--left">
        {{$t('terms_of_use_p1')}} <a :href="linkShop + $t('terms_of_use_link')" target="_blank" class="lk-form__link">{{$t('terms_of_use')}}</a>
        {{$t('terms_of_use_p2')}}
      </label>
    </div>

    <slot name="extra"></slot>

    <!-- Submit Button -->
    <div class="bx--form-item">
      <button class="bx--btn bx--btn--primary lk-form__submit" type="submit" :disabled="isLoading">
        {{$t('register_complete')}}
      </button>
    </div>
  </form>

</template>

<script>
    import './registrationpage.scss';

    export default {
        props: {
            userTypes: {
                required: true
            },
            userType: {
                required: true
            },
            $v: {
                required: true
            },
            handleSubmit: {
                required: true
            },
            getViolation: {
                required: true
            },
            optOut: {
                required: true
            },
            linkShop: {
                required: true
            },
            isLoading: {
                required: true
            }
        },
        methods: {
            optOutFunction(event) {
                this.$emit('optOut', event.target.checked);
            }
        }
    }
</script>
