import * as types from './mutation_types'
import data from "./dummy-data";

export const retrieve = ({ commit }, id) => {
  commit(types.TOGGLE_LOADING);
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ITEMS, data);
  commit(types.SET_RETRIEVED, data)
}

export const updateRetrieved = ({ commit }, updated) => {
  commit(types.UPDATE_RETRIEVED, updated)
}
export const update = ({ commit, state }) => {

}
