<template>
  <div class="bx--pagination lk-pagination" data-pagination >
    <div class="bx--pagination__left">
      <span class="bx--pagination__text">{{ $t('items_per_page') }}</span>
      <div class="bx--select bx--select--inline">
        <label class="bx--visually-hidden" for="select-id-pagination">Number of items per page</label>
        <select @change="paginationItemsPerPageChange($event.target.value)" v-model="paginationItemsPerPage" class="bx--select-input" data-items-per-page id="select-id-pagination">
          <option class="bx--select-option" value="10">10</option>
          <option class="bx--select-option" value="20">20</option>
          <option class="bx--select-option" value="30">30</option>
          <option class="bx--select-option" value="40">40</option>
          <option class="bx--select-option" value="50">50</option>
          <option class="bx--select-option" value="100">100</option>
          <option class="bx--select-option" value="200">200</option>
        </select>
        <span class="bx--select__arrow lk-carbon-icon lk-carbon-icon-caret--down"></span>
      </div>
      <span class="bx--pagination__text">
        <span>|&nbsp;</span>
        <span data-displayed-item-range>{{ itemsLength }}</span> {{ $t('of') }}
        <span data-total-items>{{ totalItems }}</span> {{ $t('items') }}
      </span>
    </div>
    <div class="bx--pagination__right bx--pagination--inline">
      <span class="bx--pagination__text">
      <span data-displayed-page-number>{{ currentPage }}</span> {{ $t('of') }}
      <span data-total-pages>{{ totalPages }}</span> {{ totalPages > 1 ? $t('pages') : $t('page')}}</span>
      <span class="bx--pagination__text bx--pagination__text--left" v-if="paginationView">
        <button
          :disabled="!paginationView['hydra:previous']"
          @click="paginationGoToPage(view['hydra:first'])"
          class="bx--pagination__button lk-be-icon lk-be-icon-toggle-arrows--left"
          type="button"></button>
        <button
          :disabled="!paginationView['hydra:previous']"
          @click="paginationGoToPage(view['hydra:previous'])" aria-label="Backward button"
          class="bx--pagination__button bx--pagination__button--border-right bx--pagination__button--backward lk-carbon-icon lk-carbon-icon-chevron--left"
          data-page-backward
          type="button">
        </button>
      </span>
      <label class="bx--visually-hidden" for="select-id-page-number">Page number input</label>
      <div class="bx--select bx--select--inline">
        <label class="bx--visually-hidden" for="select-id-page-number">Number of page</label>
        <select @change="paginationCurrentPageChange($event.target.value)" v-model="paginationCurrentPage" class="bx--select-input" data-page-number-input id="select-id-page-number">
          <option class="bx--select-option" v-bind:value="number" v-for="number in Number(totalPages)">
            {{ number }}
          </option>
        </select>
        <span class="bx--select__arrow lk-carbon-icon lk-carbon-icon-caret--down"></span>
      </div>
      <span class="bx--pagination__text bx--pagination__text--right" v-if="view">
        <button
          :disabled="!view['hydra:next']"
          @click="paginationGoToPage(view['hydra:next'])" aria-label="Forward button"
          class="bx--pagination__button bx--pagination__button--forward lk-carbon-icon lk-carbon-icon-chevron--right"
          data-page-forward
          type="button">
        </button>
        <button
          :disabled="!view['hydra:last']"
          @click="paginationGoToPage(view['hydra:last'])"
          class="bx--pagination__button lk-be-icon lk-be-icon-toggle-arrows--right"
          type="button"></button>
      </span>
    </div>
  </div>
</template>

<script>
  import '../../../../lingoking-workbench/src/components/pagination/pagination.scss';
  import '../../../../lingoking-workbench/src/components/searchbutton/searchbutton.scss';
  export default {
    name: 'Pagination',
    props: [
      'itemsPerPageChangeParent',
      'itemsPerPage',
      'itemsLength',
      'totalItems',
      'currentPage',
      'totalPages',
      'view',
      'currentPageChangeParent',
      'curPage',
      'getPage',
    ],
    methods: {
      paginationItemsPerPageChange (newItemsPerPage) {
        this.itemsPerPageChangeParent(newItemsPerPage)
      },
      paginationCurrentPageChange (newCurrentPage) {
        this.paginationCurrentPage = newCurrentPage
        this.currentPageChangeParent(newCurrentPage)
      },
      paginationGoToPage (params) {
        let pageParams = new URLSearchParams(params);

        this.getPage(params, pageParams.get('page'))
      }
    },
    computed: {
      paginationView () {
        return this.view || null
      }
    },
    data() {
      return {
        paginationCurrentPage: this.curPage,
        paginationItemsPerPage: this.itemsPerPage
      }
    },
    watch: {
      curPage: function(newVal, oldVal) {
        this.paginationCurrentPage = newVal
      },
      itemsPerPage: function (newVal) {
        this.paginationItemsPerPage = newVal
      }
    }
  }
</script>
