import {ENTRYPOINT} from "../config/entrypoint";
import {jwtService} from "../_services";
import axios from "axios";
import router from "../router";
import {store} from "../store";
import SubmissionError from "../error/SubmissionError";

const MIME_TYPE = 'application/ld+json'
const locale = localStorage.getItem('locale')

const ignore401ErrorFromEndpoints = [
  `${ENTRYPOINT}/token/refresh`,
  `${ENTRYPOINT}/logout`,
  `${ENTRYPOINT}/login_check`
];

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched() {
  isAlreadyFetchingAccessToken = false

  // Execute Callbacks
  subscribers = subscribers.filter(({resolve, originalRequest}) => {
    resolve(axios(originalRequest))
  })

  clearSubscribers()
}

function rejectSubscribers() {
  subscribers = subscribers.filter(({reject, originalRequest}) => {
    reject(originalRequest)
  })

  clearSubscribers()
}

function addSubscriber(data) {
  subscribers.push(data)
}

function clearSubscribers() {
  subscribers = []
}

if (process.env.NODE_ENV === 'development') { axios.defaults.withCredentials = true }

axios.interceptors.request.use(options => {

  const accessToken = jwtService.getAccessToken()

  if (options.headers === undefined) {
    options.headers = {}
  }

  if (options.headers['Accept'] === undefined) {
    options.headers['Accept'] = MIME_TYPE
  }

  if (options.data !== undefined && !(options.data instanceof FormData) && options.headers['Content-Type'] === undefined) {
    options.headers['Content-Type'] = MIME_TYPE
  }

  if (accessToken) {
    options.headers['Authorization'] = 'Bearer ' + accessToken
  }

  if (options.headers['X-LOCALE'] === undefined && locale) {
    options.headers['X-LOCALE'] = locale
  }

  return options
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(
  response => response,
  error => {
    let originalRequest = error.config

    if (error.response.status === 401 && ignore401ErrorFromEndpoints.includes(originalRequest.url)) {
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true

        store.dispatch("account/refresh")
          .then(() => { onAccessTokenFetched() })
          .catch(() => {
            isAlreadyFetchingAccessToken = false

            rejectSubscribers()

            router.push('/login')
          })
      }

      return new Promise((resolve, reject) => {
        addSubscriber({resolve, reject, originalRequest})
      })
    }

    const errorMessage = error.response.data['hydra:description']
      ? error.response.data['hydra:description'] : error.response.statusText

    const errors = {
      _error: errorMessage,
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data['message'],
      params: error.response.data['params'],
    }

    if (error.response.data.violations) {
      error.response.data.violations.map(violation =>
        Object.assign(errors, {[violation.propertyPath]: violation.message}))
    }

    throw new SubmissionError(errors)

  })

export default function (id, options = {}) {
  return axios(new URL(id, ENTRYPOINT).toString(), options).then((response) => response.data)
}
