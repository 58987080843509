<template>
  <layout-default>
    <div class="container lk-container--white lk-container--inner lk-container--layer-raised">
      <div class="bx--row">
        <div class="bx--col-xs-12 bx--col-md-12">

          <!-- Customer Form Component -->
          <CustomerForm
            :config="config"
            :locale="locale"
            :item="item"
            :countries="countries"
            :lineOfBusiness="lineOfBusiness"
            :update-field="updateField"
            :handle-submit="handleSubmit"
            :is-loading="isLoading"
            :error="error"
            :success="updated"
            :success-message="'Your profile has been updated.'"
            :line-of-business-first-item="lineOfBusinessFirstItem"
            :disable-search-companies="true"
          >
            <template slot="actionbar">
              <div class="lk-form__actionbar">
                <button :disabled="isLoading" class="btn btn-success bx--btn bx--btn--primary"
                        type="submit">
                  {{ item && item.confirmed && item.active ? config.translations[locale].saveBtn :
                  $t('register_complete') }}
                </button>
              </div>
            </template>
          </CustomerForm>

        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
    import 'lingoking-workbench/src/components/form/form.scss'
    import {UserTypes, UserTitles, Timezones} from 'lingoking-workbench/src/enums'
    import LayoutDefault from './../layouts/LayoutDefault.vue'
    import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
    import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
    import Tooltip from 'lingoking-workbench/src/components/tooltip/Tooltip.vue'
    import CustomerForm from '../../../../lingoking-workbench/src/components/customer/Customer.vue'
    import {mapGetters, mapState, mapActions} from 'vuex'
    import { DEFAULT_BILLING_PERIOD } from "lingoking-workbench/src/constants/Company"

    export default {

        components: {
            LayoutDefault,
            InlineNotification,
            Loading,
            Tooltip,
            CustomerForm
        },

        data() {
            return {
                locale: 'de',
                userTypes: UserTypes,
                userTitles: UserTitles,
                timezones: Timezones,
                selectedAddressCountry: null,
                lineOfBusinessFirstItem: [],
                defaultItem: {
                    billingAddress: {
                        streetAddress: null,
                        houseNumber: null,
                        addressSuffix: null,
                        addressCountry: null,
                        addressLocality: null,
                        postalCode: null
                    },
                    userType: null,
                    userTitle: null,
                    firstName: null,
                    lastName: null,
                    timezone: null,
                    companyPosition: null,
                    company: {
                        billingAddress: {
                            streetAddress: null,
                            houseNumber: null,
                            addressSuffix: null,
                            addressCountry: null,
                            addressLocality: null,
                            postalCode: null
                        },
                        billingPeriod: DEFAULT_BILLING_PERIOD,
                        collectiveInvoice: false,
                        description: null,
                        fax: null,
                        lineOfBusiness: null,
                        name: null,
                        phone: null,
                        url: null,
                        vatId: null
                    }
                },
                config: {
                  isBackend: false,
                  showBackToList: false,
                  localeSwitcherShow: false,
                  disableEmail: true,
                  showCollectiveInvoice: false,
                  showBillingPeriod: false,
                  showProjectManager: false,
                  showPhoneNumber: true,
                  showTimezone: true,
                  translations: {
                    en: {
                      backToListBtn: "Back to list",
                      saveBtn: "Submit",
                      completeRegistrationBtn: "Complete registration",
                      status: "Status",
                      statusLabel: "Status",
                      statusPrivateCustomer: "Private customer",
                      statusBusinessCustomer: "Business customer",
                            salutation: "Salutation",
                            salutationLabel: "Please enter a form of first name, last name.",
                            salutationMrs: "Mrs",
                            salutationMr: "Mr",
                            salutationFirstName: "First name",
                            salutationLastName: "Last name",
                            billing: {
                                address: "Billing Address",
                                addressLabel: "Please tell us your billing address.",
                                addressStreetNumber: "Street, number",
                                addressZipCode: "ZIP-code",
                                addressCity: "City",
                                addressCountry: "Country",
                                addressCountrySelect: "Please select a country"
                            },
                            email: "Email",
                            emailLabel: "Your e-mail address.",
                            timezone: "Timezone",
                            timezoneLabel: "Your time zone.",
                            timezoneTooltip: "In order to correctly calculate the delivery date for orders, please enter here the time zone in which you live.",
                            timezoneSelect: "Please select a timezone",
                            completeRegistrationButton: "Complete registration",
                            companyData: "Company data",
                            companyDataLabel: "Company data",
                            companyDataLineOfBusiness: "Line Ofbusiness",
                            companyDataPosition: "Position",
                            companyDataVat: "Vat",
                            companyDataWebsite: "Website",
                            companyDataCompanyName: "Company name",
                            collectiveInvoice: "Collective invoice"
                        },
                        de: {
                            backToListBtn: "Back to list",
                            saveBtn: "Speichern",
                            completeRegistrationBtn: "Registrierung abschliessen",
                            status: "Status",
                            statusLabel: "Sind Sie Geschäfts- oder Privatkunde?",
                            statusPrivateCustomer: "Privatkunde",
                            statusBusinessCustomer: "Geschäftskunde",
                            salutation: "Anrede",
                            salutationLabel: "Bitte geben sie eine Anredeform an.",
                            salutationMrs: "Frau",
                            salutationMr: "Herr",
                            salutationFirstName: "Vorname",
                            salutationLastName: "Nachname",
                            billing: {
                                address: "Rechnungsadresse",
                                addressLabel: "Bitte teilen Sie Ihre Rechnungsadresse an.",
                                addressStreetNumber: "Strasse, Hausnummer",
                                addressZipCode: "Postleitzahl ",
                                addressCity: "Stadt",
                                addressCountry: "Land",
                                addressCountrySelect: "Land aus der Liste wählen"
                            },
                            email: "Email",
                            emailLabel: "Deine Emailadresse.",
                            timezone: "Zeitzone",
                            timezoneLabel: "Ihre Zeitzone.",
                            timezoneTooltip: "Um für Aufträge den Liefertermin korrekt berechnen zu lassen, geben Sie bitte hier die Zeitzone an, in der Sie leben.",
                            timezoneSelect: "Bitte geben Sie eine Zeitzone ein",
                            completeRegistrationButton: "Complete registration",
                            companyData: "Firmen Daten",
                            companyDataLabel: "Company data Label",
                            companyDataLineOfBusiness: "Branche",
                            companyDataPosition: "Position",
                            companyDataVat: "USt.-ID",
                            companyDataWebsite: "Website",
                            companyDataCompanyName: "Firmenname",
                            collectiveInvoice: "Sammelrechnung",
                            billingPeriod: "Abrechnungszeitraum"
                        }
                    }
                }
            }
        },
        computed: {
            ...mapState({
                account: state => state.account,
                alert: state => state.alert
            }),

            ...mapGetters({
                userLoading: 'customer/update/isLoading',
                error: 'customer/update/error',
                retrieved: 'customer/update/retrieved',
                updated: 'customer/update/updated',
                violations: 'customer/update/violations',
                countries: 'country/list/items',
                countriesLoading: 'country/list/isLoading',
                lineOfBusiness: 'lineofbusiness/list/items',
                lineOfBusinessLoading: 'lineofbusiness/list/isLoading',
            }),

            item: {
                get() {
                    if (this.retrieved) {
                        if (!this.retrieved.billingAddress) {
                            this.updateRetrieved({['billingAddress']: this.defaultItem.billingAddress});
                        }

                        if (!this.retrieved.lineOfBusiness && this.lineOfBusinessFirstItem) {
                            this.updateRetrieved({['lineOfBusiness']: this.defaultItem.lineOfBusiness});
                        }

                    }
                    return this.retrieved || this.defaultItem;
                }
            },
            isLoading() {
                return this.userLoading || this.countriesLoading || this.lineOfBusinessLoading;
            },
        },
        methods: {
            ...mapActions({
                retrieve: 'customer/update/retrieve',
                updateReset: 'customer/update/reset',
                update: 'customer/update/update',
                updateRetrieved: 'customer/update/updateRetrieved',
                getCountries: 'country/list/default',
                activate: 'account/activate',
                getLineOfBusiness: 'lineofbusiness/list/default',
                registerAlertSuccess: 'account/registerAlertSuccess'
            }),

            handleSubmit() {
                this.update();
            },
            reset() {
                this.updateReset()
            },
            updateField(field, value) {
                this.updateRetrieved({[field]: value})
            },
        },
        mounted() {

            // Get Countries
            this.getCountries();

            // Get Business Line
            this.getLineOfBusiness();

            // If exist in url parameter id
            if (this.$route.params.id) {
                this.retrieve({'id': decodeURIComponent(this.$route.params.id), 'locale': this.locale});

                // Else not exist parameter id check account id and customer type
            } else if (this.account.user.id && this.account.user.type === "App\\Entity\\Customer\\Customer") {
                this.retrieve('/customers/' + this.account.user.id);
            }
        },
        watch: {
            updated: function (updated) {
                if (!updated) {
                    return;
                }
                if (!updated.active) {
                    this.retrieve('/customers/' + this.account.user.id);
                    return;
                }
                if (!this.account.user.active) {
                    this.activate()
                    setTimeout(() => {
                        this.registerAlertSuccess();
                    });
                } else {
                    this.retrieve('/customers/' + this.account.user.id);
                }
                this.$scrollTo('body');
            },
            error: function (error) {
                this.$scrollTo('body');
            },
            lineOfBusiness: function (retrieved) {
                if (!retrieved) {
                    return;
                }
                this.lineOfBusinessFirstItem = retrieved[0];
            }
        },
        beforeDestroy() {
            this.reset();
        }
    }
</script>
