const InvoiceStatus = {
  DRAFT: {
    schema: 'draft',
    status: 'warning'
  },
  OPEN: {
    schema: 'open',
    status: 'warning'
  },
  SETTLED: {
    schema: 'settled',
    status: 'done'
  },
  VOID: {
    schema: 'void',
    status: 'warning'
  },
  UNCOLLECTIBLE: {
    schema: 'uncollectible',
    status: 'error'
  },
  DELETED: {
    schema: 'deleted',
    status: 'error'
  },
};

export default InvoiceStatus

export function getInvoiceStatus(invoiceStatus) {
  let status = ''

  Object.keys(InvoiceStatus).some(function (k) {
    if (InvoiceStatus[k]['schema'] === invoiceStatus) {
      status = InvoiceStatus[k]['status']
      return true;
    }
  });

  return status
}
