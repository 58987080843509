<template>
  <div>
    <JobDetailViewSection
      :headline="$t('tracking_code')"
      v-if="job.status !== jobStatus.REALIZATION && job.order.trackingCode">
      <template slot="extra-content">
        <h5 class="lk-detailview__table-left">{{ $t('tracking_code')}}: {{ job.order.trackingCode }}</h5>
        <a v-if="job.order.shippingMailNotification" :href="dhlShipmentUrl" class="lk-detailview__table-right" target="_blank">
          {{ $t('track_shipment')}}
        </a>
      </template>
    </JobDetailViewSection>

    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <!-- Kundenanschrift START -->
    <JobDetailViewSection
      :headline="$t('customer_address')"
      :table="customerAddress"
      v-if="shouldDisplayCustomerAddress && job.status === jobStatus.REALIZATION || job.status === jobStatus.FINISHED || job.status === jobStatus.COLLECTED">
    </JobDetailViewSection>
    <!--Kundenanschrift END-->

    <JobDetailViewTrackingCodeForm
      :job="job"
      :send-tracking-code="sendTrackingCode"
      v-if="job.status === jobStatus.REALIZATION">
    </JobDetailViewTrackingCodeForm>
  </div>
</template>
<script>
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import {mapActions, mapGetters} from "vuex";

import JobDetailViewTrackingCodeForm from "lingoking-workbench/src/components/jobdetailview/JobDetailViewTrackingCodeForm";
import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import {OrderStatus} from "lingoking-workbench/src/enums";
import JobTypes from "lingoking-workbench/src/enums/JobType";
import JobStatus from "lingoking-workbench/src/enums/JobStatus";

export default {
  name: 'Shipment',
  components: {
    JobDetailViewTrackingCodeForm,
    JobDetailViewSection,
  },
  props: {
    job: {
      type: Object
    },
    linguist: {
      type: Object
    },
    shouldDisplayCustomerAddress: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      alertSuccess: 'alert/success',
      trackingCode: 'job/show/trackingCode',
    }),

    deadlines() {
      return [
        {'head': 'due_on', 'data': this.$moment(this.job.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')}
      ];
    },

    customerAddress() {
      let customerAddress = [];
      let fullName = this.job.order.address['name'] ? this.job.order.address['name'] : this.job.order.address['firstName'] + ' ' + this.job.order.address['lastName']

      if (this.job.order.address['companyName']) {
        customerAddress.push({'head': 'company_name', 'data': this.job.order.address['companyName']})
      }

      customerAddress.push(
        {'head': 'first_and_last_name', 'data': fullName},
        {'head': 'address_street', 'data': this.job.order.address['streetAddress']},
        {'head': 'number', 'data': this.job.order.address['houseNumber']},
        {'head': 'address_suffix', 'data': this.job.order.address['addressSuffix']}
      );
      return customerAddress.concat(this.basicCustomerAddress)
    },

    basicCustomerAddress() {
      return [
        {
          'head': 'postal_code_and_city',
          'data': this.job.order.address['postalCode'] + ' ' + this.job.order.address['addressLocality']
        },
        {
          'head': 'address_country',
          'data': (this.job.order.address['addressCountry']['id'] ? this.job.order.address['addressCountry']['id'] : this.job.order.address['addressCountry'])
        }
      ];
    },
  },

  methods: {
    ...mapActions({
      updateRetrieved: 'job/show/updateRetrieved',
      updateTrackingCode: 'job/show/updateTrackingCode',
    }),

    sendTrackingCode: function (trackingCode, shipmentCarrier) {
      this.updateRetrieved({'trackingCode': trackingCode})
      this.updateRetrieved({'shipmentCarrier': shipmentCarrier})
      this.updateTrackingCode()
    },
  },

  watch: {
    trackingCode: function (trackingCode) {
      if (trackingCode) {
        this.$router.push({name: 'JobList'})
        setTimeout(() => {
          // display success message after route change completes:
          this.alertSuccess('tracking_code_saved', {root: true})
        })
      }
    }
  },
  mounted() {
    if (this.job) {
      if (this.job.order.trackingCode) {
        this.dhlShipmentUrl = this.dhlPrefix + this.job.order.trackingCode.replace(/\s/g, '');
      }
    }
  },
  data() {
    return {
      jobStatus: JobStatus,
      orderStatus: OrderStatus,
      jobTypes: JobTypes,
      dhlPrefix: 'https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=',
      dhlShipmentUrl: ''
    }
  },
}
</script>
