<template>
  <div
    class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom">
    <div class="bx--row">
      <div class="bx--col-xs-12 bx--col-md-12">
        <h2 class="lk-form__step-headline">{{ $t('contact_details') }}</h2>

        <!-- Title / FirstName / LastName -->
        <fieldset>
          <legend>{{ $t('customer_salutation') }}</legend>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              {{ $t('customer_salutation_desc') }}
            </div>
            <div class="bx--form-item bx--col-md-9">
              <div class="bx--radio-button-group" :data-invalid="isInvalid('userTitle')">
                <input
                  :checked="item.userTitle === userTitles.TITLE_MRS"
                  :data-invalid="isInvalid('userTitle')"
                  :value="userTitles['TITLE_MRS']"
                  @change="handleUpdateField('userTitle', $event.target.value)"
                  class="bx--radio-button"
                  id="radio-button-title-mrs"
                  name="userTitle"
                  tabindex="0"
                  type="radio"/>
                <label class="bx--radio-button__label" for="radio-button-title-mrs">
                  <span class="bx--radio-button__appearance"></span>
                  {{ $t('customer_salutation_mrs') }}
                </label>
                <input
                  :checked="item.userTitle === userTitles.TITLE_MR"
                  :data-invalid="isInvalid('userTitle')"
                  :value="userTitles['TITLE_MR']"
                  @change="handleUpdateField('userTitle', $event.target.value)"
                  class="bx--radio-button"
                  id="radio-button-title-mr"
                  name="userTitle"
                  tabindex="0"
                  type="radio"/>
                <label class="bx--radio-button__label" for="radio-button-title-mr">
                  <span class="bx--radio-button__appearance"></span>
                  {{ $t('customer_salutation_mr') }}
                </label>
                <div class="bx--form-requirement" v-if="!$v.item.userTitle.required">
                  {{ $t('customer_salutation') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('userTitle')">
                  {{ getViolation('userTitle') }}
                </div>
              </div>
            </div>
          </div>
          <div class="bx--row">
            <div
              v-bind:class="{ 'bx--offset-md-3 bx--col-md-9' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :data-invalid="isInvalid('firstName')"
                  :value="item.firstName"
                  @change="handleUpdateField('firstName', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-firstname"
                  name="name"
                  :placeholder="$t('first_name')"
                  type="text"
                >
                <label class="bx--label" for="text-input-firstname">{{ $t('first_name') }} *</label>
                <div class="bx--form-requirement" v-if="!$v.item.firstName.required">
                  {{ $t('first_name') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('firstName')">
                  {{ getViolation('firstName') }}
                </div>
              </div>
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :data-invalid="isInvalid('lastName')"
                  :value="item.lastName"
                  @change="handleUpdateField('lastName', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-lastname"
                  name="lastName"
                  :placeholder="$t('last_name')"
                  type="text"
                >
                <label class="bx--label" for="text-input-lastname">{{ $t('last_name') }} *</label>
                <div class="bx--form-requirement" v-if="!$v.item.lastName.required">
                  {{ $t('last_name') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('lastName')">
                  {{ getViolation('lastName') }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- BillingAddress -->
        <fieldset>
          <legend>{{ $t('billing_address') }}</legend>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              {{ $t('billing_address_label') }}
            </div>
            <div v-bind:class="{ 'bx--col-md-4' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :data-invalid="isInvalid('billingAddress', 'streetAddress')"
                  :value="item.billingAddress.streetAddress"
                  @input="handleUpdateAddressField('streetAddress', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-street-address"
                  :placeholder="$t('address_street')"
                  type="text"/>
                <label class="bx--label" for="text-input-street-address">{{ $t('address_street') }} *</label>
                <div class="bx--form-requirement"
                     v-if="!$v.item.billingAddress.streetAddress.required">
                  {{ $t('address_street') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement"
                     v-if="getViolation('billingAddress', 'firstName')">
                  {{ getViolation('billingAddress', 'firstName') }}
                </div>
              </div>
            </div>
            <div v-bind:class="{ 'bx--col-md-2' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input :data-invalid="isInvalid('billingAddress', 'houseNumber')"
                  v-bind:value="item.billingAddress.houseNumber"
                  class="bx--text-input"
                  id="text-input-street-number"
                  :placeholder="$t('number')"
                  type="text"
                  @input="handleUpdateAddressField('houseNumber', $event.target.value)"
                />
                <label class="bx--label" for="text-input-street-number">{{$t('number')}}
                  *</label>
                <div class="bx--form-requirement" v-if="!$v.item.billingAddress.houseNumber.required">
                  {{$t('number')}} {{$t('required')}}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('billingAddress', 'houseNumber')">
                  {{ getViolation('billingAddress','houseNumber') }}
                </div>
              </div>
            </div>
            <div v-bind:class="{ 'bx--col-md-3' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :value="item.billingAddress.addressSuffix"
                  @input="handleUpdateAddressField('addressSuffix', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-address-suffix"
                  :placeholder="$t('address_suffix')"
                  type="text"/>
                <label class="bx--label" for="text-input-street-address">{{ $t('address_suffix') }}</label>
              </div>
            </div>
          </div>
          <div class="bx--row">
            <div
              v-bind:class="{ 'bx--offset-md-3 bx--col-md-4 bx--col-sm-6' : showAdditionalText, 'bx--col-md-4 bx--col-sm-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :data-invalid="isInvalid('billingAddress', 'postalCode')"
                  :value="item.billingAddress.postalCode"
                  @input="handleUpdateAddressField('postalCode', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-postal-code"
                  :placeholder="$t('address_zip_code')"
                  type="text"/>
                <label class="bx--label" for="text-input-postal-code">{{ $t('address_zip_code') }} *</label>
                <div class="bx--form-requirement"
                     v-if="!$v.item.billingAddress.postalCode.required">
                  {{ $t('address_zip_code') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement"
                     v-if="getViolation('billingAddress', 'postalCode')">
                  {{ getViolation('billingAddress', 'postalCode') }}
                </div>
              </div>
            </div>
            <div class="bx--col-md-5 bx--col-sm-6">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :data-invalid="isInvalid('billingAddress', 'addressLocality')"
                  :value="item.billingAddress.addressLocality"
                  @input="handleUpdateAddressField('addressLocality', $event.target.value)"
                  class="bx--text-input"
                  id="text-input-address-locality"
                  :placeholder="$t('address_city')"
                  type="text"/>
                <label class="bx--label" for="text-input-address-locality">{{ $t('address_city') }} *</label>
                <div class="bx--form-requirement"
                     v-if="!$v.item.billingAddress.addressLocality.required">
                  {{ $t('address_city') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement"
                     v-if="getViolation('billingAddress', 'addressLocality')">
                  {{ getViolation('billingAddress', 'addressLocality') }}
                </div>
              </div>
            </div>
          </div>
          <div class="bx--row">
            <div
              v-bind:class="{ 'bx--offset-md-3 bx--col-md-9' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item">
                <div class="bx--select" style="width: 100%">
                  <label class="bx--label" for="multi-select-address-country">
                    {{ $t('address_country') }} *
                  </label>
                  <multiselect
                    :class="[$v.item.billingAddress.addressCountry.$error ? 'is-invalid' : '']"
                    :data-invalid="isInvalid('billingAddress', 'addressCountry')"
                    :hideSelected="true"
                    id="multi-select-address-country"
                    :multiple="false"
                    :options="countryOptions"
                    label="name"
                    :loading="searchIsLoading"
                    track-by="id"
                    :required="true"
                    :taggable="false"
                    v-model="selectedCountry"
                    :show-labels="false"
                    :internal-search="true"
                    :placeholder="config.addressCountry"
                    :tag-placeholder="config.addressCountry"
                  ></multiselect>
                  <div class="bx--form-requirement"
                       v-if="!$v.item.billingAddress.addressCountry.isInvalid">
                    {{ $t('address_country') }} {{ $t('required') }}
                  </div>
                  <div class="bx--form-requirement" v-if="getViolation('billingAddress','addressCountry')">
                    {{ getViolation('billingAddress', 'addressCountry') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- Contact -->
        <fieldset>
          <legend>{{ $t('contact') }}</legend>

          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              {{ $t('phone_description') }}
            </div>
            <div class="bx--col-md-3">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  id="linguist_telephone"
                  :data-invalid="isInvalid('telephone')"
                  :value="item.telephone"
                  :class="['bx--text-input', 'form-control', isInvalid('telephone') ? 'is-invalid' : '']"
                  type="text"
                  :placeholder="$t('phone')"
                  @input="handleUpdateField('telephone', $event.target.value)">
                <label
                  for="linguist_telephone"
                  class="form-control-label bx--label">{{ $t('phone') }} *</label>
                <div class="bx--form-requirement" v-if="!$v.item.telephone.required">
                  {{ $t('phone') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('telephone')">
                  {{ getViolation('telephone') }}
                </div>
              </div>
            </div>
            <div class="bx--col-md-3">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  id="linguist_mobile_phone"
                  :data-invalid="isInvalid('mobilePhone')"
                  :value="item.mobilePhone"
                  :class="['bx--text-input', 'form-control', isInvalid('mobilePhone') ? 'is-invalid' : '']"
                  type="text"
                  :placeholder="$t('mobile_phone')"
                  @input="handleUpdateField('mobilePhone', $event.target.value)">
                <label class="bx--label" for="linguist_mobile_phone">{{ $t('mobile_phone') }}</label>
              </div>
            </div>
            <div class="bx--col-md-3">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  id="linguist_fax_number"
                  :data-invalid="isInvalid('faxNumber')"
                  :value="item.faxNumber"
                  :class="['bx--text-input', 'form-control', isInvalid('faxNumber') ? 'is-invalid' : '']"
                  type="text"
                  :placeholder="$t('fax_number')"
                  @input="handleUpdateField('faxNumber', $event.target.value)"/>
                <label class="bx--label" for="linguist_fax_number">{{ $t('fax_number') }}</label>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- Email -->
        <fieldset>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              {{ config.email_description }}
            </div>
            <div v-bind:class="{ 'bx--col-md-9' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--text-input-wrapper">
                <input
                  :value="item.email"
                  @input="handleUpdateField('email', $event.target.value)"
                  :data-invalid="isInvalid('email')"
                  class="bx--text-input"
                  :disabled="!editMail"
                  id="text-input-email"
                  name="email"
                  :placeholder="$t('email')"
                  type="text"/>
                <label class="bx--label" for="text-input-email">{{ $t('your_email') }} *</label>
                <div class="bx--form-requirement" v-if="!$v.item.email.required">
                  {{config.emailAddressLabel}} required
                </div>
                <div class="bx--form-requirement" v-if="getViolation('email')">
                  {{ getViolation('email') }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- Timezone -->
        <fieldset>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              {{ config.timezone_description }}
            </div>
            <div v-bind:class="{ 'bx--col-md-9' : showAdditionalText, 'bx--col-md-12' : !showAdditionalText }">
              <div class="bx--form-item bx--select" v-if="timezones">
                <label class="bx--label" for="multi-select-timezone">{{ $t('timezone') }} *</label>
                <multiselect
                  :data-invalid="$v.item.timezone.$error"
                  @input="updateTimezoneField"
                  :multiple="false"
                  :options="timezones"
                  :taggable="false"
                  id="multi-select-timezone"
                  :placeholder="$t('select_timezone')"
                  label="timezone"
                  required="true"
                  tag-placeholder="Add a native language"
                  :value="selectedTimezone"></multiselect>
                <div class="bx--form-requirement" v-if="!$v.item.timezone.required">
                  {{ $t('timezone') }} {{ $t('required') }}
                </div>
                <div class="bx--form-requirement" v-if="!$v.item.timezone.inEnum">
                  {{ config.timezone_inEnum }}
                </div>
                <div class="bx--form-requirement" v-if="getViolation('timezone')">
                  {{ getViolation('timezone') }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="showStatus">
          <legend>Status</legend>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3">
              Status
            </div>
            <div class="bx--col-md-2">
              <input
                id="checkbox-enabled"
                name="enabled"
                type="checkbox"
                class="bx--checkbox"
                :checked="item.enabled"
                :value="item.enabled"
                @change="handleUpdateField('enabled', !item.enabled)"
                :data-invalid="isInvalid('enabled')"/>
              <label for="checkbox-enabled" class="bx--checkbox-label">Enable User</label>
            </div>
            <div class="bx--col-md-2">
              <input
                id="checkbox-premium"
                name="premium"
                type="checkbox"
                class="bx--checkbox"
                :checked="item.premium"
                :value="item.premium"
                @change="handleUpdateField('premium', !item.premium)"
                :data-invalid="isInvalid('premium')"/>
              <label for="checkbox-premium" class="bx--checkbox-label">Premium User</label>
            </div>

            <div class="bx--col-md-2">
              <input
                id="checkbox-inhouse_translators"
                name="inhouseTranslators"
                type="checkbox"
                class="bx--checkbox"
                :checked="item.inhouseTranslators"
                :value="item.inhouseTranslators"
                @change="handleUpdateField('inhouseTranslators', !item.inhouseTranslators)"
                :data-invalid="isInvalid('inhouseTranslators')"/>
              <label for="checkbox-inhouse_translators" class="bx--checkbox-label">Inhouse translators</label>
            </div>
          </div>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3">
            </div>
            <div class="bx--col-md-2">
              <input
                id="checkbox-active"
                name="active"
                type="checkbox"
                class="bx--checkbox"
                :checked="item.active"
                :value="item.active"
                @change="handleUpdateField('active', !item.active)"
                :data-invalid="isInvalid('active')"/>
              <label for="checkbox-active" class="bx--checkbox-label">Active</label>
            </div>
            <div class="bx--col-md-2">
              <input
                id="checkbox-confirmed"
                name="confirmed"
                type="checkbox"
                class="bx--checkbox"
                :checked="item.confirmed"
                :value="item.confirmed"
                @change="handleUpdateField('confirmed', !item.confirmed)"
                :data-invalid="isInvalid('confirmed')"/>
              <label for="checkbox-confirmed" class="bx--checkbox-label">Confirmed</label>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="showLinguistGroups">
          <legend>LSP Gruppen</legend>
          <div class="bx--row">
            <div class="lk-form-desc bx--col-md-3" v-if="showAdditionalText">
              Gruppen von Linguisten, zu denen dieser Benutzer gehört.
            </div>

            <div class="bx--col-md-4">
              <div class="bx--form-item bx--select">
                <multiselect
                  id="linguist_groups"
                  placeholder="Type to find a group by name"
                  track-by="@id"
                  label="name"
                  v-model="selectedGroups"
                  :options="groupOptions"
                  :multiple="true"
                  :searchable="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :hideSelected="false"
                  :show-no-results="true"
                  :loading="isLoading"
                  :options-limit="300"
                  @search-change="debouncedGetGroups">
                  <template slot="selection" slot-scope="{ values }">
                    <span class="multiselect__single" v-if="values.length">{{ values.length }} group(s) selected</span>
                  </template>
                  <span slot="noResult">No linguist groups found.</span>
                </multiselect>

                <div class="invalid-feedback" v-if="isInvalid('groups')">
                  {{ violations.groups }}
                </div>
              </div>
            </div>
            <div class="bx--col-md-4">
              <div class="form-group bx--form-item bx--checkbox-wrapper" style="margin-top: 5%;">
                <input
                  :class="['bx--checkbox', 'bx--checkbox-new', 'form-control', isInvalid('standard') ? 'is-invalid' : '']"
                  id="onlySelectedCustomers"
                  type="checkbox"
                  v-model="onlySelectedGroups">
                <label
                  class="form-control-label bx--checkbox-label"
                  for="onlySelectedCustomers">Show Only Selected Linguist Groups</label>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- Password START -->
        <LinguistPasswordForm
          v-if="showPassword"
          :config="config"
          :item="item"
          :$v="$v"
          :get-violation="getViolation"
          :is-invalid="isInvalid"
          :handle-update-field="handleUpdateField"></LinguistPasswordForm>
        <!-- Password END -->
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../form/form.scss';
  import Multiselect from 'vue-multiselect'
  import Tooltip from './../tooltip/Tooltip.vue';
  import {UserTitles} from './../../enums'
  import LinguistPasswordForm from "./LinguistPasswordForm";
  import {mapActions, mapGetters} from "vuex";
  import { debounce } from 'lodash';

  export default {
    name: 'ContactDetailsForm',
    components: {
      Multiselect,
      Tooltip,
      LinguistPasswordForm
    },
    props: {
      editMail: {
        type: Boolean,
        default: false
      },
      config: {
        type: Object,
        required: true
      },
      showPassword:{
        type: Boolean,
      },
      isInvalid: {
        type: Function,
        required: false
      },
      handleUpdateField: {
        type: Function,
        required: false
      },
      item: {
        type: Object,
        required: false
      },
      getViolation: {
        type: Function,
        required: false
      },
      handleUpdateAddressField: {
        type: Function,
        required: true
      },
      handleUpdateAddressCountryField: {
        type: Function,
        required: true
      },
      countries: {
        type: Array
      },
      timezones: {
        type: Array
      },
      $v: {
        type: Object,
        required: true
      },
      showAdditionalText: {
        type: Boolean,
        default: false
      },
      showLinguistGroups: {
        type: Boolean,
        default: false
      },
      showStatus: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      searchForCountry: {
        type: Function
      }
    },
    data() {
      return {
        userTitles: UserTitles,
        addressCountry: null,
        selectedTimezone: null,
        // LSP Groups
        onlySelectedGroups: false,
        selectedGroupsData: null,
        debouncedGetGroups: null,
        // Others
        emptyQuery: true,
      }
    },
    methods: {
      ...mapActions({
        getGroups: 'linguistgroup/list/default',
      }),

      updateTimezoneField(value) {
        this.selectedTimezone = value
        this.handleUpdateField('timezone', value['label'])
      },

      getLinguistGroupsOptions (query) {
        this.emptyQuery = true;

        if(query) {
          this.emptyQuery = false;
          // this.isLoading = true;

          this.getGroups('linguist_groups?name=' + query).then(response => {
            // this.isLoading = false;
          });
        }
      }
    },
    computed: {
      ...mapGetters({
        groups: 'linguistgroup/list/items'
      }),

      searchIsLoading() {
        return this.isLoading || false
      },

      countryOptions() {
        return this.countries || []
      },

      groupOptions() {
        if(this.onlySelectedGroups || this.emptyQuery) {
          return this.selectedGroups || []
        }

        return this.groups || []
      },

      selectedCountry: {
        get() {
          if (this.addressCountry) {
            return this.addressCountry;
          }
          return this.countries.find(country => country.id === this.item.billingAddress.addressCountry);
        },
        set(country) {
          this.addressCountry = country;
          this.handleUpdateAddressCountryField(country.id);
        }
      },

      selectedGroups: {
        get: function () {
          return this.selectedGroupsData || this.item.groups
        },
        set: function (newValue) {
          this.selectedGroupsData = newValue
          this.handleUpdateField('groups', newValue)
        }
      },
    },
    watch: {
      item(item) {
        this.selectedTimezone = this.timezones.find(t => t.label === item.timezone)
      }
    },
    created() {
      // Delay the query trigger to one second after typing:
      this.debouncedGetGroups = debounce(this.getLinguistGroupsOptions.bind(this), 1000);
    }
  }
</script>
