import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({ commit }, createData) => {
  commit(types.MEDIAOBJECT_CREATE_SET_ERROR, '')
  commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)

  let type = createData.type

  return fetch('media_objects', { method: 'POST', data: createData.values })
    .then((response) => {
      commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)

      return response
    })
    .then((data) => {
      if(type === 'reference') {
        commit(types.MEDIAOBJECT_CREATE_SET_CREATED_REFERENCE, data)
      }
      else {
        commit(types.MEDIAOBJECT_CREATE_SET_CREATED, data)
      }
    })
    .catch((e) => {
      commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)
      if (e instanceof SubmissionError) {
        commit(types.MEDIAOBJECT_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.MEDIAOBJECT_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.MEDIAOBJECT_CREATE_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.MEDIAOBJECT_CREATE_RESET)
}
