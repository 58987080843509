import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const reset = ({ commit }) => {
  commit(types.RESET)
}

export const retrieve = ({ commit }, id) => {
  commit(types.TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export const update = ({ commit, state }) => {
  commit(types.SET_ERROR, '')
  commit(types.TOGGLE_LOADING)

  return fetch(state.retrieved['@id'], {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json' },
    data: JSON.stringify(state.retrieved)
  })

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      const errorMessage = (e.errors && e.errors._error) ? e.errors._error : e.message;
      commit(types.SET_ERROR, errorMessage)
    })
}

export const updateRetrieved = ({ commit }, updated) => {
  commit(types.UPDATE_RETRIEVED, updated)
}

export const upload = ({ commit }, values) => {
  commit(types.SET_UPLOAD_ERROR, '')
  commit(types.TOGGLE__FILE_UPLOAD_LOADING)

  return fetch('/linguists/upload', { method: 'POST', data: values.fileUploadForm })
    .then((response) => {
      commit(types.TOGGLE__FILE_UPLOAD_LOADING)

      return response
    })
    .then((data) => {
      commit(types.SET_UPLOAD_UPDATED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE__FILE_UPLOAD_LOADING)
      if (e instanceof SubmissionError) {
        commit(types.SET_UPLOAD_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.SET_UPLOAD_ERROR, e.errors._error)
        return
      }

      commit(types.SET_UPLOAD_ERROR, e.message)
    })
}
