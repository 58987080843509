import * as types from './mutation_types'

export default {
  [types.JOB_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: null,
      acceptedOrRejected: null,
      trackingCode: null,
      shipmentCarrier: null,
    })
  },

  [types.JOB_SHOW_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.JOB_SHOW_SET_RETRIEVED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },

  [types.JOB_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.JOB_SHOW_UPDATE_RETRIEVED] (state, updated) {
    Object.assign(state.retrieved, updated)
  },

  [types.JOB_SET_UPLOAD_UPDATED] (state, updated) {
    Object.assign(state, { uploadError: '', uploadUpdated: updated, uploadViolations: null })
  },

  [types.JOB_SET_UPLOAD_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.JOB_SET_UPLOAD_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.JOB_TOGGLE_FILE_UPLOAD_LOADING] (state) {
    Object.assign(state, {fileUploadIsLoading: !state.fileUploadIsLoading})
  },

  [types.JOB_SHOW_SET_ACCEPTED_OR_REJECTED](state, acceptedOrRejected) {
    Object.assign(state, {acceptedOrRejected})
  },

  [types.JOB_SHOW_SET_TRACKING_CODE](state, trackingCode) {
    Object.assign(state, {trackingCode})
  },

  [types.JOB_SHOW_SET_PM](state, projectManager) {
    Object.assign(state, {projectManager})
  },
}
