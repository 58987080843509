<template>
  <div>
    <div :key="n" class="bx--row lk-form__skill-wrapper" :class="skills[n-1]['actionStatus'] ? 'lk-form__skill-wrapper--' + skills[n-1]['actionStatus'].replace(' ', '-') : ''" v-for="n in skills.length">
      <div class="bx--col-md-12">
        <div class="bx--row">
          <div class="lk-form-desc bx--col-md-3">
            <button @click="removeSkill(n)" class="lk-form__remove-btn" type="button" v-if="skills.length > 1">
              <span class="lk-carbon-icon lk-carbon-icon-close--glyph"></span>
            </button>
            {{ label }} {{ n }}{{ n === 1 ? '*' : '' }}
          </div>
          <div class="bx--col-md-9">
            <div class="bx--row">
              <div class="bx--col-md-4">
                <div :data-invalid="$v.item.skills.$error && !skills[n-1]['sourceLanguage']"
                     class="form-group bx--form-item bx--select-wrapper bx--select"
                     v-if="allLanguagesOptions">
                  <multiselect
                    :class="[$v.item.skills.$error && !skills[n-1]['sourceLanguage'] ? 'is-invalid' : '']"
                    :data-invalid="$v.item.skills.$error && !skills[n-1]['sourceLanguage']"
                    :hideSelected="true"
                    :id="inputIdPrefix + '-source-language-' + n"
                    :multiple="false"
                    :options="allLanguagesOptions"
                    :taggable="false"
                    @input="updateSkills"
                    label="name"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    placeholder="Source language"
                    required="true"
                    tag-placeholder="Source language"
                    track-by="id"
                    v-model="skills[n-1]['sourceLanguage']"></multiselect>
                  <label
                    :for="inputIdPrefix + '-source-language-' + n"
                    class="form-control-label bx--label">{{ config.sourceLabel }} *</label>

                  <div class="bx--form-requirement"
                       v-if="$v.item.skills.$error && !skills[n-1]['sourceLanguage']">
                    {{ config.sourceRequired }}
                  </div>
                </div>
              </div>
              <div class="bx--col-md-4">
                <div :data-invalid="$v.item.skills.$error && !skills[n-1]['targetLanguage']"
                     class="form-group bx--form-item bx--select-wrapper bx--select"
                     v-if="allLanguagesOptions">
                  <multiselect
                    :class="[$v.item.skills.$error && !skills[n-1]['targetLanguage'] ? 'is-invalid' : '']"
                    :data-invalid="$v.item.skills.$error && !skills[n-1]['targetLanguage']"
                    :hideSelected="true"
                    :id="inputIdPrefix + '-target-language-' + n"
                    :multiple="false"
                    :options="allLanguagesOptions"
                    :taggable="false"
                    @input="updateSkills"
                    label="name"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    placeholder="Target language"
                    required="true"
                    tag-placeholder="Target language"
                    track-by="id"
                    v-model="skills[n-1]['targetLanguage']"></multiselect>
                  <label
                    :for="inputIdPrefix + '-target-language-' + n"
                    class="form-control-label bx--label">Target language *</label>

                  <div class="bx--form-requirement"
                       v-if="$v.item.skills.$error && !skills[n-1]['targetLanguage']">
                    {{ config.targetError }}
                  </div>
                </div>
              </div>
              <div class="bx--col-md-4">
                <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="topicItems">
                  <multiselect
                    :class="[$v.item.skills.$error ? 'is-invalid' : '']"
                    :hideSelected="true"
                    :id="inputIdPrefix + '-expertise-' + n"
                    :multiple="true"
                    :options="topicItems"
                    :required="false"
                    :taggable="false"
                    @input="updateSkills"
                    label="title"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    placeholder="Areas of expertise"
                    tag-placeholder="Areas of expertise"
                    track-by="id"
                    v-model="skills[n-1]['topics']"></multiselect>
                  <label
                    :for="inputIdPrefix + '-expertise-' + n"
                    class="form-control-label bx--label">{{ config.areaLabel }}</label>
                </div>
              </div>
            </div>
            <div class="bx--row">
              <div class="bx--col-md-12">
                <div
                  class="bx--checkbox-wrapper lk-checkbox-wrapper lk-checkbox-wrapper--inline lk-checkbox-wrapper--with-tooltip"
                  v-if="skillQualities.includes(SkillQualities.BIDIRECTIONAL) || hasSkillQuality(SkillQualities.BIDIRECTIONAL)">
                  <input
                    :disabled="bidirectionalSkillQuality && bidirectionalSkillQuality.disabled"
                    :id="inputIdPrefix + '-bidirectional-' + n"
                    :required="bidirectionalSkillQuality && bidirectionalSkillQuality.required"
                    @input="updateSkillsCheckbox(n-1, 'bidirectional', !skills[n-1]['bidirectional'])"
                    class="bx--checkbox"
                    type="checkbox"
                    v-if="bidirectionalSkillQuality"
                    v-model="skills[n-1]['bidirectional']"/>

                  <input
                    :id="inputIdPrefix + '-bidirectional-' + n"
                    @input="updateSkillsCheckbox(n-1, 'bidirectional', !skills[n-1]['bidirectional'])"
                    class="bx--checkbox"
                    type="checkbox"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    v-else
                    v-model="skills[n-1]['bidirectional']"/>

                  <label :for="inputIdPrefix + '-bidirectional-' + n" class="bx--checkbox-label">{{
                    config.bidirectionalLabel }}</label>
                </div>
                <div
                  class="bx--checkbox-wrapper lk-checkbox-wrapper lk-checkbox-wrapper--inline lk-checkbox-wrapper--with-tooltip"
                  v-if="skillQualities.includes(SkillQualities.SIMULTANEOUS)">
                  <input
                    :id="inputIdPrefix + '-simultaneous-' + n"
                    @input="updateSkillsCheckbox(n-1, 'simultaneous', !skills[n-1]['simultaneous'])"
                    class="bx--checkbox"
                    type="checkbox"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    v-model="skills[n-1]['simultaneous']"/>
                  <label :for="inputIdPrefix + '-simultaneous-' + n" class="bx--checkbox-label">{{
                    config.simultaneousLabel }}</label>

                </div>
                <div
                  class="bx--checkbox-wrapper lk-checkbox-wrapper lk-checkbox-wrapper--inline lk-checkbox-wrapper--with-tooltip"
                  v-if="skillQualities.includes(SkillQualities.SWORN)">
                  <input
                    :id="inputIdPrefix + '-sworn-' + n"
                    @input="updateSkillsCheckbox(n-1, 'sworn', !skills[n-1]['sworn'])"
                    class="bx--checkbox"
                    type="checkbox"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    v-model="skills[n-1]['sworn']"/>
                  <label :for="inputIdPrefix + '-sworn-' + n" class="bx--checkbox-label">{{ config.swormLabel }}</label>

                </div>
                <div
                  class="bx--checkbox-wrapper lk-checkbox-wrapper lk-checkbox-wrapper--inline lk-checkbox-wrapper--with-tooltip"
                  v-if="skillQualities.includes(SkillQualities.ONLY_EXPERTISE_AREA)">
                  <input
                    :id="inputIdPrefix + '-only-expertise-area-' + n"
                    @input="updateSkillsCheckbox(n-1, 'only-expertise-area', !skills[n-1]['only-expertise-area'])"
                    class="bx--checkbox"
                    type="checkbox"
                    :disabled="isEditable(skills[n-1]['actionStatus'])"
                    v-model="skills[n-1]['only-expertise-area']"/>
                  <label :for="inputIdPrefix + '-only-expertise-area-' + n" class="bx--checkbox-label">Only expertise area</label>
                </div>
              </div>
            </div>
            <div class="bx--row" v-if="showStatus">
              <div class="bx--col-md-12">
                <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="!skillStatusReadonly">
                  <multiselect
                    :class="[$v.item.skills.$error ? 'is-invalid' : '']"
                    :hideSelected="true"
                    :id="inputIdPrefix + '-action-status-' + n"
                    :multiple="false"
                    :options="actionStatuses"
                    :required="false"
                    :show-labels="false"
                    :taggable="false"
                    @input="updateSkills"
                    placeholder="Action status"
                    tag-placeholder="Action status"
                    v-model="skills[n-1]['actionStatus']"></multiselect>
                  <label
                    :for="inputIdPrefix + '-action-status-' + n"
                    class="form-control-label bx--label">{{ config.actionStatusLabel }}</label>
                </div>

                <div class="form-group bx--form-item bx--text-input-wrapper" v-else-if="skills[n-1]['actionStatus']">
                  <label class="form-control-label bx--label">Accreditation status</label>
                  <InlineNotification
                    :closeButton="false"
                    subtitle=""
                    icon="lk-carbon-icon lk-carbon-icon-checkmark--glyph"
                    kind="success"
                    notificationClass="bx--inline-notification bx--inline-notification--success"
                    role="alert"
                    :title="skills[n-1]['actionStatus']"
                    v-if="skills[n-1]['actionStatus'] === 'accredited'"
                  />
                  <InlineNotification
                    :closeButton="false"
                    :subtitle="skills[n-1]['rejectReason'] ? skills[n-1]['rejectReason'] : ''"
                    icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                    kind="error"
                    notificationClass="bx--inline-notification bx--inline-notification--error"
                    role="alert"
                    :title="skills[n-1]['actionStatus']"
                    v-else-if="skills[n-1]['actionStatus'] === 'denied'"
                  />
                  <InlineNotification
                    :closeButton="false"
                    :subtitle="config.note"
                    icon="lk-carbon-icon lk-carbon-icon-warning"
                    kind="error"
                    notificationClass="bx--inline-notification bx--inline-notification--warning"
                    role="alert"
                    title="in review"
                    v-else-if="!skills[n-1]['actionStatus'] || skills[n-1]['actionStatus'] === 'in review'"
                  />
                  <!--<label
  :for="inputIdPrefix + '-action-status-readonly-' + n"
  class="form-control-label bx--label">Accreditation status</label>-->
                  <!--<input
                    :id="inputIdPrefix + '-action-status-readonly-' + n"
                    :value="skills[n-1]['actionStatus']"
                    class="bx--text-input"
                    disabled="disabled"/>-->
                </div>
              </div>


              <div class="bx--col-md-8" v-if="skills[n-1]['actionStatus'] === 'denied'">
                <div class="bx--form-item bx--text-area-wrapper" v-if="!skillStatusReadonly">
                  <textarea
                    :id="inputIdPrefix + '-reject-reason-' + n"
                    @input="updateSkills"
                    class="bx--text-area form-control"
                    placeholder="Reject reason"
                    type="text"
                    v-model="skills[n-1]['rejectReason']"></textarea>
                  <label
                    :for="inputIdPrefix + '-reject-reason-' + n"
                    class="form-control-label bx--label">{{ config.rejectReasonLabel }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../../form/form.scss'
  import Multiselect from 'vue-multiselect'
  import Tooltip from '../../tooltip/Tooltip.vue'
  import InlineNotification from '../../notification/InlineNotification.vue'
  import {SkillQualities} from './../../../enums';

  export default {
    name: 'SkillForm',
    components: {
      InlineNotification,
      Multiselect,
      Tooltip
    },
    props: {
      config: {
        type: Object,
        required: true
      },
      showStatus: {
        type: Boolean,
        dafault: false
      },
      label: {
        type: String,
        default: ''
      },

      removeButtonLabel: {
        type: String,
        default: 'Remove'
      },

      removeSkill: {
        type: Function,
        required: true
      },

      $v: {
        type: Object,
        required: true
      },

      allLanguagesOptions: {
        type: Array
      },

      topicItems: {
        type: Array
      },

      actionStatuses: {
        type: Array
      },

      inputIdPrefix: {
        type: String,
        required: true
      },

      skills: {
        required: true
      },

      skillQualities: {
        required: true
      },

      updateSkills: {
        type: Function
      },
      skillStatusReadonly: {
        type: Boolean
      }
    },

    methods: {
      updateSkillsCheckbox: function (n, field, value) {
        this.skills[n][field] = value
        let index = this.skills[n]['skillQualities'].indexOf(field)

        if (!value) {
          this.skills[n]['skillQualities'].splice(index, 1)
        } else if (value && index === -1) {
          this.skills[n]['skillQualities'].push(field)
          // array unique
          this.skills[n]['skillQualities'] = this.skills[n]['skillQualities'].filter((v, i, a) => a.indexOf(v) === i)
        }

        this.updateSkills()
      },
      hasSkillQuality: function (quality) {
        let matchedQuality = this.skillQualities.filter(function (skillQuality) {
          return skillQuality.type === quality
        })

        return matchedQuality.length
      },

      statusIcon: function (status) {
        return status === 'in review' ? 'lk-carbon-icon lk-carbon-icon-spinner' : (status === 'denied' ? 'lk-carbon-icon lk-carbon-icon-close' : 'lk-carbon-icon lk-carbon-icon-checkmark')
      },

      isEditable: function (status) {
        if(typeof status === 'undefined' || !this.skillStatusReadonly) {
          return false
        }

        return status !== 'in review'
      }
    },

    computed: {
      // TODO loop skill qualities!
      bidirectionalSkillQuality() {
        return this.skillQualities.find(function (skillQuality) {
          return skillQuality.type === SkillQualities.BIDIRECTIONAL
        })
      }
    },

    data() {
      return {
        SkillQualities: SkillQualities
      }
    }
  }
</script>
