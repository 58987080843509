import AccountSetup from '../components/account/Setup'
import AccountSetupLSP from '../components/account/SetupLsp'
import QuickAccess from '../components/account/QuickAccess'
import FinanceInvoiceList from '../components/finance/customer/invoices/List';

// import Favorites from '../components/account/Favorites'

export default [
  {
    name: 'AccountSetup',
    path: '/account/setup',
    component: AccountSetup,
    meta: {
      resource: 'Account',
      action: 'update',
      customerAuth: true,
      authRequired: true
    }
  },
  {
    name: 'AccountSetupLSP',
    path: '/account/setup-lsp',
    component: AccountSetupLSP,
    meta: {
      resource: 'Account',
      action: 'update',
      lspAuth: true,
      authRequired: true
    }
  },
  // {
  //   name: 'Favorites',
  //   path: '/favorites',
  //   component: Favorites,
  //   meta: {
  //     resource: 'Favorites',
  //     action: 'update',
  //     customerAuth: true,
  //     authRequired: true
  //   }
  // },
  {
    name: 'QuickAccess',
    path: '/quick-access',
    component: QuickAccess,
    meta: {
      resource: 'QuickAccess',
      action: 'update',
      customerAuth: true,
      authRequired: true
    }
  },
  {
    name: 'Bills',
    path: '/bills',
    component: FinanceInvoiceList,
    meta: {
      customerAuth: true,
      authRequired: true
    }
  }
]
