<template>
  <div class="lk-data-table-container lk-checkbox-filter">
    <div class="lk-data-table">
      <div class="lk-checkbox-filter__label-wrapper">
        <label class="bx--label">{{ $t('filter_by') }}</label>
      </div>
      <div v-for="filterValue in filterData" class="bx--form-item bx--checkbox-wrapper">
        <input
          :id="filterValue.id"
          :placeholder="filterValue.label"
          type="checkbox"
          :value="filterValue.value" v-model="checkedData"
          @change="filter()"
          class="bx--checkbox bx--checkbox-new form-control">
        <label :for="filterValue.id" class="form-control-label bx--checkbox-label">{{ $t(filterValue.label) }}</label>
      </div>
      <div class="bx--form-item bx--checkbox-wrapper">
        <a @click="clearAll()" class="lk-checkbox-filter__clear-all" href="#">{{ $t('clear_all') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import './checkboxfilter.scss';

  export default {
    name: "CheckboxFilter",

    props: {
      filterData: Array
    },
    data() {
      return {
        checkedData: []
      }
    },
    methods: {
      clearAll() {
        this.checkedData = [];
        this.filter();
      },

      filter() {
        this.$emit('filter', this.checkedData);
      }
    }
  }
</script>
