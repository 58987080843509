import update from './update'
import show from './show'

export default {
  namespaced: true,
  modules: {
    update,
    show
  }
}
