<template>
  <layout-default>
    <Loading v-if="isLoading"/>

    <div>
      <div class="bx--row">
        <div class="bx--col-md-4">
          <h1 class="lk-service-backend__headline">{{ $tc('credit_note', 2) }}</h1>
        </div>
        <div class="bx--offset-md-4 bx--col-md-4">
          <div class="lk--fe--searchbar">
            <div class="bx--search bx--search--sm bx--search--light"
                 data-search role="search">
              <button
                @click="searchItems"
                class="bx--btn bx--btn--ghost bx--btn--sm bx--search__button lk-carbon-icon lk-carbon-icon-search lk-finance-customer-invoices_search-icon">
              </button>
              <input
                class="bx--search-input" id="search__input "
                :placeholder="$t('searchCcn')"
                role="search"
                type="text"
                v-model="searchString" v-on:keyup.enter="searchItems">
            </div>
          </div>
        </div>
      </div>
      <InlineNotification
        v-if="error"
        notificationClass="bx--inline-notification bx--inline-notification--error"
        kind="error"
        role="alert"
        icon="lk-carbon-icon lk-carbon-icon-close--glyph"
        :title="error"
        :subtitle="error"
        :closeButton="true"
      />
      <div class="bx--row">
<!--        <div class="bx&#45;&#45;col-xs-12 bx&#45;&#45;col-md-2">-->
<!--          <div class="lk-list-view">-->
<!--            <CheckboxFilter-->
<!--              @filter="filter"-->
<!--              :filter-data="filterData"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
        <div class="bx--col-xs-12 bx--col-md-12">
          <FeTable
            :hide-header="totalItems === 0"
            :columns="columns"
            :items="items"
            @sort="sort($event)"
          >
            <template slot="downloadButton" slot-scope="items">
              <JwtDownloadButton
                :btn-class="['bx--table-expand-v2__button', 'lk-data-table-expand__button']"
                :download-url="renderDownloadUrl(items.item)"
                :download-name="renderDownloadName(items.item)">
                <template>
                  <span class="lk-carbon-icon lk-carbon-icon-download-rounded lk-carbon-icon-2point5x"></span>
                </template>
              </JwtDownloadButton>
            </template>
          </FeTable>

          <pagination
            class="lk-container--layer-raised lk-pagination--borderless"
            v-if="totalItems>itemsPerPage"
            :cur-page="currentPage"
            :current-page="currentPage"
            :current-page-change-parent="currentPageChange"
            :get-page="goToPage"
            :items-length="items.length"
            :items-per-page="itemsPerPage"
            :items-per-page-change-parent="itemsPerPageChange"
            :total-items="totalItems"
            :total-pages="totalPages"
            :view="view">
          </pagination>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import LayoutDefault from '../../../layouts/LayoutDefault.vue';
  import CheckboxFilter from 'lingoking-workbench/src/components/checkboxfilter/CheckboxFilter.vue';
  import Pagination from 'lingoking-workbench/src/components/datatable/Pagination.vue';
  import FeTable from "lingoking-workbench/src/components/fetable/FeTable.vue";
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import {ENTRYPOINT} from './../../../../config/entrypoint';
  import JwtDownloadButton from 'lingoking-workbench/src/components/jwtdownloadbutton/JwtDownloadButton.vue';
  import {OrderStatus} from 'lingoking-workbench/src/enums/index.js';
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import {userService} from "../../../../_services";

  export default {
    name: "CreditNoteList",

    components: {
      LayoutDefault,
      CheckboxFilter,
      Pagination,
      FeTable,
      Loading,
      JwtDownloadButton,
      InlineNotification
    },

    computed: {
      ...mapState({
        //
      }),
      ...mapGetters({
        ccn: 'finance/lspCcnList/items',
        currentPage: 'finance/lspCcnList/currentPage',
        totalItems: 'finance/lspCcnList/totalItems',
        view: 'finance/lspCcnList/view',
        items: 'finance/lspCcnList/items',
        isLoading: 'finance/lspCcnList/isLoading',
        error: 'finance/lspCcnList/error',
      }),

      totalPages() {
        return this.totalItems < this.itemsPerPage ? 1 : Math.ceil(this.totalItems / this.itemsPerPage).toFixed(0);
      }
    },

    created() {
      this.getCcn(this.ccnUrl + this.defaultSortParam + '&itemsPerPage=' + this.itemsPerPage + '&page=' + this.curPage);
    },

    data() {
      return {
        apiUrl: ENTRYPOINT,
        ccnUrl: '/ccns?paymentStatus[exists]=true&cancelled=false&orderStatus='+OrderStatus.ORDER_DELIVERED.schema+'&',
        defaultSortParam: 'orderBy[createdAt]=desc',
        itemsPerPage: 10,
        curPage: 1,
        searchString: '',
        searchFields: ['receiptNumber'],
        columns: [
          {
            label: this.$i18n.t('name'),
            sortable: true,
            index: 'name',
            header: true
          },
          {
            label: this.$i18n.t('ccn_number'),
            sortable: true,
            index: 'receiptNumber',
            renderFcn: this.renderReceiptNumber
          },
          {
            label: this.$i18n.t('creation_date'),
            sortable: true,
            index: 'createdAt',
            renderFcn: this.renderDate
          },
          {
            label: this.$i18n.t('ccn_amount'),
            sortable: true,
            index: 'finalFee',
            renderFcn: this.renderPrice
          },
          {
            label: '',
            sortable: false,
            index: 'download',
            icon: 'lk-carbon-icon-download-rounded lk-carbon-icon-2point5x',
            custom: true,
            columnSlotName: 'downloadButton'
          }

        ],
        filterData: [
          {
            id: 'filter-cancelled',
            label: 'Cancelled',
            value: 'cancelled=true'
          },
          {
            id: 'filter-order-delivered',
            label: 'Order Delivered',
            value: 'orderDelivered=orderDelivered'
          },
          {
            id: 'filter-order-in-transit',
            label: 'Order In Transit',
            value: 'orderInTransit=orderInTransit'
          }
        ],
        sortByIndex: '',
        sortDirection: '',
        searchBy: [
          'receiptNumber',
        ],
        emptyDashboard: true
      }
    },

    methods: {
      ...mapActions({
        getCcn: 'finance/lspCcnList/default',
      }),

      searchItems() {
        this.requestCcn(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      currentPageChange(newCurrentPage) {
        if (newCurrentPage) {
          this.curPage = newCurrentPage;
        }
        this.requestCcn(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      goToPage(page) {
        if (page) {
          const user = userService.getUserData();
          const userId = user.id;
          page = page.replace('linguists/' + userId + '/', '');
          this.getCcn(page);
          if (this.items.length < this.itemsPerPage) {
            this.curPage = 1;
          }
        }
      },

      itemsPerPageChange(newItemsPerPage) {
        if (newItemsPerPage) {
          this.itemsPerPage = newItemsPerPage;
        }
        if (this.items.length < this.itemsPerPage) {
          this.curPage = 1
        }
        this.requestCcn(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection);
      },

      filter(data) {
        let search = '';
        data.forEach(function (item) {
          search += item + "&"
        });
        this.getCcn(this.ccnUrl + search + 'itemsPerPage=' + this.itemsPerPage);
      },
      renderReceiptNumber(item) {
        return item.receiptNumber ? item.receiptNumber : ''
      },

      renderPrice(item) {
        return item.finalFee + " €";
      },

      renderDate(item, field) {
        return this.$moment(item[field]).format("DD.MM.YYYY");
      },

      renderDownloadUrl(item) {
        return '/ccns/' + item.id + '/download_pdf';
      },
      renderDownloadName(item) {
        const timestamp = new Date().getTime();
        return `ccn_${item.receiptNumber}_${timestamp}.pdf`;
      },

      sort({sortDirection, sortByIndex}) {
        this.sortByIndex = sortByIndex;
        this.sortDirection = sortDirection;
        this.requestCcn(this.searchString, this.curPage, this.itemsPerPage, this.sortByIndex, this.sortDirection)
      },

      requestCcn(term, page, itemsPerPage, sortByIndex, sortDirection) {

        if (term !== null && term !== '') {
          let generateUrl = '';
          this.searchBy.forEach(function (item) {
            generateUrl += item + '=' + term + "&"
          });
          term = generateUrl;
        }

        if (sortByIndex !== null && sortDirection !== null && sortByIndex !== '' && sortDirection !== '') {
          sortByIndex = 'orderBy[' + sortByIndex + ']=' + sortDirection + '&';
        } else {
          sortByIndex = this.defaultSortParam;
        }

        if (itemsPerPage !== null) {
          itemsPerPage = 'itemsPerPage=' + itemsPerPage + '&';
        }

        if (page !== null) {
          page = 'page=' + page;
        }

        let generateUrl = this.ccnUrl + term + sortByIndex + itemsPerPage + page;
        this.getCcn(generateUrl);
      },
    },

    watch: {
      ccn: function (items) {
        this.emptyDashboard = !(items && items.length);
      }
    },
  }
</script>
