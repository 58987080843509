<template>
  <div>
    <InlineNotification
      v-if="acceptedOrRejected"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(approvalMessage)"
      :closeButton="true"
    />
    <InlineNotification
      v-if="mediaObjectDeleteError"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="mediaObjectDeleteError"
      :closeButton="true"
    />
    <InlineNotification
      v-if="mediaObjectDeleted && !mediaObjectDeleteError"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t('delete_file_success')"
      :closeButton="true"
    />
    <InlineNotification
      :closeButton="true"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      kind="success"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      role="alert"
      :subtitle="$t('upload_file_success')"
      :title="$t('success')"
      v-if="showUploadSuccess"
    />

    <div style="margin-top: 1rem;">
      <JobDetailStatus :job="job"/>
      <JobDetailViewActionButtons v-if="job.status === jobStatus.REALIZATION" :buttons="headerActions"/>
    </div>
    <!-- Shipment -->

    <JobDetailViewSection
        :headline="$t('tracking_code')"
        v-if="job.status !== jobStatus.REALIZATION && job.order.trackingCode">
      <template slot="extra-content">
        <h5 class="lk-detailview__table-left">{{ $t('tracking_code')}}: {{ job.order.trackingCode }}</h5>
        <a :href="dhlShipmentUrl" class="lk-detailview__table-right" target="_blank">
          {{ $t('track_shipment')}}
        </a>
      </template>
    </JobDetailViewSection>

    <!-- JOB TARGET FILES DOWNLOAD START -->
    <JobDetailViewSection
      :headline="$t('download_translation')"
      :download-action="downloadAction"
      :downloads="targetFilesDownload"
      :admin="admin"
      v-if="job.status === jobStatus.REALIZATION">
    </JobDetailViewSection>
    <!-- JOB TARGET FILES DOWNLOAD END -->

    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD START -->
    <JobDetailViewSection
      :border="!(job.order.status === orderStatus.ORDER_PROCESSING.schema && job.status !== jobStatus.FINISHED)"
      :headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      :downloads="translationSourceFiles"
      :table="jobOrderQuantity"
      :download-action="downloadAction"
      :remove-action="job.order.status === orderStatus.ORDER_PROCESSING.schema ? removeTranslationSourceFile : null"
      :remove-enabled="removeEnabled"
      :admin="admin"
      v-if="translationSourceFiles">
    </JobDetailViewSection>
    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD END -->

    <!-- JOB TRANSLATION SOURCE FILES UPLOAD START -->
    <JobDetailViewUploadSection
      :headline="$t('file_upload')"
      :text="''"
      :upload-action="uploadTranslationSourceFiles"
      :upload-action-label="$t('save')"
      :files="newTranslationSourceFiles"
      :handle-change="handleFileUploadChange"
      handleChangeName="translation-source-files"
      v-if="(job.order.status === orderStatus.ORDER_PROCESSING.schema && job.status !== jobStatus.REALIZATION && job.status !== jobStatus.FINISHED) && translationSourceFiles.length < 1">
    </JobDetailViewUploadSection>
    <!-- JOB TRANSLATION SOURCE FILES UPLOAD END -->

    <!-- JOB TARGET FILES DOWNLOAD START -->
    <JobDetailViewSection
      :download-action="downloadAction"
      :headline="$t('your_translation_to_download')"
      :downloads="translationFiles"
      :admin="admin"
      v-if="job.status === jobStatus.REALIZATION || job.status === jobStatus.FINISHED || job.status === jobStatus.COLLECTED || job.status === jobStatus.PAID || job.order.status === orderStatus.ORDER_DELIVERED.schema">
    </JobDetailViewSection>
    <!-- JOB TARGET FILES DOWNLOAD END -->

    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <LKModal
      :modal-data="{
        rejectButtonLabel: this.cancelModalLabel,
        acceptButtonLabel: this.rejectTranslationLabel,
        headline: job.name
      }"
      :optional-label="$t('reject_translation')"
      :modal-id="headerActions.rejectModalTarget"
      :close-button="true"
      :handle-accept-button-click="headerActions.rejectMethod"
      v-if="headerActions && headerActions.rejectModalTarget">
      <template slot="content">
        <p>{{ $t('reject_translation_desc') }}</p>
        <br/>
        <form class="lk-form">
          <div class="bx--form-item bx--text-input-wrapper">
            <textarea
              rows="8"
              class="bx--text-area"
              id="reject-reason"
              name="job-reject-reason"
              :placeholder="$t('request')"
              @input="handleRejectReasonUpdate($event.target.value)"
            ></textarea>
            <label class="bx--label" for="reject-reason">{{ $t('refusal_reason') }} *</label>
            <div class="bx--form-requirement">
              Message is required
            </div>
          </div>
          <file-upload
            :id="'file-upload-' + headerActions.rejectModalTarget"
            accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
            button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
            invalid-class="bx--form-requirement"
            invalid-message="* At least one document is required."
            :button-label="$t('upload_correction_here')"
            :clearOnReselect="false"
            :max-file-size="25"
            :multiple="true"
            :removable="true"
            :same-file-name="false"
            :handle-change="handleRejectReasonCorrectionFileUpdate"
          />
        </form>
      </template>
    </LKModal>
    <LKModal
      :modal-data="{
        rejectButtonLabel: $t('cancel'),
        acceptButtonLabel: $t('confirm'),
        headline: job.name
      }"
      :optional-label="$t('accept_translation')"
      :modal-id="headerActions.acceptModalTarget"
      :close-button="true"
      :handle-accept-button-click="headerActions.acceptMethod"
      v-if="headerActions && headerActions.acceptModalTarget">
      <template slot="content" v-if="job.order.physicalDelivery">
        <p>{{ $t('accept_translation_desc_physical') }}</p>
      </template>
      <template slot="content" v-else>
        <p>{{ $t('accept_translation_desc_mail') }}</p>
      </template>
    </LKModal>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import FileUpload from 'lingoking-workbench/src/components/fileupload/FileUpload.vue';
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import JobDetailStatus from 'lingoking-workbench/src/components/jobdetailview/JobDetailStatus.vue';
import JobDetailViewHeader
  from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeader.vue";
import JobDetailViewHeaderStatus
  from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeaderStatus.vue";
import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import JobDetailViewUploadSection from 'lingoking-workbench/src/components/jobdetailview/JobDetailViewUploadSection.vue'
import JobDetailViewActionButtons
  from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewActionButtons.vue";
import LKModal from 'lingoking-workbench/src/components/modal/Modal.vue'

import {OrderStatus} from "lingoking-workbench/src/enums";

import JobStatus from "lingoking-workbench/src/enums/JobStatus";

export default {
  name: 'CustomerApproval',
  components: {
    FileUpload,
    InlineNotification,
    JobDetailStatus,
    JobDetailViewHeader,
    JobDetailViewHeaderStatus,
    JobDetailViewSection,
    JobDetailViewUploadSection,
    JobDetailViewActionButtons,
    LKModal
  },

  props: {
    job: {
      type: Object
    },
    actionButtons: {
      type: Object
    },
    linguist: {
      type: Object
    },
    admin: {
      type: Boolean,
      default: false
    },
    downloadAction: {
      type: Function
    },
    removeFileAction: {
      type: Function,
    },
    getTranslationFiles: {
      type: Function
    },
    getTranslationTargetFileDownloads: {
      type: Function
    },
    deliveryDate: {
      type: String
    },
    translationSourceFiles: {
      type: Array
    },
    jobOrderQuantity: {
      type: Array
    },
  },

  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert,
    }),

    ...mapGetters({
      item: 'job/show/retrieved',
      acceptedOrRejected: 'job/show/acceptedOrRejected',
      projectManager: 'job/show/projectManager',
      //File Download
      downloadedMediaObject: 'mediaobject/show/downloaded',
      mediaObjectIsLoading: 'mediaobject/show/isLoading',
      //File Deletion
      mediaObjectDeleted: 'mediaobject/del/deleted',
      mediaObjectDeleteIsLoading: 'mediaobject/del/isLoading',
      mediaObjectDeleteError: 'mediaobject/del/error',
      // File Upload
      translationFileIsLoading: 'translationfile/create/isLoading',
      translationFileError: 'translationfile/create/error',
      createdTranslationFile: 'translationfile/create/created',
      chatUsers: 'chatUsers/list/items',
    }),

    approvalMessage() {
      return this.approval;
    },

    deadlines() {
      return [
        {'head': 'due_on', 'data': this.deliveryDate},
      ];
    },

    headerActions() {
      return {
        buttonAcceptLabel: 'accept',
        buttonRejectLabel: 'reject',
        acceptMethod: this.acceptTranslation,
        rejectMethod: this.rejectTranslation,
        acceptModalTarget: 'lk-lsp-backend__action-button-modal-accept',
        rejectModalTarget: 'lk-lsp-backend__action-button-modal-reject',
        handleRejectReasonChange: this.handleRejectReasonChange,
        handleRejectReasonCorrectionFileUpdate: this.handleRejectReasonCorrectionFileUpdate
      }
    },

    removeEnabled() {
      if (this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.status === this.jobStatus.REALIZATION) {
        return false
      }

      return this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length > 1
    },

    targetFilesDownload() {
      if (this.job.status === JobStatus.REALIZATION) {
        this.customerApprovalModals = true
      }

      return this.getTranslationTargetFileDownloads('download_translation')
    },

    translationFiles() {
      return this.getTranslationFiles('target-file', null)
    },

    rejectTranslationLabel() {
      return this.rejection ? this.$t('confirm') : null
    },

    cancelModalLabel() {
      return this.rejection ? this.$t('cancel') : null
    }
  },
  mounted() {
    if (this.job) {
      if (this.job.order.trackingCode) {
        this.dhlShipmentUrl = this.dhlPrefix + this.job.order.trackingCode.replace(/\s/g, '');
      }
    }
  },
  data() {
    return {
      jobStatus: JobStatus,
      orderStatus: OrderStatus,
      approval: null,
      filesToUpload: null,
      rejectReasonFile: null,
      rejectReason: '',
      showUploadSuccess: false,
      newTranslationSourceFiles: [],
      rejection: false,
      customerApprovalModals: false,
      dhlPrefix: 'https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=',
      dhlShipmentUrl: ''
    }
  },

  methods: {
    ...mapActions({
      retrieve: 'job/show/retrieve',
      retrieveOrder: 'order/show/retrieveLspOrder',
      alertSuccess: 'alert/success',
      updateRetrieved: 'job/show/updateRetrieved',
      acceptOrReject: 'job/show/acceptOrReject',
      downloadMediaObject: 'mediaobject/show/download',
      deleteMediaObject: 'mediaobject/del/del',
      createTranslationFile: 'translationfile/create/create',
      getChatUsers: 'chatUsers/list/getItems',
    }),

    acceptTranslation: function () {
      this.updateRetrieved({'approved': true, 'rejectReason': '', file: null})
      this.approval = 'accepted_translation_success'
      this.acceptOrReject()
    },

    handleFileUploadChange: function (fieldName, fileList) {
      if (!fileList.length) return

      this.filesToUpload = []
      // append the files to a FormData:
      Array
      .from(Array(fileList.length).keys())
      .map(x => {
        this.filesToUpload.push({
          file: fileList[x].file,
          filename: fileList[x].file.name
        })
      })
    },

    handleRejectReasonChange: function (value) {
      this.rejectReason = value
    },

    handleRejectReasonUpdate(value) {
      if (value) {
        this.rejection = true

        if (this.headerActions.handleRejectReasonChange) {
          this.headerActions.handleRejectReasonChange(value)
        }
      } else {
        this.rejection = false
      }
    },

    handleRejectReasonCorrectionFileUpdate(_, files) {
        const file = files && files.length > 0 ? files[0].file : null

        this.rejectReasonFile = file
    },

    rejectTranslation: function () {
      if (this.rejectReason) {
        this.updateRetrieved({
          'approved': false,
          'rejectReason': this.rejectReason,
          'file': this.rejectReasonFile,
        })
        this.approval = 'upload_translation_rejected'
        this.acceptOrReject()
      }
    },

    removeTranslationSourceFile: function (translationSourceFile) {
      this.showUploadSuccess = false
      this.deleteMediaObject(translationSourceFile)
    },

    uploadTranslationSourceFiles: function () {
      let fileUploadForm = null

      if (this.filesToUpload && this.filesToUpload.length) {
        fileUploadForm = new FormData()

        for (let key in this.filesToUpload) {
          fileUploadForm.append('file[]', this.filesToUpload[key]['file'], this.filesToUpload[key]['filename'])

          // order key:
          this.filesToUpload[key]['order'] = this.job.order.id
        }

        fileUploadForm.append('orders', JSON.stringify(this.filesToUpload))

        this.showUploadSuccess = false
        this.createTranslationFile(fileUploadForm)
      }
    },
  },

  watch: {
    error: function (error) {
      if (!error) {
        return
      }

      if (error) {
        this.$router.push({name: 'InvoiceList'});
      }
    },

    acceptedOrRejected: function (acceptedOrRejected) {
      if (acceptedOrRejected) {
        this.$scrollTo('body')

        this.retrieve(decodeURIComponent(this.job['@id']))
        this.retrieveOrder(decodeURIComponent(this.$route.params.id))
      }
    },

    createdTranslationFile: function (created) {
      if (!created) {
        return
      }

      if (!this.translationFileError && !this.translationFileIsLoading) {
        this.showUploadSuccess = true
        this.retrieve(decodeURIComponent(this.$route.params.id));
      }
    },

    mediaObjectDeleted: function (deleted) {
      if (!deleted) {
        return
      }

      if (!this.mediaObjectDeleteError && !this.mediaObjectDeleteIsLoading) {
        this.retrieve(decodeURIComponent(this.$route.params.id));
      }
    }
  }
}
</script>
