<template>
  <div>
    <ul v-if="items && items.length" class="bx--file-container">
      <li class="bx--list__item" v-for="(file, index) in items" :key="file['@id']">
        <div class="bx--row">
          <span class="bx--file__selected-file bx--file__selected-file__min-width">
            <p class="bx--file-filename">
              <TranslationFileDownload :file="file" />
            </p>
            <span v-if="showDelete" class="bx--file__state-container">
              <svg
                @click="remove(index)"
                @keydown.enter.prevent="remove(index)"
                @keydown.space.prevent
                @keyup.space.prevent="remove(index)"
                alt="Remove file"
                arial-label="Remove file"
                class="bx--file-close"
                fill-rule="evenodd"
                height="16"
                role="button"
                tabindex="0"
                viewBox="0 0 16 16"
                width="16">
                  <title>{{ removeTitle }}</title>
                  <path
                    d="M8 6.586L5.879 4.464 4.464 5.88 6.586 8l-2.122 2.121 1.415 1.415L8 9.414l2.121 2.122 1.415-1.415L9.414 8l2.122-2.121-1.415-1.415L8 6.586zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
              </svg>
            </span>
          </span>
          <ScheduledDocumentDeletionLabel :file="file" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  import {ENTRYPOINT} from './../../../apps/config/entrypoint';
  import TranslationFileDownload from '../../translationfile/TranslationFileDownload.vue'
  import ScheduledDocumentDeletionLabel from '../../translationfile/ScheduledDocumentDeletionLabel.vue';

  export default {
    components: {
      TranslationFileDownload,
      ScheduledDocumentDeletionLabel
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      showDelete: {
        type: Boolean,
        default: true
      },
      remove: {
        type: Function
      },
      removeTitle: {
        type: String
      },
      downloadAction: {
        type: Function
      }
    },
    data() {
      return {
        apiUrl: ENTRYPOINT
      }
    }
  }
</script>
