<template>
  <div
    v-if="
      job.order.status === orderStatus.ORDER_CANCELLED.schema ||
      job.order.status === orderStatus.ORDER_DELETED.schema">
    <p class="lk-lsp-backend__accept-name">{{ orderStatusLabel }}</p>
  </div>
  <div v-else-if="job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL">
    <p
      class="lk-lsp-backend__accept-name"
      v-if="job.order.translator || job.order.corrector"
    >
      {{
        $t(
          (job.order.status === orderStatus.ORDER_DELIVERED.schema || job.status === jobStatus.REALIZATION)
            ? "translated_from"
            : "translate_from"
        )
      }}
      <span v-if="job.order.translator">{{ job.order.translator }}</span>
      <span v-if="job.order.corrector"
      >{{ job.order.translator ? ", " : "" }}{{ job.order.corrector }}</span
      >
    </p>
    <p class="lk-lsp-backend__accept-name" v-else>
      {{ $t("lsp_search") }}
    </p>
  </div>
</template>

<script>
import {OrderStatus, getOrderStatusLabel} from "../../enums";

import JobTypes from "../../enums/JobType";
import JobStatus from "../../enums/JobStatus"

export default {
  props: {
    job: {
      type: Object,
    },
  },
  data() {
    return {
      jobTypes: JobTypes,
      jobStatus: JobStatus,
      orderStatus: OrderStatus
    };
  },
  computed: {
    orderStatusLabel() {
      let locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "de";

      return this.job ? getOrderStatusLabel(this.job.order.status, locale) : "";
    },
  },
};
</script>
