import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const update = ({ commit }, customerId) => {
  commit(types.SET_ERROR, '')
  commit(types.TOGGLE_LOADING)

  return fetch('customers/' + customerId + '/unsubscribe_feedback_email', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json', 'Content-Length': 0 },
    data: JSON.stringify({}),
  })
    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      // eslint-disable-next-line
      commit(types.SET_ERROR, ((e.errors && e.errors._error) ? e.errors._error : e))
    })
}
