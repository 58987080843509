<template>
  <div class="bx--row">
    <div :class="'bx--col-xl-' + colsLeft" v-if="job">
      <JobDetailViewHeaderActions
        :job="job"
        :download-action="downloadAction"
        :action-buttons="headerActions ? headerActions : {}"
        :show-translation-job-polnumber="showTranslationJobPolnumber">
      </JobDetailViewHeaderActions>
    </div>
  </div>
</template>
<script>
  import JobDetailViewHeaderActions from './JobDetailViewHeaderActions.vue'

  export default {
    name: 'JobDetailViewHeader',
    components: {
      JobDetailViewHeaderActions,
    },
    props: {
      job: {
        type: Object
      },
      colsLeft: {
        type: String,
        default: '6'
      },
      colsRight: {
        type: String,
        default: '6'
      },
      headerActions: {
        type: Object
      },
      downloadAction: {
        type: Function
      },
      showTranslationJobPolnumber: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
