<template>
  <div :class="wrapperClass" class="bx--col-md-12 lk-file-upload">
    <div class="bx--row">
      <ul class="bx--col-xs-12">
        <li v-for="(order, key) in orders" :key="key" :class="sectionClass" class="bx--row lk-container--white lk-container--layer-raised lk-file-upload__section">
          <TranslationSourceFileUploadItem
            :id="order.id"
            :order="order"
            :orderFileChange="orderFileChange"
          />
        </li>
      </ul>
    </div>
    <div :class="sectionClass" class="bx--row lk-container--white lk-container--layer-raised lk-file-upload__section">
      <div class="bx--col-md-7">
        <TranslationSourceFileUploadSubmit
          :disableSendFiles="disableSendFiles"
          :handleFileUpload="handleFileUpload"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import "./translationsourcefileupload.scss"

  import FileUpload from './FileUpload.vue'
  import TranslationSourceFileUploadItem from './TranslationSourceFileUploadItem.vue'
  import TranslationSourceFileUploadSubmit from './TranslationSourceFileUploadSubmit.vue'

  export default {
    name: 'TranslationSourceFileUpload',
    props: {
      orders: {
        type: Array,
        required: true
      },
      orderFileChange: {
        type: Function,
        required: true
      },
      handleFileUpload: {
        type: Function,
        required: true
      },
      disableSendFiles: {
        type: Boolean,
        default: false
      },
      wrapperClass: {
        type: String,
        default: ''
      },
      sectionClass: {
        type: String,
        default: ''
      }
    },
    components: {
      FileUpload,
      TranslationSourceFileUploadItem,
      TranslationSourceFileUploadSubmit
    },
  }
</script>
