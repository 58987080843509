import * as types from './mutation_types'

export default {
  [types.INVOICE_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: null,
      retrievedOrders: null,
      ordersLoading: false
    })
  },

  [types.INVOICE_SHOW_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.INVOICE_SHOW_SET_RETRIEVED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },

  [types.INVOICE_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.INVOICE_SHOW_ORDERS_TOGGLE_LOADING] (state) {
    Object.assign(state, { ordersLoading: !state.ordersLoading })
  },

  [types.INVOICE_SHOW_SET_ORDERS_RETRIEVED] (state, retrievedOrders) {
    Object.assign(state, { retrievedOrders: retrievedOrders })
  }
}
