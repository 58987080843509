export const JOB_SHOW_RESET = 'JOB_SHOW_RESET'
export const JOB_SHOW_SET_ERROR = 'JOB_SHOW_SET_ERROR'
export const JOB_SHOW_SET_RETRIEVED = 'JOB_SHOW_SET_RETRIEVED'
export const JOB_SHOW_TOGGLE_LOADING = 'JOB_SHOW_TOGGLE_LOADING'
export const JOB_SHOW_UPDATE_RETRIEVED = 'JOB_SHOW_UPDATE_RETRIEVED'
export const JOB_SET_UPLOAD_ERROR = 'JOB_SET_UPLOAD_ERROR'
export const JOB_SET_UPLOAD_VIOLATIONS = 'JOB_SET_UPLOAD_VIOLATIONS'
export const JOB_TOGGLE_FILE_UPLOAD_LOADING = 'JOB_TOGGLE_FILE_UPLOAD_LOADING'
export const JOB_SET_UPLOAD_UPDATED = 'JOB_SET_UPLOAD_UPDATED'
export const JOB_SHOW_SET_ACCEPTED_OR_REJECTED = 'JOB_SHOW_SET_ACCEPTED_OR_REJECTED'
export const JOB_SHOW_SET_TRACKING_CODE = 'JOB_SHOW_SET_TRACKING_CODE'
export const JOB_SHOW_SET_PM = 'JOB_SHOW_SET_PM'
