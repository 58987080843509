export default class SubmissionError extends Error {
  constructor (errors) {
    super('Validation Error!')

    this.errors = errors
    this.name = this.constructor.name

    return this
  }
}
