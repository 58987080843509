const JobTypes = {
  JOB_TYPE_TRANSLATOR: 'translator',
  JOB_TYPE_CORRECTOR: 'corrector',
  JOB_FINAL_REVISION: 'final-revision',
  JOB_TYPE_QM: 'qm',
  JOB_FINAL_REVISION_QM: 'final-revision-qm',
  JOB_TYPE_CUSTOMER_APPROVAL: 'customer-approval',
  JOB_TYPE_ORDER_ADJUSTMENT: 'order-adjustment',
  JOB_TYPE_PRINTING: 'printing',
  JOB_TYPE_SHIPMENT: 'shipment',
  JOB_TYPE_CONFIRMATION: 'confirmation',
  JOB_TYPE_XRECHNUNG_VALIDATION: 'xrechnung-validation',
}

export default JobTypes

export const LinguistJobTypes = [
  'all',
  JobTypes.JOB_TYPE_TRANSLATOR,
  JobTypes.JOB_TYPE_CORRECTOR
]
