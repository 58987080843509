export const ENTRYPOINT = window.lingokingConfig.API_URL
export const LOGIN_PAGE = window.lingokingConfig.SHOP_SERVICE_URL + '/login'
export const SHOP_SERVICE_URL = window.lingokingConfig.SHOP_SERVICE_URL
export const PAYPAL_ENV = window.lingokingConfig.PAYPAL_ENV
export const SENTRY_ENV = window.lingokingConfig.SENTRY_ENV
export const SENTRY_RELEASE = window.lingokingConfig.SENTRY_RELEASE
export const SENTRY_DSN_PRODUCTORDER = window.lingokingConfig.SENTRY_DSN_CONFIGURATOR
export const SENTRY_DSN_SHOPPINGCART = window.lingokingConfig.SENTRY_DSN_CHECKOUT
export const SENTRY_SAMPLERATE = parseFloat(window.lingokingConfig.SENTRY_SAMPLERATE)
export const ADMIN_CONFIGURATOR_URL = window.lingokingConfig.ADMIN_CONFIGURATOR_URL
