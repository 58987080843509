<template>
  <layout-default>
    <Loading v-if="isLoading || additionFileIsUploading"/>

    <div v-if="item" class="bx--row">
      <div class="bx--col-md-12">
        <div class="bx--row">
          <div class="bx--col-md-12">
            <div class="bx--row">
              <div class="bx--col-xl-12">
                <div class="lk-lsp-backend__accept-request">
                  <h3 class="lk-lsp-backend__accept-headline">{{ item.product.name }}</h3>
                  <p class="lk-lsp-backend__accept-name">
                    <span>{{ item.orderName }}</span>
                  </p>

                  <!-- Customer Notes -->
                  <div style="margin-bottom: 1.5rem;">
                    <div class="lk-detailview__table bx--row" v-if="item.message">
                      <div class="lk-detailview__table-row bx--col-lg-10" v-for="(tableRow, index) in customerMessage" :key="'lk-detailview-table-row-'+index">
                        <span class="lk-detailview__table-left">
                          <span>{{ $t(tableRow.head) }}</span>
                        </span>
                        <span :class="tableRow.bold ? 'lk-detailview__table-right--bold' : ''" class="lk-detailview__table-right">
                          {{ tableRow.data === 'true' ? '' : tableRow.data }}
                        </span>
                        <span v-if="tableRow.data === 'true'" class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Requirements -->
                  <div style="margin-bottom: 1.5rem;">
                    <div class="lk-detailview__table bx--row" v-if="jobRequirements">
                      <div class="lk-detailview__table-row bx--col-lg-10" v-for="(tableRow, index) in jobRequirements" :key="'lk-detailview-table-row-'+index">
                        <span class="lk-detailview__table-left">
                          <span>{{ $t(tableRow.head) }}</span>
                          <span v-if="!!tableRow.modal" class="lk-carbon-icon lk-carbon-icon-info--glyph lk-icon-modal" @click="openModal(tableRow.modal)"></span>
                        </span>
                        <span :class="tableRow.bold ? 'lk-detailview__table-right--bold' : ''" class="lk-detailview__table-right">
                          {{ tableRow.data === 'true' ? '' : tableRow.data }}
                        </span>
                        <span v-if="tableRow.data === 'true'" class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Progress Bar -->
                  <div class="progress-bar" style="margin-bottom: 1.5rem;">
                    <bx-progress-indicator style="justify-content: space-between;" :vertical="vertical">
                      <bx-progress-step
                        @click="changeCurrentJob(job)"
                        style="flex-grow: 1;"
                        v-for="job in jobsFiltered" :key="job['@id']"
                        :disabled="(job.user ? job.user['@id'].replace(/^(?:[^\/]*\/){2}\s*/, '') !== account.user.id : true) || (item.status === orderStatus.ORDER_CANCELLED.schema || item.status === orderStatus.ORDER_DELETED.schema)"
                        :label-text="job.jobDefinition.name"
                        :secondary-label-text="account.user.roles.includes('ROLE_CUSTOMER') ? null : job.purchaseOrderNumber"
                        :state="getStateClass(job)"/>
                    </bx-progress-indicator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bx--row">
          <div class="bx--col-md-8">
            <!-- Job Data -->
            <div v-if="currentUserJob" style="margin-top: 18px;" :class="[
              { 'job-done' :  getStateClass(currentUserJob) === 'complete' },
              { 'job-rejected' : getStateClass(currentUserJob) === 'invalid' },
              { 'job-progress' : getStateClass(currentUserJob) === 'current' }]">
              <div v-if="getStateClass(currentUserJob) === 'complete'" class="job-done-inner">
                <span class="lk-carbon-icon lk-carbon-icon-checkmark" style="font-size: 15px;"></span>
                <span style="margin-top: 2px;">{{ $t('job_done') }}</span>
              </div>
              <div v-if="getStateClass(currentUserJob) === 'invalid'" class="job-rejected-inner">
                <span class="lk-carbon-icon lk-carbon-icon-close" style="font-size: 15px;"></span>
                <span style="margin-top: 2px;">{{ $t('job_rejected') }}</span>
              </div>
              <div v-if="getStateClass(currentUserJob) === 'current'" class="job-progress-inner">
                <span class="lk-carbon-icon lk-carbon-icon-warning" style="font-size: 15px;"></span>
                <span style="margin-top: 2px;">{{ $t('job_progress') }}</span>
              </div>

              <!-- Job -->
              <div v-if="item.status !== orderStatus.ORDER_CANCELLED.schema && item.status !== orderStatus.ORDER_DELETED.schema" class="lk-detailview" style="margin-top: 0;">
                <JobShow
                  :job-id="currentUserJob['@id']"
                  :linguist="linguist"
                />
              </div>
            </div>

            <!-- Order Data -->
            <div class="lk-detailview">
              <div class="lk-detailview__headline-wrapper">
                <h4 class="lk-detailview__headline">Order Information</h4>
                <span class="lk-detailview__details">{{ item.partOfInvoice.confirmationNumber }}</span>
              </div>
              <!-- Order Name -->
              <div class="lk-detailview__section-wrapper">
                <h5 class="lk-detailview__section-headline">{{ $t('name') }}</h5>
                <p>
                  {{ item.orderName }}
                </p>
              </div>
              <!-- Product Notes -->
              <div v-if="itemJob && itemJob.jobDefinition.defaultRole === 'customer' && itemJob.order.product.note"
                   class="lk-detailview__section-wrapper">
                <h5 class="lk-detailview__section-headline">{{ $t('notes') }}</h5>
                <p>
                  {{ itemJob.order.product.note }}
                </p>
              </div>
              <!-- Requirements -->
              <div class="lk-detailview__section-wrapper">
                <h5 class="lk-detailview__section-headline">{{ $t('requirements') }}</h5>
                <div class="lk-detailview__table bx--row" v-if="jobRequirements">
                  <div class="lk-detailview__table-row bx--col-lg-10" v-for="(tableRow, index) in jobRequirements"
                       :key="'lk-detailview-table-row-'+index">
                    <span class="lk-detailview__table-left">
                      <span>{{ $t(tableRow.head) }}</span>
                      <span v-if="!!tableRow.modal" class="lk-carbon-icon lk-carbon-icon-info--glyph lk-icon-modal"
                            @click="openModal(tableRow.modal)"></span>
                    </span>
                    <span :class="tableRow.bold ? 'lk-detailview__table-right--bold' : ''"
                          class="lk-detailview__table-right">
                      {{ tableRow.data === 'true' ? '' : tableRow.data }}
                    </span>
                    <span v-if="tableRow.data === 'true'" class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
                  </div>
                </div>
              </div>
              <!-- Customer Notes -->
              <div class="lk-detailview__section-wrapper" v-if="item.message">
                <h5 class="lk-detailview__section-headline">{{ $t('customer_notes') }}</h5>
                <p>
                  {{ item.message }}
                </p>
              </div>
              <!-- Customer Basic Address -->
              <div class="lk-detailview__section-wrapper" v-if="shouldDisplayCustomerAddress">
                <h5 class="lk-detailview__section-headline">{{ $t('customer_address') }}</h5>
                <div class="lk-detailview__table bx--row">
                  <div class="lk-detailview__table-row bx--col-lg-10"
                       v-for="(tableRow, index) in account.user.roles.includes(UserRoles.ROLE_CUSTOMER) ? customerAddress : basicCustomerAddress"
                       :key="'lk-detailview-table-row-'+index">
                    <span class="lk-detailview__table-left">
                      <span>{{ $t(tableRow.head) }}</span>
                      <span v-if="!!tableRow.modal" class="lk-carbon-icon lk-carbon-icon-info--glyph lk-icon-modal"
                            @click="openModal(tableRow.modal)"></span>
                    </span>
                    <span :class="tableRow.bold ? 'lk-detailview__table-right--bold' : ''"
                          class="lk-detailview__table-right">
                      {{ tableRow.data === 'true' ? '' : tableRow.data }}
                    </span>
                    <span v-if="tableRow.data === 'true'" class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Additional -->
          <div class="bx--col-md-4">
            <div class="bx--row">
              <div class="bx--col-md-12">
                <!-- Chat Messages -->
                <div style="margin-top: 18px;">
                  <JobDetailViewChat
                    :messages="messages"
                    :active="chatActive"
                    :admin="false"
                    :users="chatUsers"/>
                </div>
                <!-- Additional Copy -->
                <div class="lk-detailview" v-if="(item.additionalCopyEnabled || showOrderAdditionalFiles.length) && jobIsAccepted">
                  <file-upload
                    :clearOnReselect="false"
                    :files="digitalFiles"
                    :handle-change="additionalFilesChange"
                    @change="additionalFilesChange"
                    :max-file-size="25"
                    :multiple="true"
                    :removable="true"
                    :label="$t('additional_digital_files')"
                    button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
                    accepted="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                    :button-label="$t('upload_documents')"
                    :id="'order_file_' + item['@id']"
                    invalid-class="bx--form-requirement"
                    :invalid-message="$t('additional_digital_files')"
                    v-if="item.additionalCopyEnabled && jobIsAccepted"/>
                  <label class="bx--toast-notification__title" v-if="digitalFiles && (digitalFiles.length > 0) ">
                    {{ $t('additional_digital_files') }}
                  </label>
                  <UploadFilesList
                    :showDelete="false"
                    :items="showOrderAdditionalFiles"
                    :download-action="downloadAction">
                  </UploadFilesList>
                  <div v-if="digitalFiles && digitalFiles.length && item.additionalCopyEnabled && jobIsAccepted">
                    <button class="bx--file-btn bx--btn bx--btn--primary" @click.prevent="uploadAdditionFilesAction">
                      {{ $t('save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LKModal
        ref="modalRef"
        :close-button="true"
        :modal-data="modalData"
        :handle-reject-button-click="closeModal"
        :modal-id="'modal-info'">
        <template slot="content">
          <div class="job-detail-modal" v-html="modalText"></div>
        </template>
      </LKModal>
    </div>
  </layout-default>
</template>

<style>

:root {
  --cds-ui-05: #efc100;
  --cds-interactive-04: #1f9dd9;
  --cds-link-01: #1f9dd9;
}

.job-done {
  border: 1px solid green;
  margin-top: 15px;
}

.job-rejected {
  border: 1px solid #e0182d;
  margin-top: 15px;
}

.job-progress {
  border: 1px solid #efc100;
  margin-top: 15px;
}

.job-done-inner {
  padding: 10px 20px;
  background: green;
  color: white;
  display: flex;
  gap: 5px;
}

.job-rejected-inner {
  padding: 10px 20px;
  background: #e0182d;
  color: white;
  display: flex;
  gap: 5px;
}

.job-progress-inner {
  padding: 10px 20px;
  background: #efc100;
  color: white;
  display: flex;
  gap: 5px;
}

.lk-icon-modal {
  float: right;
  padding-top: 3px;
  padding-left: 4px;
  cursor: pointer;
}
.lk-icon-modal:hover {
  color: #333;
}
.job-detail-modal {
  line-height: 24px;
}

</style>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import Accordion from "../reusable/accordion/accordion.vue";
import AccordionItem from "../reusable/accordion/accordion-item.vue";
import JobShow from "../job/Show.vue";
import LayoutDefault from '../layouts/LayoutDefault.vue'

import 'carbon-web-components/es/components/progress-indicator/index.js';

import {bus} from "lingoking-workbench/src/util/bus.js"
import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import FileUpload from 'lingoking-workbench/src/components/fileupload/FileUpload.vue';
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import JobDetailViewChat from "lingoking-workbench/src/components/jobdetailview/JobDetailViewChat.vue";
import LKModal from "lingoking-workbench/src/components/modal/Modal.vue";
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import UploadFilesList from 'lingoking-workbench/src/components/linguist/ApplicationDocumentList/UploadFilesList.vue';

import JobStatus from "lingoking-workbench/src/enums/JobStatus"
import JobTypes from "lingoking-workbench/src/enums/JobType"
import orderStatus from "lingoking-workbench/src/enums/OrderStatus";

import fetch from '../../utils/fetch'
import {ENTRYPOINT} from '../../config/entrypoint'

import UserRole from "../../enums/UserRoles";
import { OrderStatus } from '../../../../lingoking-workbench/src/enums';

export default {
  components: {
    LayoutDefault,
    AccordionItem,
    Accordion,
    JobShow,
    FileUpload,
    InlineNotification,
    JobDetailViewChat,
    LKModal,
    Loading,
    UploadFilesList
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),

    ...mapGetters({
      item: 'order/show/retrieved',
      itemJob: 'job/show/retrieved',
      error: 'order/show/error',
      isLoading: 'order/show/isLoading',
      linguist: 'linguist/show/retrieved',
      chatUsers: 'chatUsers/list/items',
      user: 'user/show/retrieved',
      // Additional File
      additionFileIsUploading: 'additionalCopy/create/isLoading',
      additionFileIsCreated: 'additionalCopy/create/created',
    }),

    basicCustomerAddress() {
      if (!this.item.physicalDelivery) {
        const address = this.item.address || {}
        const postalCode = address.postalCode || ''
        const addressLocality = address.addressLocality || ''
        const addressCountry = address.addressCountry || {}
        const addressCountryId = addressCountry.id || ''

        return [
          {
            'head': 'postal_code_and_city',
            'data': `${postalCode} ${addressLocality}`.trim()
          },
          {
            'head': 'address_country',
            'data': addressCountryId
          }
        ]
      } else {
          return [
            {
              'head': 'postal_code_and_city',
              'data': this.item.address['postalCode'] + ' ' + this.item.address['addressLocality']
            },
            {
              'head': 'address_country',
              'data': this.item.address['addressCountry']['id']
            }
          ];
      }
    },

    currentUserJob() {
      if (this.selectedJob) {
        return this.selectedJob
      }

      let userJobs = this.item.jobs.filter(job => {
        if (!job.user) {
          return false;
        }

        return job.user['@id'].replace(/^(?:[^\/]*\/){2}\s*/, '') === this.account.user.id
      })
      .sort(function(a,b) {
        return new Date(a.updatedAt) - new Date(b.updatedAt);
      });

      let realizationUserJob = userJobs.findLast(job => {
        return job.status === this.jobStatus.REALIZATION
      })

      if (realizationUserJob) {
        return realizationUserJob;
      }

      return userJobs.pop()
    },

    customerAddress() {
      let customerAddress = [];
      let fullName = this.item.address['name'] ? this.item.address['name'] : this.item.address['firstName'] + ' ' + this.item.address['lastName']

      if (this.item.address['companyName']) {
        customerAddress.push({'head': 'company_name', 'data': this.item.address['companyName']})
      }

      customerAddress.push(
        {'head': 'first_and_last_name', 'data': fullName},
        {'head': 'address_street', 'data': this.item.address['streetAddress']},
        {'head': 'number', 'data': this.item.address['houseNumber']},
        {'head': 'address_suffix', 'data': this.item.address['addressSuffix']}
      );

      return customerAddress.concat(this.basicCustomerAddress)
    },

    customerMessage() {
      return [
        {'head': 'customer_notes', 'data': this.item.message},
      ]
    },

    finishedJob() {
      return this.item.jobs.findLast(job => {
        return job.status === this.jobStatus.FINISHED
      })
    },

    jobRequirements() {
      let jobReqs = [
        {'head': 'source_language', 'data': this.item.sourceLanguage.name},
        {'head': 'target_language', 'data': this.item.targetLanguage.name}
      ]

      const modalsInfo = !this.linguist ? {} : {
        qm: this.$t('lsp_qm_info')
      };

      if (this.item && this.item.options) {
        this.item.options
          .filter((option) => this.admin || option.productOption.showInJobOverview)
          .forEach((option) => {
            if (
              option.productOption
              && option.productOptionValue
              && option.productOptionValue !== "false") {
              let data = {
                'head': option.productOption.label,
                'data': option.productOptionValue,
                'modal': modalsInfo[option.productOption.name]
              };

              jobReqs.push(data);
            }
          })
      }

      return jobReqs
    },

    jobIsAccepted() {
      if (this.item) {
        const jobs = this.item.jobs

        for (let key in jobs) {
          if (jobs[key].constructor === Object && jobs[key].status === JobStatus.FINISHED && jobs[key].jobDefinition.type === 'customer-approval')
            return true;
        }

        return false;
      }

      return false;
    },

    jobsFiltered() {
      return this.item.jobs.filter(job => {
        return this.user ? job.jobDefinition.visibility.includes(this.user.userType) : false;
      })
    },

    showOrderAdditionalFiles() {
      if (this.item && this.item.orderAdditionalFiles && this.item.orderAdditionalFiles.length) {
        return this.item.orderAdditionalFiles
      }

      return [];
    },

    shouldDisplayCustomerAddress() {
      return !(this.item.options.some(option =>
        option.productOption.name === 'email_delivery_method_standard' ||
        option.productOption.name === 'email_delivery_method_express' ||
        option.productOption.name === 'delivery_certified_scan_only'))
    },
  },

  created() {
    this.retrieve(decodeURIComponent(this.$route.params.id))

    if(this.account.user.roles.includes(UserRole.ROLE_LSP)) {
      this.retrieveLinguist(decodeURIComponent('users/' + this.account.user.id))
    }

    // responsiveness:
    this.checkWindowSize(window)
    window.addEventListener("resize", this.resizeEventHandler);
  },

  beforeDestroy() {
    bus.$off('postMessage')
    this.chatActive = false;
  },

  data() {
    return {
      apiUrl: ENTRYPOINT,
      selectedJob: null,
      // Additional File Upload
      additionalFilesToUpdate: null,
      digitalFiles: [],
      // Others
      orderStatus: OrderStatus,
      jobStatus: JobStatus,
      UserRoles: UserRole,
      vertical: false,
      // Modal
      modalText: null,
      modalData: {
        rejectButtonLabel: this.$t("close")
      },
      // Chat Message
      chatActive: false,
      messages: [],
      messageLoader: () => {
        if (this.chatActive) {
          this.getMessages()
          setTimeout(this.messageLoader, 300000);
        }
      },
    }
  },

  methods: {
    ...mapActions({
      retrieve: 'order/show/retrieveLspOrder',
      retrieveLinguist: 'linguist/show/retrieve',
      updateRetrieved: 'job/show/updateRetrieved',
      alertSuccess: 'alert/success',
      getChatUsers: 'chatUsers/list/getItems',
      downloadMediaObject: 'mediaobject/show/download',
      uploadAdditionalFiles: 'additionalCopy/create/upload',
    }),

    additionalFilesChange(fieldName, fileList) {
      if (this.item) {
        this.handleUpdateField('orderAdditionalFile', this.digitalFiles);
        this.additionalFiles = fileList;
      } else {
        this.additionalFiles = [];
        this.handleUpdateField('orderAdditionalFile', null);
      }
    },

    changeCurrentJob: function (job) {
      this.selectedJob = this.getCurrentUserJob(job);
    },

    checkWindowSize: function (window) {
      this.vertical = window.innerWidth < 768;
    },

    downloadAction: function (downloadItem) {
      if (downloadItem) {
        this.downloadMediaObject(downloadItem)
      }
    },

    getMessages: function () {
      if (this.item) {
        const orderId = this.item['@id'].split('/').pop();
        const that = this;
        let url = '/retrieve_chat_messages/' + orderId;
        if (this.messages.length !== 0) {
          url += '/' + this.messages[this.messages.length - 1].id
        }
        fetch(url)
          .then((data) => {
            // ~ update messages only if there was a change
            if (data.messages && (that.messages.length === 0 ||
              that.messages[that.messages.length - 1].id !== data.messages[data.messages.length - 1].id)) {
              that.messages = data.messages;
            }
          })
          .catch((e) => {
            console.error(e)
          });
      }
    },

    getStateClass: function (job) {
      if (this.itemJob && this.itemJob.id === job.id) {
        let rejectionReasons = this.itemJob.rejectionReasons.find(rejectionReason => !rejectionReason.solved && rejectionReason.type === 'rejection');

        if (rejectionReasons) {
          return 'invalid';
        }
      }

      if (job.status === JobStatus.REALIZATION) {
        return 'current'
      }

      if (job.status === JobStatus.FINISHED) {
        return 'complete'
      }

      return 'queued'
    },

    getCurrentUserJob(jobToFind) {
      let userJobs = this.item.jobs.filter(job => {
        if (!job.user) {
          return false
        }

        return job.user['@id'].replace(/^(?:[^\/]*\/){2}\s*/, '') === this.account.user.id
      })

      return userJobs.find(job => {
        return job['@id'] === jobToFind['@id']
      });
    },

    handleUpdateField: function (field, value) {
      Object.assign(this.additionalFilesToUpdate, {[field]: value});
    },

    postMessage: function (message, receiver) {
      if (!message) return;

      const that = this;

      if (this.item) {
        const orderId = this.item['@id'].split('/').pop();
        let url = '/post_chat_message/' + orderId;

        if (receiver) {
          url += '/' + receiver.id
        }

        let formData = new FormData();

        formData.append('message', message);

        fetch(url, {method: 'POST', data: formData})
          .then((data) => {
            that.messages = data.messages;
          })
          .catch((e) => {
            console.error(e)
          });
      }
    },

    resizeEventHandler(e) {
      this.checkWindowSize(e.target)
    },

    uploadAdditionFilesAction() {
      if (this.additionalFiles && this.additionalFiles.length) {
        this.handleUpdateField('orderAdditionalFile', this.additionalFiles)
        let fileUploadForm = new FormData();

        for (let key in this.additionalFilesToUpdate['orderAdditionalFile']) {
          if (this.additionalFilesToUpdate['orderAdditionalFile'].hasOwnProperty(key)) {
            fileUploadForm.append(
              'orderAdditionalFile[]',
              this.additionalFilesToUpdate['orderAdditionalFile'][key]['file'],
              this.additionalFilesToUpdate['orderAdditionalFile'][key]['filename']
            );
          }
        }

        fileUploadForm.append('order', this.additionalFilesToUpdate['id']);

        this.uploadAdditionalFiles({fileUploadForm: fileUploadForm});
      }
    },
    // Modal
    openModal (text) {
      this.modalText = text;
      this.$refs.modalRef.modalInstance.show();
    },

    closeModal () {
      this.$refs.modalRef.modalInstance.hide  ();
    },
  },

  watch: {
    additionFileIsCreated: function (item) {
      this.retrieve(decodeURIComponent(this.$route.params.id))
    },

    item: function (item) {
      if (item) {
        let orderId = item['@id'].replace(/^(?:[^\/]*\/){2}\s*/, '');

        this.getChatUsers(orderId);
        this.getMessages();

        // TODO: check chat rules
        if (this.currentUserJob && this.currentUserJob.jobDefinition.type === JobTypes.JOB_TYPE_CUSTOMER_APPROVAL) {
          this.chatActive = item.status !== orderStatus.ORDER_DELIVERED.schema
            && item.status !== orderStatus.ORDER_CANCELLED.schema
            && item.status !== orderStatus.ORDER_DELETED.schema
        } else {
          this.chatActive = item.status !== orderStatus.ORDER_DELIVERED.schema
            && this.currentUserJob
            && this.currentUserJob.jobDefinition.type !== JobTypes.JOB_TYPE_CORRECTOR;
        }

        this.additionalFilesToUpdate = {
          id: item.id,
          orderName: item.orderName,
          '@id': item['@id']
        };

        setTimeout(this.messageLoader, 300000);

        // clean selected job:
        this.selectedJob = null
      }
    },

    chatActive: function (chatActive) {
      if (chatActive) {
        bus.$on('postMessage', this.postMessage);
      }
    },

    error: function (error) {
      if (!error) {
        return
      }

      if (error && error === 'Not Found') {
        this.$router.push({name: 'JobList'})
      }
    },
  }
};
</script>

