<template>
    <div class="login-layout bx--body lk-body--rows lk-body--light">
        <header class="lk-header lk-header--overlay">
            <div class="bx--row">
                <div class="bx--col-xs-12 bx--col-md-3">
                  <a :href="shop_url">
                    <img alt="Lingoking" class="lk-header__logo" :src="logoLink"/>
                  </a>
                </div>
            </div>
        </header>

        <aside class="bx--col-xs-12 bx--col-lg-4 lk-aside-container">
            <div class="lk-aside-container__image--bg" v-lazy:background-image="imageLink">
            </div>
        </aside>
        <main class="bx--col-xs-12 bx--col-lg-8 lk-main-container">
            <slot/>
        </main>
    </div>
</template>


<script>
  import 'lingoking-workbench/src/components/header/header.scss'
  import logo from '../../assets/images/logo_rebranded.svg'
  import teaserImageLink from 'lingoking-workbench/assets/example/login-teaser-image.png'
  import {mapGetters} from "vuex";
  import {getShopServiceLocales} from "lingoking-workbench/src/util";
  import {SHOP_SERVICE_LSP_URL} from "../../config/entrypoint";
  export default {
    name: 'LayoutFront',
    metaInfo() {
      let links = [];
      const locale = this.$route.params.locale;
      const path = this.$route.path.substring(3);
      getShopServiceLocales().forEach(function (value) {
        links.push(
            value === locale ? {rel: 'canonical', href: `${ location.href }`} : {rel: 'alternate', hreflang: value, href: location.origin + '/' + value + path}
        )
      });
      return {
        link: links
      }
    },
    computed:{
      ...mapGetters({
        intercomSettings: 'intercom/allSettings',
      }),
    },
    data() {
      return {
        imageLink: teaserImageLink,
        logoLink: logo,
        shop_url: SHOP_SERVICE_LSP_URL

      }
    },
    created: function () {
      window.Intercom('boot', this.intercomSettings);
    },
    mounted: function () {
    }
  }
</script>
