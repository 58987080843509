import $ from 'jquery'

var resizeEndEvent = {
  start: true,
  endTimer: null,
  bindResizeEndEvent: function () {
    return $.Deferred(function (deferred) {
      resizeEndEvent.start = true
      resizeEndEvent.endTimer = null
      $(window).on('resize', function () {
        if (resizeEndEvent.start) {
          resizeEndEvent.start = false
          $(window).trigger('resizestart')
          window.dispatchEvent(new Event('resizestart'))
        }
        clearTimeout(resizeEndEvent.endTimer)
        resizeEndEvent.endTimer = setTimeout(function () {
          resizeEndEvent.start = true

          setTimeout(function () {
            $(window).trigger('resizeend')
            window.dispatchEvent(new Event('resizeend'))
          }, 50)
        }, 650)
      })
      deferred.resolve()
    }).promise()
  }
}

export default resizeEndEvent
