<template>
  <div>
    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <!-- Product Job Notes START -->
    <JobDetailViewSection
      :headline="$t('notes')"
      :border="!productOptionsJobNotes.length"
      :text="job.order.product.jobNotes[0]['description']"
      v-if="job.order.product.jobNotes && job.order.product.jobNotes.length">
    </JobDetailViewSection>
    <!-- Product Job Notes END -->

    <!-- Product Option Job Notes START -->
    <div v-if="productOptionsJobNotes && productOptionsJobNotes.length">
      <JobDetailViewSection
        :border="index === (productOptionsJobNotes.length-1)"
        :text="productOptionJobNote"
        v-for="(productOptionJobNote, index) in productOptionsJobNotes" :key="'option-job-note-' + index">
      </JobDetailViewSection>
    </div>
    <!-- Product Option Job Notes END -->

    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD START -->
    <JobDetailViewSection
      :border="false"
      :headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      :download-action="downloadAction"
      :downloads="translationSourceFiles"
      :remove-action="null"
      :remove-enabled="removeEnabled"
      :admin="admin"
      :table="jobOrderQuantity"
      v-if="translationSourceFiles">
    </JobDetailViewSection>
    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD END -->

    <!-- JOB TARGET FILES DOWNLOAD START -->
    <JobDetailViewSection
      :download-action="downloadAction"
      :headline="$t('translation_to_correct')"
      :downloads="translationFiles"
      :admin="admin"
      v-if="targetFilesDownload">
    </JobDetailViewSection>
    <!-- JOB TARGET FILES DOWNLOAD END -->

    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM START -->
    <JobDetailViewLinguist
      :job="job"
      :admin="admin"
      :action-buttons="actionButtons"
      :linguist="linguist"
      :upload-action="uploadTranslatedFiles"
      :handle-file-upload-change="handleFileUploadChange"
      :upload-translation-file-label="'upload_correction_here'"
      :uploaded-correction-files-label="uploadedCorrectionFilesLabel"
      :uploaded-files-label="uploadedFilesLabel"
      :download-action="downloadAction"
      :translation-files="translationFiles"
      :job-in-progress="job.status === jobStatus.REALIZATION"
      :remove-action="null" />
    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM END -->
  </div>
</template>
<script>
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import JobDetailViewUploadSection from 'lingoking-workbench/src/components/jobdetailview/JobDetailViewUploadSection.vue'
import JobDetailViewLinguist from "lingoking-workbench/src/components/jobdetailview/JobDetailViewLinguist.vue";

import {OrderStatus} from "lingoking-workbench/src/enums";

import JobStatus from "lingoking-workbench/src/enums/JobStatus";

export default {
  name: 'Corrector',
  components: {
    JobDetailViewLinguist,
    JobDetailViewSection,
    JobDetailViewUploadSection
  },

  props: {
    admin: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object
    },
    linguist: {
      type: Object
    },
    actionButtons: {
      type: Object
    },
    handleFileUploadChange: {
      type: Function
    },
    downloadAction: {
      type: Function
    },
    uploadAction: {
      type: Function,
    },
    getTranslationFiles: {
      type: Function
    },
    getTranslationTargetFileDownloads: {
      type: Function
    },
    deliveryDate: {
      type: String
    },
    uploadedCorrectionFilesLabel: {
      type: String,
      default: 'translation_to_correct'
    },
    uploadedFilesLabel: {
      type: String,
      default: 'your_uploaded_correction'
    },
    jobOrderQuantity: {
      type: Array
    },
    translationSourceFiles: {
      type: Array
    },
    productOptionsJobNotes: {
      type: Array
    }
  },

  computed: {
    deadlines() {
      return [
        {'head': 'due_on', 'data': this.deliveryDate},
        {
          'head': 'deadline_translation',
          'data': this.translationJobDeliveryDate,
          'hide': false
        }
      ];
    },

    removeEnabled() {
      return this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length > 1
    },

    targetFilesDownload() {
      return this.job.status === this.jobStatus.REALIZATION
    },

    translationJobDeliveryDate() {
      if (!this.translationJob)
        return null

      return this.$moment(this.translationJob.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    translationJob() {
      return this.job.order.jobs.find(job => job.jobDefinition && job.jobDefinition.type === 'translator')
    },

    translationFiles() {
      return this.getTranslationFiles('correction-file', JobStatus.REALIZATION)
    },

    // TODO: check
    downloads() {
      if (this.job.status !== JobStatus.FINISHED) {
        return this.getTranslationTargetFileDownloads('download_translation_to_be_corrected')
      }

      return []
    },
  },

  methods:{
    uploadTranslatedFiles: function () {
      this.uploadAction('translationCorrectionFiles[]')
    },
  },

  data() {
    return {
      jobStatus: JobStatus,
      orderStatus: OrderStatus
    }
  },
}
</script>
