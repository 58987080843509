import {ENTRYPOINT} from "../config/entrypoint";
import axios from 'axios'
import router from '../router'
//temporary TODO: CREATE A SHARED SERVICE LAYER
import {jwtService} from "../_services";
import SubmissionError from "../error/SubmissionError";

const MIME_TYPE = 'application/ld+json'
const refreshTokenEndpoint = `${ENTRYPOINT}/token/refresh`

if (process.env.NODE_ENV === 'development') { axios.defaults.withCredentials = true }

axios.interceptors.request.use(options => {
  const accessToken = jwtService.getAccessToken()

  if (options.headers === undefined) {
    options.headers = {}
  }

  if (options.headers['Accept'] === undefined) {
    options.headers['Accept'] = MIME_TYPE
  }

  if (options.data !== undefined && !(options.data instanceof FormData) && options.headers['Content-Type'] === undefined) {
    options.headers['Content-Type'] = MIME_TYPE
  }

  if (accessToken) {
    options.headers['Authorization'] = 'Bearer ' + accessToken
  }

  let locale = localStorage.getItem('locale')

  if (options.headers['X-LOCALE'] === undefined && locale) {
    options.headers['X-LOCALE'] = locale
  }

  return options
}, error => {
  return Promise.reject(error)
})


axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url === refreshTokenEndpoint) {
      router.push(`/${locale}/login`)
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      const refreshToken = jwtService.getRefreshToken()

      return axios.post(refreshTokenEndpoint, {
        "refresh_token": refreshToken
      }).then(res => {
        if (res.status === 200) {
          jwtService.clearToken()
          jwtService.saveToken(res.data)

          originalRequest.headers['Authorization'] = `Bearer ${jwtService.getAccessToken()}`

          return axios(originalRequest)
        }
      })

    }

    const errorMessage = error.response.data['hydra:description']
      ? error.response.data['hydra:description'] : error.response.statusText

    const errors = {
      _error: errorMessage,
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data['message'],
      params: error.response.data['params'],
    }

    if (error.response.data.violations) {
      error.response.data.violations.map(violation =>
        Object.assign(errors, {[violation.propertyPath]: violation.message}))
    }

    throw new SubmissionError(errors)

  })

export default function (id, options) {

  return axios(new URL(id, ENTRYPOINT).toString(), options).then((response) => response.data)
}
