<template>
  <div :class="customClass">
    <form @submit.prevent="handleFileUpload" class="lk-form">
      <p class="lk-file-upload__content">{{$t('please_uplod_file')}}</p>
      <br/>
      <button :disabled="disableSendFiles" class="bx--btn bx--btn--primary" type="submit">{{$t('submit_order')}}
      </button>
    </form>
  </div>
</template>
<script>
  import TranslationSourceFileUploadItem from './TranslationSourceFileUploadItem.vue'

  export default {
    name: 'TranslationSourceFileUploadSubmit',
    props: {
      handleFileUpload: {
        type: Function,
        required: true
      },
      disableSendFiles: {
        type: Boolean,
        default: false
      },
      customClass: {
        type: String,
        required: false
      },
    }
  }
</script>
