<template>
  <layout-default>
    <Loading v-if="isLoading"/>
    <InlineNotification
      v-if="alert && alert.message && alert.type === 'alert-danger'"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="alert.message"
      :closeButton="true"
    />
    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <div class="bx--row" v-if="emptyDashboard && !this.search">
      <div class="bx--col-md-12">
        <div class="lk-empty-page-content-wrapper">
          <div class="lk-empty-page-content">
            <div class="lk-empty-page-content__image-wrapper">
              <img src="../../../assets/images/lk-empty-dashboard.png" alt="Lingoking Empty Dashboard"
                   class="lk-empty-page-content__image"/>
            </div>
            <div class="lk-empty-page-content__teaser">
              <h2 class="lk-empty-page-content__headline">
                {{ $t('empty_jobs') }}
              </h2>
              <p class="lk-empty-page-content__text">
                {{ $t('empty_help_lsp_1') }} <a :href="shop_url + $t('help_linkLSP')"
                                                class="lk-empty-page-content__link">FAQ</a>
                {{ $t('empty_help_lsp_2') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!isLoading">
        <h1 class="lk-service-backend__headline">{{ completed ? $t('completed_jobs') : $t('recent_jobs') }}</h1>
        <div>
          <section class="bx--table-toolbar multi-select-sortBy">
            <div class="bx--offset-md-4 bx--col-md-3">
              <label class="bx--label" for="multi-select-sortBy">{{ $t('sort_by') }}</label>
            </div>
          </section>
          <section class="bx--table-toolbar bx--table-toolbar-action">
            <div class="bx--col-md-4">
              <div class="bx--toolbar-search-container">
                <div class="bx--search bx--search--sm bx--search--light" data-search role="search">
                  <button @click="searchItems"
                          class="bx--btn bx--btn--ghost bx--btn--sm bx--search__button lk-carbon-icon lk-carbon-icon-search">
                  </button>
                  <label class="bx--label" for="search__input" id="search-input-label">Search</label>
                  <input aria-labelledby="search-input-label" class="bx--search-input" id="search__input"
                         placeholder="Search" role="search" type="search" v-model="searchString"
                         @keyup.enter="searchItems"/>
                </div>
              </div>
            </div>
            <div class="bx--col-md-3">
              <div class="bx--form-item">
                <Multiselect
                  id="multi-select-sortBy"
                  :multiple="false"
                  :options="sortByItems"
                  label="name"
                  track-by="id"
                  :required="false"
                  :taggable="false"
                  v-model="value"
                  :show-labels="false"
                  @input="sortBy"
                  :preselect-first="true"
                ></Multiselect>
              </div>
            </div>
          </section>
        </div>
        <ListView :data-table-items="dataTableItems" :data-table-head-items="dataTableHeadItems" :expanded="false">
          <template v-slot:explandable-content-default="slotProps">
              <div class="progress-bar" style="margin-bottom: 1.5rem; padding: 0rem 1.75rem 0.5rem 1.75rem;">
                <router-link :to="{ name: 'OrderShow', params: { id: encodeURIComponent(slotProps.order.id) } }" target="_blank">
                  <bx-progress-indicator style="justify-content: space-between;">
                    <bx-progress-step
                      style="flex-grow: 1;"
                      v-for="job in getJobsFiltered(slotProps.order.items)" :key="job['@id']"
                      :disabled="job.user ? job.user.replace(/^(?:[^\/]*\/){2}\s*/, '') !== account.user.id : true"
                      :label-text="job.jobDefinition.name"
                      :secondary-label-text="job.purchaseOrderNumber"
                      :state="getStateClass(job)"/>
                  </bx-progress-indicator>
                </router-link>
              </div>
          </template>
        </ListView>
        <pagination class="lk-container--layer-raised lk-pagination--borderless" v-if="totalItems"
                    :cur-page="currentPage"
                    :current-page="currentPage"
                    :current-page-change-parent="currentPageChange"
                    :get-page="goToPage"
                    :items-length="totalItems"
                    :items-per-page="itemsPerPage"
                    :items-per-page-change-parent="itemsPerPageChange"
                    :total-items="totalItems"
                    :total-pages="totalPages"
                    :view="view">
        </pagination>
      </div>
    </div>
  </layout-default>
</template>

<style>

:root {
  --cds-ui-05: #efc100;
  --cds-interactive-04: #1f9dd9;
  --cds-link-01: #1f9dd9;
}

.job-done {
  border: 1px solid green;
  margin-top: 15px;
}

.job-rejected {
  border: 1px solid #e0182d;
  margin-top: 15px;
}

.job-progress {
  border: 1px solid #efc100;
  margin-top: 15px;
}

.job-done-inner {
  padding: 10px 20px;
  background: green;
  color: white;
  display: flex;
  gap: 5px;
}

.job-rejected-inner {
  padding: 10px 20px;
  background: #e0182d;
  color: white;
  display: flex;
  gap: 5px;
}

.job-progress-inner {
  padding: 10px 20px;
  background: #efc100;
  color: white;
  display: flex;
  gap: 5px;
}

.lk-icon-modal {
  float: right;
  padding-top: 3px;
  padding-left: 4px;
  cursor: pointer;
}

.lk-icon-modal:hover {
  color: #333;
}

.job-detail-modal {
  line-height: 24px;
}

.lk-data-table__col::before {
    box-shadow:  0 2px 2px 0 rgba(224, 224, 224) !important;
}

</style>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Multiselect from 'vue-multiselect';
import 'carbon-web-components/es/components/progress-indicator/index.js';

import router from "../../../router";
import LayoutDefault from '../../layouts/LayoutDefault.vue'
import {SHOP_URL} from '../../../config/entrypoint'

import InlineLoading from 'lingoking-workbench/src/components/loading/LoadingInline.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import ListView from 'lingoking-workbench/src/components/listview/ListView.vue'
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import PriceUnits from "lingoking-workbench/src/enums/PriceUnits";
import Pagination from 'lingoking-workbench/src/components/datatable/Pagination.vue';
import JobStatus from "lingoking-workbench/src/enums/JobStatus";

import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import {OrderStatus} from 'lingoking-workbench/src/enums/index.js';
import {getInvoiceStatusLabel} from "lingoking-workbench/src/enums";

export default {
  props: {
    completed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),
    ...mapGetters({
      error: 'order/list/error',
      orders: 'order/list/items',
      isLoading: 'order/list/isLoading',
      view: 'order/list/view',
      currentPage: 'order/list/currentPage',
      totalItems: 'order/list/totalItems',
      messages: 'message/messages',
      user: 'user/show/retrieved',
    }),

    totalPages() {
      return this.totalItems < this.itemsPerPage ? 1 : Math.ceil(this.totalItems / this.itemsPerPage).toFixed(0)
    },

    rejected() {
      return false
    },

    messageInfo() {
      if (!this.messages)
        return JSON.parse(atob(localStorage.getItem('messagesInfo')))

      return this.messages
    },

    sortByItems() {
      return [
        {"id": "deliveryDate", "name": this.$t('delivery_date')},
        {"id": "name", "name": this.$t('name')},
        {"id": "purchaseOrderNumber", "name": this.$t('order_number')},
        {"id": "order.pagesOrWords", "name": this.$t('amount')},
        {"id": "fee", "name": this.$t('fee')},
        {"id": "state", "name": this.$t('state')},
      ]
    },

    dataTableItems() {
      let dataTableItems = []
      if (this.orders && this.orders.length) {
        dataTableItems = this.prepareListView(this.orders)
      }
      return dataTableItems
    },

    emptyDashboard() {
      return !this.orders || this.orders.length === 0
    },

    dataTableHeadItems() {
      let headItems = [
        {
          label: 'name',
          sortable: false,
          index: 'name'
        },
        {
          label: 'amount',
          sortable: false,
          index: 'quantity'
        },
        {
          label: 'delivery_date',
          sortable: false,
          index: 'deliveryDate'
        },
        {
          label: 'fee',
          sortable: false,
          index: 'fee'
        },
        {
          label: 'current_job',
          sortable: false,
          index: 'currentJob'
        },
        {
          label: 'state',
          sortable: false,
          index: 'state'
        },
        {
          label: 'POL',
          sortable: false,
          index: 'polNumber'
        },
      ]

      if (this.completed) {
        headItems.splice(headItems.map(e => e.label).indexOf('current_job'), 1)
      }

      return headItems
    },
  },
  components: {
    Loading,
    LayoutDefault,
    InlineLoading,
    ListView,
    InlineNotification,
    Pagination,
    Multiselect,
  },
  mounted() {
    this.getAllMessages(this.account.user.id)

    // window widht
    this.windowWidth = window.innerWidth

    window.addEventListener('resizeend', () => {
      this.windowWidth = window.innerWidth
    })
  },
  data() {
    return {
      currentSortDir: 'asc',
      itemsPerPage: 10,
      curPage: 1,
      value: [],
      orderBy: {"id": "deliveryDate"},
      shop_url: SHOP_URL,
      search: null,
      searchString: '',
      jobStatus: JobStatus,
      jobStateFilter: '',
      mobileMaxWidth: 992,
      mobile: false,
      smallResolutionMaxWidth: 1300,
      smallResolution: false,
      windowWidth: 0,
    }
  },

  watch: {
    '$route': {
      handler: function () {
        this.setFilter()
        this.getAllMessages(this.account.user.id)
      },
      deep: true,
      immediate: true
    },

    windowWidth(newWidth, oldWidth) {
      if(oldWidth !== newWidth) {
        this.mobile = newWidth < this.mobileMaxWidth
      }
      if(oldWidth !== newWidth) {
        this.smallResolution = newWidth < this.smallResolutionMaxWidth
      }
    },
  },

  methods: {
    ...mapActions({
      getOrdersByLinguist: 'order/list/getOrdersByLinguist',
      getAllMessages: 'message/getAllLinguistMessages'
    }),

    prepareListView: function (items) {
      let self = this
      let preparedItems = []

      let unreadMessageCount = null

      items.forEach(order => {
        let quantity = order.pagesOrWords + ' '

        if (order.priceUnit === PriceUnits.PRICE_UNIT_PAGE) {
          quantity += order.pagesOrWords > 1 ? self.$t('pages') : self.$t('page')
        } else if (order.priceUnit === PriceUnits.PRICE_UNIT_WORD) {
          quantity += order.pagesOrWords > 1 ? self.$t('words') : self.$t('word')
        }

        let jobMessageCount = self.messageInfo.filter((m) => m.orderId === order['@id'].replace(/(.+\/)/, ''))

        unreadMessageCount = jobMessageCount[0] ? jobMessageCount[0].unread : 0

        let preparedItem = {
          purchaseOrderNumber: order.partOfInvoice.confirmationNumber,
          name: order.product.name,
          id: order['@id'],
          deliveryDate: self.getCurrentJob(order) ? self.$moment(self.getCurrentJob(order).deliveryDate).format("DD.MM.YYYY, HH:mm") : self.$moment(order.deliveryDate).format("DD.MM.YYYY, HH:mm"),
          fee: self.getTotalFee(order),
          polNumber: self.getFirstJobPolNumber(order),
          quantity: quantity,
          expandable: !this.mobile || !this.smallResolutionMaxWidth,
          expanded: false,
          state: order.lspState ? self.$t('lsp_job_state_' + order.lspState.replace('-', '_')) : getInvoiceStatusLabel(order.partOfInvoice.orderStatus),
          currentJob: self.getCurrentJobLabel(order),
          pendingAction: self.isPendingAction(order),
          items: [...order.jobs],
          actions: self.getLspNotifications(order) ? [
            {
              type: 'icon-link',
              icon: 'lk-carbon-icon lk-carbon-icon-header--notification',
              badge: 'lk-data-table__col-list__badge--red lk-data-table__col-list__badge--icon',
              badgeText: self.getLspNotifications(order)
            }
          ] : null,
          messageNotification: {
            type: 'icon-link',
            icon: 'lk-carbon-icon lk-carbon-icon-email',
            badge: 'lk-data-table__col-list__badge--red lk-data-table__col-list__badge--icon',
            badgeText: unreadMessageCount
          }
        }

        if (this.mobile || this.smallResolution) {
          preparedItem.link = router.resolve({name: 'OrderShow', params: {id: encodeURIComponent(order['@id'])}}).href
        }

        preparedItems.push(preparedItem)
      })

      return preparedItems
    },

    itemsPerPageChange(newItemsPerPage) {
      if (newItemsPerPage) {
        this.itemsPerPage = newItemsPerPage;
      }
      if (this.dataTableItems.length < this.itemsPerPage) {
        this.curPage = 1
      }
      this.completed ?
        this.getOrdersByLinguist(this.account.user.id
          + '?page=' + this.curPage
          + '&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_DELIVERED.schema + '&order.status[]=' + OrderStatus.ORDER_CANCELLED.schema)
        :
        this.getOrdersByLinguist(this.account.user.id
          + '?page=' + this.curPage
          + '&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_PROCESSING.schema)
    },

    currentPageChange(newCurrentPage) {
      if (newCurrentPage) {
        this.curPage = newCurrentPage;
      }
      this.completed ?
        this.getOrdersByLinguist(this.account.user.id
          + '?page=' + this.curPage
          + '&itemsPerPage=' + this.itemsPerPage + '&order['
          + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_DELIVERED.schema + '&order.status[]=' + OrderStatus.ORDER_CANCELLED.schema)
        :
        this.getOrdersByLinguist(this.account.user.id
          + '?page=' + this.curPage
          + '&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_PROCESSING.schema)
    },

    goToPage(page) {
      if (page) {
        this.getOrdersByLinguist(page)
        if (this.dataTableItems.length < this.itemsPerPage) {
          this.curPage = 1
        }
      }
    },

    setFilter(sortBy) {
      if (!sortBy) this.currentSortDir = this.completed ? 'desc' : 'asc';

      if (this.completed) {
        this.getOrdersByLinguist(this.account.user.id
          + '?page=1'
          + '&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_DELIVERED.schema + '&order.status[]=' + OrderStatus.ORDER_CANCELLED.schema)
      } else {
        this.getOrdersByLinguist(this.account.user.id
          + '?page=1'
          + '&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_PROCESSING.schema)
      }
    },

    sortBy(currentSort) {
      if (currentSort === null) {
        currentSort = this.orderBy;
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }

      this.orderBy = currentSort;
      this.setFilter(true);
    },

    searchItems() {
      //this is a workaround to prevent displaying the empty dashboard component when the client clears the search input
      this.search = this.searchString
      let searchFor = encodeURI(this.search.trim())

      if (this.completed) {
        this.getOrdersByLinguist(this.account.user.id
          + '?purchaseOrderNumber=' + searchFor
          + '&page=1&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_DELIVERED.schema + '&order.status[]=' + OrderStatus.ORDER_CANCELLED.schema)
      } else {
        this.getOrdersByLinguist(this.account.user.id
          + '?purchaseOrderNumber=' + searchFor
          + '&page=1&itemsPerPage=' + this.itemsPerPage
          + '&order[' + this.orderBy.id + ']=' + this.currentSortDir
          + '&order.status[]=' + OrderStatus.ORDER_PROCESSING.schema)
      }
    },

    getTotalFee(order) {
      let totalFee = 0
      let jobsUser = order.jobs.filter(job => {
        if (job.user) {
          return job.user.replace(/^(?:[^\/]*\/){2}\s*/, '') === this.account.user.id
        }
      })

      jobsUser.forEach(job => {
        if (job.fee) {
          totalFee += job.fee
        }
      })

      return parseFloat(totalFee).toFixed(2).replace('.', ',') + ' €'
    },

    getCurrentJob(order) {
      return order.jobs.find(job => {
        return job.status === JobStatus.REALIZATION || job.status === JobStatus.FINISHED || job.status === JobStatus.CANCELLED || job.status === JobStatus.COLLECTED || job.status === JobStatus.PAID
      })
    },

    getCurrentJobLabel(order) {
      let currentJob = order.jobs.find(job => {
        return job.status === JobStatus.REALIZATION || job.status === JobStatus.FINISHED || job.status === JobStatus.CANCELLED || job.status === JobStatus.COLLECTED || job.status === JobStatus.PAID
      })

      if(currentJob.jobDefinition.visibility.includes(this.user.userType)) {
        return currentJob.jobDefinition.name
      }

      return this.$t('internal_process')
    },

    getFirstJobPolNumber(order) {
      return order.jobs[0].purchaseOrderNumber
    },

    getLspNotifications(order) {
      let notifications = 0

      order.jobs.forEach(job => {
        notifications += job.lspNotifications
      })

      return notifications
    },

    isPendingAction(order) {
      let jobInRealization = order.jobs.find(job => {
        return job.status === JobStatus.REALIZATION;
      })

      if (!jobInRealization) {
        return false
      }

      return jobInRealization.user.replace(/^(?:[^\/]*\/){2}\s*/, '') === this.account.user.id;
    },

    getStateClass: function (job) {
      if (this.itemJob && this.itemJob['@id'] === job['@id']) {
        let rejectionReasons = this.itemJob.rejectionReasons.find(rejectionReason => !rejectionReason.solved);

        if (rejectionReasons) {
          return 'invalid';
        }
      }

      if (job.status === JobStatus.REALIZATION) {
        return 'current'
      }

      if (job.status === JobStatus.FINISHED) {
        return 'complete'
      }
    },

    getJobsFiltered(jobs) {
      return jobs.filter(job => {
        return job.jobDefinition.visibility.includes(this.user.userType)
      })
    },
  }
};
</script>

<style scoped>
#search__input {
  border: lightgrey 1px solid;
}
</style>
