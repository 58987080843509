<template>
  <component :is="'span'">
    {{ label }}
  </component>
</template>

<script>
export default {
  name: 'TranslationFileLabel',
  props: ['file'],
  computed: {
    label() {
      return this.file.name;
    },
  }
};
</script>