<template>
    <div data-modal :id="modalId" class="bx--modal" role="dialog"
         aria-modal="true" :aria-labelledby="optionalLabel ? '#' + modalId + '-label' : ''" :aria-describedby="modalData.headline ? '#' + modalId + '-heading' : ''" tabindex="-1">
        <div :class="['bx--modal-container', large ? 'bx--modal-container--large' : '']">
            <div class="bx--modal-header">
                <p class="bx--modal-header__label bx--type-delta" :id="modalId + '-label'" v-if="optionalLabel">{{ optionalLabel }}</p>
                <p class="bx--modal-header__heading bx--type-beta" :id="modalId + '-heading'" v-if="modalData.headline">{{ modalData.headline }}</p>

                <button class="bx--modal-close" type="button" data-modal-close aria-label="close modal" v-if="closeButton" @click="closeModal">
                    <svg class="bx--modal-close__icon" width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                        <title>{{ closeModalLabel }}</title>
                        <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z" fill-rule="nonzero" />
                    </svg>
                </button>
            </div>

            <div :class="['bx--modal-content', overflowUnset ? 'bx--modal-content__overflow-unset' : '']">
                <slot name="content">
                    <p>
                        Passive modal notifications should only appear if there is an action
                        the user needs to address immediately. Passive modal notifications
                        are persistent on the screen.
                    </p>
                </slot>
            </div>

            <div class="bx--modal-footer" v-if="modalData.acceptButtonLabel || modalData.rejectButtonLabel">
                <button :disabled="rejectDisabled" :class="['bx--btn bx--btn--secondary', rejectDisabled ? 'disabled' : '']" type="button" v-if="modalData.rejectButtonLabel" @click.prevent="reject">{{ translatedRejectButtonLabel ? translatedRejectButtonLabel : modalData.rejectButtonLabel }}</button>
                <button :disabled="acceptDisabled" :class="['bx--btn bx--btn--primary', acceptDisabled ? 'disabled' : '']"   type="button" data-modal-primary-focus v-if="modalData.acceptButtonLabel" @click.prevent="accept">{{ translatedAcceptButtonLabel ? translatedAcceptButtonLabel : modalData.acceptButtonLabel}}</button>
            </div>
        </div>
    </div>
</template>

<script>

  import { Modal } from '../../../node_modules/carbon-components'
  import './modal.scss'

  export default {
    name: 'Modal',
    props:  {
      modalData: { type: Object, default: null },
      modalId:  { type: String, default: '#modal' },
      optionalLabel:  { type: String, default: '' },
      closeModalLabel:  { type: String, default: 'Close Modal' },
      closeButton: { type: Boolean, default: true },
      hideOnAccept: { type: Boolean, default: true },
      acceptDisabled: { type: Boolean, default: false },
      rejectDisabled: { type: Boolean, default: false },
      translatedRejectButtonLabel:  { type: String, default: '' },
      translatedAcceptButtonLabel:  { type: String, default: '' },
      overflowUnset: { type: Boolean, default: false },
      handleAcceptButtonClick: {
        type: Function
      },
      handleRejectButtonClick: {
        type: Function
      },
      large:{
        type: Boolean,
        default: false
      }
    },
    methods: {
      reject (evt) {
        if(this.handleRejectButtonClick) {
          this.handleRejectButtonClick()
        }
        this.modalInstance.hide()
        evt.stopPropagation();
      },

      accept (evt) {
        if(this.handleAcceptButtonClick){
          this.handleAcceptButtonClick();
        }
        if(this.hideOnAccept){
          this.modalInstance.hide();
        }
        evt.stopPropagation();
      },

      closeModal (evt) {
        this.modalInstance.hide()
        evt.stopPropagation();
      }
    },
    data () {
      return {
        modalInstance: null
      }
    },
    mounted: function() {
      Modal.init()
      this.modalInstance = Modal.create(this.$el)
    },

    destroyed() {
      this.modalInstance.release()
    }
  }
</script>
