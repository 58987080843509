import * as types from './mutation_types'

export default {
  [types.SET_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: null,
      updated: null,
      violations: null,
      favoriteLinguists: null
    })
  },

  [types.SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.SET_UPDATED] (state, updated) {
    Object.assign(state, { error: '', updated, violations: null })
  },
}
