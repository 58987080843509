<template>
  <div class="">
    <div class="bx--row">
      <div class="bx--col-xs-12 bx--col-md-12">

        <Loading class="alert alert-info" role="status" v-if="isLoading"></Loading>


        <InlineNotification
          :closeButton="true"
          :title="error"
          icon="lk-carbon-icon lk-carbon-icon-close--glyph"
          kind="error"
          notificationClass="bx--inline-notification bx--inline-notification--error"
          role="alert"
          :subtitle="$t('try_again')"
          v-if="error"
        />

        <InlineNotification
          notificationClass="bx--inline-notification bx--inline-notification--success"
          kind="success"
          role="alert"
          icon="lk-carbon-icon lk-carbon-icon-checkmark"
          :title="$t('success')"
          :subtitle="successMessage"
          :closeButton="true"
          v-if="success"
        />

        <form @submit.prevent="submit" class="lk-form" v-if="item">

          <!-- Contact Details Form component -->
          <ContactDetailsForm
            :config="config"
            :language="language"
            :formType="'billing'"
            :countries="countries"
            :item="item"
            :is-invalid="isInvalid"
            :handle-update-field="handleUpdateField"
            :handle-update-timezone-field="handleUpdateTimezoneField"
            :handle-update-project-manager-field="handleUpdateProjectManagerField"
            :$v="$v"
            :get-violation="getViolation"
            :userTitles="userTitles"
            :timezones="timezones"
            :default-timezone="defaultTimezone"
            :linguists="linguists"
            :handle-user-types-field="handleUserTypesField"
          />
          <!-- Company data -->
          <CustomerCompanyForm
            v-if="item.userType === userTypes.TYPE_CUSTOMER_BUSINESS"
            :item="item"
            :companies="companies"
            :$v="$v"
            :isInvalid="isInvalid"
            :handleUpdateField="updateField"
            :handleUpdateAddressBillingCountryField="handleUpdateAddressBillingCountryField"
            :handleUpdateLineOfBusiness="handleUpdateLineOfBusiness"
            :getViolation="getViolation"
            :line-of-business="lineOfBusiness"
            :disable-search-companies="disableSearchCompanies"
          />

          <!-- Billing Address -->
          <CustomerAddressForm
            :config="config"
            :language="language"
            :formType="'billing'"
            :countries="countries"
            :item="item"
            :is-invalid="isInvalid"
            :handle-update-address-field="handleUpdateBillingAddressField"
            :handle-update-address-country-field="handleUpdateAddressBillingCountryField"
            :$v="$v"
            :get-violation="getViolation"
            :addressType="'billingAddress'"
          />

          <!-- Language Settings -->
          <CustomerLanguageSettingsForm
            v-if="showLanguageSettings"
            :key="'lang-settings' + item.id"
            :config="config"
            :item="item"
            :$v="$v"
            :handleUpdateField="updateField"
          />

          <!-- Notification Settings -->
          <CustomerNotificationSettingsForm
            v-if="showNotificationSettings"
            :key="'notification-settings' + item.id"
            :config="config"
            :item="item"
            :$v="$v"
            :handleUpdateField="updateField"
          />

          <!-- Password -->
          <CustomerPasswordForm
            v-if="config.showPassword"
            :config="config"
            :language="language"
            :item="item"
            :$v="$v"
            :get-violation="getViolation"
            :is-invalid="isInvalid"
            :handle-update-field="handleUpdateField"
          ></CustomerPasswordForm>

          <!-- Submit -->
          <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-md-12">
              <InlineNotification
                v-if="$v.$error"
                notificationClass="bx--inline-notification bx--inline-notification--error"
                kind="error"
                role="alert"
                icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                :title="$t('sorry')"
                :subtitle="$t('invalid_data')"
                :closeButton="false"
              />
            </div>
          </div>
          <div>
            <slot name="actionbar"></slot>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
    import '../form/form.scss'
    import {required, minLength, minValue, sameAs} from 'vuelidate/lib/validators'
    import {inArray} from './../../validators'
    import {UserTypes, UserTitles, Timezones} from './../../enums'
    import InlineNotification from './../notification/InlineNotification.vue'
    import Loading from '../loading/Loading.vue'
    import CustomerAddressForm from './forms/CustomerAddressForm.vue'
    import ContactDetailsForm from './forms/ContactDetailsForm.vue';
    import CustomerPasswordForm from './forms/CustomerPasswordForm.vue';
    import CustomerCompanyForm from './forms/CustomerCompanyForm.vue';
    import CustomerNotificationSettingsForm from './forms/CustomerNotificationSettingsForm.vue';
    import CustomerLanguageSettingsForm from './forms/CustomerLanguageSettingsForm.vue';

    export default {

      props: [
            'config',
            'locale',
            'item',
            'countries',
            'companies',
            'lineOfBusiness',
            'updateField',
            'handleSubmit',
            'isLoading',
            'error',
            'success',
            'successMessage',
            'lineOfBusinessFirstItem',
            'project-managers',
            'linguists',
            'defaultTimezone',
            'showLanguageSettings',
            'showNotificationSettings',
            'disableSearchCompanies',
        ],

        components: {
            InlineNotification,
            Loading,
            CustomerAddressForm,
            ContactDetailsForm,
            CustomerPasswordForm,
            CustomerCompanyForm,
            CustomerNotificationSettingsForm,
            CustomerLanguageSettingsForm
        },

        computed: {

            customerValidations() {
                return {
                    userType: {
                        required,
                        inEnum: inArray(Object.values(UserTypes))
                    },
                    userTitle: {
                        required,
                        inEnum: inArray(Object.values(UserTitles))
                    },
                    firstName: {
                        required,
                      minLength: minLength(3)
                    },
                  lastName: {
                    required,
                    minLength: minLength(1)
                  },
                  timezone: {
                    required,
                  },
                  companyPosition: {},
                  telephone: {
                    minLength: minLength(3),
                  },
                  mobilePhone: {
                    minLength: minLength(3),
                  },
                  faxNumber: {
                    minLength: minLength(3),
                  },
                  email: {
                    required,
                  },
                  language: {
                  },
                  newsletter: {
                  },
                  notificationEnabled: {
                  },
                  subscribeFeedbackEmail: {
                  },
                  billingAddress: {
                    houseNumber: {
                      required
                    },
                    streetAddress: {
                      required,
                      minLength: minLength(3)
                    },
                    addressSuffix: { },
                    postalCode: {
                      required,
                            minLength: minLength(3)
                        },
                        addressLocality: {
                            required,
                            minLength: minLength(3)
                        },
                        addressCountry: {
                            required
                        }
                    }
                }
            },

            businessValidation() {
                return Object.assign({}, this.customerValidations, {
                    company: {
                        description: {},
                        fax: {},
                        name: {
                            required
                        },
                        phone: {},
                        url: {},
                        vatId: {
                          required,
                          isValid: (value) => {
                            if (!value)
                              return true;
                            const vatIDRegexp = /^[A-Z]{2,2}[0-9A-Z]{2,13}$/;
                            return vatIDRegexp.test(value);
                          }
                        },
                        collectiveInvoice: {},
                        billingPeriod: {
                            required,
                            minValue: minValue(1)
                        },
                        lineOfBusiness: {
                            required,
                        }
                    }
                })
            },
            passwordValidation() {
                return {
                    password: {
                        required,
                        minLength: minLength(5),
                    },
                    passwordConfirm: {
                        required,
                        minLength: minLength(5),
                        sameAsPassword: sameAs('password')
                    },
                };
            },
            emailConfirmationValidation() {
                return {
                    emailConfirm: {
                        required,
                        minLength: minLength(5),
                        sameAsEmail: sameAs('email')
                    },
                };
            },
            browserTimezone() {
                const defaultTimezone = '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna';
                const intlTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (!intlTimezone) {
                    return defaultTimezone
                }
                const timezoneParts = intlTimezone.split("/");
                if (timezoneParts.length < 2) {
                    return defaultTimezone;
                }
                const capital = timezoneParts[1];
                const timezone = this.timezones.find(t => t.label.indexOf(capital) > -1);
                if (!timezoneParts || !timezone) {
                    return defaultTimezone;
                }
                return timezone.label;
            }
        },


        methods: {
            submit() {
                if (!this.config.showTimezone && !this.item.timezone) {
                    this.handleUpdateField('timezone', this.browserTimezone);
                }
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.handleSubmit();
                }
            },

            getViolation(...path) {
                return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, this.violations || {});
            },

            isInvalid(...path) {
                let error = path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, this.$v.item);
                return error ? error.$error || this.getViolation(path) : null;
            },

            handleUpdateField(field, value, touch = true) {
                this.updateField(field, value);
                if (this.getViolation(field))
                    if (touch) this.$v.item[field].$touch();
            },
            handleUpdateIntField(field, value, touch = true) {
                const intValue = isNaN(parseInt(value)) ? null : parseInt(value);
                this.updateField(field, intValue);
                if (this.getViolation(field))
                    if (touch) this.$v.item[field].$touch();
            },
            handleUserTypesField(field, value) {
                this.handleUpdateField('userType', value);
                if (value === this.userTypes.TYPE_CUSTOMER_BUSINESS && !this.item.lineOfBusiness) {
                    this.handleUpdateField('lineOfBusiness', this.lineOfBusinessFirstItem['@id']);
                    if (this.$v.item['lineOfBusiness']) {
                        this.$v.item['lineOfBusiness'].$touch();
                    }
                }
            },
            handleUpdateLineOfBusiness(field, value, touch = true) {
                this.updateField(field, value);
                if (this.getViolation(field))
                    if (touch) this.$v.item[field].$touch();
            },

            handleUpdateBillingAddressField(field, value, touch = true) {
                let address = Object.assign({}, this.item.billingAddress, {[field]: value});
                this.updateField('billingAddress', address);
                if (touch)
                    this.$v.item.billingAddress[field].$touch();
            },

            handleUpdateAddressBillingCountryField(value, touch = true) {
                this.handleUpdateBillingAddressField('addressCountry', value, touch);
            },

            handleUpdateTimezoneField(value) {
                this.handleUpdateField('timezone', value);
            },
            handleUpdateProjectManagerField(value) {
              this.handleUpdateField('pm', value)
            }
        },

        data() {
            return {
                default: {
                    billingAddress: {
                        streetAddress: null,
                        houseNumber: null,
                        addressSuffix: null,
                        addressCountry: null,
                        addressLocality: null,
                        postalCode: null
                    },
                    userType: null,
                    userTitle: null,
                    firstName: null,
                    lastName: null,
                    timezone: null,
                    email: null,
                    emailConfirm: null,
                    password: null,
                    passwordConfirm: null,
                    companyPosition: null,
                    pm: null,
                    // TODO research how to use something like typescript interface/ js pojo / java vo
                    company: {
                        billingAddress: {
                            streetAddress: null,
                            houseNumber: null,
                            addressSuffix: null,
                            addressCountry: null,
                            addressLocality: null,
                            postalCode: null
                        },
                        description: null,
                        fax: null,
                        name: null,
                        phone: null
                    }
                },
                userTypes: UserTypes,
                userTitles: UserTitles,
                timezones: Timezones,
                selectedAddressCountry: null,
                selectedBillingAddressCountry: null,
                selectedDeliveryAddressCountry: null,
                language: this.locale,
            }
        },

        validations() {
            if (this.item && this.item.userType === this.userTypes.TYPE_CUSTOMER_BUSINESS) {
                let validation = this.businessValidation;
                if (this.config.showPassword) {
                    validation = Object.assign({}, this.businessValidation, this.passwordValidation);
                }
                if (this.config.confirmEmail) {
                    validation = Object.assign(validation, this.emailConfirmationValidation);
                }

                return {item: validation}
            } else {
                let validation = this.customerValidations;
                if (this.config.showPassword) {
                    validation = Object.assign({}, this.customerValidations, this.passwordValidation);
                }

                if (this.config.confirmEmail) {
                    validation = Object.assign(validation, this.emailConfirmationValidation);
                }

                return {item: validation}
            }
        },
    }
</script>

<style scoped>
  .lk-container--inner {
    padding: 0;
  }
</style>
