import ProductType from './ProductTypes'
import PriceUnit from './PriceUnits'

let PriceUnitsPerProductType = []

PriceUnitsPerProductType[ProductType.PRODUCT_TYPE_TEXT_TRANSLATION] = [
  PriceUnit.PRICE_UNIT_WORD,
  PriceUnit.PRICE_UNIT_PAGE,
  PriceUnit.PRICE_UNIT_FIXED
]

PriceUnitsPerProductType[ProductType.PRODUCT_TYPE_VOICE] = [
  PriceUnit.PRICE_UNIT_HALF_HOUR,
  PriceUnit.PRICE_UNIT_FIXED
]
PriceUnitsPerProductType[ProductType.PRODUCT_TYPE_INTERPRETER] = [
  PriceUnit.PRICE_UNIT_DAY,
  PriceUnit.PRICE_UNIT_HALF_DAY,
  PriceUnit.PRICE_UNIT_HOUR
]
export default PriceUnitsPerProductType
