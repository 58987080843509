<template>
  <div :class="border ? '' : 'lk-detailview__section-wrapper--no-border'" class="lk-detailview__section-wrapper">
    <h5 class="lk-detailview__section-headline" v-if="headline">{{ headline }}</h5>
    <p v-if="text">
      {{ text }}
    </p>
    <br />
    <file-upload
      :clearOnReselect="false"
      :files="files"
      :handle-change="handleChange"
      :max-file-size="25"
      :multiple="true"
      :removable="true"
      :same-file-name="false"
      button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
      accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
      :button-label="$t('upload_documents')"
      :id="handleChangeName"
      @change="handleChange(handleChangeName, $event)"
      invalid-class="bx--form-requirement"
      invalid-message="* At least one document is required."></file-upload>

    <div v-if="files && files.length && uploadAction">
      <button class="bx--file-btn bx--btn bx--btn--primary" @click.prevent="uploadAction">{{ uploadActionLabel ? uploadActionLabel : 'Freigeben' }}</button>
    </div>
  </div>
</template>
<script>
  import InlineNotification from "../notification/InlineNotification.vue";
  import FileUpload from '../fileupload/FileUpload.vue';
  import UploadFilesList from '../linguist/ApplicationDocumentList/UploadFilesList.vue';

  export default {
    name: 'JobDetailViewUploadSection',
    components: {
      InlineNotification,
      FileUpload,
      UploadFilesList
    },
    props: {
      jobId: {
        type: String
      },
      headline: {
        type: String
      },
      text: {
        type: String
      },
      uploadAction: {
        type: Function
      },
      uploadActionLabel: {
        type: String
      },
      border: {
        type: Boolean,
        default: true
      },
      removeFile: {
        type: Function
      },
      files: {
        type: Array,
        default: []
      },
      handleChange: {
        type: Function
      },
      handleChangeName: {
        type: String,
        default: 'job-translated-files'
      }
    }
  }
</script>
