import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    items: [],
    currentPage: 1,
    totalItems: 0,
    view: []
  },
  actions,
  getters,
  mutations
}
