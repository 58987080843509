<template>
  <div :class="notificationClass">
    <div class="bx--inline-notification__details">
      <span aria-label="closes notification" :class="['bx--inline-notification__icon', icon ]"></span>
      <div class="bx--inline-notification__text-wrapper">
        <p class="bx--inline-notification__title">{{ title }}</p>
        <div class="bx--inline-notification__subtitle">{{ subtitle }}</div>
        <div class="bx--inline-notification__details" style="margin-left: 0.25em">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
    <button class="bx--inline-notification__close-button lk-carbon-icon lk-carbon-icon-close" type="button" @click="close" v-if="closeButton"></button>
  </div>
</template>

<script>
  import './notification.scss'
  import { Notification } from '../../../node_modules/carbon-components'

  export default {
    name: 'InlineNotification',
    props: ['icon', 'title', 'subtitle', 'closeButton', 'notificationClass', 'closeInlineNotification'],
    methods: {
      close () {
        this.notificationInstance.remove();
      }
    },
    data () {
      return {
        notificationInstance: null
      }
    },
    mounted: function() {
      this.notificationInstance = Notification.create(this.$el)
    },
  }
</script>
