const CarrierTracking = [
    { name: 'AmazonMws', value: 'AmazonMws'},
    { name: 'APC', value: 'APC'},
    { name: 'Asendia', value: 'Asendia'},
    { name: 'Asendia USA', value: 'AsendiaUsa'},
    { name: 'Australia Post', value: 'AustraliaPost'},
    { name: 'AxlehireV3', value: 'AxlehireV3'},
    { name: 'Better Trucks', value: 'BetterTrucks'},
    { name: 'Bond', value: 'Bond'},
    { name: 'Cainiao', value: 'Cainiao'},
    { name: 'Canada Post', value: 'CanadaPost'},
    { name: 'Canpar', value: 'Canpar'},
    { name: 'CDL Last Mile Solutions', value: 'ColumbusLastMile'},
    { name: 'Chronopost', value: 'Chronopost'},
    { name: 'CloudSort', value: 'CloudSort'},
    { name: 'Courier Express', value: 'CourierExpress'},
    { name: 'CouriersPlease', value: 'CouriersPlease'},
    { name: 'Dai Post', value: 'DaiPost'},
    { name: 'Deutsche Post', value: 'DeutschePost'},
    { name: 'Deutsche Post UK', value: 'DeutschePostUK'},
    { name: 'DHL eCommerce Asia', value: 'DHLEcommerceAsia'},
    { name: 'DHL eCommerce Solutions', value: 'DhlEcs'},
    { name: 'DHL Express', value: 'DHLExpress'},
    { name: 'DPD', value: 'DPD'},
    { name: 'DPD UK', value: 'DPDUK'},
    { name: 'ePost Global', value: 'ePostGlobal'},
    { name: 'Estafeta', value: 'Estafeta'},
    { name: 'Fastway', value: 'Fastway'},
    { name: 'FedEx', value: 'FedEx'},
    { name: 'FedEx Cross Border', value: 'FedExCrossBorder'},
    { name: 'FedEx Mailview', value: 'FedExMailview'},
    { name: 'FedEx SameDay City', value: 'FedExSameDayCity'},
    { name: 'FedEx SmartPost', value: 'FedexSmartPost'},
    { name: 'FirstMile', value: 'FirstMile'},
    { name: 'Globegistics', value: 'Globegistics'},
    { name: 'GSO', value: 'GSO'},
    { name: 'Hermes', value: 'Hermes'},
    { name: 'Interlink Express', value: 'InterlinkExpress'},
    { name: 'JP Post', value: 'JPPost'},
    { name: 'Kuroneko Yamato', value: 'KuronekoYamato'},
    { name: 'La Poste', value: 'LaPoste'},
    { name: 'LaserShip', value: 'LaserShipV2'},
    { name: 'Loomis Express', value: 'LoomisExpress'},
    { name: 'LSO', value: 'LSO'},
    { name: 'Newgistics', value: 'Newgistics'},
    { name: 'OnTrac', value: 'OnTrac'},
    { name: 'Osm Worldwide', value: 'OsmWorldwide'},
    { name: 'Parcelforce', value: 'Parcelforce'},
    { name: 'Passport', value: 'PassportGlobal'},
    { name: 'PCF Final Mile', value: 'PcfFinalMile'},
    { name: 'PostNL', value: 'PostNL'},
    { name: 'Purolator', value: 'Purolator'},
    { name: 'Royal Mail', value: 'RoyalMail'},
    { name: 'SEKO OmniParcel', value: 'OmniParcel'},
    { name: 'SF Express', value: 'SFExpress'},
    { name: 'Spee-Dee', value: 'SpeeDee'},
    { name: 'StarTrack', value: 'StarTrack'},
    { name: 'TForce', value: 'TForce'},
    { name: 'UDS', value: 'UDS'},
    { name: 'UPS', value: 'UPS'},
    { name: 'UPS i-parcel', value: 'UPSIparcel'},
    { name: 'UPS Mail Innovations', value: 'UPSMailInnovations'},
    { name: 'USPS', value: 'USPS'},
    { name: 'Veho', value: 'Veho'},
    { name: 'Yanwen', value: 'Yanwen'}
]
export default CarrierTracking