var PriceUnits = {
  PRICE_UNIT_WORD: 'word',
  PRICE_UNIT_PAGE: 'page',
  PRICE_UNIT_FIXED: 'fixed',
  PRICE_UNIT_HOUR: 'hour',
  PRICE_UNIT_HALF_HOUR: 'half hour',
  PRICE_UNIT_DAY: 'day',
  PRICE_UNIT_HALF_DAY: 'half day'
}
export default PriceUnits
