<template>
  <div class="bx--row">
    <div class="bx--col-md-12">
      <div class="container lk-container--white lk-container--inner lk-container--layer-raised">
        <Loading class="alert alert-info" role="status" v-if="isLoading"></Loading>

       <InlineNotification
          notificationClass="bx&#45;&#45;inline-notification bx&#45;&#45;inline-notification&#45;&#45;success"
          kind="success"
          role="alert"
          icon="lk-carbon-icon lk-carbon-icon-checkmark"
          title="Success"
          :subtitle="$t('profile_updated')"
          :closeButton="true"
          v-if="updated"
        />

        <InlineNotification
          :closeButton="true"
          :title="error"
          icon="lk-carbon-icon lk-carbon-icon-close--glyph"
          kind="error"
          notificationClass="bx--inline-notification bx--inline-notification--error"
          role="alert"
          :subtitle="$t('try_again')"
          v-if="error"
        />

        <form @submit.prevent="handleSubmit" class="lk-form">

          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('deliveryAddress')"
              id="text-input-deliveryAddress"
              :value="items.deliveryAddress"
              @change="handleUpdateField('deliveryAddress', $event.target.value)"
              placeholder="Delivery address"
              type="text"
              class="bx--text-input">
            <label for="text-input-deliveryAddress" class="bx--label">Delivery address *</label>

            <div class="bx--form-requirement" v-if="!$v.items.deliveryAddress.required" >
              Delivery address required
            </div>
            <div class="bx--form-requirement" v-if="getViolation('deliveryAddress')">
              {{ getViolation('deliveryAddress') }}
            </div>
          </div>


          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('amount')"
              id="text-input-amount"
              :value="items.amount"
              @change="handleUpdateField('amount', $event.target.value)"
              placeholder="Amount"
              type="number"
              step="0.01"
              class="bx--text-input">
            <label for="text-input-amount" class="bx--label">Amount *</label>

            <div class="bx--form-requirement" v-if="!$v.items.amount.required" >
              Amount required
            </div>
            <div class="bx--form-requirement" v-if="getViolation('amount')">
              {{ getViolation('amount') }}
            </div>
          </div>

          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('jobName')"
              id="text-input-jobName"
              :value="items.jobName"
              @change="handleUpdateField('jobName', $event.target.value)"
              placeholder="Job name"
              type="text"
              class="bx--text-input">
            <label for="text-input-jobName" class="bx--label">Job name *</label>

            <div class="bx--form-requirement" v-if="!$v.items.jobName.required" >
              Job name required
            </div>
            <div class="bx--form-requirement" v-if="getViolation('jobName')">
              {{ getViolation('jobName') }}
            </div>
          </div>

          <InlineNotification
            v-if="$v.$error"
            notificationClass="bx--inline-notification bx--inline-notification--error"
            kind="error"
            role="alert"
            icon="lk-carbon-icon lk-carbon-icon-close--glyph"
            :title="$t('sorry')"
            :subtitle="$t('invalid_data')"
            :closeButton="false"
          />

          <div class="lk-form__actionbar lk-alignment--right">
            <button class="btn btn-success bx--btn bx--btn--primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
    import InlineNotification from './../../notification/InlineNotification'

    export default {
      components: {
          InlineNotification
      },
      props: {
          handleSubmit: {
              type: Function,
              required: true
          },
          isInvalid: {
              type: Function,
              required: true
          },
          handleUpdateField: {
              type: Function,
              required: true
          },
          getViolation: {
              type: Function,
              required: true
          },
          items: {
             type: Object,
             required: true
          },
          $v: {
              type: Object,
              required: true
          },
          updated: {
              type: Boolean,
              required: true
          },
          error: {
              type: String,
              required: true
          },
          isLoading: {
              type: Boolean,
              required: true
          }
      }
  }
</script>
