import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({commit}, {password, token}) => {
  commit(types.PASSWORD_RESET_CREATE_SET_ERROR, '')
  commit(types.PASSWORD_RESET_CREATE_TOGGLE_LOADING)

  const formData = new FormData();
  formData.append("plainPassword", password);

  return fetch('reset_password/reset/' + token, {method: 'POST', data: formData, config: { headers: {'Content-Type': 'multipart/form-data' }}})
    .then(() => {
      commit(types.PASSWORD_RESET_CREATE_TOGGLE_LOADING)

      commit(types.PASSWORD_RESET_CREATE_SET_CREATED, true)

    })
    .catch((e) => {
      commit(types.PASSWORD_RESET_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.PASSWORD_RESET_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.PASSWORD_RESET_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.PASSWORD_RESET_CREATE_SET_ERROR, e.message)
    })
}

export const reset = ({commit}) => {
  commit(types.PASSWORD_RESET_CREATE_RESET, true)
}
