<template>
  <div>
    <Loading v-if="isLoading"/>
    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <div v-if="item">
      <div class="lk-detailview__headline-wrapper">
        <h4 class="lk-detailview__headline">{{ item.productOptionJobDefinition.jobTitle ? item.productOptionJobDefinition.jobTitle : item.jobDefinition.name }}</h4>
        <span class="lk-detailview__details">{{ item.purchaseOrderNumber }}</span>
      </div>
      <div style="margin-top: -0.1rem;">
        <span style="color: #8897a2; font-size: 0.95rem; font-weight: bold;">{{ item.productOptionJobDefinition.jobTitle ? item.jobDefinition.name : null }}</span>
      </div>

      <div class="bx--row">
        <Loading v-if="isUploading || mediaObjectIsLoading"/>
        <div class="bx--col-md-12">
          <div class="bx--row" v-if="item.status !== jobStatus.CANCELLED">
            <div class="bx--col-md-12">
              <InlineNotification
                v-if="error"
                notificationClass="bx--inline-notification bx--inline-notification--error"
                kind="error"
                role="alert"
                icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                :title="$t('error_occurred')"
                :subtitle="$t('try_again')"
                :closeButton="true"
              />
              <InlineNotification
                v-if="uploadError"
                notificationClass="bx--inline-notification bx--inline-notification--error"
                kind="error"
                role="alert"
                icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                :title="$t('error_occurred')"
                :subtitle="$t('try_again')"
                :closeButton="true"
              />
              <InlineNotification
                v-if="uploadUpdated && finishedJob"
                notificationClass="bx--inline-notification bx--inline-notification--success"
                kind="success"
                role="alert"
                icon="lk-carbon-icon lk-carbon-icon-checkmark"
                :title="$t('success')"
                :subtitle="$t('upload_success', {job: finishedJob.jobDefinition.name})"
                :closeButton="true"
              />

              <JobDetailViewHeaderStatus :job="item" />

              <JobDetail
                v-if="item"
                :hideProductDescription="true"
                :job="item"
                :linguist="linguist" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import LayoutDefault from '../layouts/LayoutDefault.vue'
import JobDetail from "./JobDetail.vue";

import FileUpload from 'lingoking-workbench/src/components/fileupload/FileUpload.vue';
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import JobDetailViewHeaderStatus from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeaderStatus.vue";
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import UploadFilesList from 'lingoking-workbench/src/components/linguist/ApplicationDocumentList/UploadFilesList.vue';

import JobStatus from "lingoking-workbench/src/enums/JobStatus"

import contactBoxImage from '../../../assets/images/logo.jpg';

export default {
  name: 'JobShow',
  components: {
    LayoutDefault,
    JobDetail,
    FileUpload,
    InlineNotification,
    JobDetailViewHeaderStatus,
    Loading,
    UploadFilesList
  },

  props: {
    jobId: {
      type: String
    },
    linguist: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),

    ...mapGetters({
      isLoading: 'job/show/isLoading',
      // Job
      item: 'job/show/retrieved',
      error: 'job/show/error',
      // Order
      itemOrder: 'order/show/retrieved',
      errorOrder: 'order/show/error',
      // File Upload
      uploadError: 'job/show/uploadError',
      uploadViolations: 'job/show/uploadViolations',
      uploadUpdated: 'job/show/uploadUpdated',
      isUploading: 'job/show/fileUploadIsLoading',
      // File Download
      downloadedMediaObject: 'mediaobject/show/downloaded',
      mediaObjectIsLoading: 'mediaobject/show/isLoading',
    }),

    finishedJob() {
      return this.itemOrder.jobs.findLast(job => {
        return job.status === this.jobStatus.FINISHED
      })
    }
  },

  created() {
    // retrieve job request data
    this.retrieve(decodeURIComponent(this.jobId))
  },

  data() {
    return {
      jobStatus: JobStatus,
      contactBox: {
        profileImage: contactBoxImage,
        headline: 'personal_contact_person',
        name: 'Marlies Behrndt',
        position: 'lingoking Support',
        email: 'marlies.behrndt@lingoking.com',
        phone: '+49 (0)89 416 12 20 0'
      },
    }
  },

  methods: {
    ...mapActions({
      retrieve: 'job/show/retrieve',
      retrieveOrder: 'order/show/retrieveLspOrder',
      updateRetrieved: 'job/show/updateRetrieved',
    }),
  },

  watch: {
    jobId:function (jobId) {
      this.retrieve(decodeURIComponent(this.jobId))
    },

    error: function (error) {
      if (!error) {
        return
      }

      if (error && error === 'Not Found') {
        this.$router.push({name: 'JobList'})
      }
    },

    uploadUpdated: function (uploadUpdated) {
      if (uploadUpdated) {
        this.$scrollTo('body')

        this.retrieve(decodeURIComponent(this.item['@id']))
        this.retrieveOrder(decodeURIComponent(this.$route.params.id))
      }
    },
  }
};
</script>
