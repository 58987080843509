<template>
    <!-- TEXT TRANSLATION SKILLS START -->
    <fieldset>
        <legend>
            <span class="lk-product-icon lk-product-icon-text-translation lk-button-icon--left"></span>
            {{ config.title }}
            <Tooltip
                    uid="lk-how-to-tip-text-translation-skills"
                    direction="left"
                    :hover="true"
                    :visible="false">
                <template slot="label">
                </template>
                <template slot="trigger">
                    <span class="lk-button-icon lk-button-icon--left lk-how-to-tip__icon lk-carbon-icon lk-carbon-icon-info--glyph"></span>
                </template>
                <template slot="content">
                    <p>{{ config.content }}</p>
                </template>
            </Tooltip>
        </legend>
        <!-- SOFTWARE SKILLS START -->
        <div class="bx--row lk-form__skill-wrapper lk-form__skill-wrapper">
            <div class="lk-form-desc bx--col-md-3">
                {{ config.softwareSkills }}
            </div>
            <div class="bx--col-md-9">
                <div class="lk-skill-specification-wrapper">
                    <div data-notification="" class="bx--inline-notification bx--inline-notification--warning" role="alert">
                        <div class="bx--inline-notification__details">
                            <span class="lk-carbon-icon lk-carbon-icon-warning"></span>
                            <div class="bx--inline-notification__text-wrapper">
                                <p class="bx--inline-notification__subtitle">{{ config.subtitle }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lk-form-desc bx--col-md-3">
            </div>
            <div class="bx--col-md-3">
                <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="skillOptions">
                    <multiselect
                            :class="[$v.item.skills.$error ? 'is-invalid' : '']"
                            :hideSelected="true"
                            :multiple="true"
                            :options="catToolsOptions"
                            :taggable="false"
                            id="linguist_catTools"
                            placeholder="CAT tools"
                            :required="false"
                            tag-placeholder="CAT tools"
                            @input="handleSoftwareSkillUpdate"
                            v-model="catSkills"></multiselect>
                    <label
                            class="form-control-label bx--label"
                            for="linguist_catTools">{{ config.catToolslabel }}</label>
                </div>
            </div>
            <div class="bx--col-md-3">
                <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="skillOptions">
                    <multiselect
                            :class="[$v.item.skills.$error ? 'is-invalid' : '']"
                            :hideSelected="true"
                            :multiple="true"
                            :options="dtpToolsOptions"
                            :taggable="false"
                            id="linguist_dtpTools"
                            placeholder="DTP tools"
                            :required="false"
                            tag-placeholder="DTP tools"
                            @input="handleSoftwareSkillUpdate"
                            v-model="dtpSkills"></multiselect>
                    <label
                            class="form-control-label bx--label"
                            for="linguist_dtpTools">{{ config.dptTools }}</label>
                </div>
            </div>
            <div class="bx--col-md-3">
                <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="skillOptions">
                    <multiselect
                            :class="[$v.item.skills.$error ? 'is-invalid' : '']"
                            :hideSelected="true"
                            :multiple="true"
                            :options="cmsToolsOptions"
                            :taggable="false"
                            id="linguist_cmsTools"
                            placeholder="CMS tools"
                            :required="false"
                            tag-placeholder="CMS tools"
                            @input="handleSoftwareSkillUpdate"
                            v-model="cmsSkills"></multiselect>
                    <label
                            class="form-control-label bx--label"
                            for="linguist_cmsTools">{{ config.cmsToolsLabel }}</label>
                </div>
            </div>
        </div>
        <!-- SOFTWARE SKILLS END  actionStatuses-->
        <SkillForm
            input-id-prefix="linguist_text_translation_skills"
            label="Text translation skill"
            :remove-skill="removeTextTranslationSkill"
            :$v="$v"
            :config="config.skillsForm"
            :showStatus="showStatus"
            :skills="textTranslationSkills"
            :action-statuses="actionStatuses"
            :all-languages-options="allLanguagesOptions"
            :topic-items="topicItems"
            :update-software-skills="updateSoftwareSkills"
            :update-skills="updateTextTranslationSkills"
            :skill-qualities="skillQualities"
            :skill-status-readonly="skillStatusReadonly">
        </SkillForm>

        <div class="bx--row">
          <div class="bx--col bx-col-md-6">
            <br />
            <button class="bx--btn bx--btn--primary lk-form__add-btn" type="button" @click="addTextTranslation()">
                {{ config.additionalTranslation }}
                <svg class="bx--btn__icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M7 7H4v2h3v3h2V9h3V7H9V4H7v3zm1 9A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" fill-rule="evenodd" />
                </svg>
            </button>
          </div>
        </div>
    </fieldset>
    <!-- TEXT TRANSLATION SKILLS END -->
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../../form/form.scss'
  import Multiselect from 'vue-multiselect'
  import SkillForm from './SkillForm.vue'

  import Tooltip from './../../tooltip/Tooltip.vue'
  import { SkillQualities, SoftwareSkills} from './../../../enums';

  export default {
    name: 'TextTranslationSkillsForm',
    components: {
      SkillForm,
      Multiselect,
      Tooltip
    },
    props: {
      config: {
        type: Object,
        required: true
      },
      actionStatuses: {
        type: Array,
        required: true
      },
      showStatus:{
        type: Boolean,
        default: false
      },
      $v: {
        type: Object,
        required: true
      },

      getViolation: {
        type: Function,
        required: true
      },

      allLanguagesOptions: {
        required: true
      },

      addTextTranslation: {
        type: Function,
        required: true
      },

      removeTextTranslationSkill: {
        type: Function,
        required: true
      },

      selectedSkills: {
        required: true
      },

      skillOptions: {
        required: true
      },

      textTranslationSkills: {
        required: true
      },

      topicItems: {
        required: true
      },

      updateTextTranslationSkills: {
        type: Function,
        required: true
      },

      updateSoftwareSkills: {
        type: Function,
        required: true
      },

      softwareSkills: {
        //type: Array,
        required: true
      },

      skillStatusReadonly: {
        type: Boolean
      },

      showOnlyExpertiseArea: {
        type: Boolean,
        default: false
      },
    },

    methods: {
      handleSoftwareSkillUpdate: function () {
        let softwareSkills = (this.catSkills.concat(this.dtpSkills, this.cmsSkills)).filter(Boolean)
        this.updateSoftwareSkills(softwareSkills)
      },
      setSoftwareSkillsPerCategory (skills) {
        let self = this
        skills.forEach(function (softwareSkill) {
          if (SoftwareSkills['cat'].includes(softwareSkill))
            self.catSkills.push(softwareSkill)
          else if (SoftwareSkills['dtp'].includes(softwareSkill)) {
            self.dtpSkills.push(softwareSkill)
          } else if (SoftwareSkills['cms'].includes(softwareSkill)) {
            self.cmsSkills.push(softwareSkill)
          }
        })
      },

    },

    computed: {
      itemSoftwareSkills () {
        return this.softwareSkills || []
      },

      skillQualities() {
        let skillQualities = [
          SkillQualities.BIDIRECTIONAL,
          SkillQualities.SWORN,
        ]
        if (this.showOnlyExpertiseArea) {
          skillQualities.push(SkillQualities.ONLY_EXPERTISE_AREA)
        }
        return skillQualities
      }
    },
    created() {
      if (this.softwareSkills) {
        this.setSoftwareSkillsPerCategory(this.softwareSkills)
      }
    },
    data () {
      return {
        catToolsOptions: SoftwareSkills['cat'],
        dtpToolsOptions: SoftwareSkills['dtp'],
        cmsToolsOptions: SoftwareSkills['cms'],
        catSkills: [],
        dtpSkills: [],
        cmsSkills: [],
      }
    }
  }
</script>
