<template>
  <fieldset>
    <legend> {{config.password}}</legend>
    <div class="bx--row">
      <div class="lk-form-desc  bx--col-md-3">
        {{config.password}}
      </div>
      <div class="bx--col-md-4">

        <div class="bx--form-item bx--text-input-wrapper">
          <input
            :data-invalid="isInvalid('password')"
            :value="item.password"
            @input="handleUpdateField('password', $event.target.value)"
            class="bx--text-input"
            id="text-input-password"
            name="password"
            autocomplete="off"
            :placeholder='config.password'
            type="password"
          >
          <label class="bx--label" for="text-input-password">{{config.password}}
            *</label>
          <div class="bx--form-requirement" v-if="!$v.item.password.required">
            {{config.password}} required
          </div>
          <div class="bx--form-requirement" v-if="!$v.item.password.minLength">
            {{config.password}} is too short
          </div>
          <div class="bx--form-requirement" v-if="getViolation('password')">
            {{ getViolation('password') }}
          </div>
        </div>
      </div>
      <div class="bx--col-md-5">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            :data-invalid="isInvalid('passwordConfirm')"
            :value="item.passwordConfirm"
            @input="handleUpdateField('passwordConfirm', $event.target.value)"
            class="bx--text-input"
            id="text-input-passwordConfirm"
            name="passwordConfirm"
            :placeholder='config.passwordConfirm'
            type="password"
          >
          <label class="bx--label" for="text-input-passwordConfirm">{{config.passwordConfirm}}
            *</label>
          <div class="bx--form-requirement" v-if="!$v.item.passwordConfirm.sameAsPassword">
            Passwords must be identical
          </div>
          <div class="bx--form-requirement" v-if="getViolation('passwordConfirm')">
            {{ getViolation('passwordConfirm') }}
          </div>
        </div>

      </div>
    </div>
  </fieldset>

</template>
<script>
  export default {
    name: "LinguistPasswordForm",

    components: {},
    props: {
      config: null,
      language: null,
      item: null,
      getViolation: {
        type: Function,
        required: true
      },
      isInvalid: {
        type: Function,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      handleUpdateField: {
        type: Function,
        required: true
      }
    },
  }
</script>
