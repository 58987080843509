import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

const getItems = ({commit}, page = '/line_of_businesses') => {
  commit(types.TOGGLE_LOADING)

  fetch(page)
    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, data['hydra:view'])
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export const getOrdersByLinguist = ({commit}, page) => {
  commit(types.TOGGLE_LOADING)

  let path = '/orders/lsp/'

  if (page.includes(path)) {
    path = ''
  }

  fetch(path + page)
    .then((data) => {

      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, data['hydra:view'])
      commit(types.SET_TOTAL_ITEMS, data['hydra:totalItems']);

      if (data['hydra:view'] && data['hydra:view']['@id']) {
        const pageParams = new URLSearchParams(data['hydra:view']['@id']);
        const currentPage = pageParams.get('page');
        if (currentPage) {
          commit(types.SET_CURRENT_PAGE, currentPage)
        }
      }
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export default getItems
