<template>
  <div class="lk-lsp-backend__action-buttons">
    <label v-if="buttons.actionLabel">{{ buttons.actionLabel }}</label>
    <a
      v-if="buttons.acceptModalTarget || buttons.acceptWithTranslationModalTarget"
      href="#"
      class="bx--btn bx--btn--primary lk-lsp-backend__accept-accept-btn"
      :data-modal-target="'#' + (buttons.acceptModalTarget || buttons.acceptWithTranslationModalTarget)"
    >
      {{ $t(buttons.buttonAcceptLabel) }}
    </a>
    <a href="#" class="bx--btn bx--btn--primary lk-lsp-backend__accept-accept-btn"
       @click.prevent="buttons.acceptMethod"
       v-else-if="buttons.buttonAcceptLabel && buttons.acceptMethod">
      {{ $t(buttons.buttonAcceptLabel) }}
    </a>

    <a
      v-if="buttons.buttonClarifyLabel && buttons.clarifyMethod"
      href="#"
      class="bx--btn bx--btn--primary lk-lsp-backend__clarify-clarify-btn"
      :data-modal-target="'#' + buttons.clarifyModalTarget"
    >
      {{ $t(buttons.buttonClarifyLabel) }}
    </a>

    <a href="#" class="bx--btn bx--btn--secondary"
       :data-modal-target="'#'+buttons.rejectModalTarget"
       v-if="buttons.rejectModalTarget">
      {{ $t(buttons.buttonRejectLabel) }}
    </a>
    <a href="#" class="bx--btn bx--btn--secondary"
       @click.prevent="buttons.rejectMethod"
       v-else-if="buttons.buttonRejectLabel && buttons.rejectMethod">
      {{ $t(buttons.buttonRejectLabel) }}
    </a>
  </div>
</template>
<script>
  export default {
    name: 'JobDetailViewActionButtons',
    components: {},
    props: {
      buttons: {
        type: Object
      }
      /*buttonAcceptLabel: { // could be: Korrektur freigeben, Übersetzung freigeben, Datei freigeben, Annehmen
        type: String
      },
      buttonRejectLabel: { // could be: Ablehnen
        type: String
      },
      acceptMethod: {
        type: Function
      },
      rejectMethod: {
        type: Function
      },
      actionLabel: { // could be: Übersetzung aktzeptieren oder ablehnen
        type: String
      }*/
    }
  }
</script>
