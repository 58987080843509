<template>
  <layout-default>
    <div class="container lk-container--white lk-container--inner lk-container--layer-raised">
      <div class="bx--row">
        <div class="bx--col-xs-12 bx--col-md-12">
          <Loading class="alert alert-info" role="status" v-if="isLoading"></Loading>


          <InlineNotification
            :closeButton="true"
            :title="$t('error')"
            icon="lk-carbon-icon lk-carbon-icon-close--glyph"
            kind="error"
            notificationClass="bx--inline-notification bx--inline-notification--error"
            role="alert"
            :subtitle="$t('try_again')"
            v-if="error"
          />
          <InlineNotification
            notificationClass="bx--inline-notification bx--inline-notification--success"
            kind="success"
            role="alert"
            icon="lk-carbon-icon lk-carbon-icon-checkmark"
            :title="$t('success')"
            :subtitle="$t('settings_update_success')"
            :closeButton="true"
            v-if="updated"
          />
          <form @submit.prevent="handleSubmit" class="lk-form" v-if="retrieved">
            <LanguageSettings
              :language="retrieved.language"
              :updateRetrieved="updateRetrieved"
              :$v="$v"
            ></LanguageSettings>

            <NotificationSettings
              :newsletter="retrieved.newsletter"
              :notification-enabled="retrieved.notificationEnabled"
              :subscribe-feedback-email="retrieved.subscribeFeedbackEmail"
              :update-retrieved="updateRetrieved"
              :subscribe-feedback-email-enabled="true"
              :$v="$v" />

            <div class="lk-form__actionbar">
              <button :disabled="isLoading"
                      class="btn btn-success bx--btn bx--btn--primary"
                      type="submit">
                {{$t('save')}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>

  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import {mapState, mapGetters, mapActions} from 'vuex'
  import {required, minLength, minValue, sameAs} from 'vuelidate/lib/validators'
  import LayoutDefault from '../layouts/LayoutDefault.vue'
  import InlineLoading from 'lingoking-workbench/src/components/loading/LoadingInline.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import Tooltip from 'lingoking-workbench/src/components/tooltip/Tooltip.vue'
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import LanguageSettings from './LanguageSettings.vue'
  import NotificationSettings from "./NotificationSettings.vue"

  export default {
    name: 'Settings',
    components: {
      NotificationSettings,
      Multiselect,
      Loading,
      LayoutDefault,
      InlineLoading,
      Tooltip,
      InlineNotification,
      LanguageSettings
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState({
        account: state => state.account
      }),
      ...mapGetters({
        userLoading: 'customer/update/isLoading',
        error: 'customer/update/error',
        retrieved: 'customer/update/retrieved',
        updated: 'customer/update/updated',
        violations: 'customer/update/violations',
      }),
      isLoading() {
        return this.userLoading;
      },
    },
    mounted() {
      this.retrieve('/customers/' + this.account.user.id);
    },
    validations() {
      return {
        language: required,
        newsletter: {},
        notificationEnabled: {},
        subscribeFeedbackEmail: {}
      }
    },
    watch: {
      updated: function (result) {
        this.$scrollTo('body');
      },
      error: function (error) {
        this.$scrollTo('body');
      },
    },

    methods: {
      ...mapActions({
        retrieve: 'customer/update/retrieve',
        updateReset: 'customer/update/reset',
        update: 'customer/update/update',
        updateRetrieved: 'customer/update/updateRetrieved',
      }),
      handleSubmit() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.update();
        }
      }
    },
    beforeDestroy() {
      this.updateReset();
    }
  };
</script>
