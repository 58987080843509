import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const saveCookie = (cookieName, data) => Cookies.set(cookieName, data)
const removeCookie = (cookieName) => Cookies.remove(cookieName)
const getCookie = (cookieName) => Cookies.get(cookieName)

export const jwtService = {
  cookieName: 'lkToken',
  getAccessToken,
  getRefreshToken,
  saveToken,
  getToken,
  getTokenData,
  clearToken,
  setCookieName
}

export default jwtService

function getAccessToken(cookieName = null) {
  cookieName = cookieName ? cookieName : this.cookieName
  return getCookie(cookieName) ? JSON.parse(atob(getCookie(cookieName))).token : null
}

function getRefreshToken(cookieName = null) {
  cookieName = cookieName ? cookieName : this.cookieName
  return getCookie(cookieName) ? JSON.parse(atob(getCookie(cookieName))).refresh_token : null
}

function saveToken(data) {
  saveCookie(this.cookieName, btoa(JSON.stringify(data)))
}

function getTokenData() {
  return getCookie(this.cookieName) ? jwt_decode(JSON.parse(atob(getCookie(this.cookieName))).token) : null
}

function getToken() {
  return getCookie() ? getCookie() : null
}

function clearToken() {
  Cookies.remove(this.cookieName)
  Cookies.remove('uid')
  removeCookie()
}

function setCookieName(newCookieName) {
  this.cookieName = newCookieName
}
