import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './modules/alert/index'
import { account } from './modules/account/index'
import country from './modules/country'
import customer from './modules/customer'
import mediaobject from './modules/mediaobject'
import user from './modules/user'
import linguist from './modules/linguist'
import language from './modules/language'
import profileimage from './modules/profileimage'
import password from './modules/password'
import invoice from './modules/invoice/'
import lineofbusiness from './modules/lineofbusiness'
import order from './modules/order'
import topic from './modules/topic'
import finance from './modules/finance'
import creditNotes from './modules/creditNotes'
import jobrequest from './modules/jobrequest'
import job from './modules/job'
import locale from './modules/locale';
import translationfile from './modules/translationfile'
import message from './modules/message'
import intercom  from './modules/intercom/index'
import additionalCopy  from './modules/additionalCopy'
import chatUsers  from './modules/chatUsers'
import batchshipment  from './modules/batchshipment'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
    country,
    customer,
    mediaobject,
    linguist,
    user,
    language,
    profileimage,
    password,
    invoice,
    lineofbusiness,
    order,
    topic,
    finance,
    creditNotes,
    jobrequest,
    job,
    locale,
    translationfile,
    message,
    intercom,
    additionalCopy,
    chatUsers,
    batchshipment
  },
  strict: debug
})
