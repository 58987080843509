import 'lingoking-workbench/dist/bundle'
import 'lingoking-workbench/dist/backend.css'
import 'lingoking-workbench/dist/bundle.css'
import 'lingoking-workbench/dist/fonts.css'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueLazyLoad from 'vue-lazyload'
import VueI18n from 'vue-i18n'
import VueMoment from 'vue-moment'
import VueScrollTo from 'vue-scrollto'
import vbclass from 'vue-body-class'
import * as Sentry from '@sentry/vue'
import {BrowserTracing} from '@sentry/tracing'

import App from './App.vue'
import {router} from './router'
import {store} from './store'
import {i18n} from '../src/plugins/i18n'
import fetch from './utils/fetch'
import resizeEndEvent from 'lingoking-workbench/src/common/js/resizeend'
import DownloadPlugin from '../src/plugins/download'
import {ENTRYPOINT, SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE, SENTRY_SAMPLERATE} from './config/entrypoint'
import * as intercomLib from '../src/plugins/intercom'

Vue.prototype.$fetch = fetch;

export const bus = new Vue()

if (!global._babelPolyfill) {
  require('babel-polyfill');
}

Sentry.init({
  Vue: Vue,
  dsn: SENTRY_DSN,
  attachProps: true,
  logErrors: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [ENTRYPOINT, "lingoking.com", "localhost", /^\//],
    }),
  ],
  tracesSampleRate: SENTRY_SAMPLERATE,
  trackComponents: true,
  environment: SENTRY_ENV,
  release: SENTRY_RELEASE,
});

resizeEndEvent.bindResizeEndEvent();

Vue.use(vbclass, router);
Vue.use(Vuelidate);
Vue.use(VueLazyLoad);
Vue.use(VueMoment);
Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.use(intercomLib);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(DownloadPlugin);
// Truncate filter
// Usage in component script: this.$options.filters.truncate('Lorem ipsum dolor sit amet, consectetur adipisicing', 30)
// In template:  {{ 'Lorem ipsum dolor sit amet, consectetur adipisicing' | truncate(10, '...') }}
Vue.filter('truncate', function (text, stop, clamp) {
  if (text) {
    return (text.slice(0, stop)).trim() + (stop < text.length ? clamp || '...' : '')
  }

  return text
})

//Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: h => h(App)
});
