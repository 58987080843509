import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  password: {
    required,
    minLength: minLength(8)
  },
  passwordRepeat: {
    passwordSameAs: sameAs('password')
  }
}
