<template>
  <layout-default>
    <Loading v-if="isLoading || mediaObjectIsLoading || mediaObjectDeleteIsLoading || translationFileIsLoading" />
    <div class="bx--row" v-else>
      <div class="bx--col-md-12">
        <div class="bx--row">
          <div class="bx--col-md-12">
            <h1 class="lk-service-backend__headline">{{ $t('current_order') }}</h1>
            <InlineNotification
              v-if="error"
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              :title="$t('error_occurred')"
              :subtitle="$t('try_again')"
              :closeButton="true"
            />
            <InlineNotification
              v-if="acceptedOrRejected"
              notificationClass="bx--inline-notification bx--inline-notification--success"
              kind="success"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-checkmark"
              :title="$t('success')"
              :subtitle="$t(approvalMessage)"
              :closeButton="true"
            />
            <InlineNotification
              v-if="mediaObjectDeleteError"
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              :title="$t('error_occurred')"
              :subtitle="mediaObjectDeleteError"
              :closeButton="true"
            />
            <InlineNotification
              v-if="mediaObjectDeleted && !mediaObjectDeleteError"
              notificationClass="bx--inline-notification bx--inline-notification--success"
              kind="success"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-checkmark"
              :title="$t('success')"
              :subtitle="$t('delete_file_success')"
              :closeButton="true"
            />
            <InlineNotification
              :closeButton="true"
              icon="lk-carbon-icon lk-carbon-icon-checkmark"
              kind="success"
              notificationClass="bx--inline-notification bx--inline-notification--success"
              role="alert"
              :subtitle="$t('upload_file_success')"
              :title="$t('success')"
              v-if="showUploadSuccess" />
          </div>
        </div>
        <div class="bx--row">
          <div class="bx--col-md-8">
            <JobDetailViewHeader
              cols-left="12"
              :job="item"
              :header-actions="headerActions"
              :download-action="downloadAction"
              v-if="item"></JobDetailViewHeader>
            <JobDetailView
              :hide-native-speaker="true"
              :hide-product-description="true"
              :customer-address-label="$t('customer_address')"
              :download-translation-label="'your_translation_to_download'"
              :download-action="downloadAction"
              :handle-file-upload-change="handleFileUploadChange"
              :upload-action="uploadTranslationSourceFiles"
              :remove-file-action="removeTranslationSourceFile"
              :job="item"
              v-if="item">
            </JobDetailView>
          </div>
          <div class="bx--col-md-4">
            <div class="bx--row">
              <div class="bx--col-md-12">
                <JobDetailViewChat
                  :messages="messages"
                  :active="chatActive"
                  :admin="false"
                  :users="chatUsers"
                />
                <div class="lk-detailview" v-if="showOrderAdditionalFiles.length">
                  <label class="bx--toast-notification__title">{{ $t('additional_digital_files') }}</label>
                  <UploadFilesList
                      :showDelete="false"
                      :items="showOrderAdditionalFiles"
                      :download-action="downloadAction">
                  </UploadFilesList>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
  import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
  import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
  import 'lingoking-workbench/src/components/detailview/detailview.scss'
  import {bus} from "lingoking-workbench/src/util/bus.js"

  import { mapState, mapGetters, mapActions } from 'vuex'
  import {OrderStatus} from 'lingoking-workbench/src/enums/index.js';
  import LayoutDefault from '../../layouts/LayoutDefault.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import JobDetailViewHeader from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeader.vue";
  import JobDetailView from "lingoking-workbench/src/components/jobdetailview/JobDetailView.vue";
  import JobDetailViewChat from "lingoking-workbench/src/components/jobdetailview/JobDetailViewChat.vue";
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import UploadFilesList from 'lingoking-workbench/src/components/linguist/ApplicationDocumentList/UploadFilesList.vue';

  import fetch from '../../../utils/fetch'

  import JobStatus from "lingoking-workbench/src/enums/JobStatus"

  import contactBoxImage from '../../../../assets/images/logo.jpg'

  import { ENTRYPOINT } from '../../../config/entrypoint'

  export default {
    components: {
      Loading,
      LayoutDefault,
      JobDetailViewHeader,
      JobDetailView,
      JobDetailViewChat,
      InlineNotification,
      UploadFilesList
    },
    computed: {
      ...mapState({
        account: state => state.account,
        alert: state => state.alert
      }),
      ...mapGetters({
        projectManager: 'job/show/projectManager',
        item: 'job/show/retrieved',
        error: 'job/show/error',
        isLoading: 'job/show/isLoading',
        acceptedOrRejected: 'job/show/acceptedOrRejected',
        //File Download
        downloadedMediaObject: 'mediaobject/show/downloaded',
        mediaObjectIsLoading: 'mediaobject/show/isLoading',
        //File Deletion
        mediaObjectDeleted: 'mediaobject/del/deleted',
        mediaObjectDeleteIsLoading: 'mediaobject/del/isLoading',
        mediaObjectDeleteError: 'mediaobject/del/error',
        // File Upload
        translationFileIsLoading: 'translationfile/create/isLoading',
        translationFileError: 'translationfile/create/error',
        createdTranslationFile: 'translationfile/create/created',
        chatUsers: 'chatUsers/list/items',
      }),

      headerActions () {
        if(this.item && this.item.status === JobStatus.REALIZATION) {
          return {
            buttonAcceptLabel: 'accept', // TODO translate
            buttonRejectLabel: 'reject', //TODO tranlate
            acceptMethod: this.acceptTranslation,
            rejectMethod: this.rejectTranslation,
            acceptModalTarget: 'lk-lsp-backend__action-button-modal-accept',
            rejectModalTarget: 'lk-lsp-backend__action-button-modal-reject',
            handleRejectReasonChange: this.handleRejectReasonChange,
            handleRejectReasonCorrectionFileUpdate: this.handleRejectReasonCorrectionFileUpdate
          }
        }

        return null
      },

      approvalMessage () {
        return this.approval;
      },
      showOrderAdditionalFiles () {
        if(this.item && this.item.order.orderAdditionalFiles && this.item.order.orderAdditionalFiles.length) {
          return this.item.order.orderAdditionalFiles
        }
        return []
      },
    },
    created () {
      // retrieve job request data
      this.retrieve(decodeURIComponent(this.$route.params.id)).then(() => {
        this.contactBox.email = this.projectManager ? this.projectManager.email : this.contactBox.email
        this.contactBox.name = this.projectManager ? this.projectManager.firstName + ' ' + this.projectManager.lastName : this.contactBox.name
        this.contactBox.phone = this.projectManager ? this.projectManager.telephone : this.contactBox.phone
      });
    },
    beforeDestroy () {
      bus.$off('postMessage')
      this.chatActive = false;
    },
    data() {
      return {
        accepted: null,
        // TODO step 2: read from assigned pm
        contactBox: {
          profileImage: contactBoxImage,
          headline: 'personal_contact_person',
          name: 'Marlies Behrndt',
          position: 'lingoking Support',
          email: 'marlies.behrndt@lingoking.com',
          phone: '+49 (0)89 416 12 20 0'
        },
        apiUrl: ENTRYPOINT,
        rejectReason: '',
        rejectReasonFile: null,
        approval: null,
        messages: [],
        chatActive: false,
        filesToUpload: null,
        showUploadSuccess: false,
        messageLoader: () => {
          if(this.chatActive) {
            this.getMessages()
            setTimeout(this.messageLoader, 300000);
          }
        }
      }
    },
    methods: {
      ...mapActions({
        retrieve: 'job/show/retrieve',
        alertSuccess: 'alert/success',
        updateRetrieved: 'job/show/updateRetrieved',
        acceptOrReject: 'job/show/acceptOrReject',
        downloadMediaObject: 'mediaobject/show/download',
        deleteMediaObject: 'mediaobject/del/del',
        createTranslationFile: 'translationfile/create/create',
        getChatUsers: 'chatUsers/list/getItems',
      }),

      downloadAction: function (downloadItem) {
        if(downloadItem) {
          this.downloadMediaObject(downloadItem)
        }
      },

      acceptTranslation: function () {
        this.updateRetrieved({ 'approved': true, 'rejectReason': '', file: null })
        this.approval = 'accepted_translation_success'
        this.acceptOrReject()
        this.chatActive = false
      },

      rejectTranslation: function () {
        if(this.rejectReason) {
          this.updateRetrieved({
            'approved': false,
            'rejectReason': this.rejectReason,
            'file': this.rejectReasonFile,
          })
          this.approval = 'upload_translation_rejected'
          this.acceptOrReject()
        }
      },

      handleRejectReasonChange: function (value) {
        this.rejectReason = value
      },

      handleRejectReasonCorrectionFileUpdate: function (file) {
        this.rejectReasonFile = file
      },

      getMessages: function () {
        if (this.item && this.item.order) {
          const orderId = this.item.order['@id'].split('/').pop();
          const that = this;
          let url = '/retrieve_chat_messages/' + orderId;
          if (this.messages.length !== 0) {
            url += '/' + this.messages[this.messages.length - 1].id
          }
          fetch(url)
            .then((data) => {
              // ~ update messages only if there was a change
              if (data.messages && (that.messages.length === 0 ||
                that.messages[that.messages.length - 1].id !== data.messages[data.messages.length - 1].id)) {
                that.messages = data.messages;
              }
            })
            .catch((e) => {
                console.error(e)
            });
        }
      },

      postMessage: function (message, receiver) {
        if (!message) return;
        const that = this;
        if (this.item && this.item.order) {

          const orderId = this.item.order['@id'].split('/').pop();

          let url = '/post_chat_message/' + orderId;

          if (receiver) {
            url += '/' + receiver.id
          }

          let formData = new FormData();

          formData.append('message', message);

          fetch(url,
            {method: 'POST', data: formData})

            .then((data) => {
              that.messages = data.messages;
            })
            .catch((e) => {
            });

        }
      },

      handleFileUploadChange: function (fieldName, fileList) {
        if (!fileList.length) return

        this.filesToUpload = []
        //let formData = new FormData()
        // append the files to a FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            this.filesToUpload.push({
              file: fileList[x].file,
              filename: fileList[x].file.name,
              order: this.item.order['id']
            })
          })
      },

      uploadTranslationSourceFiles: function () {
        let fileUploadForm = null

        if (this.filesToUpload && this.filesToUpload.length) {
          fileUploadForm = new FormData()
          for (let key in this.filesToUpload) {
            fileUploadForm.append('file[]', this.filesToUpload[key]['file'], this.filesToUpload[key]['filename'])
          }

          fileUploadForm.append('orders', JSON.stringify(this.filesToUpload))

          this.showUploadSuccess = false
          this.createTranslationFile(fileUploadForm)
        }
      },

      removeTranslationSourceFile: function (translationSourceFile) {
        this.showUploadSuccess = false
        this.deleteMediaObject(translationSourceFile)
      }
    },

    watch : {
      item : function(item) {
        if (item) {
          if (item.order) {
            this.getMessages();
            this.getChatUsers(item.order.id);

            // TODO: check if the chat is still active && time
            // this.chatActive = item.status !== JobStatus.FINISHED;
            this.chatActive = item.order.status !== OrderStatus.ORDER_DELIVERED.schema
              && item.order.status !== OrderStatus.ORDER_CANCELLED.schema
              && item.order.status !== OrderStatus.ORDER_DELETED.schema
            setTimeout(this.messageLoader, 300000);
          }
        }
      },
      chatActive: function(chatActive) {

       if (chatActive) {
         bus.$on('postMessage', this.postMessage);
       }
      },
      error: function (error) {
        if(!error) {
          return
        }

        if(error) {
          this.$router.push({name: 'InvoiceList'});
        }
      },

      acceptedOrRejected: function (acceptedOrRejected) {
        if(acceptedOrRejected) {
          this.$scrollTo('body')
          this.retrieve(decodeURIComponent(this.$route.params.id))
        }
      },

      createdTranslationFile: function (created) {
        if (!created) {
          return
        }
        if (!this.translationFileError && !this.translationFileIsLoading) {
          this.showUploadSuccess = true
          this.retrieve(decodeURIComponent(this.$route.params.id));
        }
      },

      mediaObjectDeleted: function (deleted) {
        if(!deleted) {
          return
        }

        if(!this.mediaObjectDeleteError && !this.mediaObjectDeleteIsLoading) {
          this.retrieve(decodeURIComponent(this.$route.params.id));
        }
      }
    }
  };
</script>
