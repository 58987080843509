import {SHOP_URL, INTRANET_URL} from '../../config/entrypoint'

let lspSuffix = function(user) {
  if (user &&  user.roles.includes('ROLE_LSP')) {
    return 'LSP';
  }
  return '';
}

export default (user) => {
  const isLsp = !!lspSuffix(user)
  return [
    {
      label: 'help',
      link: SHOP_URL,
      cmspage: 'help_link' + lspSuffix(user),
      target: '_blank',
      menuIconClass: 'lk-be-icon lk-be-icon-help lk-backend-menu__icon',
    },
    !isLsp ? null : {
      label: 'intranet',
      link: '',
      cmspage: INTRANET_URL,
      target: '_blank',
      menuIconClass: 'lk-be-icon lk-be-icon-requests lk-backend-menu__icon',
    },
    /*  {
        label: 'messages',
        link: '#',
        path: '/messages',
        menuIconClass: 'lk-be-icon lk-be-icon-notification lk-backend-menu__icon',
      },
      {
        label: 'contact',
        link: '#',
        path: '/contact',
        menuIconClass: 'lk-be-icon lk-be-icon-contact lk-backend-menu__icon',
      },
     */
    {
      label: 'profile',
      link: '#',
      path: '/profile',
      menuIconClass: 'lk-be-icon lk-be-icon-user lk-backend-menu__icon',
      children: [
        // {
        //   name: 'Dashboard',
        //   label: 'dashboard',
        // },
        {
          label: 'profile',
          name: 'AccountSetup' + lspSuffix(user),
        },
        {
          label: 'settings',
          name: 'Settings' + lspSuffix(user),
        },
        // {
        //   name: 'Logout',
        //   label: 'logout',
        //   separated: true
        // },
      ]
    },
  ].filter(route => route);
}
