<template>
  <fieldset>
    <legend>{{ $t('native_language') }}</legend>
    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3" v-if="config.showAdditionalText">
        {{ $t('native_language_desc1') }}
      </div>
      <div v-bind:class="{ 'bx--col-md-9' : config.showAdditionalText, 'bx--col-md-12' : !config.showAdditionalText }">
        <div class="form-group bx--form-item bx--select-wrapper bx--select" :data-invalid="$v.item.nativeLanguages.$error" v-if="languages">

          <multiselect
            :data-invalid="$v.item.nativeLanguages.$error"
            :class="[$v.item.nativeLanguages.$error ? 'is-invalid' : '']"
            :hideSelected="true"
            :multiple="true"
            :options="languages"
            :taggable="false"
            id="linguist_native_languages"
            label="name"
            :placeholder="$t('native_language_placeholder')"
            required="true"
            tag-placeholder="Add a native language"
            track-by="id"
            v-model="itemNativeLanguages"></multiselect>
          <label
            class="form-control-label bx--label"
          >{{ $t('native_languages') }} *</label>

          <div class="bx--form-requirement" v-if="$v.item.nativeLanguages.$error">
            {{ $t('native_language_error') }}
          </div>
          <div class="bx--form-requirement" v-if="getViolation('nativeLanguages')">
            {{ getViolation('nativeLanguages') }}
          </div>
        </div>
        <div class="lk-form-desc">{{ $t('native_language_desc2') }}</div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {mapGetters} from "vuex";

export default {
  name: "NativeLanguage",
  components: {
    Multiselect
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    setNativeLanguage: {
      type: Function,
      required: true
    },
    getNativeLanguage: {
      type: Function,
      required: true
    },

    $v: {
      type: Object,
      required: true
    },
    getViolation: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      languages: 'language/list/items'
    }),
    itemNativeLanguages: {
      get: function () {
        return this.getNativeLanguage();
      },
      set: function (newValue) {
        this.setNativeLanguage(newValue);
      }
    },
  },
  methods: {
    prepareLanguages(options) {
      let preparedLanguages = []
      if (options.length) {
        for (let key in options) {
          let languageOption = JSON.parse(JSON.stringify(options[key]))
          preparedLanguages.push(languageOption['@id'])
        }
      }
      return preparedLanguages
    }
  }
}
</script>
