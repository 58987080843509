import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

const getItems  = ({ state, commit, dispatch }, page = '/linguists/get_list?itemsPerPage=50') => {
  commit(types.TOGGLE_LOADING)

  fetch(page)

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      let items = state.viewItems
      items = items.concat(data['hydra:member'])
      commit(types.SET_VIEW_ITEMS, items)

      if(data['hydra:view'] && data['hydra:view']['hydra:next']) {
        dispatch('default', data['hydra:view']['hydra:next'])
      }
      else {
        commit(types.SET_ITEMS, items)
        commit(types.SET_VIEW, data['hydra:view'])
      }
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export default getItems

