<template>
  <layout-default>
    <h1>Credit notes edit</h1>

    <CreditFormUpdate
      :$v="$v"
      :items="items"
      :isInvalid="isInvalid"
      :handleSubmit="handleSubmit"
      :handleUpdateField="handleUpdateField"
      :getViolation="getViolation"
      :updated="updated"
      :error="error"
      :isLoading="isLoading"></CreditFormUpdate>
  </layout-default>

</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import LayoutDefault from '../layouts/LayoutDefault.vue'
    import CreditFormUpdate from "./../../../../lingoking-workbench/src/components/creditnotes/forms/Update"
    import {required} from 'vuelidate/lib/validators'

    export default {
        components: {
            LayoutDefault,
            CreditFormUpdate
        },
        created() {
           this.retrieve( this.$route.params.id );
        },
        computed: {
            ...mapGetters({
                isLoading: 'creditNotes/update/isLoading',
                items: "creditNotes/update/items",
                updated: 'creditNotes/update/updated',
                error: 'creditNotes/update/error',
                violations: 'creditNotes/update/violations',
            })
        },
        validations() {
            return {
                items: {
                    deliveryAddress: {
                        required
                    },
                    amount: {
                        required
                    },
                    jobName: {
                        required
                    }
                }
            }
        },
        methods: {
            ...mapActions({
                retrieve: 'creditNotes/update/retrieve',
                updateRetrieved: "creditNotes/update/updateRetrieved"
            }),
            handleUpdateField(field, value, touch = true) {
                this.updateRetrieved({[field]: value});
                if (this.getViolation(field))
                    if (touch) this.$v.items[field].$touch();
            },
            getViolation(...path) {
                return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, this.violations || {});
            },
            isInvalid(...path) {
                let error = path.reduce(
                    (xs, x) => (xs && xs[x]) ? xs[x] : null,
                    this.$v.items
                );
                return error ? error.$error || this.getViolation(path) : null;
            },
            handleSubmit(){
                this.$v.$touch();
                if (!this.$v.$invalid) {

                }
            }
        }
    }
</script>
