import * as types from './mutationTypes'

export default {
  [types.reset](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
      view: [],
      currentPage: 1,
      totalItems: 0
    })
  },

  [types.setError](state, error) {
    Object.assign(state, {error})
  },

  [types.setItems](state, items) {
    Object.assign(state, {items})
  },

  [types.toggleLoading](state) {
    Object.assign(state, {isLoading: !state.isLoading})
  },

  [types.setViews](state, view) {
    Object.assign(state, {view})
  },

  [types.setCurrentPage](state, currentPage) {
    Object.assign(state, {currentPage})
  },

  [types.setTotalItems](state, totalItems) {
    Object.assign(state, {totalItems})
  },
}
