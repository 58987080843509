import * as types from './mutation_types'

export default {
  [types.BATCH_SHIPMENT_UPDATE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.BATCH_SHIPMENT_UPDATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },
}
