<template>
  <span v-if="isDeleted">
    <a
      class="lk-detailview__file-link lk-detailview__file-link-deleted"
      rel="noopener"
    >
      <span class="lk-carbon-icon lk-carbon-icon-document lk-button-icon--left"></span>
      <TranslationFileLabel :file="file" />
    </a>
  </span>
  <span v-else>
    <a
      :href="link"
      class="lk-detailview__file-link"
      rel="noopener"
      target="_blank"
      @click.prevent="download()"
    >
        <span class="lk-carbon-icon lk-carbon-icon-document lk-button-icon--left"></span>
        <TranslationFileLabel :file="file" class="mr-5" />
        <span v-if="downloading" class="lk-carbon-icon lk-carbon-icon-spinner animate-spinner"></span>
    </a>
  </span>
</template>

<script>
import TranslationFileLabel from './TranslationFileLabel.vue';
import { TRANSLATION_FILE_STATUS } from '../../enums/TranslationFileStatus';

export default {
  name: 'TranslationFileDownload',
  components: {TranslationFileLabel},
  props: ['file'],
  data() {
    return {
      downloading: false,
    }
  },
  computed: {
    isDeleted() {
      return this.file.status === TRANSLATION_FILE_STATUS.DELETED;
    },
    link() {
      return this.file.link;
    },
  },
  methods: {
    download() {
      const resourceId = this.file['resourceId'] ? this.file['resourceId'] : this.file['@id'];

      this.downloading = true;

      this.$fetch(resourceId, { responseType: 'blob' })
        .then((blob) => {
          let element = document.createElement('a');
          document.body.appendChild(element);
          element.style = 'display: none';
          let url = window.URL.createObjectURL(blob);
          element.href = url;
          element.download = this.file.name;
          element.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((e) => {
          alert(e.message);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style>
.mr-5 {
  margin-right: 5px;
}

.animate-spinner {
  animation: animate-spinner 1.2s linear infinite;
}

@keyframes animate-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>