var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lk-lsp-backend__accept-request"},[(_vm.headerData.headline)?_c('h3',{staticClass:"lk-lsp-backend__accept-headline"},[_vm._v(_vm._s(_vm.headerData.headline))]):_vm._e(),_vm._v(" "),(_vm.headerData.subHeadline)?_c('p',{staticClass:"lk-lsp-backend__accept-name"},[_c('span',[_vm._v(_vm._s(_vm.headerData.subHeadline))]),_vm._v(" "),(_vm.showTranslationJobPolnumber)?_c('br'):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.downloads && _vm.downloads.length)?_c('ul',_vm._l((_vm.downloads),function(download,index){return _c('li',{key:'lk-lsp-backend__download-item-'+index},[(download.downloadLabel)?_c('h6',[_vm._v(_vm._s(_vm.$t(download.downloadLabel)))]):_vm._e(),_vm._v(" "),(download.downloadLink || download.link)?_c('a',{staticClass:"lk-lsp-backend__file-link lk-detailview__file-link",attrs:{"rel":"noopener","target":"_blank","href":download.downloadLink || download.link},on:{"click":function($event){return _vm.downloadItem(download, $event)}}},[_vm._v("\n        "+_vm._s(download.linkText)+"\n        "),(download.downloadLink)?_c('span',{staticClass:"lk-carbon-icon lk-carbon-icon-download--glyph lk-button-icon--right"}):_vm._e()]):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.actionButtons)?_c('JobDetailViewActionButtons',{attrs:{"buttons":_vm.actionButtons}}):_vm._e(),_vm._v(" "),(_vm.job && _vm.actionButtons.rejectModalTarget)?_c('LKModal',{attrs:{"modal-data":{
      rejectButtonLabel: this.cancelModalLabel,
      acceptButtonLabel: this.rejectTranslationLabel,
      headline: _vm.job.name
    },"optional-label":_vm.$t('reject_translation'),"modal-id":_vm.actionButtons.rejectModalTarget,"close-button":true,"handle-accept-button-click":_vm.actionButtons.rejectMethod}},[_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t('reject_translation_desc')))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('form',{staticClass:"lk-form"},[_c('div',{staticClass:"bx--form-item bx--text-input-wrapper"},[_c('textarea',{staticClass:"bx--text-area",attrs:{"rows":"8","id":"reject-reason","name":"job-reject-reason","placeholder":_vm.$t('request')},on:{"input":function($event){return _vm.handleRejectReasonUpdate($event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"bx--label",attrs:{"for":"reject-reason"}},[_vm._v(_vm._s(_vm.$t('refusal_reason'))+" *")]),_vm._v(" "),_c('div',{staticClass:"bx--form-requirement"},[_vm._v("\n            Message is required\n          ")])]),_vm._v(" "),_c('file-upload',{attrs:{"id":'file-upload-' + _vm.actionButtons.rejectModalTarget,"accepted":".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt","button-class":"bx--file-btn bx--btn bx--btn--secondary bx--btn--sm","invalid-class":"bx--form-requirement","invalid-message":"* At least one document is required.","button-label":_vm.$t('upload_correction_here'),"clearOnReselect":false,"max-file-size":25,"multiple":true,"removable":true,"same-file-name":false,"handle-change":_vm.handleRejectReasonCorrectionFileUpdate}})],1)])],2):_vm._e(),_vm._v(" "),(_vm.job && _vm.actionButtons.clarifyModalTarget)?_c('LKModal',{attrs:{"modal-data":{
      rejectButtonLabel: this.cancelModalLabel,
      acceptButtonLabel: this.rejectTranslationLabel,
      headline: _vm.job.name
    },"optional-label":_vm.$t('clarify_with_customer'),"modal-id":_vm.actionButtons.clarifyModalTarget,"close-button":true,"handle-accept-button-click":_vm.actionButtons.clarifyMethod}},[_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t('reject_translation_desc')))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('form',{staticClass:"lk-form"},[_c('div',{staticClass:"bx--form-item bx--text-input-wrapper"},[_c('textarea',{staticClass:"bx--text-area",attrs:{"rows":"8","id":"clarify-reason","name":"job-clarify-reason","placeholder":_vm.$t('request')},on:{"input":function($event){return _vm.handleClarifyReasonChange($event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"bx--label",attrs:{"for":"clarify-reason"}},[_vm._v(_vm._s(_vm.$t('clarification'))+" *")]),_vm._v(" "),_c('div',{staticClass:"bx--form-requirement"},[_vm._v("\n            Message is required\n          ")])]),_vm._v(" "),_c('file-upload',{attrs:{"id":'file-upload-' + _vm.actionButtons.clarifyModalTarget,"accepted":".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt","button-class":"bx--file-btn bx--btn bx--btn--secondary bx--btn--sm","invalid-class":"bx--form-requirement","invalid-message":"* At least one document is required.","button-label":_vm.$t('upload_correction_here'),"clearOnReselect":false,"max-file-size":25,"multiple":false,"removable":true,"same-file-name":false,"handle-change":_vm.handleClarifyReasonCorrectionFileUpdate}})],1)])],2):_vm._e(),_vm._v(" "),(_vm.actionButtons.acceptModalTarget)?_c('LKModal',{attrs:{"modal-data":{
      rejectButtonLabel: _vm.$t('cancel'),
      acceptButtonLabel: _vm.$t('confirm'),
      headline: _vm.job.name
    },"optional-label":_vm.$t('accept_translation'),"modal-id":_vm.actionButtons.acceptModalTarget,"close-button":true,"handle-accept-button-click":_vm.actionButtons.acceptMethod}},[(_vm.job.order.physicalDelivery)?_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t('accept_translation_desc_physical')))])]):_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t('accept_translation_desc_mail')))])])],2):_vm._e(),_vm._v(" "),(_vm.actionButtons.acceptWithTranslationModalTarget)?_c('LKModal',{attrs:{"modal-data":{
      rejectButtonLabel: _vm.$t('cancel'),
      acceptButtonLabel: _vm.$t('confirm'),
      headline: _vm.job.name
    },"optional-label":_vm.$t('accept_translation'),"modal-id":_vm.actionButtons.acceptWithTranslationModalTarget,"close-button":true,"handle-accept-button-click":_vm.actionButtons.acceptMethod}},[_c('template',{slot:"content"},[(_vm.job.order.physicalDelivery)?_c('p',[_vm._v(_vm._s(_vm.$t('accept_translation_desc_physical')))]):_c('p',[_vm._v(_vm._s(_vm.$t('accept_translation_desc_mail')))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('h6',[_vm._v("* Optionally you can add a new translation")]),_vm._v(" "),_c('form',{staticClass:"lk-form"},[_c('file-upload',{attrs:{"id":'file-upload-' + _vm.actionButtons.clarifyModalTarget,"accepted":".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt","button-class":"bx--file-btn bx--btn bx--btn--secondary bx--btn--sm","invalid-class":"bx--form-requirement","invalid-message":"* At least one document is required.","button-label":_vm.$t('upload_translation_here'),"clearOnReselect":false,"max-file-size":25,"multiple":false,"removable":true,"same-file-name":false,"handle-change":_vm.handleAcceptTranslationFile}})],1)])],2):_vm._e(),_vm._v(" "),_c('JobDetailViewHeaderStatus',{attrs:{"job":_vm.job}}),_vm._v(" "),_c('JobDetailStatus',{attrs:{"job":_vm.job}})],1)}
var staticRenderFns = []
export { render, staticRenderFns }