import Vue from 'vue';
import Locales from 'lingoking-workbench/src/translations';
const supportedLocales = Object.getOwnPropertyNames(Locales);
export default {
  namespaced: true,
  state: {
    locale: localStorage.getItem('locale'),
  },
  mutations: {
    SET_LOCALE(state, lang) {
      localStorage.setItem('locale', lang);
      state.locale = lang;
    },
  },
  actions: {
    setLocale({ commit }, locales) {
      if (typeof locales === 'string') {
        commit('SET_LOCALE', locales);
      } else {
        const locale = supportedLocales.find(sl =>
          locales.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)));
        commit('SET_LOCALE', locale);
      }
    },
  },
};
