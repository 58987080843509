import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import { userService } from "../../../../_services";

const getItems = ({ commit }, page = '/job_requests') => {
  commit(types.TOGGLE_LOADING)

  const user = userService.getUserData()
  if(user && user.id) {
    page = page.includes('/linguists/') ? page  : '/linguists/' + user.id + page
  }

  fetch(page)
    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, data['hydra:view'])
      commit(types.SET_TOTAL_ITEMS, data['hydra:totalItems']);
      if (data['hydra:view'] && data['hydra:view']['@id']) {
        const pageParams = new URLSearchParams(data['hydra:view']['@id']);
        const currentPage = pageParams.get('page');
        if (currentPage) {
          commit(types.SET_CURRENT_PAGE, currentPage)
        }
      }
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export default getItems
