import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const del = ({ commit }, item) => {
  commit(types.MEDIAOBJECT_DELETE_TOGGLE_LOADING)

  let resourceId = item['@id'] ? item['@id'] : (item['resourceId'] ? item['resourceId'].replace('/download_translation_file', '') : null)

  if(resourceId) {
    return fetch(resourceId, { method: 'DELETE' })
      .then(() => {
        commit(types.MEDIAOBJECT_DELETE_TOGGLE_LOADING)
        commit(types.MEDIAOBJECT_DELETE_SET_DELETED, item)
      })
      .catch((e) => {
        commit(types.MEDIAOBJECT_DELETE_TOGGLE_LOADING)
        commit(types.MEDIAOBJECT_DELETE_SET_ERROR, e.message)
      })
  }
  else {
    commit(types.MEDIAOBJECT_DELETE_TOGGLE_LOADING)
  }

}

export const reset = ({ commit }) => {
  commit(types.MEDIAOBJECT_DELETE_RESET)
}
