<template>
  <div data-loading class="bx--loading bx--loading--small">
    <svg class="bx--loading__svg" viewBox="-75 -75 150 150">
      <title>Loading</title>
      <circle  cx="0" cy="0" r="37.5" />
    </svg>
  </div>
</template>

<script>
  import './loading.scss'
  export default {
    name: 'LoadingInline'
  }
</script>
