<template>
  <div class="lk-detailview__section-wrapper">
    <h5 class="lk-detailview__section-headline" id="tracking-code">{{ $t('enter_tracking_code') }}</h5>
    <p>
      {{ $t('translation_was_accepted') }}
    </p>
    <br />
    <form @submit.prevent="handleSubmit" class="lk-form">
      <div class="bx--row">
        <div class="bx--col-xs-12 bx--col-md-4">
          <div class="form-group bx--form-item bx--input-wrapper bx--select">
            <multiselect
                track-by="value"
                label="name"
                :allow-empty="false"
                :options="CarrierTrackingList"
                :show-labels="false"
                id="carrier-group"
                :placeholder="$t('select_carrier')"
                v-model="shipmentCarrier">
            </multiselect>
          </div>
        </div>
        <div class="bx--col-xs-12 bx--col-md-8">
          <div class="form-group bx--form-item bx--text-input-wrapper">
            <input
                id="tracking-code-input"
                :placeholder="$t('tracking_code')"
                type="text"
                v-model="$v.trackingCode.$model"
                :data-invalid="$v.trackingCode.$error"
                class="bx--text-input" />
            <div class="bx--form-requirement"
                 v-if="!$v.trackingCode.required">
              {{ $t('tracking_code') }} {{ $t('required') }}
            </div>
          </div>
        </div>
      </div>

      <button
        class="bx--file-btn bx--btn bx--btn--primary"
        :disabled="!$v.trackingCode.$model"
        type="submit">{{ $t('release') }}</button>
    </form>
  </div>
</template>
<script>
  import {minLength, required} from 'vuelidate/lib/validators'
  import Multiselect from 'vue-multiselect'
  import CarrierTracking from "../../enums/CarrierTracking";
  export default {
    name: 'JobDetailViewTrackingCodeForm',
    components: {
      Multiselect
    },
    props: {
      job: {
        type: Object,
        required: true
      },
      sendTrackingCode: {
        type: Function,
        required: true
      }
    },

    data () {
      return {
        trackingCode: null,
        shipmentCarrier: { name: 'Deutsche Post', value: 'DeutschePost'},
        CarrierTrackingList: CarrierTracking
      }
    },

    validations() {
      return {
        trackingCode: {
          required,
          minLength: minLength(3)
        }
      }
    },

    created () {
      if(this.job && this.job.order.trackingCode && this.job.order.shipmentCarrier) {
        this.trackingCode = this.job.order.trackingCode
      }
    },

    methods: {
      handleSubmit() {
        // validate
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.sendTrackingCode(this.trackingCode, this.shipmentCarrier.value)
        }
      },
    }
  }
</script>
