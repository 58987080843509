<template>
<div>
      <h2 class="lk-form__step-headline">{{ $t('your_skills') }}</h2>

      <NativeLanguage :$v="$v"
                      :getViolation="getViolation"
                      :config="config"
                      :setNativeLanguage="setNativeLanguage"
                      :getNativeLanguage="getNativeLanguage"></NativeLanguage>

     <Skills
        :$v="$v"
        :config="config"
        :getViolation="getViolation"
        :setItemSkills="setItemSkills"
        :getItemSkills="getItemSkills"
        :skillOptions="skillOptions"
      ></Skills>

  </div>

</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../form/form.scss';
  import NativeLanguage from "./SkillsForm/NativeLanguage.vue"
  import Skills from "./SkillsForm/Skills.vue"

  export default {
    name: 'SkillsForm',
    components: {
      NativeLanguage,
      Skills
    },
    props: {
      config: {
        type: Object,
        required: true
      },
      skillOptions: {
        type: Array,
        required: true
      },
      setItemSkills: {
        type: Function,
        required: true
      },
      getItemSkills: {
        type: Function,
        required: true
      },
      setNativeLanguage: {
        type: Function,
        required: true
      },
      getNativeLanguage: {
        type: Function,
        required: true
      },
      handleUpdateField: {
        type: Function,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      getViolation: {
        type: Function,
        required: true
      },
      selectedLanguages: null
    },
  }
</script>
