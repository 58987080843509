<template>
  <layout-default>
    <InlineNotification
      v-if="alert && alert.message && alert.type === 'alert-danger' && alert.message !== 'email_not_confirmed'"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <InlineNotification
      v-if="alert && alert.message && alert.type === 'alert-danger' && alert.message  === 'email_not_confirmed'"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="$t(alert.message)"
      :closeButton="true">
      <template v-slot:content v-if="alert.message  === 'email_not_confirmed'">
       <a href="javascript:" @click="sendEmailConfirmation"> {{ $t('email_confirmation_label') }}</a>
      </template>
    </InlineNotification>

    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <DashboardCustomer v-if="customer"></DashboardCustomer>
    <DashboardLsp v-else></DashboardLsp>
  </layout-default>
</template>
<script>

  import {mapState, mapGetters, mapActions} from 'vuex'
  import LayoutDefault from './layouts/LayoutDefault.vue'
  import InlineLoading from 'lingoking-workbench/src/components/loading/LoadingInline.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import DashboardCustomer from './dashboard/DashboardCustomer.vue'
  import DashboardLsp from './dashboard/DashboardLsp.vue'
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'

  export default {
    computed: {
      ...mapState({
        account: state => state.account,
        alert: state => state.alert
      }),
    },
    created() {
      const user = this.account.user
      if (user.roles.includes('ROLE_LSP')) {
        this.customer = false
      }
    },
    methods: {
      ...mapActions({
        sendEmailConfirmation: 'account/sendEmailConfirmation'
      })
    },
    components: {
      DashboardLsp,
      Loading,
      LayoutDefault,
      InlineLoading,
      DashboardCustomer,
      InlineNotification
    },

    data() {
      return {
        customer: true
      }
    }
  };
</script>
