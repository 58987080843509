import token from './token'
import reset from './reset'

export default {
  namespaced: true,
  modules: {
    token,
    reset
  }
}
