import { i18n } from '../../../../plugins/i18n'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const retrieve = ({ commit }, id) => {
  commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)
      commit(types.JOBREQUEST_SHOW_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)
      commit(types.JOBREQUEST_SHOW_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.JOBREQUEST_SHOW_RESET)
}


export const acceptOrReject = ({ dispatch, commit, state }) => {
  commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)

  return fetch(state.retrieved['@id'] + '/accept_or_reject', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json' },
    data: JSON.stringify(state.retrieved)
  })

    .then((data) => {
      commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)
      commit(types.JOBREQUEST_SHOW_SET_ACCEPTED_OR_REJECTED, data)

      if(data.error) {
        dispatch('alert/error', 'job_reject_fail', {root: true});
      }
      else {
        dispatch('alert/success', 'job_reject_success', { root: true })
      }
    })
    .catch((e) => {
      commit(types.JOBREQUEST_SHOW_TOGGLE_LOADING)
      commit(types.JOBREQUEST_SHOW_SET_ERROR, e.message)
      dispatch('jobrequest/show/retrieve', state.retrieved['@id'], { root: true })
    })
}

export const updateRetrieved = ({ commit }, updated) => {
  commit(types.JOBREQUEST_SHOW_UPDATE_RETRIEVED, updated)
}
