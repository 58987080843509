<template>
  <div>
    <template>
      <!-- JOB TARGET / CORRECTION FILES UPLOAD START -->
      <JobDetailViewUploadSection
        :headline="$t('target_file')"
        :text="$t(uploadTranslationFileLabel)"
        :upload-action="uploadAction"
        :upload-action-label="job.jobDefinition.type === jobTypes.JOB_TYPE_TRANSLATOR ? $t('release_translation') : (job.jobDefinition.type === jobTypes.JOB_TYPE_CORRECTOR ? $t('release_correction') : $t('upload_correction'))"
        :files="translatedFiles"
        :handle-change="handleFileUploadChange"
        v-if="jobInProgress">
      </JobDetailViewUploadSection>
      <!-- JOB TARGET / CORRECTION FILES UPLOAD END -->

      <!-- JOB UPLOADED TARGET FILES START -->
      <JobDetailViewSection
        :headline="$t(uploadedFilesLabel)"
        :downloads="translationFiles"
        :download-action="downloadAction"
        :admin="admin"
        v-if="job.jobDefinition.type !== jobTypes.JOB_TYPE_CORRECTOR && translationFiles.length">
      </JobDetailViewSection>
      <!-- JOB UPLOADED TARGET FILES END -->

      <!-- JOB CORRECTION FILES DOWNLOAD START -->
      <JobDetailViewSection
        :headline="$t(uploadedCorrectionFilesLabel)"
        :downloads="correctionFiles"
        :admin="admin"
        :download-action="downloadAction"
        :remove-enabled="false"
        v-if="correctionFiles.length">
      </JobDetailViewSection>
      <!-- JOB CORRECTION FILES DOWNLOAD END -->

      <!-- REJECTION REASONS START-->
      <JobDetailViewSection
        :headline="$t('rejection_reason')"
        :rejections="[rejectionSection]"
        v-if="(linguist || admin) && rejectionSection">
      </JobDetailViewSection>
      <!-- REJECTION REASONS END -->

      <!-- PREVIOUS REJECTION REASONS START-->
      <JobDetailViewSection
        :headline="$t('previous_rejection_reasons')"
        :rejections="previousRejectionSection"
        v-if="(linguist || admin) && previousRejectionSection">
      </JobDetailViewSection>
      <!-- PREVIOUS REJECTION REASONS START-->

      <!-- JOB FEE START -->
      <JobDetailViewSection
        :border="false"
        :table="feeSection"
        v-if="linguist && showLspFees">
      </JobDetailViewSection>
      <!-- JOB FEE END -->

      <!-- For Job Requests: Linguist View Only -->
      <div class="lk-detailview__section-spacer" v-if="actionButtons">
        <JobDetailViewActionButtons
          :buttons="actionButtons">
        </JobDetailViewActionButtons>
      </div>
    </template>
  </div>
</template>
<script>
  import '../lspbackend/lspbackend.scss'
  import '../detailview/detailview.scss'

  import JobDetailViewSection from './JobDetailViewSection.vue'
  import JobDetailViewActionButtons from './jobdetailviewheader/JobDetailViewActionButtons.vue'
  import JobDetailViewUploadSection from './JobDetailViewUploadSection.vue'

  import JobStatus from '../../enums/JobStatus'
  import JobTypes from "../../enums/JobType";
  import { ENTRYPOINT } from '../../apps/config/entrypoint'

  export default {
    name: 'JobDetailViewLinguist',
    components: {
      JobDetailViewActionButtons,
      JobDetailViewSection,
      JobDetailViewUploadSection
    },
    props: {
      job: {
        type: Object
      },
      actionButtons: {
        type: Object
      },
      linguist: {
        type: Object
      },
      uploadAction: {
        type: Function,
        default: null
      },
      admin: {
        type: Boolean,
        default: false
      },
      handleFileUploadChange: {
        type: Function
      },
      uploadTranslationFileLabel: {
        type: String,
        default: ''
      },
      uploadedFilesLabel: {
        type: String,
        default: ''
      },
      uploadedCorrectionFilesLabel: {
        type: String,
        default: ''
      },
      downloadAction: {
        type: Function
      },
      removeFileAction: {
        type: Function,
        default: null
      },
      translationFiles: {
        type: Array,
        default: () => []
      },
      jobInProgress: {
        type: Boolean,
        default: false
      },
      removeAction: {
        type: Function,
        default: null
      },
    },

    computed: {

      jobFee () {
        let fee = this.job.fee ? parseFloat(this.job.fee).toFixed(2) : null

        if (fee) {
          return fee.replace('.', ',') + ' €'
        }

        return null
      },

      showLspFees () {
        let productOptionIsEnabled = this.job.order.options.filter(option => {
           return option.productOption.name === 'hide_lsp_fees';
        });
        return !productOptionIsEnabled.length || this.job.status === 'done';
      },

      feeSection () {
        let feeSection = []
        if(this.admin && this.linguist) {
          feeSection.push(
            {'head' : 'Dienstleister', 'data': this.linguist.firstName + ' ' + this.linguist.lastName},
            {'head' : 'Annahme Datum', 'data': this.$moment(this.job.assignDate).format("DD.MM.YYYY, HH:mm") + ' MEZ' }
          )
        }
        if(this.jobFee) {
          feeSection.push( {'head' : this.admin ? 'fee' : 'your_fee', 'data': this.jobFee, 'bold' : true})
        }

        return feeSection
      },

      rejectionReasons() {
        let referenceJob = this.job;
        let order = this.job.order;

        if (this.admin) {
          return order.rejectionReasons
        }

        if(referenceJob.childJobs && referenceJob.childJobs.length) {
          referenceJob = referenceJob.childJobs[referenceJob.childJobs.length - 1]
        }

        let rejectionReasons = referenceJob.rejectionReasons || []
        let hasQmJob = !!order.jobs.find(job => job instanceof Object && job.jobDefinition.type === JobTypes.JOB_TYPE_QM)

        rejectionReasons = rejectionReasons.filter(rejectionReason => rejectionReason.type === 'rejection')

        if (hasQmJob) {
          rejectionReasons = rejectionReasons.filter(rejectionReason => rejectionReason.originJob instanceof Object && rejectionReason.originJob.jobDefinition.type !== JobTypes.JOB_TYPE_CUSTOMER_APPROVAL)
        }

        return rejectionReasons
      },

      rejectionSection () {

        if (!this.rejectionReasons || this.rejectionReasons.length === 0)
          return null

        let r = this.rejectionReasons[this.rejectionReasons.length - 1]

        return { 'reason' : r.reason, 'date': this.$moment(r.createdAt).format('DD.MM.YYYY, HH:mm'), 'createdBy' : r.createdBy.firstName + ' ' + r.createdBy.lastName, 'file': r['translationFile'] ? r['translationFile'] : '', }

      },

      previousRejectionSection() {

          if(!this.rejectionReasons || this.rejectionReasons.length === 0)
              return null

          let reasons = []

          this.rejectionReasons.forEach( r => {
              reasons.push({ 'reason' : r.reason, 'date': this.$moment(r.createdAt).format('DD.MM.YYYY, HH:mm'), 'createdBy' : r.createdBy.firstName + ' ' + r.createdBy.lastName, 'file': r['translationFile'] ? r['translationFile'] : '', })
          })

        // it's not pretty but it works
          reasons.pop()

          return reasons.length === 0 ? null : reasons

      },

      correctionFiles () {
        if(this.job) {
          let correctionFiles = []

          if(this.job.order.translationCorrectionFiles && this.job.order.translationCorrectionFiles.length) {
            correctionFiles = this.job.order.translationCorrectionFiles
          }

          if(correctionFiles && correctionFiles.length) {
            let downloadItems = []
            let self = this
            correctionFiles.forEach(function (correctionFile) {
              let description = ''
              if(self.admin && self.job.jobDefinition.type === self.jobTypes.JOB_TYPE_QM) {
                description = 'Version ' + correctionFile.version + ' (' + self.$moment(correctionFile.createdAt).format("DD.MM.YYYY, H:MM") + ')'
              }

              downloadItems.push({
                name: correctionFile.name,
                link: self.getFilePath(correctionFile.path),
                downloadAction: true,
                resourceId: correctionFile['@id'],
                type: 'correction-file',
                description: description,
                created_at: correctionFile.createdAt,
                scheduledDeletionDate: correctionFile.scheduledDeletionDate,
                status: correctionFile.status,
              })
            })
            return downloadItems
          }
        }

        return []
      }
    },

    data () {
      return {
        jobStatus: JobStatus,
        jobTypes: JobTypes,
        translatedFiles: [],
        newTranslationTargetFiles: [],
        newCorrectionFiles: [],
        apiUrl: ENTRYPOINT,
      }
    },

    methods: {
      getFilePath: function (path) {
        return this.apiUrl + path
      },
    },

    watch: {
      job (job) {
        if(job) {
          this.translatedFiles = []
          this.newCorrectionFiles = []
          this.newTranslationTargetFiles = []
        }
      }
    }
  }
</script>
