import {MESSAGES_SET, TOGGLE_LOADING} from './mutations_types'
import fetch from "../../../utils/fetch";

export default {
  async getAllCustomerMessages({commit, state}, customerId) {

    commit(TOGGLE_LOADING)
    let url = '/retrieve_chat_messages_tile/' + customerId

    await fetch(url)
      .then((response => {
        commit(MESSAGES_SET, response.messages)
      }))
      .finally(() => {
        commit(TOGGLE_LOADING)
      })
  },

  async getAllLinguistMessages({commit, state}, linguistId) {

    commit(TOGGLE_LOADING)
    let url = '/retrieve_chat_messages_tile_lsp/' + linguistId

    await fetch(url)
      .then((response => {
        commit(MESSAGES_SET, response.messages)
      }))
      .finally(() => {
        commit(TOGGLE_LOADING)
      })
  }
}
