import * as types from './mutation_types'

export default {
  [types.JOBREQUEST_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: null,
      acceptedOrRejected: null
    })
  },

  [types.JOBREQUEST_SHOW_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.JOBREQUEST_SHOW_SET_RETRIEVED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },

  [types.JOBREQUEST_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.JOBREQUEST_SHOW_UPDATE_RETRIEVED] (state, updated) {
    Object.assign(state.retrieved, updated)
  },

  [types.JOBREQUEST_SHOW_SET_ACCEPTED_OR_REJECTED] (state, acceptedOrRejected) {
    Object.assign(state, { acceptedOrRejected })
  },
}
