var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',[_c('legend',[_vm._v(_vm._s(_vm.$t("settings_notifications")))]),_vm._v(" "),_c('div',{staticClass:"bx--row"},[_c('div',{staticClass:"lk-form-desc bx--col-md-3"},[_c('div',{staticClass:"lk-tooltip cv-interactive-tooltip"},[_c('Tooltip',{attrs:{"uid":"lk-how-to-tip-timezone","direction":"right","hover":true,"visible":false}},[_c('template',{slot:"label"},[_vm._v(_vm._s(_vm.$t("notifications")))]),_vm._v(" "),_c('template',{slot:"trigger"},[_c('span',{staticClass:"\n                lk-button-icon lk-button-icon--left\n                lk-how-to-tip__icon\n                lk-carbon-icon\n                lk-carbon-icon-info--glyph\n              "})]),_vm._v(" "),_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t("settings_notifications_tooltip")))])])],2)],1)]),_vm._v(" "),_c('div',{staticClass:"bx--col-md-9"},[_c('div',{staticClass:"bx--row"},[_c('div',{staticClass:"bx--col-md-3 lk-checkbox-wrapper--margin-top"},[_c('div',{staticClass:"form-group bx--form-item bx--checkbox-wrapper"},[_c('input',{class:[
                'bx--checkbox',
                'bx--checkbox-new',
                'form-control',
                _vm.$v.newsletter.isInvalid ? 'is-invalid' : '' ],attrs:{"id":"checkbox-newsletter","name":"checkbox-newsletter","type":"checkbox","data-invalid":_vm.$v.newsletter.isInvalid},domProps:{"checked":_vm.newsletter,"value":_vm.newsletter},on:{"change":function($event){return _vm.updateField('newsletter', !_vm.newsletter)}}}),_vm._v(" "),_c('label',{staticClass:"form-control-label bx--checkbox-label",attrs:{"for":"checkbox-newsletter"}},[_vm._v("\n              "+_vm._s(_vm.$t("newsletter"))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"bx--col-md-3 lk-checkbox-wrapper--margin-top"},[_c('div',{staticClass:"form-group bx--form-item bx--checkbox-wrapper"},[_c('input',{class:[
                'bx--checkbox',
                'bx--checkbox-new',
                'form-control',
                _vm.$v.notificationEnabled.isInvalid ? 'is-invalid' : '' ],attrs:{"id":"checkbox-notifications","name":"checkbox-notifications","type":"checkbox","data-invalid":_vm.$v.notificationEnabled.isInvalid},domProps:{"checked":_vm.notificationEnabled,"value":_vm.notificationEnabled},on:{"change":function($event){return _vm.updateField('notificationEnabled', !_vm.notificationEnabled)}}}),_vm._v(" "),_c('label',{staticClass:"form-control-label bx--checkbox-label",attrs:{"for":"checkbox-notifications"}},[_vm._v("\n              "+_vm._s(_vm.$t("email"))+"\n            ")])])]),_vm._v(" "),(_vm.subscribeFeedbackEmailEnabled)?_c('div',{staticClass:"bx--col-md-6 lk-checkbox-wrapper--margin-top"},[_c('div',{staticClass:"form-group bx--form-item bx--checkbox-wrapper"},[_c('input',{class:[
                'bx--checkbox',
                'bx--checkbox-new',
                'form-control',
                _vm.$v.subscribeFeedbackEmail.isInvalid ? 'is-invalid' : '' ],attrs:{"id":"checkbox-feedback","name":"checkbox-feedback","type":"checkbox","data-invalid":_vm.$v.subscribeFeedbackEmail.isInvalid},domProps:{"checked":_vm.subscribeFeedbackEmail,"value":_vm.subscribeFeedbackEmail},on:{"change":function($event){return _vm.updateField('subscribeFeedbackEmail', !_vm.subscribeFeedbackEmail)}}}),_vm._v(" "),_c('label',{staticClass:"form-control-label bx--checkbox-label",attrs:{"for":"checkbox-feedback"}},[_vm._v("\n              "+_vm._s(_vm.$t("subscribe_feedback_email"))+"\n            ")])])]):_vm._e()])])])])}
var staticRenderFns = []
export { render, staticRenderFns }