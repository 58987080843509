import update from './update'
import show from './show'
import list from './list'

export default {
  namespaced: true,
  modules: {
    list,
    update,
    show
  }
}
