import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const retrieve = ({ commit }, id) => {
  commit(types.INVOICE_SHOW_TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.INVOICE_SHOW_TOGGLE_LOADING)
      commit(types.INVOICE_SHOW_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.INVOICE_SHOW_TOGGLE_LOADING)
      commit(types.INVOICE_SHOW_SET_ERROR, e.message)
    })
}

export const retrieveOrders = ({ commit }, id) => {
  commit(types.INVOICE_SHOW_ORDERS_TOGGLE_LOADING)
  return fetch(id + '/orders')

    .then((data) => {
      commit(types.INVOICE_SHOW_ORDERS_TOGGLE_LOADING)
      commit(types.INVOICE_SHOW_SET_ORDERS_RETRIEVED, data['hydra:member'])
    })
    .catch((e) => {
      commit(types.INVOICE_SHOW_ORDERS_TOGGLE_LOADING)
      commit(types.INVOICE_SHOW_SET_ERROR, e.message)
    })
}


export const reset = ({ commit }) => {
  commit(types.INVOICE_SHOW_RESET)
}
