<template>
  <layout-default>
    <div class="container lk-container--white lk-container--inner lk-container--layer-raised">
      <div class="bx--row">
        <div class="bx--col-xs-12 bx--col-md-12">

          <Loading
            class="alert alert-info"
            role="status"
            v-if="isLoading">
          </Loading>

          <InlineNotification
            :closeButton="true"
            :title="error"
            icon="lk-carbon-icon lk-carbon-icon-close--glyph"
            kind="error"
            notificationClass="bx--inline-notification bx--inline-notification--error"
            role="alert"
            :subtitle="$t('try_again')"
            v-if="error"
          />

          <!-- Customer -->
          <div v-if="customer && products.length !== 0" class="lk-container lk-container--inner">
            <h1 class="lk-service-backend__headline">{{ $t('customized_service_title') }}</h1>
            <p>{{ $t('customized_service') }}</p>
            <hr style="color: gray">
          </div>
          <ul v-if="customer" class="lk-teaser__list lk-container lk-container--inner">
            <div v-for="product in products" class="lk-teaser lk-teaser--small-width lk-teaser--margin-bottom">
              <div class="lk-teaser__item lk-teaser__item--white lk-teaser__item--shadow">
                <span :class="`lk-carbon-icon lk-product-icon ${product.icon} lk-teaser__item-icon`"></span>
                <div class="lk-teaser__item-content">
                  <div class="lk-teaser__content-above">
                    <h3 class="lk-teaser__item-title">
                      <a :href="`${shopServiceUrl}/${locale.locale}/${product.slug}`" class="lk-subnav-dropdown-sub-menu__link" target="_blank">
                        {{ product.name }}
                      </a>
                    </h3>
                    <p class="lk-teaser__item-text">{{ product.shortDescription }}</p>
                  </div>
                  <p class="lk-teaser__item-price">{{ product.annotation }}</p>
                </div>
              </div>
            </div>
          </ul>

          <!-- Company -->
          <div v-if="customer && customer.company && productsCompany.length !== 0" class="lk-container lk-container--inner">
            <h1 class="lk-service-backend__headline">{{ $t('customized_service_company_title') }}</h1>
            <p>{{ $t('customized_service_company') }}</p>
            <hr style="color: gray">
          </div>

          <ul v-if="customer && customer.company" class="lk-teaser__list lk-container lk-container--inner">
            <div v-for="product in productsCompany" class="lk-teaser lk-teaser--small-width lk-teaser--margin-bottom">
              <div class="lk-teaser__item lk-teaser__item--white lk-teaser__item--shadow">
                <span :class="`lk-carbon-icon lk-product-icon ${product.icon} lk-teaser__item-icon`"></span>
                <div class="lk-teaser__item-content">
                  <div class="lk-teaser__content-above">
                    <h3 class="lk-teaser__item-title">
                      <a :href="`${shopServiceUrl}/${locale.locale}/${product.slug}`" class="lk-subnav-dropdown-sub-menu__link" target="_blank">
                        {{ product.name }}
                      </a>
                    </h3>
                    <p class="lk-teaser__item-text">{{ product.shortDescription }}</p>
                  </div>
                  <p class="lk-teaser__item-price">{{ product.annotation }}</p>
                </div>
              </div>
            </div>
          </ul>

        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import 'lingoking-workbench/src/components/form/form.scss'
  import 'lingoking-workbench/src/components/teaser/teaser.scss'

  import {mapActions, mapGetters, mapState} from 'vuex'

  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import LayoutDefault from './../layouts/LayoutDefault.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'

  import {SHOP_URL} from '../../config/entrypoint'

  export default {
    components: {
      InlineNotification,
      LayoutDefault,
      Loading
    },

    data() {
      return {
        shopServiceUrl: SHOP_URL
      }
    },

    methods: {
    },

    computed: {
      ...mapState({
        account: state => state.account,
        locale: state => state.locale,
      }),

      ...mapGetters({
        customer: 'user/show/retrieved',
        customerLoading: 'user/show/isLoading',
        error: 'user/show/error',
      }),

      products() {
        return this.customer.products || []
      },

      productsCompany() {
        return this.customer.company.products || []
      },

      isLoading() {
        return this.customerLoading
      }
    }
  }
</script>
