<template>
  <div class="lk-main-container">
    <div class="container">
      <header class="lk-alignment--center">
        <a href="/">
          <img
            src="../../assets/images/logo-lingoking.svg"
            alt="Lingoking"
            class="lk-header__logo"
          />
        </a>
      </header>
      <main>
        <div
          class="
            lk-page-content
            container
            lk-container lk-container--inner lk-container--white
            lk-service-backend__container
          "
        >
          <div class="bx--col-md-12">
            <h4 class="lk-alignment--center">
              {{ $t('unsubscribe_feedback_email') }}
            </h4>
            <br />
            <br />
            <Loading class="alert alert-info" role="status" v-if="isLoading" />
            <div class="lk-alignment--center">
              <a class="btn btn-primary" href="/">{{ $t('close') }}</a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import Loading from "lingoking-workbench/src/components/loading/Loading.vue";

export default {
  name: "UnsubscribeFeedbackEmailPage",
  components: {
    Loading,
    LayoutDefault,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
    ...mapGetters({
      isLoading: "customer/unsubscribeFeedbackEmail/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      update: "customer/unsubscribeFeedbackEmail/update",
    }),
  },
  mounted() {
    this.update(this.account.user.id);
  }
};
</script>
