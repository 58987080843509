import * as types from './mutation_types'
import {jwtDownload} from 'lingoking-workbench/src/util/JwtDownload';

export const downloadPdf = ({commit}, item) => {
  commit(types.BATCH_SHIPMENT_UPDATE_TOGGLE_LOADING);

  const url = item['@id'] + '/download_shipment_sheet';
  const fileName = `Begleitschreiben_BA_${item.name}_.pdf`;
  const downloadPromises = jwtDownload(url, fileName);
  downloadPromises
      .then((data) => {
        commit(types.BATCH_SHIPMENT_UPDATE_TOGGLE_LOADING);
        commit(types.BATCH_SHIPMENT_UPDATE_SET_ERROR, null);
      })
      .catch((error) => {
        commit(types.BATCH_SHIPMENT_UPDATE_TOGGLE_LOADING);
        commit(types.BATCH_SHIPMENT_UPDATE_SET_ERROR, error);
      });
}