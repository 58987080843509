import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const reset = ({ commit }) => {
  commit(types.RESET)
}

export const retrieve = ({ commit }, id) => {
  commit(types.TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_RETRIEVED, data)

    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export const update = ({ commit, state }) => {
  commit(types.SET_ERROR, '')
  commit(types.TOGGLE_LOADING)

  return fetch(state.retrieved['@id'], {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json' },
    data: JSON.stringify(state.retrieved)
  })

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.SET_ERROR, e.errors.message);

        return
      }

      // eslint-disable-next-line
      commit(types.SET_ERROR, ((e.errors && e.errors.message) ? e.errors.message : e))
    })
}

export const updateRetrieved = ({ commit }, updated) => {
  commit(types.UPDATE_RETRIEVED, updated)
}

export const getFavoriteLinguists = ({ commit }, id) => {
  commit(types.TOGGLE_LOADING)

  let page = id + '/favorite_linguists'

  return fetch(page)

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_FAVORITE_LINGUISTS, data['hydra:member'])
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export const updateFavoriteLinguists = ({ commit }, toUpdate) => {
  commit(types.SET_ERROR, '')
  commit(types.TOGGLE_LOADING)

  return fetch(toUpdate.id, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json' },
    data: JSON.stringify(toUpdate.data)
  })

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_UPDATED, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.SET_ERROR, e.errors._error)
    })
}
