<template>
   <p class="scheduled-document-deletion-label">
      {{ label }}
   </p>
</template>

<script>
import { TRANSLATION_FILE_STATUS } from '../../enums/TranslationFileStatus';
import './ScheduledDocumentDeletionLabel.scss';

export default {
  name: 'ScheduledDocumentDeletionLabel',
  props: ['file'],
  computed: {
    isArchived() {
      return this.file.status === TRANSLATION_FILE_STATUS.ARCHIVED;
    },
    isDeleted() {
      return this.file.status === TRANSLATION_FILE_STATUS.DELETED;
    },
    label() {
      if (this.isDeleted) {
        return this.$i18n.t('file_was_deleted_on', { date: this.getScheduledDeletionDate() });
      }
      if (this.isArchived) {
        return this.$i18n.t('file_will_be_deleted_on', { date: this.getScheduledDeletionDate() });
      }
      return '';
    },
  },
  methods: {
    getScheduledDeletionDate() {
      if (this.file.scheduledDeletionDate)
        return this.$moment(this.file.scheduledDeletionDate).format("DD.MM.YYYY");
      return null;
    }
  }
};
</script>