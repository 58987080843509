import fetch from "../apps/utils/fetch";

export function jwtDownload(url, fileName) {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  return fetch(url, {responseType: "blob"})
    .then(blob => {
      let objectUrl = window.URL.createObjectURL(new Blob([blob]));
      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch(error => {
      alert('Download error!');
    })
}
