<template>
    <div>
        <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-md-12">
                <h2 class="lk-form__step-headline">{{ $t('profile_summary') }}</h2>

                <fieldset>
                    <legend>{{ $t('profile_photo') }}</legend>
                    <p>{{ $t('profile_photo_desc') }}<p class="lk-form__desc-text">
                        <strong>{{ $t('profile_photo_desc2') }}</strong>
                    </p>
                    <div class="bx--row">
                        <div class="lk-form-desc bx--col-md-3">
                        </div>
                        <div class="bx--col-md-9">
                            <div class="bx--form-item bx--text-input-wrapper">
                                <file-upload
                                        :clearOnReselect="true"
                                        :files="profileImageFile"
                                        :handle-change="profileImageChange"
                                        :max-file-size="2"
                                        :multiple="false"
                                        :removable="true"
                                        accepted=".jpg,.png"
                                        :button-label="$t('select_file')"
                                        :description="$t('only_jpg')"
                                        id="lsp_profile_image"
                                        :label="$t('profile_photo')"></file-upload>


                                <span class="bx--file__selected-file"
                                      v-if="itemProfileImage && itemProfileImage['contentUrl']">
                                    <p class="bx--file-filename">
                                        <a :download="'lk-profile-image-' + itemProfileImage['name'] "
                                           href="#"
                                           @click.prevent="downloadAction"
                                           class="lk-list__link lk-list__link--download"
                                           rel="noopener" target="_blank">
                                            <span class="lk-carbon-icon lk-carbon-icon-user lk-button-icon--left"></span>
                                            {{ itemProfileImage['name'] }}
                                        </a>
                                    </p>
                                     <span class="bx--file__state-container">
                                        <svg
                                                @click="removeProfileImage"
                                                @keydown.enter.prevent="removeProfileImage"
                                                @keydown.space.prevent
                                                @keyup.space.prevent="removeProfileImage"
                                                alt="Remove file"
                                                arial-label="Remove file"
                                                class="bx--file-close"
                                                fill-rule="evenodd"
                                                height="16"
                                                role="button"
                                                tabindex="0"
                                                viewBox="0 0 16 16"
                                                width="16">
                                            <title>{{config.remove}}</title>
                                            <path
                                                    d="M8 6.586L5.879 4.464 4.464 5.88 6.586 8l-2.122 2.121 1.415 1.415L8 9.414l2.121 2.122 1.415-1.415L9.414 8l2.122-2.121-1.415-1.415L8 6.586zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
                                        </svg>
                                     </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
  import '../form/form.scss'
  import {ENTRYPOINT} from './../../apps/config/entrypoint'
  import FileUpload from './../upload/FileUpload.vue'
  import { mapActions } from 'vuex'

  export default {
    name: 'ProfilePhotoForm',
    components: {
      FileUpload
    },    
    props: {
      config: {
        type: Object,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      profileImageChange: {
        type: Function,
        required: true
      },
      profileImage: {},
      profileImageFile: {
        type: Array,
        required: true
      },
      removeProfileImage: {
        type: Function,
        required: true
      }
    },
    methods: {
      ...mapActions({
        downloadMediaObject: 'mediaobject/show/download'
      }),
      downloadAction () {
        if(this.profileImage) {
          this.downloadMediaObject({
            resourceId: this.profileImage["@id"],
            ...this.profileImage
          })
        }
      }
    },
    computed: {
      itemProfileImage() {
        return this.profileImage || null
      }
    },
    data() {
      return {
        apiUrl: ENTRYPOINT
      }
    }
  }
</script>
