<template>
  <div :class="customClass">
    <h4 class="lk-shopping-cart-section__subheadline" v-if="order.orderName">{{ order.orderName }}</h4>
    <h3 class="lk-shopping-cart-section__headline" v-if="order.product">{{ order.product.name }} </h3>
    <FileUpload
      :id="id"
      :handle-change="orderFileChange"
      @change="orderFileChange"
      :clearOnReselect="false"
      :max-file-size="25"
      :multiple="true"
      :removable="true"
      :same-file-name="false"
      :button-label="$t('upload_documents')"
      invalid-class="bx--form-requirement"
      accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
      button-class="bx--btn bx--btn--secondary" />
  </div>
</template>
<script>
  import FileUpload from './FileUpload.vue'

  export default {
    name: 'TranslationSourceFileUploadItem',
    props: {
      id: {
        type: String,
        required: true
      },
      order: {
        type: Object,
        required: true
      },
      orderFileChange: {
        type: Function,
        required: true
      },
      customClass: {
        type: String,
        required: false
      },
    },
    components: {
      FileUpload
    }
  }
</script>
