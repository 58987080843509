<template>
  <!-- Address -->
  <fieldset>
    <legend>{{$t('billing_address')}}</legend>
    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3">
        {{$t('billing_address_label')}}
      </div>
      <div class="bx--col-md-9">
        <div class="bx--row">
          <div class="bx--col-md-12">
            <div class="bx--form-item bx--text-input-wrapper">
              <input
                :value="item[addressType].streetAddress"
                :data-invalid="isInvalid(addressType, 'streetAddress')"
                :placeholder="$t('address_street')"
                id="text-input-street-address"
                class="bx--text-input"
                type="text"
                @input="handleUpdateAddressField('streetAddress', $event.target.value)"
              />
              <label class="bx--label" for="text-input-street-address">{{$t('address_street')}}
                *</label>
              <div class="bx--form-requirement" v-if="!$v.item[addressType].streetAddress.required">
                {{$t('address_street')}} {{$t('required')}}
              </div>
              <div class="bx--form-requirement" v-if="!$v.item[addressType].streetAddress.minLength">
                {{$t('address_street')}} {{$t('too_short')}}
              </div>
              <div class="bx--form-requirement" v-if="getViolation(addressType,'streetAddress')">
                {{ getViolation(addressType,'streetAddress') }}
              </div>
            </div>
          </div>
          <div class="bx--col-md-5">
            <div class="bx--form-item bx--text-input-wrapper">
              <input
                :value="item[addressType].houseNumber"
                :data-invalid="isInvalid(addressType, 'houseNumber')"
                :placeholder="$t('number')"
                class="bx--text-input"
                id="text-input-street-number"
                @input="handleUpdateAddressField('houseNumber', $event.target.value)"
              />
              <label class="bx--label" for="text-input-street-number">{{$t('number')}}
                *</label>
              <div class="bx--form-requirement" v-if="!$v.item[addressType].houseNumber.required">
                {{$t('number')}} {{$t('required')}}
              </div>
              <div class="bx--form-requirement" v-if="getViolation(addressType, 'houseNumber')">
                {{ getViolation(addressType,'houseNumber') }}
              </div>
            </div>
          </div>
          <div class="bx--col-md-7">
            <div class="bx--form-item bx--text-input-wrapper">
              <input
                :value="item[addressType].addressSuffix"
                :placeholder="$t('address_suffix')"
                id="text-input-address-suffix"
                class="bx--text-input"
                type="text"
                @input="handleUpdateAddressField('addressSuffix', $event.target.value)"
              />
              <label class="bx--label" for="text-input-address-suffix">{{$t('address_suffix')}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row">
      <div class="bx--offset-md-3 bx--col-md-4 bx--col-sm-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            :data-invalid="isInvalid(addressType, 'postalCode')"
            :value="item[addressType].postalCode"
            @input="handleUpdateAddressField('postalCode', $event.target.value)"
            class="bx--text-input"
            id="text-input-postal-code"
            :placeholder="$t('address_zip_code')"
            type="text"
          >
          <label class="bx--label" for="text-input-postal-code">
            {{$t('address_zip_code')}} *
          </label>
          <div class="bx--form-requirement" v-if="!$v.item[addressType].postalCode.required">
            {{$t('address_zip_code')}} {{$t('required')}}
          </div>
          <div class="bx--form-requirement" v-if="getViolation(addressType,'addressZipCode')">
            {{ getViolation(addressType,'addressZipCode') }}
          </div>
        </div>
      </div>
      <div class="bx--col-md-5 bx--col-sm-6">
        <div class="bx--form-item bx--text-input-wrapper">
          <input
            :data-invalid="isInvalid(addressType, 'addressLocality')"
            :value="item[addressType].addressLocality"
            @input="handleUpdateAddressField('addressLocality', $event.target.value)"
            class="bx--text-input"
            id="text-input-address-locality"
            :placeholder="$t('address_city')"
            type="text"/>
          <label class="bx--label" for="text-input-address-locality">
            {{$t('address_city')}} *
          </label>
          <div class="bx--form-requirement" v-if="!$v.item[addressType].addressLocality.required">
            {{$t('address_city')}} {{$t('required')}}
          </div>
          <div class="bx--form-requirement" v-if="!$v.item[addressType].addressLocality.minLength">
            {{$t('address_city')}} {{$t('too_short')}}
          </div>
          <div class="bx--form-requirement" v-if="getViolation(addressType,'addressLocality')">
            {{ getViolation(addressType,'addressLocality') }}
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row">
      <div class="bx--offset-md-3 bx--col-md-4">
        <div class="bx--form-item">
          <div class="bx--select" style="width: 100%">
            <label class="bx--label" for="multi-select-address-country">{{$t('address_country')}} *</label>
            <multiselect
              :class="[$v.item[addressType].addressCountry.$error ? 'is-invalid' : '']"
              :data-invalid="isInvalid(addressType, 'addressCountry')"
              :hideSelected="true"
              id="multi-select-address-country"
              :multiple="false"
              :options="countries"
              label="name"
              track-by="id"
              :required="true"
              :taggable="false"
              v-model="selectedCountry"
              :show-labels="false"
              :placeholder="$t('address_country')"
              :tag-placeholder="config.translations[language][formType].addressCountry"
            ></multiselect>
            <div class="bx--form-requirement"
                 v-if="!$v.item[addressType].addressCountry.isInvalid">
              {{$t('address_country')}} {{$t('required')}}
            </div>
            <div class="bx--form-requirement" v-if="getViolation(addressType,'addressCountry')">
              {{ getViolation(addressType,'addressCountry') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
    name: "CustomerAddressForm",
    components: {
      Multiselect
    },
    data() {
      return {
        addressCountry: null
      }
    },
    props: {
      config: null,
      language: null,
      formType: String,
      countries: null,
      item: null,
      getViolation: {
        type: Function,
        required: true
      },
      isInvalid: {
        type: Function,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      handleUpdateAddressField: {
        type: Function,
        required: true
      },
      handleUpdateAddressCountryField: {
        type: Function,
        required: true
      },
      addressType: {
        type: String,
        required: true
      }
    },
    computed: {
      selectedCountry: {
        get() {
          if(this.addressCountry){
            return this.addressCountry;
          }
          return this.countries.find(country => country.id === this.item[this.addressType].addressCountry);
        },
        set(country) {
          this.addressCountry = country;
          this.handleUpdateAddressCountryField(country.id);
        }
      }
    }
  }

</script>
