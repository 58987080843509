<template>
  <div class="lk-lsp-backend__accept-request">
    <h3 class="lk-lsp-backend__accept-headline" v-if="headerData.headline">{{ headerData.headline }}</h3>
    <p class="lk-lsp-backend__accept-name" v-if="headerData.subHeadline">
      <span>{{ headerData.subHeadline}}</span>
      <br v-if="showTranslationJobPolnumber" />
    </p>

    <ul v-if="downloads && downloads.length">
      <li v-for="(download, index) in downloads" :key="'lk-lsp-backend__download-item-'+index">
        <h6 v-if="download.downloadLabel">{{ $t(download.downloadLabel) }}</h6>
        <a class="lk-lsp-backend__file-link lk-detailview__file-link"
           rel="noopener" target="_blank"
           :href="download.downloadLink || download.link"
           @click="downloadItem(download, $event)"
           v-if="download.downloadLink || download.link">
          {{ download.linkText }}
          <span class="lk-carbon-icon lk-carbon-icon-download--glyph lk-button-icon--right" v-if="download.downloadLink"></span>
        </a>
      </li>
    </ul>

    <!-- Customer Approval / Job Request Approval Buttons START -->
    <JobDetailViewActionButtons
      :buttons="actionButtons"
      v-if="actionButtons">
    </JobDetailViewActionButtons>
    <!-- Customer Approval / Job Request Approval Buttons END -->
    <!-- Customer Approval Modals START -->
    <LKModal
      :modal-data="{
        rejectButtonLabel: this.cancelModalLabel,
        acceptButtonLabel: this.rejectTranslationLabel,
        headline: job.name
      }"
      :optional-label="$t('reject_translation')"
      :modal-id="actionButtons.rejectModalTarget"
      :close-button="true"
      :handle-accept-button-click="actionButtons.rejectMethod"
      v-if="job && actionButtons.rejectModalTarget">
      <template slot="content">
        <p>{{$t('reject_translation_desc')}}</p>
        <br />
        <form class="lk-form">
          <div class="bx--form-item bx--text-input-wrapper">
            <textarea
              rows="8"
              class="bx--text-area"
              id="reject-reason"
              name="job-reject-reason"
              :placeholder="$t('request')"
              @input="handleRejectReasonUpdate($event.target.value)"
            ></textarea>
            <label class="bx--label" for="reject-reason">{{$t('refusal_reason')}} *</label>
            <div class="bx--form-requirement">
              Message is required
            </div>
          </div>
          <file-upload
            :id="'file-upload-' + actionButtons.rejectModalTarget"
            accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
            button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
            invalid-class="bx--form-requirement"
            invalid-message="* At least one document is required."
            :button-label="$t('upload_correction_here')"
            :clearOnReselect="false"
            :max-file-size="25"
            :multiple="true"
            :removable="true"
            :same-file-name="false"
            :handle-change="handleRejectReasonCorrectionFileUpdate"
          />
        </form>
      </template>
    </LKModal>
    <LKModal
      v-if="job && actionButtons.clarifyModalTarget"
      :modal-data="{
        rejectButtonLabel: this.cancelModalLabel,
        acceptButtonLabel: this.rejectTranslationLabel,
        headline: job.name
      }"
      :optional-label="$t('clarify_with_customer')"
      :modal-id="actionButtons.clarifyModalTarget"
      :close-button="true"
      :handle-accept-button-click="actionButtons.clarifyMethod"
    >
      <template slot="content">
        <p>{{$t('reject_translation_desc')}}</p>
        <br />
        <form class="lk-form">
          <div class="bx--form-item bx--text-input-wrapper">
            <textarea
              rows="8"
              class="bx--text-area"
              id="clarify-reason"
              name="job-clarify-reason"
              :placeholder="$t('request')"
              @input="handleClarifyReasonChange($event.target.value)"
            ></textarea>
            <label class="bx--label" for="clarify-reason">{{$t('clarification')}} *</label>
            <div class="bx--form-requirement">
              Message is required
            </div>
          </div>
          <file-upload
            :id="'file-upload-' + actionButtons.clarifyModalTarget"
            accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
            button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
            invalid-class="bx--form-requirement"
            invalid-message="* At least one document is required."
            :button-label="$t('upload_correction_here')"
            :clearOnReselect="false"
            :max-file-size="25"
            :multiple="false"
            :removable="true"
            :same-file-name="false"
            :handle-change="handleClarifyReasonCorrectionFileUpdate"
          />
        </form>
      </template>
    </LKModal>
    <LKModal
      :modal-data="{
        rejectButtonLabel: $t('cancel'),
        acceptButtonLabel: $t('confirm'),
        headline: job.name
      }"
      :optional-label="$t('accept_translation')"
      :modal-id="actionButtons.acceptModalTarget"
      :close-button="true"
      :handle-accept-button-click="actionButtons.acceptMethod"
      v-if="actionButtons.acceptModalTarget">
      <template slot="content" v-if="job.order.physicalDelivery">
        <p>{{ $t('accept_translation_desc_physical') }}</p>
      </template>
      <template slot="content" v-else>
        <p>{{ $t('accept_translation_desc_mail') }}</p>
      </template>
    </LKModal>
    <LKModal
      v-if="actionButtons.acceptWithTranslationModalTarget"
      :modal-data="{
        rejectButtonLabel: $t('cancel'),
        acceptButtonLabel: $t('confirm'),
        headline: job.name
      }"
      :optional-label="$t('accept_translation')"
      :modal-id="actionButtons.acceptWithTranslationModalTarget"
      :close-button="true"
      :handle-accept-button-click="actionButtons.acceptMethod"
    >
      <template slot="content" >
        <p v-if="job.order.physicalDelivery">{{ $t('accept_translation_desc_physical') }}</p>
        <p v-else>{{ $t('accept_translation_desc_mail') }}</p>
        <br />
        <h6>* Optionally you can add a new translation</h6>
        <form class="lk-form">
          <file-upload
            :id="'file-upload-' + actionButtons.clarifyModalTarget"
            accepted=".jpg,.png,.pdf,.idml,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.odt,.txt"
            button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
            invalid-class="bx--form-requirement"
            invalid-message="* At least one document is required."
            :button-label="$t('upload_translation_here')"
            :clearOnReselect="false"
            :max-file-size="25"
            :multiple="false"
            :removable="true"
            :same-file-name="false"
            :handle-change="handleAcceptTranslationFile"
          />
        </form>
      </template>
    </LKModal>

    <JobDetailViewHeaderStatus :job="job" />
    <JobDetailStatus :job="job" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import FileUpload from '../../../components/fileupload/FileUpload.vue';
import LKModal from '../../../components/modal/Modal.vue'
import InlineNotification from "../../notification/InlineNotification.vue";
import JobDetailViewActionButtons from "./JobDetailViewActionButtons.vue";
import JobDetailViewHeaderStatus from "./JobDetailViewHeaderStatus.vue";
import JobStatus from "../../../enums/JobStatus"
import JobTypes from "../../../enums/JobType";
import JobDetailStatus from "../JobDetailStatus.vue";
import { getOrderStatusLabel } from "../../../enums/OrderStatus";
import { ENTRYPOINT } from "../../../apps/config/entrypoint"

import  '../../../components/form/form.scss'

export default {
  name: 'JobDetailViewHeaderActions',
  components: {
    FileUpload,
    JobDetailViewActionButtons,
    JobDetailViewHeaderStatus,
    JobDetailStatus,
    InlineNotification,
    LKModal
  },
  props: {
    actionButtons: {
      type: Object
    },
    job: {
      type: Object
    },
    rejectReason: {
      type: String
    },
    downloadAction: {
      type: Function
    },
    showTranslationJobPolnumber: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      apiUrl: ENTRYPOINT,
      customerApprovalModals: false,
      rejection: false,
      jobTypes: JobTypes,
      dhlPrefix: 'https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=',
      dhlShipmentUrl: ''
    }
  },

  computed: {
    ...mapGetters({
      intercomSettings: 'intercom/allSettings',
    }),

    polNumberFromTranslationJob() {
      if (this.job && this.job.order && this.job.order.jobs) {
        let translationJob = this.job.order.jobs.find(job => {
          return job instanceof Object && job.jobDefinition.type === this.jobTypes.JOB_TYPE_TRANSLATOR
        });
        return translationJob && this.job.id != translationJob.id ? translationJob.purchaseOrderNumber : null;
      }
      return null;
    },

    headerData() {
      if (this.job) {
        return {
          headline: this.job.order.product.name,
          subHeadline: (this.job.jobDefinition.recruitable) ? this.job.purchaseOrderNumber : this.job.order.partOfInvoice.confirmationNumber
        }
      }

      return {}
    },
    downloads () {
      //:headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      if(this.job && this.job.user) {
        if(this.job.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR && this.job.status !== JobStatus.JOB_STATUS_SHIPMENT && this.job.status !== JobStatus.FINISHED) {

          let translationSourceFiles = []
          if(this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length) { // if there are more files
            let self = this
            this.job.order.translationSourceFiles.forEach(function (translationSourceFile, i) {

              let downloadFile = {
                downloadLink: self.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
                linkText: translationSourceFile.name,
                downloadAction: true,
                resourceId: translationSourceFile['@id'],
                type: 'source-file',
                name: translationSourceFile.name,
                created_at: translationSourceFile.createdAt,
                scheduledDeletionDate: translationSourceFile.scheduledDeletionDate,
                status: translationSourceFile.status,
              }

              if(!i) {
                downloadFile['downloadLabel'] = 'documents_to_translate'
              }

              translationSourceFiles.push(downloadFile)
            })
          }
          else { // only one file
            translationSourceFiles = [{
              downloadLabel: 'document_to_translate',
              downloadLink: this.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
              linkText: this.job.order.translationSourceFile.name,
              downloadAction: true,
              resourceId: this.job.order.translationSourceFile['@id'],
              type: 'source-file',
              name: this.job.order.translationSourceFile.name,
              created_at: this.job.order.translationSourceFile.createdAt,
              scheduledDeletionDate: this.job.order.translationSourceFile.scheduledDeletionDate,
              status: this.job.order.translationSourceFile.status,
            }]
          }

          return translationSourceFiles
        }
        else if (this.job.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR && this.job.status !== JobStatus.FINISHED) {
          return this.getTranslationTargetFileDownloads('download_translation_to_be_corrected')
        }
        else if(this.job.status === JobStatus.REALIZATION && this.job.jobDefinition.type === JobTypes.JOB_TYPE_CUSTOMER_APPROVAL) {
          this.customerApprovalModals = true
          return this.getTranslationTargetFileDownloads('download_translation')
        }
        else if(this.job.status === JobStatus.REALIZATION && this.job.jobDefinition.type === JobTypes.JOB_TYPE_QM) {
          return this.getTranslationTargetFileDownloads('download_translation')
        }
      }
      return []
    },

    rejectTranslationLabel () {
      return this.rejection ? this.$t('confirm') : null
    },

    cancelModalLabel () {
      return this.rejection ? this.$t('cancel') : null
    },

    orderStatus() {
      let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de'
      return this.job ? getOrderStatusLabel(this.job.order.status, locale) : ''
    }
  },

  methods: {
    ...mapActions({
      registerPolNumber: 'intercom/registerPolNumber',
      registerJobName: 'intercom/registerJobName',
      registerProductName: 'intercom/registerProductName',
      registerJobFee: 'intercom/registerJobFee',
    }),
    handleRejectReasonUpdate (value) {
      if(value) {
        this.rejection = true
        if(this.actionButtons.handleRejectReasonChange) {
          this.actionButtons.handleRejectReasonChange(value)
        }
      }
      else {
        this.rejection = false
      }
    },

    handleRejectReasonCorrectionFileUpdate(_, files) {
      const file = files && files.length > 0 ? files[0].file : null
      if (this.actionButtons.handleRejectReasonCorrectionFileUpdate) {
        this.actionButtons.handleRejectReasonCorrectionFileUpdate(file)
      }
    },

    handleClarifyReasonChange (value) {
      if(value) {
        this.rejection = true
        if(this.actionButtons.handleClarifyReasonChange) {
          this.actionButtons.handleClarifyReasonChange(value)
        }
      }
      else {
        this.rejection = false
      }
    },

    handleClarifyReasonCorrectionFileUpdate(_, files) {
      const file = files && files.length > 0 ? files[0].file : null
      if (this.actionButtons.handleClarifyReasonCorrectionFileUpdate) {
        this.actionButtons.handleClarifyReasonCorrectionFileUpdate(file)
      }
    },

    handleAcceptTranslationFile(_, files) {
      const file = files && files.length > 0 ? files[0].file : null
      if (file && this.actionButtons.handleAcceptTranslationFile) {
        this.actionButtons.handleAcceptTranslationFile(file)
      }
    },

    getTranslationTargetFileDownloads (downloadLabel) {
      let self = this
      let targetFiles = this.job.order.translationTargetFiles

      let downloadItems = []
      const version = Math.max(...targetFiles.map(o => o.version), 0);

      targetFiles.forEach(function (targetFile, index) {
        if (targetFile.version === version){
          let downloadItem = {
            downloadLink: self.$getDownloadURL(targetFile['@id'], targetFile.name),
            linkText: targetFile.name,
            downloadAction: true,
            resourceId: targetFile['@id'],
            type: 'target-file',
            name: targetFile['name'],
            created_at: targetFile.createdAt,
            scheduledDeletionDate: targetFile.scheduledDeletionDate,
            status: targetFile.status,
          }

          if(!index && downloadLabel) {
            downloadItem.downloadLabel = downloadLabel
          }
          downloadItems.push(downloadItem)
        }

      })

      return downloadItems
    },

    downloadItem (downloadItem, e) {
      if(downloadItem.downloadAction) {
        e.preventDefault()
        e.stopPropagation()

        this.downloadAction(downloadItem)

        return false
      }

      return true
    }
  },
  mounted() {
    if (this.job) {
      if (this.job.order.trackingCode) {
        this.dhlShipmentUrl = this.dhlPrefix + this.job.order.trackingCode.replace(/\s/g, '');
      }
      if (typeof window.Intercom !== 'undefined') {
        this.registerPolNumber(this.job.purchaseOrderNumber);
        this.registerJobName(this.job.name);
        this.registerJobFee(this.job.fee);
        this.registerProductName(this.job.order.product.name);
        window.Intercom('update', this.intercomSettings);
      }
    }
  }
}
</script>
