import { helpers } from 'vuelidate/lib/validators'

const inArray = (param) =>
  helpers.withParams(
    { type: 'inArray', value: param },
    (value) => !helpers.req(value) || param.includes(value)
  )


export default inArray

