import * as types from './mutation_types'

export default {

  [types.SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.SET_ITEMS] (state, items) {
    Object.assign(state, { items: items })
  },

  [types.TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },
  [types.UPDATE_RETRIEVED] (state, updated) {
    Object.assign(state.retrieved, updated)
  },
  [types.SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },
  [types.SET_RETRIEVED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },
}
