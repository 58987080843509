import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const COOKIE_NAME = 'lkToken'
const saveCookie = (data) => Cookies.set(COOKIE_NAME, data)
const removeCookie = () => Cookies.remove(COOKIE_NAME)
const getCookie = () => Cookies.get(COOKIE_NAME)

export const jwtService = {
  getAccessToken,
  getRefreshToken,
  saveToken,
  getToken,
  getTokenData,
  clearToken
}

function getAccessToken() {
  return getCookie() ? JSON.parse(atob(getCookie())).token : null
}

function getRefreshToken() {
  return getCookie() ? JSON.parse(atob(getCookie())).refresh_token : null
}

function saveToken(data) {
  saveCookie(btoa(JSON.stringify(data)))
}

function getTokenData() {
  return getCookie() ? jwt_decode(JSON.parse(atob(getCookie())).token) : null
}

function getToken() {
  return getCookie() ? getCookie() : null
}

function clearToken() {
  Cookies.remove('lkToken')
  Cookies.remove('uid')
  removeCookie()
}

