export const error = state => state.error
export const isLoading = state => state.isLoading
export const retrieved = state => state.retrieved
export const fileUploadIsLoading = state => state.fileUploadIsLoading
export const uploadError = state => state.uploadError
export const uploadViolations = state => state.uploadViolations
export const uploadUpdated = state => state.uploadUpdated
export const acceptedOrRejected = state => state.acceptedOrRejected
export const trackingCode = state => state.trackingCode
export const shipmentCarrier = state => state.shipmentCarrier
export const projectManager = state => state.projectManager
