<template>
  <div class="lk-list-view" data-table-v2>
    <div class="lk-data-table-container">
      <table class="lk-data-table" v-if="!noTable">
        <thead v-if="dataTableHeadItems && dataTableHeadItems.length">
        <tr>
          <th class="lk-data-table__col" v-for="dataTableHeadItem in dataTableHeadItems">
            <button class="bx--table-sort-v2" data-event="sort" v-if="dataTableHeadItem.sortable" :data-sort-by-index="dataTableHeadItem.index">
              <span class="bx--table-header-label">{{ $t(dataTableHeadItem.label) }}</span>
              <span class="bx--table-sort-v2__icon lk-table-sort__icon lk-carbon-icon lk-carbon-icon-caret--down" aria-label="Sort rows by this header in descending order"></span>
            </button>
            <span class="bx--table-header-label" v-else>{{ $t(dataTableHeadItem.label) }}</span>
          </th>
        </tr>
        </thead>
        <tbody v-if="dataTableItems && dataTableItems.length">
        <template v-for="(row,rowInd) in sortedTableItems">
          <tr :class="['lk-data-table__row bx--parent-row-v2 lk-container--layer-raised', row.link && !row.expandable ? 'lk-data-table__row--link' : '']"
              :key="'lk-data-table__row-' + row.id" @click.prevent="openLink(row.link, '_self')">
            <th class="lk-data-table__col">
              <span>{{ row.name }}</span>
              <span v-if="row['productName']" style="color: darkgray; font-size: 13px">({{ row['productName']}})</span>
            </th>
            <td class="lk-data-table__col" :class="Array.isArray(row[dataTableHeadItem.index]) ? 'lk-no-padding--top-bottom' : ''" v-for="dataTableHeadItem in dataTableHeadItems" v-if="dataTableHeadItem.index !== 'name'">
              <ul class="lk-data-table__col-list" v-if="Array.isArray(row[dataTableHeadItem.index])">
                <li class="lk-data-table__col-list__item" :class="'lk-data-table__col-list__item--' + rowItem.type" v-for="(rowItem, index) in row[dataTableHeadItem.index]"
                    @click.prevent="openLink(rowItem.link, '_self')">
                  <a class="lk-data-table__col-list__link" :href="rowItem.link"  v-if="rowItem.link">
                    <span :class="rowItem.icon" v-if="rowItem.icon"></span>
                    <span v-if="rowItem.text">{{ rowItem.text }}</span>
                  </a>
                  <span :class="rowItem.icon" v-else-if="rowItem.icon"></span>
                  <span class="lk-data-table__col-list__badge" :class="rowItem.badge" v-if="rowItem.badge">
                      {{ rowItem.badgeText ? rowItem.badgeText : '' }}
                    </span>
                </li>
              </ul>
              <span v-else style="display: flex; align-items: center; column-gap: 0.5rem;">
                <span v-if="dataTableHeadItem.index === 'state' && row['pendingAction']"
                      class="lk-carbon-icon lk-carbon-icon-warning--glyph"
                      style="color: #bd1427; font-size: large;">
                </span>
                {{ row[dataTableHeadItem.index] }}
              </span>
            </td>
            <td class="lk-data-table__col" v-if="row.messageNotification">
              <ul class="lk-data-table__col-list" v-if="row.messageNotification.badgeText !== 0">
                <li class="lk-data-table__col-list__item"
                    :class="'lk-data-table__col-list__item--' + row.messageNotification.type">
                  <a class="lk-data-table__col-list__link" :href="row.messageNotification.link" v-if="row.messageNotification.link">
                    <span :class="row.messageNotification.icon" v-if="row.messageNotification.icon"></span>
                    <span v-if="row.messageNotification.text">{{ row.messageNotification.text }}</span>
                  </a>
                  <span :class="row.messageNotification.icon" v-else-if="row.messageNotification.icon"></span>
                  <span class="lk-data-table__col-list__badge" :class="row.messageNotification.badge" v-if="row.messageNotification.badge">
                      {{ row.messageNotification.badgeText ? row.messageNotification.badgeText : '' }}
                    </span>
                </li>
              </ul>
            </td>
            <td class="lk-data-table__col bx--table-expand-v2" v-if="row.expandable">
              <button class="bx--table-expand-v2__button lk-data-table-expand__button" @click.prevent="toggleExpanded(rowInd)">
                <span :class="['lk-carbon-icon', rowIsExpanded(rowInd) ? 'lk-carbon-icon-chevron--up' : 'lk-carbon-icon-chevron--down']"></span>
              </button>
            </td>
            <td class="lk-data-table__col bx--table-expand-v2" v-if="!row.expandable">
              <a :href="row.link" class="lk-data-table-expand__button" v-if="row.link">
                <span class="lk-carbon-icon lk-carbon-icon-chevron--right"></span>
              </a>
            </td>
          </tr>
          <tr :class="['lk-data-table__row lk--expandable-row-v2 lk-container--layer-raised', rowIsExpanded(rowInd) ? '' : 'lk--expandable-row--hidden-v2']" v-if="row.items && row.items.length && rowIsExpanded(rowInd)">
            <td class="lk-data-table__col lk-no-padding--left-right" :colspan="tableSize + 1">
              <slot
                name="explandable-content"
                v-bind:order="row"
                v-bind:product-options="getGroupedProductOptionList(row.items)">
              </slot>
              <slot
                name="explandable-content-default"
                v-bind:order="row">
              </slot>
            </td>
          </tr>
        </template>
        </tbody>
        <tbody v-else>
          <tr class="lk-data-table__row bx--parent-row-v2 lk-container--layer-raised">
            <td colspan="7" class="lk-data-table__col">
              <p style="text-align: center">{{$t('no_orders')}}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <ListViewMobile
        :data-table-head-items="dataTableHeadItems"
        :data-table-items="dataTableItems"
        :row-is-expanded="rowIsExpanded"
        :toggle-expanded="toggleExpanded"
        v-else-if="noTable && dataTableItems && dataTableItems.length">
      </ListViewMobile>
    </div>
  </div>
</template>

<script>
  import { DataTableV2 } from 'carbon-components'
  import './listview.scss'
  import ListViewMobile from "./ListViewMobile.vue"
  import ProductOptionList from './ProductOptionList.vue'
  import {ProductOptionCategoryType} from "../../enums";

  export default {
    name: 'ListView',
    components: {
      ListViewMobile,
      ProductOptionList,
    },
    props: {
      dataTableHeadItems: {
        type: Array,
        required: true
      },
      dataTableItems: {
        type: Array,
        required: true
      },
      colSize: {
        type: Number,
      },
      expanded: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      toggleExpanded (key) {
        // we have to do this manually, because carbon's expanding does not work correctly with sorting...
        if(this.sortedItems[key]) {
          this.sortedItems[key]['expanded'] = !this.sortedItems[key]['expanded']
        }
      },

      rowIsExpanded (key) {
        if(this.noTable) {
          return this.dataTableItems[key]['expanded']
        }

        return this.sortedItems[key]['expanded']
      },

      openLink (link, target) {
        window.open(link, target)
      },

      eventAfterSort (evt) {
        if(evt.detail.element && evt.detail.element.dataset) {
          if(evt.detail.element.dataset.previousValue) {
            this.sorted = evt.detail.element.dataset.previousValue === 'ascending' ? 'descending' : 'ascending'
          }
          if(evt.detail.element.dataset.sortByIndex) {
            let sortByIndex = evt.detail.element.dataset.sortByIndex
            this.sortedBy = sortByIndex
            if(sortByIndex === 'name' || sortByIndex === 'status') {
              self.sortType = 'string'
            }
          }
        }
      },

      // group the items based on category type!
      getGroupedProductOptionList (items) {
        let grouped = {}  //this.groupBy(items, 'productOptionCategory')
        let sorting = Object.values(ProductOptionCategoryType)
        sorting.forEach(function(key) {
          if(key !== ProductOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_OTHER) {
            let found = items.filter(function(item) {
              return item['productOptionCategory'] === key
            })

            if(found && found.length) {
              grouped[key] = found
            }
          }
        })
        return grouped
      },

      groupBy(array, key){
        const result = {}
        array.forEach(item => {
          if (!result[item[key]]){
            result[item[key]] = []
          }
          result[item[key]].push(item)
        })
        return result
      }
    },
    mounted () {
      this.windowWidth = window.innerWidth

      window.addEventListener('resizeend', () => {
        this.windowWidth = window.innerWidth
      })

      if(this.expanded) {
        this.dataTableItems.forEach((value, index) => {
          this.dataTableItems[index]['expanded'] = true
        } )
      }

      this.dataTableV2Instance = DataTableV2.create(this.$el)
      this.$el.addEventListener('data-table-v2-aftertogglesort', this.eventAfterSort)
    },
    computed: {
      tableSize () {
        return this.dataTableHeadItems.length + 1
      },
      sortedTableItems () {
        if(this.sortedBy) {
          let self = this
          this.sortedItems = this.dataTableItems.sort((a, b) => {
            let aCompareValue = a[self.sortedBy]
            let bCompareValue = b[self.sortedBy]

            if(self.sortType === 'string') {
              aCompareValue = aCompareValue && aCompareValue.toLowerCase()
              bCompareValue = bCompareValue && bCompareValue.toLowerCase()
            }

            if(self.sorted === 'descending') {
              if(aCompareValue < bCompareValue) { return -1 }
              if(aCompareValue > bCompareValue) { return 1 }
              return 0
            }
            else {
              if(aCompareValue < bCompareValue) { return 1 }
              if(aCompareValue > bCompareValue) { return -1 }
              return 0
            }
          })
        }
        else {
          this.sortedItems = this.dataTableItems
        }
        return this.sortedItems
      }

    },

    watch: {
      windowWidth(newWidth, oldWidth) {
        if(oldWidth !== newWidth) {
          this.noTable = newWidth < this.noTableMaxWidth
        }
      },
    },

    data () {
      return {
        productOptionCategoryType: ProductOptionCategoryType,
        sortedBy: 'date',
        sorted: 'ascending',
        sortType: 'string',
        dataTableV2Instance: null,
        sortedItems: null,
        noTableMaxWidth: 992,
        windowWidth: 0, // for checking if accordion or not
        noTable: false, // for mobile we have an accordion instead of tabs
      }
    }
  }
</script>

<style scoped>

  div.bx--select-input__wrapper > select.bx--select-input {
    padding-left: 0;
    margin-right: 70px;
  }

  label.bx--label {
    margin-bottom: 0;
  }

  .lk-carbon-icon-email {
    font-size: 1.2rem;
  }

</style>
