const ProductOptionType = {
  PRODUCT_OPTION_TYPE_TRANSLATION: 'translation',
  PRODUCT_OPTION_TYPE_VOICE: 'voice',
  PRODUCT_OPTION_TYPE_HYBRID: 'interpretation',
  PRODUCT_OPTION_TYPE_PROOFREADING: 'proofreading',
  PRODUCT_OPTION_TYPE_DELIVERY: 'delivery',
  PRODUCT_OPTION_TYPE_QM: 'qm',
  PRODUCT_OPTION_TYPE_TMS: 'tms',
  PRODUCT_OPTION_TYPE_PHYSICAL_DELIVERY: 'physical-delivery',
  //PRODUCT_OPTION_TYPE_ADDITIONAL_COPY: 'additional_copy',
  //PRODUCT_OPTION_TYPE_OTHER: 'other',
  //PRODUCT_OPTION_TYPE_NATIVE_SPEAKER: 'native_speaker'
};

export default ProductOptionType
