<template>
  <div>

    <!-- UserType -->
    <fieldset>
      <legend> {{config.translations[language].status}}</legend>
      <div class="bx--row">
        <div class="lk-form-desc  bx--col-md-3">
          {{$t('customer_type')}}
        </div>
        <div class="bx--form-item bx--col-md-9">
          <CustomerTypeForm
            :$v="$v"
            :validation-key="validationKey"
            :get-violation="getViolation"
            :handle-update-field="handleUpdateField"
            :handle-user-types-field="handleUserTypesField"
            :is-invalid="isInvalid"
            :item="item"/>
        </div>
      </div>
    </fieldset>

    <!-- Title, FirstName, LastName -->
    <fieldset>
      <legend>{{$t('customer_salutation')}}</legend>
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          <!-- {{$t('customer_salutation_desc')}} -->
        </div>
        <div class="bx--form-item bx--col-md-9">
          <div class="bx--radio-button-group">
            <input
              :checked="item.userTitle === userTitles.TITLE_MRS"
              :data-invalid="isInvalid('userTitle')"
              :value="userTitles['TITLE_MRS']"
              @change="handleUpdateField('userTitle', $event.target.value)"
              class="bx--radio-button"
              id="radio-button-title-mrs"
              name="userTitle"
              tabindex="0"
              type="radio"
            >
            <label class="bx--radio-button__label" for="radio-button-title-mrs">
              <span class="bx--radio-button__appearance"></span>
              {{$t('customer_salutation_mrs')}}
            </label>
            <input
              :checked="item.userTitle === userTitles.TITLE_MR"
              :data-invalid="isInvalid('userTitle')"
              :value="userTitles['TITLE_MR']"
              @change="handleUpdateField('userTitle', $event.target.value)"
              class="bx--radio-button"
              id="radio-button-title-mr"
              name="userTitle"
              tabindex="0"
              type="radio"
            >
            <label class="bx--radio-button__label" for="radio-button-title-mr">
              <span class="bx--radio-button__appearance"></span>
              {{$t('customer_salutation_mr')}}
            </label>
            <div class="bx--form-requirement" v-if="!$v[validationKey].userTitle.required">
              {{$t('customer_salutation')}} {{$t('required')}}
            </div>
            <div class="bx--form-requirement" v-if="getViolation('userTitle')">
              {{ getViolation('userTitle') }}
            </div>
          </div>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--offset-md-3 bx--col-md-9">

          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('firstName')"
              :value="item.firstName"
              @input="handleUpdateField('firstName', $event.target.value)"
              class="bx--text-input"
              id="text-input-firstname"
              name="name"
              :placeholder="$t('first_name')"
              type="text"
            >
            <label class="bx--label" for="text-input-firstname">{{$t('first_name')}}
              *</label>
            <div class="bx--form-requirement" v-if="!$v[validationKey].firstName.required">
              {{$t('first_name_required')}}
            </div>
            <div class="bx--form-requirement" v-if="!$v[validationKey].firstName.minLength">
              {{$t('first_name_too_short')}}
            </div>
            <div class="bx--form-requirement" v-if="getViolation('firstName')">
              {{ getViolation('firstName') }}
            </div>
          </div>

          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('lastName')"
              :value="item.lastName"
              @input="handleUpdateField('lastName', $event.target.value)"
              class="bx--text-input"
              id="text-input-lastname"
              name="lastName"
              :placeholder="$t('last_name')"
              type="text"
            >
            <label class="bx--label" for="text-input-lastname">{{$t('last_name')}}
              *</label>
            <div class="bx--form-requirement" v-if="!$v[validationKey].lastName.minLength">
              {{$t('last_name_too_short')}}
            </div>
            <div class="bx--form-requirement" v-if="!$v[validationKey].lastName.required">
              {{ $t('last_name_required') }}
            </div>
            <div class="bx--form-requirement" v-if="getViolation('lastName')">
              {{ getViolation('lastName') }}
            </div>
          </div>

        </div>
      </div>
    </fieldset>

    <!-- Phone    -->
    <fieldset v-if="config.showPhoneNumber">
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          {{ $t('phone_description') }}
        </div>
        <div class="bx--col-md-9">
          <div class="bx--form-item bx--text-input-wrapper">

            <input :data-invalid="isInvalid('phone')"
                   :placeholder="$t('phone_description')"
                   :value="item.telephone"
                   @input="handleUpdateField('telephone', $event.target.value)"
                   class="bx--text-input"
                   id="text-input-mobile"
                   name="phone"
                   type="text"/>

            <label class="bx--label" for="text-input-phone">
              {{ $t('phone') }}
            </label>
            <div class="bx--form-requirement" v-if="getViolation('phone')">
              {{ getViolation('phone') }}
            </div>
          </div>
          <div class="bx--form-item bx--text-input-wrapper">
            <input :data-invalid="isInvalid('mobilePhone')"
                   :placeholder="$t('mobile_phone_description')"
                   :value="item.mobilePhone"
                   @input="handleUpdateField('mobilePhone', $event.target.value)"
                   class="bx--text-input"
                   id="text-input-phone"
                   name="mobile"
                   type="text"/>
            <label class="bx--label" for="text-input-phone">
              {{ $t('mobile_phone') }}
            </label>
            <div class="bx--form-requirement" v-if="getViolation('phone')">
              {{ getViolation('phone') }}
            </div>
          </div>
          <div class="bx--form-item bx--text-input-wrapper">

            <input :data-invalid="isInvalid('faxNumber')"
                   :placeholder="$t('fax_number_description')"
                   :value="item.faxNumber"
                   @input="handleUpdateField('faxNumber', $event.target.value)"
                   class="bx--text-input"
                   id="text-input-faxNumber"
                   name="faxNumber"
                   type="text"/>

            <label class="bx--label" for="text-input-phone">
              {{ $t('fax_number') }}
            </label>
            <div class="bx--form-requirement" v-if="getViolation('faxNumber')">
              {{ getViolation('faxNumber') }}
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Email -->
    <fieldset>
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          {{ $t('your_email') }}
        </div>
        <div class="bx--col-md-9">
          <div class="bx--form-item bx--text-input-wrapper">

            <input v-if="!config.disableEmail"
                   :data-invalid="isInvalid('email')"
                   :value="item.email"
                   @input="handleUpdateField('email', $event.target.value)"
                   class="bx--text-input"
                   id="text-input-email"
                   name="email"
                   :placeholder="config.translations[language].email"
                   type="text"/>

            <input v-else
                   :value="item.email"
                   class="bx--text-input"
                   disabled
                   id="text-input-email"
                   name="email"
                   :placeholder="$t('your_email')"
                   type="text"/>

            <label class="bx--label" for="text-input-email">
              {{$t('your_email')}} *
            </label>
            <div class="bx--form-requirement" v-if="!$v[validationKey].email.required">
              {{$t('email_required')}}
            </div>
            <div class="bx--form-requirement" v-if="getViolation('email')">
              {{ getViolation('email') }}
            </div>
          </div>
        </div>
      </div>
      <!-- Confirm Email -->
      <div class="bx--row" v-if="config.confirmEmail">
        <div class="bx--offset-md-3 bx--col-md-9">
          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="isInvalid('emailConfirm')"
              :value="item.emailConfirm"
              @input="handleUpdateField('emailConfirm', $event.target.value)"
              class="bx--text-input"
              id="text-input-email-confirm"
              name="emailConfirm"
              :placeholder="$t('repeat_email')"
              type="text"/>
            <label class="bx--label" for="text-input-email">{{$t('repeat_email')}} *</label>
            <div class="bx--form-requirement" v-if="!$v[validationKey].emailConfirm.sameAsEmail">
              {{$t('repeat_email_required')}}
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Timezone -->
    <fieldset v-if="config.showTimezone">
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          <Tooltip
            uid="lk-how-to-tip-timezone"
            direction="right"
            :hover="true"
            :visible="false">
            <template slot="label">{{$t('your_timezone')}}</template>
            <template slot="trigger">
              <span
                class="lk-button-icon lk-button-icon--left lk-how-to-tip__icon lk-carbon-icon lk-carbon-icon-info--glyph"></span>
            </template>
            <template slot="content">
              <p>{{config.translations[language].timezoneTooltip}}</p>
            </template>
          </Tooltip>
        </div>
        <div class="bx--col-md-9">
          <div class="bx--form-item bx--select">
            <multiselect
              :data-invalid="isInvalid('timezone')"
              :class="[$v[validationKey].timezone.isInvalid ? 'is-invalid' : '']"
              :hideSelected="true"
              id="multi-select-timezone"
              :multiple="false"
              :options="timezones"
              :required="false"
              :taggable="false"
              @input="updateTimezoneField"
              :value="selectedTimezone"
              label="timezone"
              :show-labels="false"
              :placeholder="$t('timezone')"
              :tag-placeholder="$t('timezone')"
            ></multiselect>
            <label class="bx--label" for="multi-select-timezone">{{$t('timezone')}} *</label>
            <div class="bx--form-requirement"
                 v-if="!$v[validationKey].timezone.isInvalid">
              {{$t('timezone')}} {{$t('required')}}
            </div>
            <div class="bx--form-requirement" v-if="getViolation('timezone')">
              {{ getViolation('timezone') }}
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <!-- Status -->
    <fieldset v-if="config.showStatus">
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          Status
        </div>
        <div class="bx--col-md-2">
          <input
            id="checkbox-enabled"
            :data-invalid="isInvalid('enabled')"
            name="enabled"
            type="checkbox"
            class="bx--checkbox"
            :checked="item.enabled"
            :value="item.enabled"
            @change="handleUpdateField('enabled', !item.enabled)"/>
          <label for="checkbox-enabled" class="bx--checkbox-label">enabled</label>
        </div>
        <div class="bx--col-md-2">
          <input
            id="checkbox-confirmed"
            :data-invalid="isInvalid('confirmed')"
            name="confirmed"
            type="checkbox"
            class="bx--checkbox"
            :checked="item.confirmed"
            :value="item.confirmed"
            @change="handleUpdateField('confirmed', !item.confirmed)"/>
          <label for="checkbox-confirmed" class="bx--checkbox-label">confirmed</label>
        </div>
        <div class="bx--col-md-2">
          <input
            id="checkbox-active"
            :data-invalid="isInvalid('active')"
            name="confirmed"
            type="checkbox"
            class="bx--checkbox"
            :checked="item.active"
            :value="item.active"
            @change="handleUpdateField('active', !item.active)"/>
          <label for="checkbox-active" class="bx--checkbox-label">active</label>
        </div>
        <div class="bx--col-md-2">
          <input
            :checked="item.guest"
            :data-invalid="isInvalid('guest')"
            :value="item.guest"
            @change="handleUpdateField('guest', !item.guest)"
            class="bx--checkbox"
            id="checkbox-guest"
            name="guest"
            type="checkbox"/>
          <label class="bx--checkbox-label" for="checkbox-guest">guest</label>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="config.showProjectManager">
      <ProjectManagerList
        :config="config"
        :language="language"
        :item="item"
        :no-project-manager="noProjectManager"
        :selected-project-manager="selectedProjectManager"
        :update-field="updateProjectManagerField"
        v-on:toggle-no-pm="toggleCheckNoSpecificPM">
      </ProjectManagerList>
    </fieldset>

    <!-- Favorite LSP -->
    <fieldset v-if="config.showFavoriteLSP">
      <legend>{{ $t('favorites') }}</legend>
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          {{ $t('favorites_desc') }}
        </div>
        <div class="bx--col-md-9">
          <div class="form-group bx--form-item bx--select-wrapper bx--select" v-if="linguists">
            <multiselect
              :hideSelected="true"
              :multiple="true"
              :options="linguists"
              :taggable="false"
              id="favorite_linguists"
              label="lastName"
              :custom-label="customLabel"
              :placeholder="$t('favorites_placeholder')"
              required="true"
              :tag-placeholder="$t('favorites_placeholder')"
              track-by="id"
              v-model="itemFavoriteLinguists"
              @search-change="debounceGetCustomers"></multiselect>
            <label
              class="form-control-label bx--label"
              for="favorite_linguists">{{ $t('favorite_linguists')}}</label>
          </div>
          <div class="lk-form-desc">{{ $t('favorites_desc2') }}</div>
        </div>
      </div>

      <div class="bx--row" v-if="config.showLinguistGroups">
        <div class="lk-form-desc bx--col-md-3">
          Gruppe von Linguisten von diesem Kunden.
        </div>

        <div class="bx--col-md-4">
          <div class="form-group bx--form-item bx--select-wrapper bx--select">
            <multiselect
              id="linguist_groups"
              placeholder="Type to find a group by name"
              track-by="@id"
              label="name"
              v-model="selectedGroups"
              :options="groupOptions"
              :multiple="true"
              :searchable="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :hideSelected="false"
              :show-no-results="true"
              :loading="isLoading"
              :options-limit="300"
              @search-change="debouncedGetGroups">
              <template slot="selection" slot-scope="{ values }">
                <span class="multiselect__single" v-if="values.length">{{ values.length }} group(s) selected</span>
              </template>
              <span slot="noResult">No linguist groups found.</span>
            </multiselect>
            <label
              class="form-control-label bx--label"
              for="linguist_groups">LSP Gruppen</label>
            <div class="invalid-feedback" v-if="isInvalid('linguistGroups')">
              {{ violations.linguistGroups }}
            </div>
          </div>
        </div>
        <div class="bx--col-md-4" style="padding-top: 15px;">
          <div class="form-group bx--form-item bx--checkbox-wrapper" style="margin-top: 5%;">
            <input
              :class="['bx--checkbox', 'bx--checkbox-new', 'form-control', isInvalid('standard') ? 'is-invalid' : '']"
              id="onlySelectedCustomers"
              type="checkbox"
              v-model="onlySelectedGroups">
            <label
              class="form-control-label bx--checkbox-label"
              for="onlySelectedCustomers">Show Only Selected Linguist Groups</label>
          </div>
        </div>
      </div>

      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3">
          Mit dieser Option werden nur die bevorzugten Linguisten für den Jobabgleich verwendet.
        </div>
        <div class="bx--col-md-2" style="padding-top: 8px;">
          <input
            :checked="item.favouriteJobMatchingLevel"
            :data-invalid="isInvalid('favouriteJobMatchingLevel')"
            :value="item.favouriteJobMatchingLevel"
            @change="handleUpdateField('favouriteJobMatchingLevel', !item.favouriteJobMatchingLevel)"
            class="bx--checkbox"
            id="checkbox-favourite-job-matching"
            name="favourite-job-matching"
            type="checkbox"/>
          <label class="bx--checkbox-label" for="checkbox-favourite-job-matching">Verwenden Sie nur Lieblingslinguisten</label>
        </div>

      </div>
    </fieldset>

  </div>
</template>

<script>
  import Tooltip from '../../tooltip/Tooltip.vue'
  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css'

  import {UserTypes} from '../../../enums/index'
  import CustomerTypeForm from "./CustomerTypeForm.vue";
  import ProjectManagerList from "../../projectmanager/ProjectManagerList.vue";
  import {mapActions, mapGetters} from "vuex";
  import {debounce} from 'lodash';

  export default {
    name: "ContactDetailsForm",

    components: {
      CustomerTypeForm,
      Tooltip,
      Multiselect,
      ProjectManagerList
    },
    props: {
      config: null,
      language: null,
      formType: String,
      countries: null,
      item: null,
      defaultTimezone: null,
      validationKey: {
        type: String,
        default: 'item'
      },
      getViolation: {
        type: Function,
        required: true
      },
      isInvalid: {
        type: Function,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      handleUpdateField: {
        type: Function,
        required: true
      },
      handleUpdateTimezoneField: {
        type: Function,
        required: true
      },
      handleUpdateProjectManagerField: {
        type: Function,
        required: true
      },
      handleUserTypesField: {
        type: Function,
        required: true
      },
      userTitles: {
        type: Object,
        required: true
      },
      timezones: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        userTypes: UserTypes,
        noProjectManager: false,
        selectedProjectManager: null,
        selectedFavoriteLinguists: null,
        selectedTimezone: null,
        debounceGetCustomers: null,
        // LSP Groups
        onlySelectedGroups: false,
        selectedGroupsData: null,
        debouncedGetGroups: null,
        // Others
        emptyQuery: true,
        isLoading: false
      }
    },
    computed: {
      ...mapGetters({
        linguists: 'linguist/list/items',
        groups: 'linguistgroup/list/items'
      }),
      //TODO: CHECK IF THIS COMPUTED IS USEFUL HERE
      projectManager() {
        this.noProjectManager = false

        if (this.item.pm) {
          this.selectedProjectManager = this.item.pm
          return this.item.pm
        }

        this.noProjectManager = true

        if (this.item.company && this.item.company.pm) {
          return this.item.company.pm
        }
      },
      groupOptions() {
        if(this.onlySelectedGroups || this.emptyQuery) {
          return this.selectedGroups || []
        }

        return this.groups || []
      },
      selectedGroups: {
        get: function () {
          return this.selectedGroupsData || this.item.linguistGroups
        },
        set: function (newValue) {
          this.selectedGroupsData = newValue
          this.handleUpdateField('linguistGroups', newValue)
        }
      },
      itemFavoriteLinguists: {
        get: function () {
          return this.selectedFavoriteLinguists || this.item['favoriteLinguists']
        },
        set: function (newValue) {
          this.selectedFavoriteLinguists = newValue
          this.handleUpdateField('favoriteLinguists', newValue)
        }
      },
    },
    created() {
      this.debounceGetCustomers = debounce(this.queryForLinguist.bind(this), 1000);
      this.debouncedGetGroups = debounce(this.getLinguistGroupsOptions.bind(this), 1000);
    },
    mounted() {
      if(this.defaultTimezone) {
        this.selectedTimezone = this.timezones.find(t => t.label === this.defaultTimezone)
        this.handleUpdateTimezoneField(this.selectedTimezone['label'])
      }
    },
    methods: {
      ...mapActions({
        getLinguists: 'linguist/list/getItems',
        getGroups: 'linguistgroup/list/default',
      }),
      toggleCheckNoSpecificPM() {
        if (this.noProjectManager)
          this.handleUpdateProjectManagerField(this.selectedProjectManager)

        if (!this.noProjectManager)
          //  this.selectedProjectManager = []
          this.handleUpdateProjectManagerField(null)

        this.noProjectManager = !this.noProjectManager
      },
      queryForLinguist(query) {

        let firstName = query.split(' ')[0]
        let lastName = query.split(' ')[1] || firstName

        if (query) {
          this.isLoading = true;

          this.getLinguists('linguists?firstName=' + firstName + '&lastName=' + lastName).then(() => {
            this.isLoading = false;
          });
        }
      },
      updateTimezoneField(value) {
        this.selectedTimezone = value
        this.handleUpdateTimezoneField(value['label']);
      },
      updateProjectManagerField(value) {
        if (value === null) {
          this.handleUpdateProjectManagerField(null)
          return;
        }

        this.selectedProjectManager = value;
        this.handleUpdateProjectManagerField(value)
      },
      customLabel({firstName, lastName}) {
        return `${firstName} ${lastName}`
      },
      getLinguistGroupsOptions (query) {
        this.emptyQuery = true;

        if(query) {
          this.emptyQuery = false;
          this.isLoading = true;

          this.getGroups('linguist_groups?name=' + query).then(response => {
            this.isLoading = false;
          });
        }
      }
    },
    watch: {
      projectManager() {
        if (!this.noProjectManager)
          this.selectedProjectManager = this.item.pm
      },
      item(item) {
        this.selectedTimezone = this.timezones.find(t => t.label === item.timezone)
      }
    }
  }
</script>
