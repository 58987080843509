const OrderStatus = {
  ORDER_DRAFT: {
    schema: 'draft',
    label_en: 'Draft',
    label_de: 'Entwurf',
    label_es: 'Reclutar',
    status: 'warning'
  },
  ORDER_SETUP: {
    schema: 'setup',
    label_en: 'Setup',
    label_de: 'Konfiguration',
    label_es: 'Configuración',
    status: 'warning'
  },
  ORDER_PROCESSING: {
    schema: 'processing',
    label_en: 'Processing',
    label_de: 'Wird bearbeitet',
    status: 'warning'
  },
  ORDER_DELIVERED: {
    schema: 'delivered',
    label_en: 'Delivered',
    label_de: 'Geliefert',
    status: 'done'
  },
  ORDER_CANCELLED: {
    schema: 'cancelled',
    label_en: 'Cancelled',
    label_de: 'Storniert',
    label_es: 'Cancelado',
    status: 'error'
  },
  ORDER_DELETED: {
    schema: 'deleted',
    label_en: 'Deleted',
    label_de: 'Gelöscht',
    label_es: 'Eliminado',
    status: 'error'
  }
};

export default OrderStatus

export function getOrderStatusStatus (orderStatus) {
  let status = ''

  Object.keys(OrderStatus).some(function (k) {
    if (OrderStatus[k]['schema'] === orderStatus) {
      status = OrderStatus[k]['status']
      return true;
    }
  });

  return status
}

export function getOrderStatusLabel (orderStatus, locale='en') {
  let label = orderStatus

  Object.keys(OrderStatus).some(function (k) {
    if (OrderStatus[k]['schema'] === orderStatus) {
      label = OrderStatus[k][`label_${locale}`]
      return true;
    }
  });

  return label
}
