<template>
  <div>
    <Loading v-if="isLoading"/>
    <div class="bx--row" v-if="emptyDashboard">
      <div class="bx--col-md-12">
        <div class="lk-empty-page-content-wrapper">
          <div class="lk-empty-page-content">
            <div class="lk-empty-page-content__image-wrapper">
              <img src="../../assets/images/lk-empty-dashboard.png" alt="Lingoking Empty Dashboard"
                   class="lk-empty-page-content__image"/>
            </div>
            <div class="lk-empty-page-content__teaser">
              <h2 class="lk-empty-page-content__headline">
                {{ $t('empty_jobs') }}
              </h2>
              <p class="lk-empty-page-content__text">
                {{ $t('empty_help_lsp_1') }} <a :href="shop_url + $t('help_linkLSP')"
                                                class="lk-empty-page-content__link">FAQ</a>
                {{ $t('empty_help_lsp_2') }}
              </p>
              <p class="lk-empty-page-content__text">
                <a :href="`${shop_url}${$t('gtc_linkLSP')}.html#gtc-translators`" class="lk-empty-page-content__link">
                  {{ $t('terms_of_use_lsp_dashboard') }}
                </a>
                {{ $t('of_lingoking') }}
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!isLoading">
        <h1>Dashboard</h1>
      </div>
      <div class="bx--col-xl-8" v-if="jobs && jobs.length">
        <!-- Jobs -->
        <div class="lk-service-backend__dashboard-item">
          <div class="lk-service-backend__dashboard-headline-wrapper">
            <span class="lk-carbon-icon lk-carbon-icon-contact lk-service-backend__dashboard-icon"></span>
            <h3 class="lk-service-backend__dashboard-headline">{{ $t('recently_updated_jobs') }}</h3>
          </div>
          <table>
            <tbody>
            <tr v-for="job in jobsNotCancelled" :key="job['@id']" class="lk-service-backend__dashboard-list"
                @click.prevent="openOrderLink(job.order['@id'], '_self')">
              <td class="lk-service-backend__dashboard-list-item lk-service-backend__dashboard-list-item--no-message">
                <a class="lk-data-table__col-list__link" href="javascript:void(0)">
                  <span class="lk-service-backend__dashboard-list-link lk-service-backend__dashboard-list-link--lsp">{{
                      job.productOptionJobDefinition.jobTitle ? job.productOptionJobDefinition.jobTitle : job.jobDefinition.name
                    }}</span>
                  <span v-if="job.order.product"
                        class="lk-service-backend__dashboard-list-charges">({{ job.order.product.name }})</span>
                </a>
              </td>
              <td class="lk-service-backend__dashboard-list-item"><span>{{ job.purchaseOrderNumber }}</span></td>
              <td class="lk-service-backend__dashboard-list-item--lsp"><span>{{ getTranslationLspJobState(job) }}</span>
              </td>
              <td class="lk-service-backend__dashboard-list-item"><span
                class="lk-service-backend__dashboard-list-charges" v-if="job.finalFee">{{ job.finalFee }}</span></td>
              <td class="lk-service-backend__dashboard-list-item"><span
                class="lk-carbon-icon lk-carbon-icon-chevron--right"></span></td>
            </tr>
            </tbody>
          </table>
          <router-link
            :to="{name: 'JobList'}"
            :title="$t('show_all')"
            class="bx--btn bx--btn--secondary bx--btn--sm lk-service-backend__dashboard-item-button">
            {{ $t('show_all') }}
          </router-link>
        </div>

        <!-- Orders -->
        <div class="lk-service-backend__dashboard-item">
          <div class="lk-service-backend__dashboard-headline-wrapper">
            <span class="lk-carbon-icon lk-carbon-icon-contact lk-service-backend__dashboard-icon"></span>
            <h3 class="lk-service-backend__dashboard-headline">Recently updated orders</h3>
          </div>
          <table>
            <tbody>
            <tr v-for="order in orders" :key="order['@id']" class="lk-service-backend__dashboard-list"
                @click.prevent="openOrderLink(order['@id'], '_self')">
              <td class="lk-service-backend__dashboard-list-item lk-service-backend__dashboard-list-item--no-message">
                <a class="lk-data-table__col-list__link" href="javascript:void(0)">
                  <span class="lk-service-backend__dashboard-list-link lk-service-backend__dashboard-list-link--lsp">{{
                      order.product.name
                    }}</span>
                  <span v-if="order.product"
                        class="lk-service-backend__dashboard-list-charges">({{ order.status }})</span>
                </a>
              </td>
              <td class="lk-service-backend__dashboard-list-item"><span>{{ order.status }}</span></td>
              <td class="lk-service-backend__dashboard-list-item--lsp"><span
                class="lk-service-backend__dashboard-list-charges">{{ order.partOfInvoice.confirmationNumber }}</span>
              </td>
              <td class="lk-service-backend__dashboard-list-item"><span
                class="lk-carbon-icon lk-carbon-icon-chevron--right"></span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bx--col-xl-4" v-if="messages && messages.length">
        <div class="lk-service-backend__dashboard-item">
          <div class="lk-service-backend__dashboard-headline-wrapper">
            <span class="lk-carbon-icon lk-carbon-icon-contact lk-service-backend__dashboard-icon"></span>
            <h3 class="lk-service-backend__dashboard-headline">{{ $t('messages') }}</h3>
          </div>
          <ul class="lk-service-backend__dashboard-list">
            <li :key="index"
                class="lk-service-backend__dashboard-list-item lk-service-backend__dashboard-list-item--no-message"
                v-for="(message, index) in messages">
              <router-link
                :to="{name: 'OrderShow', params:{id: '/orders/' + message.orderId}}">
                <div class="lk-service-backend__dashboard-second-row">
                  <p class="lk-service-backend__dashboard-list-link">
                    {{ message.confirmationNumber + ' - ' + message.orderName }}</p>
                  <div class="lk-service-backend__dashboard-link-wrapper">
                    <span
                      class="lk-carbon-icon lk-carbon-icon-chevron--right lk-service-backend__dashboard-list-icon"></span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="bx--col-xl-8">
          <a class="lk-service-backend__dashboard-list-charges" :href="`${shop_url}${$t('gtc_linkLSP')}.html#gtc-translators`" target="_blank">{{ $t('terms_of_use_lsp_dashboard') }}</a>
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import LayoutDefault from '../layouts/LayoutDefault.vue'

import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import InlineLoading from 'lingoking-workbench/src/components/loading/LoadingInline.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'

import {SHOP_URL} from '../../config/entrypoint'
import router from "../../router";

export default {
    name:'DashboardLSP',
    components: {
      InlineLoading,
      Loading,
      LayoutDefault
    },
    computed: {
      ...mapState({
        account: state => state.account,
        locale: state => state.locale
      }),

      ...mapGetters({
        jobs: 'job/list/items',
        isLoading: 'job/list/isLoading',
        messages: 'message/messages',
        messagesIsLoading: 'message/isLoading',
      }),

      jobsNotCancelled: function () {
        return this.jobs.filter(job => job['state'] !== 'cancelled')
      },

      orders() {
        if(!this.jobs) {
          return;
        }

        let orders = [];
        let jobs = JSON.parse(JSON.stringify(this.jobs));

        jobs.forEach(job => {
          let order = orders.find((orderIterator) => {
            return orderIterator['@id'] === job.order['@id'];
          })

          if(!order) {
            order = JSON.parse(JSON.stringify(job.order))

            order.jobs = []
            orders.push(order)
          }

          delete job.order

          order.jobs.push(job)
        })

        return orders;
      },
    },
    methods: {
      ...mapActions({
        getJobs: 'job/list/default',
        getAllMessages: 'message/getAllLinguistMessages'
      }),
      getTranslationLspJobState(item) {
        if(!item.status) {
          return null
        }

        let jobStatus = item.status

        if(item.childJobs && item.childJobs.length) {
          item.childJobs.forEach(function (childJob) {
            jobStatus = childJob.status
          })
        }

        return item['lspJobState'] ? this.$t('lsp_job_state_' + item['lspJobState'].replace('-', '_')) : this.$t('lsp_job_state_' + jobStatus.replace('-', '_'));
      },
      openLink (id, target) {
        window.open(router.resolve({name: 'JobLSPShow', params: {id: encodeURIComponent(id)}}).href, target);
      },
      openOrderLink (id, target) {
        window.open(router.resolve({name: 'OrderShow', params: {id: encodeURIComponent(id)}}).href, target);
      },
    },
    watch: {
      jobs: function (items) {
        this.emptyDashboard = !(items && items.length)

        /*
          items.forEach((job) => {
            this.orders[job.order['@id']] = this.orders[job.order['@id']] ? this.orders[job.order['@id']] :[];
            this.orders[job.order['@id']].push(job)
          })
        */
      }
    },
    data () {
      return {
        emptyDashboard: false,
        shop_url: SHOP_URL,
        orderBy: '[updatedAt]=desc',
      }
    },
    created () {
      this.getJobs('/jobs?order' + this.orderBy)
      this.getAllMessages(this.account.user.id)
    },
  };
</script>
