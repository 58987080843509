<template>
  <div class="bx--radio-button-group">
    <input
      :checked="item.userType === userTypes.TYPE_CUSTOMER_PRIVATE"
      :data-invalid="isInvalid('userType')"
      :value="userTypes.TYPE_CUSTOMER_PRIVATE"
      @change="handleUpdateField('userType', $event.target.value)"
      class="bx--radio-button"
      id="radio-button-user-type-private"
      name="userType"
      tabindex="0"
      type="radio"/>
    <label class="bx--radio-button__label" for="radio-button-user-type-private">
      <span class="bx--radio-button__appearance"></span>
      {{ $t('private_customer') }}
    </label>

    <input
      :checked="item.userType === userTypes.TYPE_CUSTOMER_BUSINESS"
      :data-invalid="isInvalid('userType')"
      :value="userTypes.TYPE_CUSTOMER_BUSINESS"
      @change="handleUserTypesField('userType', $event.target.value)"
      class="bx--radio-button"
      id="radio-button-user-type-business"
      name="userType"
      tabindex="0"
      type="radio" />

    <label class="bx--radio-button__label" for="radio-button-user-type-business">
      <span class="bx--radio-button__appearance"></span>
      {{ $t('business_customer') }}
    </label>

    <!--<div class="bx--form-requirement" v-if="!$v.item.userType.required">Customer type required</div>
    <div class="bx--form-requirement" v-if="getViolation('userType')">
      {{ getViolation('userType') }}
    </div>-->
  </div>
</template>
<script>
  import { UserTypes } from '../../../enums'
  export default {
    name: "CustomerTypeForm",

    props: {
      item: null,
      /*getViolation: {
        type: Function,
        required: true
      },*/
      validationKey: {
        type: String,
        default: 'item'
      },
      isInvalid: {
        type: Function,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      handleUpdateField: {
        type: Function,
        required: true
      },
      handleUserTypesField: {
        type: Function,
        required: true
      },
    },
    data() {
      return {
        userTypes: UserTypes
      }
    }
  }
</script>
