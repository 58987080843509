<template>
    <div>
        <strong class="bx--label">{{ label }} *</strong>
        <p class="bx--label-description">{{ description}}</p>
        <div :class="['bx--file', multiple ? '' : 'bx--file--single']" data-file>
            <label
                    :for="id"
                    class="bx--file-btn bx--btn bx--btn--primary bx--btn--sm"
                    role="button"
                    tabindex="0">{{ buttonLabel }}</label>
            <input
                    :accept="accepted"
                    :data-invalid="invalid"
                    :id="id"
                    :multiple="multiple"
                    :name="id"
                    @change="handleFileChange($event.target.name, $event.target.files)"
                    class="bx--file-input bx--select-input"
                    data-file-uploader
                    data-target="[data-file-container]"
                    type="file"
                    v-bind="$attrs" />
            <div :class="invalidClass && invalidMessage ? invalidClass : ''" v-if="invalid">
                {{ invalidMessage }}
            </div>

            <div data-file-container class="bx--file-container">
                <div
                    data-file-container
                    class="bx--file-container"
                    v-for="(file, index) in internalFiles"
                    :key="index">

                    <span class="bx--file__selected-file">
                        <p class="bx--file-filename">{{ file.file.name }}</p>
                        <span
                            :data-for="id"
                            class="bx--file__state-container"
                            :data-test="file.state">
                            <div
                              v-if="file.state === 'uploading'"
                              class="bx--loading"
                              tabindex="0"
                              style="width: 1rem; height: 1rem;">
                                <svg class="bx--loading__svg" viewBox="-42 -42 84 84">
                                  <circle cx="0" cy="0" r="37.5"></circle>
                                </svg>
                            </div>
                            <svg
                              v-if="file.state === 'complete'"
                              class="bx--file-complete"
                              fill-rule="evenodd"
                              height="16"
                              role="img"
                              viewBox="0 0 16 16"
                              width="16"
                              aria-label="Complete"
                              alt="Complete">
                                <title>Complete</title>
                                <path
                                        d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.293-11.332L6.75 9.21 4.707 7.168 3.293 8.582 6.75 12.04l5.957-5.957-1.414-1.414z"
                                ></path>
                            </svg>
                            <svg
                              v-if="removable"
                              class="bx--file-close"
                              fill-rule="evenodd"
                              role="button"
                              height="16"
                              width="16"
                              viewBox="0 0 16 16"
                              tabindex="0"
                              alt="Remove file"
                              arial-label="Remove file"
                              @click="remove(index)"
                              @keydown.enter.prevent="remove(index)"
                              @keydown.space.prevent
                              @keyup.space.prevent="remove(index)">
                                <title>Remove</title>
                                <path
                                        d="M8 6.586L5.879 4.464 4.464 5.88 6.586 8l-2.122 2.121 1.415 1.415L8 9.414l2.121 2.122 1.415-1.415L9.414 8l2.122-2.121-1.415-1.415L8 6.586zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
                            </svg>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import '../fileupload/fileupload.scss'
  // we do not use the file uploader any more, but use code from
  // https://github.com/carbon-design-system/carbon-components-vue/blob/master/src/components/cv-file-uploader/cv-file-uploader.vue
  // import { FileUploader } from 'lingoking-workbench/node_modules/carbon-components'

  export default {
    name: 'FileUpload',
    props: {
      clearOnReselect: Boolean,
      files: Array,
      label: String,
      helperText: String,
      initialStateUploading: Boolean,
      removable: Boolean,
      buttonLabel: { type: String, default: 'Select file' },
      multiple: Boolean,
      id: String,
      description: String,
      handleChange: Function,
      accepted: String,
      invalid: Boolean,
      invalidMessage: String,
      invalidClass: String,
      maxFileSize: { type: Number, default: 2},
    },
    methods: {
      handleFileChange: function (name, files) {
        if (!files.length) {
          this.handleChange(name, [])
          return
        }

        if(this.clearOnReselect) {
          //this.internalFiles = [];
          this.handleChange(name, [])
        }

        Array
          .from(Array(files.length).keys())
          .map(x => {
            var FileSize = files[x].size / 1024 / 1024; // in MB
            var file = files[x]
            if (FileSize > this.fileSize) {
              alert('File size exceeds ' + this.fileSize + ' MB') // TODO
            } else {
              this.internalFiles.push({
                state: 'ok',
                file,
              })
            }
          })

        if(this.internalFiles.length) {
          this.handleChange(name, this.internalFiles)
        }
      },
      remove(index) {
        this.internalFiles.splice(index, 1)

        this.handleChange(name, this.internalFiles)
        this.$emit('change', this.internalFiles)
      },
    },
    data () {
      return {
        fileUploadInstance: null,
        fileSize: this.maxFileSize ? this.maxFileSize : 2
      }
    },
    mounted: function() {
      // this.fileUploadInstance = FileUploader.create(this.$el)
      //this.internalFiles = this.files ? JSON.parse(JSON.stringify(this.files)) : []
    },
    watch: {
      files() {
        //this.internalFiles = this.files ? JSON.parse(JSON.stringify(this.files)) : []
        //console.log(this.internalFiles)
      },
    },

    computed: {
      internalFiles () {
        return this.files || []
      }
    }
  }
</script>
