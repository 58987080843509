<template>
  <div class="lk-sub-orders lk-data-table-section">
    <table>
      <thead>
        <tr>
          <th>
            <span>{{ $t("target_language") }}</span>
          </th>
          <th>
            <span>{{ $t("price") }}</span>
          </th>
          <th>
            <span>{{ $t("state") }}</span>
          </th>
          <th>
            <span>{{ $t("Actions") }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>
            <span
              class="
                lk-data-table__col-list__item
                lk-data-table__col-list__item--link
              "
            >
              <span>{{ order.targetLanguages }}</span>
              <span
                v-for="(target, index) in order.purchaseOrderNumbers"
                v-show="target.badge"
                :key="index"
                class="lk-data-table__col-list__badge"
                :class="target.badge"
              >
                {{ target.badgeText ? target.badgeText : "" }}
              </span>
            </span>
          </td>
          <td>
            <span
              class="
                lk-data-table__col-list__item
                lk-data-table__col-list__item--link
              "
            >
              <span>{{ order.price }}</span>
            </span>
          </td>
          <td>
            <JobDetailStatus
              v-if="getCustomerApprovalJobFromOrder(order.$original)"
              :job="{
                ...getCustomerApprovalJobFromOrder(order.$original),
                order: order.$original,
              }"
            />
          </td>
          <td>
            <ul class="lk-data-table__col-list">
              <li
                v-for="(rowItem, index) in order.actions"
                :key="order.id + index"
                class="lk-data-table__col-list__item text-center"
                :class="'lk-data-table__col-list__item--' + rowItem.type"
                @click.prevent="openLink(rowItem.link, '_self')"
              >
                <a
                  class="lk-data-table__col-list__link"
                  :href="rowItem.link"
                  v-if="rowItem.link"
                >
                  <span :class="rowItem.icon" v-if="rowItem.icon"></span>
                  <span v-if="rowItem.text">{{ rowItem.text }}</span>
                </a>
                <span :class="rowItem.icon" v-else-if="rowItem.icon"></span>
                <span
                  class="lk-data-table__col-list__badge"
                  :class="rowItem.badge"
                  v-if="rowItem.badge"
                >
                  {{ rowItem.badgeText ? rowItem.badgeText : "" }}
                </span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import JobTypes from "lingoking-workbench/src/enums/JobType";
import JobDetailStatus from "lingoking-workbench/src/components/jobdetailview/JobDetailStatus.vue";

export default {
  name: "SubOrdersList",
  props: ["orders"],
  components: { JobDetailStatus },
  methods: {
    getCustomerApprovalJobFromOrder: function (order) {
      if (!order.jobs || !order.jobs.length) {
        return null;
      }
      return order.jobs.find(function (job) {
        return job.jobDefinition.type === JobTypes.JOB_TYPE_CUSTOMER_APPROVAL;
      });
    },
    openLink(link, target) {
      window.open(link, target);
    },
  },
};
</script>

<style scoped>
.lk-sub-orders {
  padding: 1.75rem;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border-bottom: 1px solid #ddd;
  padding: 6px !important;
}
th {
  text-align: left !important;
}
.info {
  color: #999;
}
</style>

<style>
.lk-sub-orders .lk-lsp-backend__accept-name {
  margin-bottom: 0 !important;
}
.lk-sub-orders ul li {
  padding: 0.5em 0em !important;
}
</style>
