import {SHOP_URL} from '../../config/entrypoint'

export default [
  {
    label: 'new_order',
    link: SHOP_URL,
    menuIconClass: 'lk-be-icon lk-be-icon-add lk-backend-menu__icon',
  },
  {
    label: 'dashboard',
    name: 'Dashboard',
    menuIconClass: 'lk-be-icon lk-be-icon-dashboard lk-backend-menu__icon',
  },
  {
    name: 'InvoiceList',
    label: 'recent_orders',
    menuIconClass: 'lk-be-icon lk-be-icon-edit lk-backend-menu__icon',
  },
  {
    name: 'CompletedInvoiceList',
    label: 'completed_orders',
    link: '#',
    menuIconClass: 'lk-be-icon lk-be-icon-archive lk-backend-menu__icon',
  },
  // {
  //   label: 'offers',
  //   link: '#',
  //   menuIconClass: 'lk-be-icon lk-be-icon-offers lk-backend-menu__icon',
  // },
  {
    name: 'Bills',
    label: 'bills',
    menuIconClass: 'lk-be-icon lk-be-icon-bills lk-backend-menu__icon',
  },
  // {
  //   name: 'Favorites',
  //   label: 'favorites',
  //   path: '/favorites',
  //   menuIconClass: 'lk-be-icon lk-be-icon-favorites lk-backend-menu__icon',
  // }
]
