import {router} from '../../../router'
import fetch from '../../../utils/fetch'
import UserRole from '../../../enums/UserRoles'
import axios from 'axios'
import {ENTRYPOINT} from "../../../config/entrypoint";
import {jwtService, userService} from '../../../_services'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser';


const user = userService.getUserData()

// TODO
const allowedOrigins = {
  "http://localhost:8083/shoppingcart.html": true,
  "http://localhost:8090/de/cart": true,
  "http://localhost:8090/en/cart": true,
  "http://localhost:8090/es/cart": true,
  "http://cms.legacy-staging.lingoking.com/de/cart": true,
  "http://cms.legacy-staging.lingoking.com/en/cart": true,
  "http://cms.legacy-staging.lingoking.com/es/cart": true,
  "https://www.lingoking.com/de/cart": true,
  "https://www.lingoking.com/en/cart": true,
  "https://www.lingoking.com/es/cart": true,
  "https://legacy.lingoking.com/de/cart": true,
  "https://legacy.lingoking.com/en/cart": true,
  "https://legacy.lingoking.com/es/cart": true,
};

const actions = {
  confirmation({dispatch, commit}, token) {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/ld+json'},
      data: JSON.stringify({
        "token": token,
      })
    };
    return axios(ENTRYPOINT + '/email_confirmation', requestOptions)
      .then(response => {
        const data = response
        if (response.status !== 202) {
          const error = response.statusText;
          dispatch('alert/error', error, {root: true});
          return Promise.reject(error);
        }
        dispatch('alert/success', 'confirmation_successful', {root: true});
        router.push('/login')
      })

      .catch((e) => {
        dispatch('alert/error', e.message, {root: true});
        router.push('/')
      })
  },

  sendEmailConfirmation({state, dispatch}) {
    return fetch('email_confirmation_token_request', {
      method: 'POST',
      headers: {'Content-Type': 'application/ld+json'},
      data: JSON.stringify({
        userId: state.user['id'],
        newConfirmation: true
      })
    })
      .then(() => {
        dispatch('alert/success', 'Email confirmation successfully sent', {root: true});
      })
      .catch((e) => {
        dispatch('alert/error', e.message, {root: true});
      })
  },

  login({dispatch, commit}, {username, password, routeQuery}) {
    commit('loginRequest', {username});

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },

      data: JSON.stringify({"email": username, "password": password})
    };

    return axios(ENTRYPOINT + '/login_check', requestOptions)
      .then(response => {

        const data = response.data

        if (response.status !== 200) {

          const error = (data && data.message) || response.statusText

          return Promise.reject(error)
        }



        return data;
      })

      .then(data => {

          jwtService.saveToken(data);

          let user = userService.getUserData()

          if (!user.enabled) {
            const error = 'account_not_enabled';
            dispatch('logout');
            return Promise.reject(error);
          }

          Cookies.set('uid', user.id)

          commit('loginSuccess', user);


          if (user && user.confirmed && user.active && user.id && routeQuery && routeQuery.redirect) {
            let keysStartingWithRedirectUrl =
              Object
                .keys(allowedOrigins)
                .filter(function (key) {
                  return (routeQuery.redirect).indexOf(key) !== -1;
                })

            if (allowedOrigins[routeQuery.redirect] === true || keysStartingWithRedirectUrl.length) {
              window.open(decodeURIComponent(routeQuery.redirect) + '&uid=' + user.id + '#lkToken=' + jwtService.getToken(), '_self')
            }
            return false
          } else {
            // User Redirect:
            if (routeQuery && routeQuery.redirectTo) {
              let slashPosition = routeQuery.redirectTo.indexOf("//");
              let entityId = encodeURIComponent(routeQuery.redirectTo.slice(slashPosition + 1));
              let path = routeQuery.redirectTo.slice(0, slashPosition + 1);

              router.push(path + entityId);
            } else if(user.active) {
              const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de';
              router.push('/' + locale + '/dashboard');
            } 

            if (user.roles && (!user.roles.includes(UserRole.ROLE_CUSTOMER) && !user.roles.includes(UserRole.ROLE_LSP))) {
              const error = 'Bad credentials.';
              dispatch('logout');
              return Promise.reject(error);
            }

            if (!user.active) {
              const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de';
              if (user.roles.includes("ROLE_CUSTOMER")) {
                router.push('/' + locale + '/shop/account/setup');
              } else if (user.roles.includes("ROLE_LSP")){
                router.push('/' + locale + '/shop/account/setup-lsp');
              }
            }

            if (!user.enabled) {
              const error = 'account_not_enabled';
              dispatch('logout');
              return Promise.reject(error);
            }

            if (!user.confirmed) {
              dispatch('alert/error', 'email_not_confirmed', {root: true})
            }
          }
        },
        error => {
          /* Sentry Debug */
          Sentry.addBreadcrumb({
            category: "auth",
            message: "User: " + username,
            level: Sentry.Severity.Info,
          });

          Sentry.captureException(error);
          /* Sentry Debug */

          let errorMessage = 'error_occurred';

          if (error === 'Bad credentials.') {
            errorMessage = error
          }

          if (error === 'account_not_enabled') {
            errorMessage = error;
          }

          commit('loginFailure', error);
          dispatch('alert/error', errorMessage, {root: true})
        })
  },

  refresh() {
    const refreshToken = jwtService.getRefreshToken()

    if (!refreshToken) {
      return Promise.reject(new Error('Refresh token not found'));
    }

    return axios
      .post(`${ENTRYPOINT}/token/refresh`, {
        'refresh_token': jwtService.getRefreshToken()
      })
      .then((response) => {
        if (response.data.token) {
          jwtService.saveToken(response.data);
        }

        return response;
      })
      .catch(reason => {
        jwtService.clearToken();

        return Promise.reject(reason);
      });
  },

  activate({commit, dispatch}) { //TODO: CHECK WHY THE USER IS BEING ACTIVATED IN SHOP-SERVICE
    // userService.activate()

    dispatch('refresh')

  },
  logout({commit}) {
    return fetch('/logout')
      .then(response => {
        if (response.status === 204) {
          const error = response.statusText;
          return Promise.reject(error)
        }

        return response
      })
      .then(user => {
        commit('logout', user);
        jwtService.clearToken();
        this.registerCustomerSettings(null);
        window.Intercom('update');
        window.Intercom('shutdown');
        window.Intercom('boot', this.allSettings);
      })
      .catch((e) => {
        commit('logout', user);
        jwtService.clearToken()
      })
  },
  register({dispatch, commit}, {user, options}) {
    commit('registerSetError', '');
    commit('registerToggleLoading');

    const userData = {
      "userType": user.userType,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "email": user.email,
      "password": user.password,
      "optOut": user.optOut,
      "recaptchaToken": user.recaptchaToken,
      "language": localStorage.getItem('locale')
    };

    const requestOptions = {
      method: 'POST',
      data: JSON.stringify(userData)
    };

    let path = options && options.path ? options.path : 'users';
    fetch('/' + path + '/register', requestOptions)
      .then((response) => {
        commit('registerToggleLoading');
        return response
      })
      .then((response) => {
        let data = response
        commit('registerSetCreated', data);
      })
      .catch((e) => {
        commit('registerToggleLoading');

        dispatch('alert/error', 'error_occurred', {root: true});
        // this isn't working for some reason
        // if (e instanceof SubmissionError) {
        if (e.errors) {
          commit('registerSetViolations', e.errors);
          // eslint-disable-next-line
          commit('registerSetError', e.errors._error);
          return;
        }
        commit('registerSetError', e.message);
      });
  },
  registerReset({commit}) {
    commit('registerReset')
  },
  registerAlertSuccess({dispatch}) {
    dispatch('alert/success', 'data_successfully_saved', {root: true})
  },
  registerAlertError({dispatch}, message) {
    dispatch('alert/error', message, {root: true})
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = {loggingIn: true};
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = {loggedIn: true};
    state.user = user
  },
  loginFailure(state) {
    state.status = {};
    state.user = null
  },
  logout(state) {
    state.status = {};
    state.user = null
  },
  registerSetCreated(state, created) {
    Object.assign(state, {created});
  },
  registerSetViolations(state, violations) {
    Object.assign(state, {violations});
  },
  registerSetError(state, error) {
    Object.assign(state, {error});
  },
  registerToggleLoading(state) {
    Object.assign(state, {isLoading: !state.isLoading});
  },
  registerReset(state) {
    Object.assign(state, {
      created: null,
      error: '',
      isLoading: false,
      violations: null
    })
  },
  updateUser(state, user) {
    state.user = user
  }
};

export const account = {
  namespaced: true,
  state: {
    created: null,
    error: '',
    isLoading: false,
    violations: null,
    status: {
      loggedIn: !!user
    },
    user: user || null
  },
  actions,
  mutations
};
