<template>
  <layout-default>
    <Loading v-if="isLoading || mediaObjectIsLoading" />
    <InlineNotification
      v-if="alert && alert.message && alert.type === 'alert-danger'"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />

    <div class="bx--row" v-else>
      <div class="bx--col-md-12">
        <h1 class="lk-service-backend__headline">{{$t('request')}}</h1>

        <div v-if="!isLoading && !!error" class="bx--row">
          <div class="bx--col-md-8">
            <InlineNotification
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              :title="error"
              :closeButton="false"
            />
          </div>
        </div>

        <div class="bx--row" v-if="isRejected">
          <div class="bx--col-md-8 lk-lsp-backend__reject-request">
              <p>{{$t('rejected_job_request')}}</p>
          </div>
        </div>

        <JobDetailViewHeader
          :job="item.job"
          cols-left="8"
          :header-actions="jobRequestActionButtons"
          v-if="item && !isRejected"></JobDetailViewHeader>

        <div class="bx--row">
          <div class="bx--col-md-8">
            <JobDetailView
              :hide-product-description="true"
              :job="item.job"
              :job-request="true"
              :action-buttons="jobRequestActionButtons"
              :download-action="downloadAction"
              :linguist="linguist"
              v-if="item && !isRejected">
            </JobDetailView>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
  import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
  import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
  import 'lingoking-workbench/src/components/detailview/detailview.scss'

  import { mapState, mapGetters, mapActions } from 'vuex'
  import { ENTRYPOINT } from '../../config/entrypoint'

  import LayoutDefault from '../layouts/LayoutDefault.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import JobDetailViewHeader from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeader.vue";
  import JobDetailView from "lingoking-workbench/src/components/jobdetailview/JobDetailView.vue";
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'

  export default {
    components: {
      Loading,
      LayoutDefault,
      JobDetailViewHeader,
      JobDetailView,
      InlineNotification
    },
    computed: {
      ...mapState({
        account: state => state.account,
        alert: state => state.alert
      }),
      ...mapGetters({
        item: 'jobrequest/show/retrieved',
        error: 'jobrequest/show/error',
        isLoading: 'jobrequest/show/isLoading',
        linguist: 'linguist/show/retrieved',
        acceptedOrRejected: 'jobrequest/show/acceptedOrRejected',
        //File Download
        downloadedMediaObject: 'mediaobject/show/downloaded',
        mediaObjectIsLoading: 'mediaobject/show/isLoading',
      }),

      isRejected() {
        if(this.item)
          return !this.item.accepted && this.item.deletedAt !== null
      },
      jobRequestActionButtons () {
        if(this.item) {
          return {
            buttonAcceptLabel: 'accept', // TODO translate
            buttonRejectLabel: 'reject', //TODO tranlate
            acceptMethod: this.acceptJobRequest,
            rejectMethod: this.rejectJobRequest,
          }
        }
        return null
      },
    },
    created () {
        // retrieve job request data
        this.retrieve(decodeURIComponent(this.$route.params.id))
        this.retrieveLinguist(decodeURIComponent('users/' + this.account.user.id))
    },
    data() {
      return {
        accepted: null,
        apiUrl: ENTRYPOINT
      }
    },
    methods: {
      ...mapActions({
        retrieve: 'jobrequest/show/retrieve',
        retrieveLinguist: 'linguist/show/retrieve',
        acceptOrReject: 'jobrequest/show/acceptOrReject',
        updateRetrieved: 'jobrequest/show/updateRetrieved',
        alertSuccess: 'alert/success',
        alertError: 'alert/error',
        downloadMediaObject: 'mediaobject/show/download'
      }),

      acceptJobRequest () {
        this.updateRetrieved({'accepted': true})
        this.accepted = true
        this.acceptOrReject()
      },

      rejectJobRequest () {
        this.updateRetrieved({'accepted': false })
        this.accepted = false
        this.acceptOrReject()
      },

      downloadAction: function (downloadItem) {
        if(downloadItem) {
          this.downloadMediaObject(downloadItem)
        }
      },
    },

    watch : {
      item: function (item) {
        if (!item) {
          return
        }
        if (item.deletedAt) {
          const matchUser = this.item.job.user['@id'] === '/linguists/' + this.account.user.id;
          if (matchUser) {
            this.$router.push({ name: 'JobLSPShow', params: { id: this.item.job['@id'] } });
            setTimeout(() => {
              this.alertSuccess('you_already_accepted_job', { root: true });
            }, 300)
            return;
          }
          this.$router.push({ name: 'OfferList' });
          setTimeout(() => {
            this.alertError('another_linguist_already_accepted_job', { root: true });
          }, 300)
        }
      },
      acceptedOrRejected: function (val) {
        if(val && !val.error) {
          if(this.accepted === false) {
            this.$router.push({name: 'OfferList'});
            setTimeout(() => {
              // display success message after route change complete
              this.alertSuccess('job_reject_success', { root: true })
            })
          }
          else if(this.accepted === true) {
            this.$router.push({name: 'OrderShow', params: { id: this.item.job.order['@id'] }});
            setTimeout(() => {
              // display success message after route change complete
              this.alertSuccess('job_accept_success', { root: true })
            })
          }
        }
      }
    }
  };
</script>
