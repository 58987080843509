<template>
  <div>
    <Loading class="alert alert-info" role="status" v-if="isLoading" />

    <div>
      <JobDetailViewSection>
        <template slot="content">

          <button
            class="bx--file-btn bx--btn bx--btn--secondary"
            @click="generatedShipmentSheetPDF(job.order.partOfInvoice)"
          >
            <span class="lk-carbon-icon lk-carbon-icon-download lk-button-icon--left"></span>
            <span>{{ $t('generate_shipment_sheet_pdf') }}</span>
          </button>

          <!-- JOB TARGET FILES DOWNLOAD START -->
          <JobDetailViewSection
            v-if="translationFiles.length"
            :download-action="downloadAction"
            :headline="uploadedFilesLabel"
            :downloads="translationFiles"
            :admin="admin"
            :border="false"
          />
          <!-- JOB TARGET FILES DOWNLOAD END -->


        </template>
      </JobDetailViewSection>

      <div style="padding-top: 1rem">
        <button
          class="bx--file-btn bx--btn bx--btn--primary"
          :disabled="job.status !== jobStatus.REALIZATION"
          @click="openModal">
          {{ $t("release") }}
        </button>
      </div>
    </div>

    <LKModal
      ref="modalRef"
      :close-button="true"
      :modal-data="modalData"
      :handle-reject-button-click="closeModal"
      :handle-accept-button-click="submit"
      :modal-id="'modal-info'"
    >
      <template slot="content">
        <div class="job-detail-modal" v-html="$t('are_you_sure_confirm_job_execution')"></div>
      </template>
    </LKModal>
  </div>
      
</template>

<script>
import { mapActions } from "vuex";

import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import InlineNotification from "lingoking-workbench/src/components/notification/InlineNotification.vue";
import Loading from "lingoking-workbench/src/components/loading/Loading.vue";
import LKModal from "lingoking-workbench/src/components/modal/Modal.vue";

import JobStatus from "lingoking-workbench/src/enums/JobStatus";
import JobTypes from "lingoking-workbench/src/enums/JobType";
import UserRole from "../../../enums/UserRoles";

import fetch from "../../../utils/fetch";

export default {
  name: "Printing",
  components: {
    JobDetailViewSection,
    InlineNotification,
    Loading,
    LKModal,
  },

  props: {
    job: {
      type: Object,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    uploadedFilesLabel: {
      type: String,
      default: "",
    },
    translationFiles: {
      type: Array,
      default: () => [],
    },
    downloadAction: {
      type: Function,
    },
  },

  computed: {},

  methods: {
    ...mapActions({
      acceptOrReject: "job/show/acceptOrReject",
      retrieve: 'job/show/retrieve',
      updateError: "job/show/updateError",
      generatedShipmentSheetPDF: "batchshipment/update/downloadPdf",
    }),
  
    openModal () {
      this.$refs.modalRef.modalInstance.show();
    },

    closeModal () {
      this.$refs.modalRef.modalInstance.hide  ();
    },

    submit() {
      this.isLoading = true;

      let jobToUpdate = {
        id: this.job.id,
        accepted: true,
      };

      fetch("jobs/" + this.job.id + "/accept_or_reject", {
        method: "PUT",
        data: jobToUpdate,
      })
        .then(() => {
          this.$emit("success");
          this.isLoading = false;
          this.retrieve(this.job['@id']);
        })
        .catch((e) => {
          this.updateError(e);
          this.isLoading = false;
        })
        .finally(() => {
          this.$scrollTo("body");
        });
    }
  },

  watch: {},

  created() {},

  data() {
    return {
      isLoading: false,
      jobStatus: JobStatus,
      jobTypes: JobTypes,
      userRoles: UserRole,
      modalData: {
        rejectButtonLabel: this.$t("close"),
        acceptButtonLabel: this.$t("confirm"),
      },
    };
  },
};
</script>
