import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import {userService} from "../../../../_services";
import JobTypes from "lingoking-workbench/src/enums/JobType";

const getItems = ({commit}, page = '/jobs') => {
  commit(types.TOGGLE_LOADING)
  const user = userService.getUserData()

  if (user && user.id) {
    page = page.includes('/users/') ? page  : '/users/' + user.id + page
  }
    // exclude jobs with type FINAL_REVISION_QM & FINAL_REVISION
    if (user.roles.includes('ROLE_LSP')) {
      page += '&status=realization'
    }

  fetch(page)
    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, data['hydra:view'])
      commit(types.SET_TOTAL_ITEMS, data['hydra:totalItems']);
      if (data['hydra:view'] && data['hydra:view']['@id']) {
        const pageParams = new URLSearchParams(data['hydra:view']['@id']);
        const currentPage = pageParams.get('page');
        if (currentPage) {
          commit(types.SET_CURRENT_PAGE, currentPage)
        }
      }
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export default getItems
