<template>
  <nav role="navigation">
    <ul class="lk-main-nav">
      <li class="lk-main-nav-item "
          :class="['lk-backend-menu__item', {'lk-backend-menu__item--selected lk-backend-menu_item--active': isActive(routes)}]"
          v-for="routes in mainNav">
        <a :href="routes.link + $t(routes.cmspage)" rel="noopener" :target="routes.target || '_self'" v-if="routes.cmspage">
          <span class="lk-main-nav-item__icon" v-bind:class="routes.menuIconClass" v-if="routes.menuIconClass"></span>
          <span class="lk-main-nav-item__label">{{ $t(routes.label) || $t(routes.name) }}</span>
        </a>
        <router-link v-bind:key="routes.id"
                     :to="{name: routes.name}"
                     class="lk-main-nav-item__link" v-else>
          <span class="lk-main-nav-item__icon" v-bind:class="routes.menuIconClass" v-if="routes.menuIconClass"></span>
          <span class="lk-main-nav-item__label">{{ $t(routes.label) || $t(routes.name) }}</span>
        </router-link>
        <div class="lk-shop-service-nav-dropdown__wrapper" v-if="routes.children">
          <div class="lk-shop-service-nav-dropdown">
            <ul>
              <li class="lk-shop-service-nav-dropdown__item"
                  :class="['lk-backend-submenu__item', {'lk-backend-submenu__item--active' : isActive(childRoute) }, {'lk-shop-service-nav-dropdown__item--separated' : childRoute.separated }]"
                  v-for="childRoute in routes.children">
                <router-link v-bind:key="childRoute.id"
                             :to="{name: childRoute.name}"
                             active-class="active">
                  {{ $t(childRoute.label) || $t(childRoute.name) }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="lk-shop-service-nav-dropdown__item-triangle"></div>
          <div class="lk-shop-service-nav-dropdown__item-triangle-hider"></div>
        </div>
      </li>
      <li class="lk-main-nav-item lk-backend-menu__item">
        <router-link :to="{ name: 'Logout', params: {locale: locale.locale}}" class="lk-main-nav-item__link">
          <span class="lk-main-nav-item__icon lk-carbon-icon lk-carbon-icon-power lk-backend-menu__icon"></span>
          <span class="lk-main-nav-item__label">{{ $t('logout') }}</span>
        </router-link>
      </li>
      <LanguageSelector></LanguageSelector>
    </ul>
  </nav>
</template>

<script>
  import mainNavGen from '../../router/navigation/mainNavigation'

  import {mapState} from 'vuex'
  import 'lingoking-workbench/src/components/shopservicenavdropdown/shopservicenavdropdown.scss'
  import LanguageSelector from "lingoking-workbench/src/components/languageselector/LanguageSelector.vue";
  import {isActive} from '../../router/navigation/util'
  import {userService} from "../../_services";

  export default {
    name: 'MainMenu',
    components: {
      LanguageSelector
    },
    computed:{
      ...mapState({locale: state => state.locale}),
      mainNav() {
        const user = userService.getUserData();
        return mainNavGen(user);}
    },
    mounted: function () {
      $('.lk-main-nav-item').each(function () {
        $(this).mouseenter(function () {
          $(this).find('.lk-shop-service-nav-dropdown__wrapper').show()
        })
        $(this).mouseleave(function () {
          $(this).find('.lk-shop-service-nav-dropdown__wrapper').hide()
        })
      })
    },
    methods: {
      isActive: isActive,
    },
    data() {
      return {
      }
    }
  }
</script>
