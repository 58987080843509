import customerInvoiceList from './customer/list'
import lspCcnList from './lsp/list'

export default {
  namespaced: true,
  modules: {
    customerInvoiceList,
    lspCcnList
  }
}
