export default {
  de: 'Alemán',
  en: 'Inglés',
  es: 'Español',
  german: 'Alemán',
  english: 'Inglés',
  spanish: 'Español',
  language: 'Idioma',
  save: 'Guardar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  close: 'Cerrar',
  delete: 'Borrar',
  error: 'Error',
  sorry: '¡Lo siento!',
  yes: 'sí',
  no: 'no',
  clarification_from_qm: 'Feedback de lingoking',
  select_from_list: 'Por favor, seleccione {elemento} de la lista',
  settings_language: 'Configuración del idioma',
  settings_language_tooltip: 'Idioma utilizado en sus e-mails',
  notifications: 'Notificaciones',
  settings_notifications: 'Configuración de la notificación',
  settings_notifications_tooltip: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
  newsletter: 'Boletín informativo',
  success: '¡Muchas gracias!',
  settings_update_success: 'Su configuración fue guardada con éxito',
  try_again: 'Por favor, inténtelo de nuevo.',
  welcome: 'Bienvenido',
  your_language: 'Su idioma',
  filter_by: 'Filtrar por',
  clear_all: 'Eliminar todo',
  error_occurred: 'Ha ocurrido un error.',
  invoice: 'Factura | Facturas',
  credit_note: 'Nota de pago | Notas de pago',
  name: 'nombre',
  service: 'servicio',
  receipt_number: 'Número de recibo',
  creation_date: 'Fecha de creación',
  ccn_number: 'Número de CCN',
  ccn_amount: 'El monto de la nota de pago',
  account_not_enabled: 'La cuenta no fue activada. Comuníquese con el servicio de asistencia para obtener información.',
  customer: 'Cliente',
  lsp: 'Traductor',
  pm: 'Asistencia lingoking',
  project_manager: 'Gerente de proyecto',
  invalid_data: 'Por favor, compruebe el formulario para ver si hay datos inválidos',
  select_timezone: 'Selecciona una zona horaria.',
  timezone: 'zona horaria',
  your_timezone: 'Su zona horaria',
  register_as_lsp: 'Regístrese como proveedor de servicios',
  register_as_customer: 'Regístrese como cliente',
  generate_shipment_sheet_pdf: 'Generar hoja de envío pdf',
  are_you_sure_confirm_job_execution: '¿Estás seguro de que quieres confirmar la ejecución del trabajo?',

  // Registration Pages
  registration_page_title: 'Regístrese ahora/Registro',
  login_now: 'Ingrese ahora',
  account_already: '¿Ya tiene una cuenta?',
  register_header: 'Empiece en unos pocos pasos',
  register_subheader: 'Regístrese ahora como LSP y utilice nuestras ofertas!',
  register_subtitle_error: 'Por favor, compruebe los datos que ha introducido y vuelva a intentarlo. ¡Muchas gracias!',
  business_customer: 'Cliente comercial',
  private_customer: 'Cliente privado',
  first_name: 'Nombre de pila',
  first_name_required: 'Se requiere el nombre de pila',
  first_name_too_short: 'El nombre es demasiado corto',
  last_name: 'Apellido',
  last_name_required: 'Se requiere el apellido',
  last_name_too_short: 'El apellido es demasiado corto',
  repeat_email: 'Repita la dirección de correo electrónico',
  repeat_email_required: 'Se requiere repetir la dirección de correo electrónico',
  repeat_password: 'Repita la contraseña',
  repeat_password_required: 'Se requiere repetir la contraseña',
  repeat_password_not_identical: 'Repita la contraseña no idéntica a la contraseña',
  opt_out: 'No me interesan las innovaciones a los productos y servicios de lingoking.',
  opt_out_privacy: 'Protección de datos',
  privacy_link: '/es/general/proteccion-de-datos',
  terms_of_use_p1: 'Al hacer clic en "registro completo" estoy de acuerdo con los ',
  terms_of_use_p2: 'de lingoking.',
  terms_of_use: 'términos de uso',
  terms_of_use_link: '/es/general/terminos-y-condiciones-de-uso',
  help_link: '/es/general/ayuda-clientes',
  help_linkLSP: '/es/general/ayuda-traductores-interpretes',
  register_complete: 'registro completo',
  required: 'se requiere',
  too_short: 'es demasiado corto',
  login_problem_classic1: '¿Tienes problemas para ingresar?',
  login_problem_classic2: 'Actualmente estamos reconstruyendo nuestra página web. Puede encontrar sus antiguos pedidos aquí: ',
  login_problem_classic3: 'Para cualquier pregunta contáctenos en el chat!',

  login_page_title: 'Inicie sesión ahora',
  no_account: '¿Todavía no tiene una cuenta?',
  register_now: 'Regístrese ahora',
  login_header: 'LSP Ingresar ahora',
  login_subheader: 'Introduzca sus datos de acceso en los espacios.',
  your_email: 'correo electrónico',
  password: 'contraseña',
  email: 'Correo electrónico',
  email_required: 'Se requiere su correo electrónico',
  email_valid: 'El correo electrónico debe ser válido',
  password_required: 'Se requiere una contraseña',
  min_length_error: 'Este valor es demasiado corto. Debe tener {número} caracteres o más.',
  password_min_length_error: 'Este contraseña es demasiado corta. Debe tener {number} caracteres o más.',
  forgot_password: '¿Olvidó su contraseña?',
  login: 'Ingresar',
  profile_updated: 'Su perfil ha sido actualizado.',
  favorites_updated: 'Sus proveedores de servicios deseados han sido actualizados.',
  customized_service_title: 'Solución personalizada',
  customized_service: 'Hemos desarrollado estos servicios individuales especialmente para usted.',
  customized_service_company_title: 'Soluciones individuales para su empresa',
  customized_service_company: 'Hemos desarrollado estos servicios individuales especialmente para su empresa.',

  // Registration LSP
  lsp_registration_page_title:  'Registro de LSP',
  welcome_title: 'Bienvenido a lingoking. Complete su perfil en cuatro fáciles pasos.',
  welcome_description: 'Gracias por su interés en trabajar con lingoking. Por favor, tómese unos minutos para completar su perfil y registro. Aquí puede averiguar cómo presentar una solicitud exitosa y conocer los requisitos técnicos de registro (navegador, configuración del navegador). Puede completar su registro en cuatro pasos sencillos. Por favor, introduzca sus datos de contacto, sus competencias y cualificaciones profesionales, así como los respectivos documentos justificativos. Tenga en cuenta que sólo puede aceptar tareas una vez que sus habilidades hayan sido acreditadas por lingoking. Lista de control para la inscripción Los siguientes documentos son necesarios para la inscripción:',
  currenct_contact_details: 'Datos de contacto actuales',
  skills: 'Competencias (tipos de servicios lingüísticos, idiomas, áreas de especialización)',
  proof_of_qualification: 'Prueba de calificación, certificación y acreditación, por ejemplo, certificados de traducción o interpretación (con calificación final), testimonios de clientes y otros documentos de apoyo que verifiquen su experiencia en traducción e interpretación, así como prueba de pertenencia a asociaciones pertinentes.',
  photo_optional: 'Foto (opcional)',
  account_details: 'Detalles de la cuenta',
  contact_details: '1. Sus datos de contacto',
  contact: 'Contacto',
  phone_description: 'Su número de teléfono',
  phone: 'Número de teléfono',
  mobile_phone: 'Número de móvil',
  mobile_phone_description: 'Su número de teléfono móvil',
  fax_number: 'Número de fax',
  fax_number_description: 'Su número de fax',
  skills_title: 'Competencias',
  skills_placeholder: 'Añade sus competencias',
  please_note: 'Por favor, tenga en cuenta',
  skill_warning: 'Sus competencias deben ser primero acreditadas por lingoking antes de que se le ofrezca una tarea adecuada.',
  your_skills: '2. Sus competencias',
  skills_desc: 'Como lingoking proveedor de servicios lingüísticos, tienes la oportunidad de trabajar como traductor, intérprete o intérprete telefónico. Por favor, especifique los servicios lingüísticos que desea ofrecer. Puede añadir más detalles en los siguientes pasos. Puede especificar una o varias habilidades.',
  native_language: 'Lengua materna',
  native_languages: 'Lenguas maternas',
  native_language_desc1: 'Por favor, seleccione su lengua materna.',
  native_language_desc2: 'Si tiene más de un leguaje materno, por favor especifique.',
  native_language_placeholder: 'Buscar y añadir lenguas maternas.',
  native_language_error: 'Se requiere por los menos una lengua materna',
  application_documents: '3. Sus documentos de aplicación',
  curriculum_vitae: 'Curriculum vitae',
  upload_curriculum_vitae: 'Por favor, suba su currículum vitae.',
  documents_allowed: 'Sólo se admiten documentos (ms-excel, ms-powerpoint, .txt, .pdf, .jpeg, .png).',
  select_files: 'Seleccione los archivos',
  select_file: 'Seleccione el archivo',
  delete_file_success: 'Archivo borrado con éxito.',
  upload_file_success: 'Archivo subido con éxito.',
  only_jpg: 'solamente archivos .jpg y.png. 500kb de tamaño máximo de archivo.',
  max_file_upload_size_mb: 'MB de tamaño máximo de archivo.',
  references: 'Referencia / testimonios',
  upload_references: 'Por favor, suba al menos una referencia o testimonio.',
  profile_summary: '4. El resumen de su perfil',
  profile_photo: 'Foto de perfil',
  profile_photo_desc: 'Usted puede mejorar su perfil público subiendo una foto de perfil, sin embargo esto es opcional. Por favor, ten en cuenta que la foto de perfil que usted introduzca será visible en su perfil público.',
  profile_photo_desc2: 'Importante: Para enviar su registro a lingoking, por favor, desplácese hasta el final de la página y haga clic en el botón "completar registro". Por favor, también tenga en cuenta la Declaración de Protección de Datos así como nuestro TOC. Escriba algo sobre usted para que el cliente lo vea. Por favor, no introduzca ningún dato como su nombre completo, dirección de correo electrónico o número de teléfono que pueda identificarle personalmente.',
  bank_account: '5. Detalles de la cuenta bancaria, número de impuestos y número de IVA',
  account: 'Cuenta',
  payment_data: 'Con sus datos de pago, puede aceptar trabajos, pero estos espacios deben ser completados en el momento en que la tarea es firmada por el cliente. Si reside en Alemania, verá un espacio para el número de IVA junto al número de identificación fiscal. En este caso, sólo complete uno de los dos espacios.',
  enter_payment_data: 'Por favor, introduzca sus datos de pago.',
  payment_method: 'Método de pago',
  payment_not_successfully_created: 'Pago no se ha creado con éxito',
  paypal_address: 'Correo electrónico de PayPal',
  tax_number: 'Número de IVA',
  vat_id: 'número de identificación fiscal',
  vat_id_invalid: 'Este no es un número de identificación del IVA válido',
  account_holder_name: 'Nombre del dueño de la cuenta',
  vat_id_required: 'Campo obligatorio para las empresas de la UE fuera de Alemania',
  search_invoices:'buscar entre las facturas',

  // Reset password pages
  password_token_reset_page_title: 'Restablecer la contraseña del token',
  reset_password: 'Restablecer la contraseña',
  enter_email: 'Introduzca su dirección de correo electrónico',
  email_unvalid: 'Correo electrónico invalido',
  password_reset_success: 'Si eres un usuario válido en nuestra plataforma, recibirás un correo electrónico para restablecer tu contraseña.',
  reset_successful: 'El restablecimiento fue exitoso',
  enter_new_password: 'Introduzca su nueva contraseña.',
  your_new_password: 'Su nueva contraseña',
  save_password: 'Guardar contraseña',
  reset_user_notfound: 'Dirección de correo electrónico desconocida. Por favor, compruebe la información.',

  // Alerts
  tracking_code_sent: 'Código de rastreo enviado con éxito',
  tracking_code_saved: 'Código de rastreo guardado con éxito',
  job_reject_success: 'La solicitud de trabajo fue rechazada con éxito.',
  job_accept_success: 'The job request was successfully accepted.',
  link_invalid: 'El link solicitado es inválido o ha caducado.',
  job_reject_fail: 'La solicitud de trabajo no podía ser rechazada',
  email_not_confirmed: '¡Todavía no ha confirmado su dirección de correo electrónico!',
  confirmation_successful: 'Confirmación exitosa',
  registration_successful: 'Registro exitoso',
  rejected_job_request: '¡Qué bien que se pase por aquí! Ya ha rechazado esta tarea. Si tiene alguna pregunta, no dude en ponerse en contacto con support@lingoking.com.',

  // Empty Dashboard
  first_order: ' Actualmente no hay pedidos en esta área',
  empty_help1: 'Si desea reservar un pedido, haga clic en "Nuevo Pedido" en la parte superior izquierda. ¿Tiene más preguntas? Visite nuestro ',
  no_results_found: '¡No se han encontrado resultados!',
  empty_help2: 'o visite nuestro ',
  empty_help_video: 'vídeo',
  no_orders: '¡Todavía no tiene ninguna orden!',
  empty_jobs: 'Actualmente no hay tareas en esta área',
  empty_help_lsp_1: '¿Tiene alguna pregunta sobre la gestión de proyectos en lingoking? Navegue por nuestro ',
  empty_help_lsp_2: 'o póngase en contacto con nosotros, ¡estamos deseando escucharle!',

  // Dashboard
  recent_orders: 'Órdenes recientes',
  show_all: 'Mostrar todo',
  dashboard: 'Panel de control',
  recently_updated_jobs: 'Tareas actualizados recientemente',

  // Profile
  customer_type: '¿Es usted un cliente de comercial o privado?',
  customer_salutation: 'Saludo',
  customer_salutation_desc: 'Por favor, introduzca una dirección.',
  customer_salutation_mrs: 'Sra',
  customer_salutation_mr: 'Sr',
  customer_billing_address: 'Por favor, introduzca su dirección de facturación.',
  billing_address: 'Dirección de facturación',
  change_billing_address: 'Cambiar dirección de facturación',
  change_delivery_address: 'Cambiar la dirección de envío',
  billing_address_label: 'Por favor, introduzca su dirección de facturación.',
  address_street_number: 'Calle, número',
  address_street: 'Calle',
  number: 'Número',
  address_zip_code: 'Código postal',
  address_city: 'Ciudad',
  address_country: 'País',
  address_suffix: 'Sufijo de la dirección',
  choose_address_country: 'Seleccione un país.',
  favorites: 'Favoritos',
  favorite_linguists: 'Traductores favoritos',
  favorites_desc: 'Por favor, seleccione sus proveedores de servicios lingüísticos favoritos aquí.',
  favorites_desc2: 'Si tiene uno o más lingüistas favoritos, por favor especifique.',
  favorites_placeholder: 'Busque y añada sus lingüistas favoritos.',
  unsubscribe_feedback_email: 'Ha cancelado con éxito su suscripción a nuestros correos electrónicos de valoración',
  subscribe_feedback_email: 'Suscríbase al correo de valoración',

  // Auftragsverarbeitung
  request: 'Solicitar',
  accept: 'Aceptar',
  reject: 'Rechazar',
  reject_translation_desc: 'Esto rechaza la traducción e inicia una corrección de la misma. Por favor, háganos saber dónde podemos mejorar la traducción.',
  refusal_reason: 'Motivo de la rechazada',
  reject_translation: 'Rechazar la traducción',
  accept_translation: 'Aceptar la traducción',
  accept_translation_desc_physical: 'Por la presente, acepta la traducción y dispone el envío inmediato de sus documentos.',
  accept_translation_desc_mail: 'Por la presente, acepta la traducción.',
  lsp_search: 'Estamos buscando un proveedor de servicios para usted',
  tracking_code: 'Código de rastreo',
  track_shipment: 'Seguimiento del envío',
  select_carrier: 'Seleccione un método de envío',
  enter_tracking_code: 'Por favor, introduzca el código de rastreo',

  // Job Detail View Section
  requirements: 'Requerimientos',
  notes: 'Notas',
  customer_notes: 'Mensaje del cliente',
  deadline: 'Fecha de entrega',
  due_on: 'Debido a',
  document_to_translate: 'Documento a ser traducido',
  documents_to_translate: 'Documentos a ser traducidos',
  address_info: 'La dirección completa se activa después de la aceptación del pedido',
  source_language: 'Idioma de origen',
  target_language: 'Idioma de destino',
  target_languages: 'Idiomas de destino',
  upload_documents: 'Subir documento(s)',
  upload_document: 'Subir documento',
  translation_was_accepted: 'Su traducción aprobada ha sido aceptada por el cliente',
  translation_was_not_accepted: 'Su traducción aprobada aún no ha sido aceptada por el cliente',
  file_was_deleted_on: 'Archivo borrado el {date}',
  file_will_be_deleted_on: 'El archivo se borrará el {date}',
  personal_contact_person: 'Su contacto personal',
  customer_address: 'Dirección del cliente',
  your_translation_to_download: 'Descargue su traducción',
  your_uploaded_translation: 'Tu traducción subida',
  your_uploaded_translation_to_download: 'Tu traducción cargada para descargar',
  translation_to_correct: 'Traducción a ser corregida',
  your_uploaded_correction: 'Su corrección subida',
  corrections_to_download: 'Descargar las correcciones',
  upload_translation_here: 'Suba la traducción aquí',
  upload_correction_here: 'Suba la corrección aquí',
  upload_corrected_translation: 'Suba la traducción corregida aquí',
  upload_correction_success: 'La corrección se ha subido con éxito.',
  upload_translation_success: 'La traducción se ha subido con éxito.',
  upload_success: 'La traducción del trabajo {job} se ha subido correctamente.',
  accepted_translation_success: '¡Muchas gracias! Ha aceptado con éxito esta traducción',
  upload_translation_rejected: 'La traducción fue rechazada con éxito.',
  translated_file_not_uploaded: 'La traducción no ha sido subido, todavía!',
  your_fee: 'Sus pagos',
  fee: 'Pago',
  final_fee: 'Pago final',
  amount: 'alcance',
  translate_from: 'Su traducción se está realizando por',
  translated_from: 'Su traducción ha sido editada por',
  for_your_information: 'Para su información:',
  release_translation: 'Entrega la traducción',
  release_correction: 'Entrega la corrección ',
  upload_correction: 'Suba la corrección',
  target_file: 'Archivo de destino',
  release: 'Entregar',
  current_order: 'Orden actual',
  download_translation: 'Descargar la traducción',
  download_translation_to_be_corrected: 'Descargar la traducción para ser corregida',
  job_cancelled: 'Tarea cancelada',
  job_cancelled_warning: 'Esta tarea ha sido cancelada. ¡No se le permite acceder a sus datos!',
  cet: 'CET',
  job_progress: 'Tarea en Progreso',
  job_done: 'Tarea Hecho',
  job_rejected: 'Tarea Rechazado',
  current_job: 'Tarea Actual',

  // List view:
  order_from: 'Encargado por',
  after_order_copy_to: 'Después de la orden, envíe una copia de la factura a',
  download_offer: 'Descargar la oferta',
  internal_process: 'Proceso Interno',

  // CompanyForm
  company_legend: 'lingoking para empresas',
  company: 'Empresa',
  company_details: 'Detalles de la empresa',
  company_name: 'Nombre de la empresa',
  company_name_required: 'Nombre de la empresa requerido',
  company_name_too_short: 'El nombre de la empresa es demasiado corto',
  company_phone: 'Teléfono',
  position_in_company: 'Posición en la empresa',
  phone_is_too_short: 'El número de teléfono es demasiado corto',
  select_industry: 'Por favor selecciona una industria',
  small_company_label: "Soy un 'Kleinunternehmer' (pequeño negocio). Mis ingresos en la jerga están exentos del impuesto de ventas basado en la sección 19 de la UStG Alemana.",
  industry: 'Sector industrial',
  vat_number: 'Número de IVA',
  vat_required: 'IVA requerido',
  url_too_short: 'La URL es demasiado corta',

  // Shopping Cart
  select_customer: 'Seleccione el cliente',
  pay_safely_with: 'Pago seguro con',
  estimated_delivery_date: 'Se estima la entrega en 3-5 días laborables',
  gtc_and_privacy: 'GTC y protección de datos',
  shipping_options: 'Opciones de envío',
  accept_gtc: 'Confirmo que he leído y comprendido los Términos y Condiciones Generales y la Política de Privacidad, los cuales acepto.',
  continue_to_payment: 'Proceder al pago',
  continue_shopping: 'Seguir comprando',
  cart_overview: 'Resumen de la cesta de la compra',
  order_overview: 'Resumen de la orden',
  cart: 'Cesta de la compra',
  overview: 'Resumen',
  login_tab: 'Sus datos',
  continue: 'continuar',
  data_successfully_saved: 'Sus datos han sido guardados. Recibirá un correo electrónico para confirmar su cuenta y verificar su contraseña.',
  please_enter_data: 'Por favor, introduzca sus datos',
  please_uplod_file: 'Suba ahora el/los archivo(s) de origen. A continuación, recibirá un correo electrónico confirmando la recepción del pedido.',
  back: 'Atrás',
  enter_login_data: 'Por favor, introduzca sus datos de acceso',
  find_right_product: '¿No ha encontrado el producto adecuado?',
  help_you: 'Podemos ayudarle',
  contact_lingoking: 'Contáctese con Lingoking',
  cart_empty: 'Su cesta de la compra está vacía.',
  to_products: 'a los productos',
  choose_option: 'Para continuar comprando, por favor elija una opción',
  new_customer: 'Nuevo cliente',
  order_as_guest: 'Ordenar como invitado',
  order_product_now: 'Solicitar traducción ahora',
  go_to_login: 'Vaya al área de inicio de sesión',
  ask_help: '¿Pedir? ¡Nos gustaría ayudarte!',
  go_to_products: 'Ir a productos',
  link_login: 'Iniciar sesión',
  link_contact: 'Contacto',
  problems: 'Problema',
  file_upload: 'Subir documentos',
  submit_order: 'Enviar pedido',
  submit_order_info: 'En unos minutos recibirá una confirmación por correo electrónico de que sus datos han sido recibidos.',
  remove_product: 'Retirar producto',
  show_details: 'Mostrar detalles',
  hide_details: 'Esconder detalles',
  source_language_placeholder: 'Busca y añade un idioma de origen.',
  target_languages_placeholder: 'Busca y añade un idioma de destino.',
  source_language_missing: 'Falta el idioma de origen',
  target_language_missing: 'Falta el idioma de destino',
  number_of_pages_missing: 'Se requiere un número de páginas',
  number_of_words_missing: 'Se requiere el número de palabras',
  number_of_fixed_items_missing: 'Se requiere un número de artículos',
  number_of_pages: 'Número de páginas',
  number_of_words: 'Número de palabras',
  number_of_fixed_items: 'Cantidad',
  number_of_hours: 'Número de horas',
  number_of_days: 'Número de días',
  number_of_half_days: 'Número de medios días',
  number_of_half_hour: 'Número de medias horas',
  how_many: 'Cuántos',
  pages: 'páginas',
  words: 'palabras',
  page: 'pagina',
  word: 'palabra',
  day: 'día',
  days: 'días',
  hour: 'hora',
  hours: 'horas',
  half_day: 'medio día',
  half_days: 'medios días',
  half_hour: 'mdia hora',
  half_hours: 'medias horas',
  to_translate: '¿Quisiera traducir?',
  how_many_to_translate: '¿Cuántas {páginas/palabras} quiere que se traduzcan?',
  interpretation_price_unit_select_headline: '¿Para cuántas {priceUnit} necesita un intérprete?',
  start_price_calculation: 'Calcule el precio de su traducción ahora!',
  total_amount_net: 'Total neto',
  total_amount: 'Gran total',
  payment: 'Pago',
  choose_payment_method: 'Elije su método de pago',
  confirmation_and_upload: 'Confirmación y envío de datos',
  net_amount: 'Cantidad neta',
  vat_abbr: 'VAT',
  login_successful: 'Ha iniciado sesión con éxito.',
  no_parcel_delivery: 'Lo sentimos, el envio de paquetes no está disponible para su país!',
  order_number: 'Número de orden',
  show_dashboard: 'Mostrar dashboard',
  not_authorized_title: 'Servicio personalizado',
  not_authorized: 'Hemos desarrollado esta solución individual especialmente para uno de nuestros clientes y sólo puede ser comprada por este cliente.  Para más información sobre este servicio, por favor, contacte a lingoking.',
  additional_copy: 'Copia adicional',
  additional_copies: 'Copias adicionales',
  additional_data: 'Información adicional',
  additional_digital_files: 'Archivo(s) digital(es) adicional(es)',
  additional_files_required: '* Se requiere al menos un Archivo Digital Adicional.',
  cart_item_remove_cancel: 'Mantener',
  cart_item_remove_confirm: 'Borrar',
  cart_item_remove_message: '¿Está seguro de que quiere retirar este producto?',
  pay_upon_invoice: 'Pagar Sobre Factura',
  pay_upon_invoice_description: 'Pago pospago de Lingoking exclusivamente a nuestros clientes comerciales.',
  order_reference: 'Su Referencia de Pedido',
  pay_upon_optional_field: "optativo, se mostrará en su factura",
  discount: 'Descuento',
  discount_code: 'Código de Descuento',
  discount_apply: 'Aplicar',
  discount_apply_label: '¿Tiene un código de descuento?',
  discount_remove: 'Eliminar',
  discount_message: 'Si tiene un código de descuento, ingréselo a continuación:',
  discount_already_applied: '¡Descuento ya aplicado! Si desea utilizar un nuevo descuento, elimine el anterior.',
  discount_already_applied_summary: 'Descuento (ya aplicado)',
  discount_not_valid: 'Descuento no encontrado o inactivo. Comprueba el código y vuelve a intentarlo.',
  discount_usage_reached: 'Se alcanzó el número máximo de usos para este código de descuento.',
  discount_not_applicable: 'Este código de descuento no se aplica a ninguno de los productos de su carrito de compras.',
  discount_min_value: 'Para aplicar este descuento debe tener un precio neto mínimo de {value} €.',

  // Product
  price_calculation: 'Calcular el precio',
  your_order: 'Su orden',
  translation_from: 'Traducción de',
  calculate_price: 'Calcular el precio',
  summary: 'Resumen',
  assign_name: 'Déle un nombre a su pedido',
  product_name: 'Nombre del producto',
  recommended: 'recomendados',
  order_name: 'Nombre de orden',
  order_date: 'Fecha de la orden',
  order_name_placeholder: 'Orden 01',
  max_chars: 'max. 50 caracteres',
  upload_after_payment: 'Puede subir el documento después del pago',
  message_to_us: 'Su mensaje para nosotros (opcional)',
  add_to_cart: 'Agregar a la cesta de la compra',
  search_add_topic: 'Buscar y añadir un tema',
  search_add_country: 'Buscar y añadir país',
  search_add_timezone: 'Buscar y añadir zona horaria',
  start_date: 'Fecha de inicio',
  end_date: 'Fecha de terminación',
  date: 'Fecha',
  order_nr: 'Número de orden',
  state: 'Estado',
  not_confirmed_yet: 'aún no confirmado',

  // Chat
  chat_deactivated: 'El chat ha sido desactivado.',
  chat_deactivated_info: 'Si tiene alguna pregunta, lingoking estará encantado de ayudar! Para contactarnos, clic en el botón del mensajero azul en la esquina inferior derecha de esta página. Nos comunicaremos con usted lo antes posible.',
  your_message: 'Su mensaje',
  no_messages: 'No hay mensajes.',
  private_message: 'Mensaje privado',
  private_message_to: 'Mensaje privado a',
  write_a_message: 'Por favor, escriba un mensaje.',

  thanks_for_order: '¡Gracias por su pedido!',
  order: 'Pedido',
  job: 'Trabajo',
  order_created: 'Su pedido ha sido creado. Por favor, suba los archivos necesarios.',
  payment_cancelled: '¡Lo sentimos! Por favor, elija otro método de pago.',
  order_completed_info: 'En su cuenta de cliente puede ver todos los detalles de sus pedidos y gestionar otros ajustes.',

  // CMS Navigation
  user_administration: 'Administración de usuarios',
  settings: 'Ajustes',
  logout: 'Cerrar sesión',
  profile: 'Perfil',
  intranet: 'Intranet',
  help: 'Ayuda',
  messages: 'Mensajes',
  message: 'Mensaje',

  new_order: 'Nuevo Pedido',
  completed_orders: 'Pedidos completados',
  offers: 'Ofertas',
  bills: 'Facturas',
  quick_access: 'Pedir ahora',
  delivery_date: 'Fecha de entrega',
  delivery_date_customer: 'Sube la traducción hasta',
  price: 'Precio',
  total_price: 'Precio total',
  margin: 'Marge',

  first_and_last_name: 'Nombre y apellido',
  street_and_number: 'Calle y número de calle',
  postal_code_and_city: 'Código postal y ciudad',

  recent_jobs: 'Tareas recientes',
  completed_jobs: 'Tareas completados',
  job_requests: 'Solicitudes de trabajo',
  another_linguist_already_accepted_job: '¡Oh, no! El pedido acaba de ser realizado por otra parte, ¡lo sentimos! ¡Seguro que la próxima vez funciona!',
  you_already_accepted_job: 'Ya aceptó este trabajo',
  payments: 'Pagos',

  // ccn
  searchCcn: 'Buscar las notas de crédito',

  // customer order states labels
  order_state_processing: 'Procesando',
  order_state_check: 'Por favor, revise',
  order_state_shipment_pending: 'Envío',
  order_state_done: 'Listo',
  order_state_cancelled: 'Cancelado',
  order_state_delivered: 'Orden entregada',
  order_state_transit: 'Orden en tránsito',
  // lsp job states labels
  lsp_job_state_translation_processing: 'Por favor, traduzca',
  lsp_job_state_corrections_pending: 'Esperando las correcciones',
  lsp_job_state_customer_approval: 'Esperando la aprobación',
  lsp_job_state_qm_approval: 'Esperando la aprobación',
  lsp_job_state_acceptance: 'A la espera de ser aceptada',
  lsp_job_state_review: 'Por favor, revise',
  lsp_job_state_shipment_pending: 'El envío es necesario',
  lsp_job_state_done: 'Listo',
  lsp_job_state_translation_pending: 'Esperando la traducción',
  lsp_job_state_correction_processing: 'Comenzar a corregir.',
  lsp_job_state_approval_pending: 'Esperando la aprobación',
  lsp_job_state_order_cancelled: 'Tarea cancelada',
  lsp_job_state_pending: 'Esperando la aprobación',
  lsp_job_state_cancelled: 'Tarea cancelada',
  // countries
  country_germany: 'Alemania',
  country_switzerland: 'Suiza',
  country_austria: 'Austria',

  // info
  lsp_qm_info: 'Este pedido incluye una gestión de calidad adicional por parte del equipo de lingoking. Esto significa que su traducción no se entrega de forma inmediata y automática al cliente después de subirla, sino que primero se ve revisada por el equipo de gestión de proyectos de lingoking. En caso de necesidades de adaptación o de clarificación, el equipo de PM se pondrá en contacto con usted, no el cliente. Si tiene alguna pregunta al respecto, póngase en contacto con nosotros directamente a través del servicio de mensajería situado en la parte inferior derecha de esta página. No utilice el chat para ponerse en contacto con lingoking, ya que estas notificaciones son visibles para los clientes.',

  // Status
  status_pending : 'Estado: Pendiente.',
  status_rejected: 'Estado: Traducción rechazada.',
  status_shipment_required: 'Estado: se requiere un envío.',
  status_uploaded: 'Estado: Traducción cargada.',
  status_correction_uploaded: 'Estado: corrección cargada.',

  //Status SubTitle
  waiting_corrections_uploads: 'Por favor, espere a que se suban las correcciones.',
  waiting_shipment: 'Esperando el envío.',
  upload_translation_correction: 'Por favor, actualiza tu traducción con las correcciones.',

  translation_rejected_pm_not_satisfied: 'Suba la traducción corregida aquí. La traducción fue rechazada por la siguiente razón: lingoking ha rechazado su traducción después de comprobarla porque son necesarias adaptaciones o cambios.',
  translation_rejected_wait_for_instructions: 'Espere las instrucciones de la gestión de proyectos de lingoking.',

  // Status Content
  rejection_reasons: '<br /><p class="bx--inline-notification__title">Rechazaron la traducción por los siguientes motivos:</p>',
  rejection_reasons_lsp: '<br /><p class="bx--inline-notification__title">La traducción fue rechazada por la siguiente razón:</p>',
  rejection_reason: 'Motivo del rechazo',
  previous_rejection_reasons: 'Motivo del rechazo anteriores',

  // PaymentData validation
  bic_required: 'Se requiere BIC',
  bic_invalid: 'BIC es inválido',
  iban_invalid: 'IBAN es inválido',
  iban_required: 'Se requiere IBAN',

  // Pagination
  items_per_page: 'Elementos por página:',
  of: 'de',
  items: 'Elementos',
  sort_by: 'Ordenar por',
  group_by: 'Agrupar por',

  no_spaces: 'sin espacios',

  // Dunning process
  invoicing_phase: 'invoicing phase',
  reminder1: 'first reminder',
  reminder2: 'second reminder',
  collection_company: 'debt collector',
  lawyer: 'lawyer',
  dunning_status_waiting_description: 'starting state for all invoices; invoice is not yet delivered and sent to customer',
  dunning_status_ongoing_description: 'invoice was sent to customer; payment is in process of collection',
  dunning_status_completed_description: 'payment has successfully been collected',
  dunning_status_unsuccessful_description: 'payment has not been successfully collected and is marked as lost (at any dunning level)',
  dunning_status_canceled_description: 'payment demand has been cancelled (e.g. due to cancelled invoice)',
  dunning_status_paused_description: 'payment demand has been paused',

   //BA
  error_leitweg_id: 'Leitweg-ID is not valid'

};
