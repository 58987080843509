<template>
  <layout-front>
    <div class="lk-login lk-container--inner">
      <div class="lk-login__header bx--row">
        <div class="bx--col-lg-4">
        </div>
        <div class="bx--col-lg-8 lk-login__header-content lk-alignment--right">
          <router-link to="register" class="lk-login__button-label">{{ $t('no_account') }}</router-link>
          <router-link to="register" class="bx--btn bx--btn--tertiary bx--btn--sm lk-login__button">
            {{ $t('register_now') }}
          </router-link>
          <LanguageSelector></LanguageSelector>
        </div>
      </div>
      <div class="lk-login__content lk-login__content--bigmargintop bx--row">
        <div class="bx--col-md-12 bx--col-lg-12 bx--col-xl-10 bx--col-xxl-8 lk-alignment--center lk-margin--center">
          <img alt="Lingoking" class="lk-header__logo" :src="squareLogoLink" width="32"/>
          <h1 class="lk-login__headline">{{ $t('login_header') }}</h1>

          <Loading
            v-if="status.loggingIn"
            class="alert alert-info"
            role="status">
          </Loading>

          <InlineNotification
            v-if="alert.message && alert.type === 'alert-danger'"
            notificationClass="bx--inline-notification bx--inline-notification--error"
            kind="error"
            role="alert"
            icon="lk-carbon-icon lk-carbon-icon-close--glyph"
            :title="$t(alert.message)"
            :subtitle="$t('try_again')"
            :closeButton="true"
          />
          <InlineNotification
            v-if="alert.message && alert.type === 'alert-success'"
            notificationClass="bx--inline-notification bx--inline-notification--success"
            kind="success"
            role="alert"
            icon="lk-carbon-icon lk-carbon-icon-checkmark"
            :title="$t('success')"
            :subtitle="$t(alert.message)"
            :closeButton="true"
          />

          <form class="lk-login-form" @submit.prevent="handleSubmit">
            <div class="bx--form-item bx--text-input-wrapper">
              <input
                v-model.trim="$v.username.$model"
                name="username"
                id="text-input-email"
                type="text"
                class="bx--text-input lk-login-form__field"
                :class="{ 'is-invalid': $v.username.$invalid && submitted}"
                :placeholder="$t('email')"
                v-model="username"
              >
              <div class="bx--form-requirement" v-if="!$v.username.required && $v.username.$dirty">
                {{ $t('email_required') }}
              </div>
              <div class="bx--form-requirement" v-if="$v.username.$invalid && $v.username.$dirty">
                {{ $t('email_valid') }}
              </div>
            </div>
            <div class="bx--form-item bx--text-input-wrapper">
              <input
                v-model.trim="$v.password.$model"
                id="text-input-password"
                type="password"
                class="bx--text-input lk-login-form__field"
                :class="{ 'is-invalid': $v.password.$invalid && $v.password.$dirty }"
                :placeholder="$t('password')" v-model="password"
                name="password"
              >

              <div class="bx--form-requirement" v-if="!$v.password.required && $v.password.$dirty">
                {{ $t('password_required') }}
              </div>
            </div>
            <div class="lk-alignment--right">
              <router-link :to="{name: 'PasswordResetToken'}" class="lk-login-form__link">{{ $t('forgot_password') }}
              </router-link>
            </div>
            <div class="bx--form-item">
              <button
                class="bx--btn bx--btn--primary lk-login-form__submit"
                type="submit"
                :disabled="status.loggingIn || $v.$invalid"
              >
                {{ $t('login') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout-front>
</template>

<script>
import 'lingoking-workbench/src/components/login/login.scss'
import {mapState, mapActions} from 'vuex'
import LayoutFront from './layouts/LayoutFront.vue'
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import logoIcon from '../assets/images/logo_rebranded_icon.png'
import LanguageSelector from "lingoking-workbench/src/components/languageselector/LanguageSelector.vue";
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'

export default {
  validations: {
    username: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(1),
    }
  },
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      squareLogoLink: logoIcon
    }
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    handleSubmit(e) {
      this.$v.$touch()
      this.submitted = true

      if (!this.$v.$invalid) {

        const {username, password} = this
        if (username && password) {
          let routeQuery = this.$route.query
          this.login({username, password, routeQuery})
        }
      }


    },
  },
  components: {
    LayoutFront,
    InlineNotification,
    Loading,
    LanguageSelector
  }
}
</script>

<style scoped>
.is-invalid {
  border-color: red
}

.bx--form-requirement {
  display: block;
  max-height: initial !important;
  color: #e0182d;
}
</style>
