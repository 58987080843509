<template>
  <layout-default>
    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <Loading v-if="isLoading"/>
    <div class="bx--row" v-else>
      <Loading v-if="isUploading || mediaObjectIsLoading"/>
      <div class="bx--col-md-12">
        <div class="bx--row">
          <div class="bx--col-md-12">
            <h1 class="lk-service-backend__headline">{{ $t('current_order') }}</h1>
          </div>
        </div>
        <div
          v-if="item && item.status && jobStatus.CANCELLED === item.status"
          data-notification
          class="bx--inline-notification bx--inline-notification--warning"
          role="alert">
          <div class="bx--toast-notification__details">
            <h3 class="bx--toast-notification__title">{{ $t('job_cancelled') }}</h3><br/>
            <p class="bx--toast-notification__subtitle">{{ $t('job_cancelled_warning') }}</p>
          </div>
        </div>

        <div class="bx--row" v-if="item && item.status && jobStatus.CANCELLED !== item.status">
          <div class="bx--col-md-8">
            <InlineNotification
              v-if="uploadError"
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              :title="$t('error_occurred')"
              :subtitle="$t('try_again')"
              :closeButton="true"
            />
            <InlineNotification
              v-if="uploadUpdated"
              notificationClass="bx--inline-notification bx--inline-notification--success"
              kind="success"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-checkmark"
              :title="$t('success')"
              :subtitle="$t(uploadSuccessMessage)"
              :closeButton="true"
            />
            <JobDetailViewHeader
              cols-left="12"
              :job="item"
              :download-action="downloadAction"
              v-if="item"></JobDetailViewHeader>
            <JobDetailView
              :hideProductDescription="true"
              :job="item"
              :handle-file-upload-change="handleFileUploadChange"
              :download-action="downloadAction"
              :upload-translation-file-label="uploadTranslationFileLabel"
              :download-translation-label="downloadTranslationLabel"
              :uploaded-files-label="uploadedFilesLabel"
              :uploaded-correction-files-label="uploadedCorrectionFilesLabel"
              :send-tracking-code="sendTrackingCode"
              :linguist="linguist"
              :upload-action="uploadTranslatedFiles"
              :should-display-customer-address="shouldDisplayCustomerAddress"
              v-if="item">
            </JobDetailView>
          </div>
          <Loading v-if="additionFileIsUploading"/>
          <div class="bx--col-md-4">
            <div class="bx--row">
              <div class="bx--col-md-12">
                <JobDetailViewChat
                  :messages="messages"
                  :active="chatActive"
                  :admin="false"
                  :users="chatUsers"
                />
                <div class="lk-detailview" v-if="(item.order.additionalCopyEnabled || showOrderAdditionalFiles.length) && jobIsAccepted">
                  <file-upload
                    :clearOnReselect="false"
                    :files="digitalFiles"
                    :handle-change="additionalFilesChange"
                    @change="additionalFilesChange"
                    :max-file-size="25"
                    :multiple="true"
                    :removable="true"
                    :label="$t('additional_digital_files')"
                    button-class="bx--file-btn bx--btn bx--btn--secondary bx--btn--sm"
                    accepted="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                    :button-label="$t('upload_documents')"
                    :id="'order_file_' + item.order.id"
                    invalid-class="bx--form-requirement"
                    :invalid-message="$t('additional_digital_files')"
                    v-if="item.order.additionalCopyEnabled && jobIsAccepted"
                  ></file-upload>
                  <label class="bx--toast-notification__title" v-if="digitalFiles && (digitalFiles.length > 0) ">{{ $t('additional_digital_files') }}</label>
                  <UploadFilesList
                    :showDelete="false"
                    :items="showOrderAdditionalFiles"
                    :download-action="downloadAction">
                  </UploadFilesList>
                  <div v-if="digitalFiles && digitalFiles.length && item.order.additionalCopyEnabled && jobIsAccepted">
                    <button class="bx--file-btn bx--btn bx--btn--primary" @click.prevent="uploadAdditionFilesAction">{{ $t('save') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
import 'lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss'
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'
import {bus} from "lingoking-workbench/src/util/bus.js"

import {mapState, mapGetters, mapActions} from 'vuex'
import LayoutDefault from '../../layouts/LayoutDefault.vue'
import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
import JobDetailViewHeader
  from "lingoking-workbench/src/components/jobdetailview/jobdetailviewheader/JobDetailViewHeader.vue";
import JobDetailView from "lingoking-workbench/src/components/jobdetailview/JobDetailView.vue";
import JobDetailViewChat from "lingoking-workbench/src/components/jobdetailview/JobDetailViewChat.vue";
import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
import contactBoxImage from '../../../../assets/images/logo.jpg';
import FileUpload from 'lingoking-workbench/src/components/fileupload/FileUpload.vue';
import UploadFilesList from 'lingoking-workbench/src/components/linguist/ApplicationDocumentList/UploadFilesList.vue';

import fetch from '../../../utils/fetch'

import JobStatus from "lingoking-workbench/src/enums/JobStatus"
import JobTypes from "lingoking-workbench/src/enums/JobType"

import {ENTRYPOINT} from '../../../config/entrypoint'
import orderStatus from "lingoking-workbench/src/enums/OrderStatus";

export default {
  components: {
    Loading,
    LayoutDefault,
    JobDetailViewHeader,
    JobDetailView,
    JobDetailViewChat,
    InlineNotification,
    FileUpload,
    UploadFilesList
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),
    ...mapGetters({
      item: 'job/show/retrieved',
      error: 'job/show/error',
      isLoading: 'job/show/isLoading',
      linguist: 'linguist/show/retrieved',
      //File Upload
      uploadError: 'job/show/uploadError',
      uploadViolations: 'job/show/uploadViolations',
      uploadUpdated: 'job/show/uploadUpdated',
      isUploading: 'job/show/fileUploadIsLoading',
      trackingCode: 'job/show/trackingCode',
      //File Download
      downloadedMediaObject: 'mediaobject/show/downloaded',
      mediaObjectIsLoading: 'mediaobject/show/isLoading',
      //Additional File
      additionFileIsUploading: 'additionalCopy/create/isLoading',
      additionFileIsCreated: 'additionalCopy/create/created',
      chatUsers: 'chatUsers/list/items',
    }),

    uploadTranslationFileLabel() {
      if (this.item) {
        if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
          return 'upload_translation_here'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
          return 'upload_correction_here'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_FINAL_REVISION) {
          return 'upload_corrected_translation'
        }
      }

      return ''
    },

    downloadTranslationLabel() {
      if (this.item) {
        if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
          return 'translation_to_correct'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
          return 'your_uploaded_translation_to_download'
        }
      }

      return ''
    },

    uploadedFilesLabel() {
      if (this.item) {
        if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
          return 'translation_to_correct'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
          return 'your_uploaded_translation'
        }
      }

      return ''
    },

    uploadedCorrectionFilesLabel() {
      if (this.item) {
        if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
          return 'your_uploaded_correction'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
          return 'corrections_to_download'
        }
      }

      return ''
    },

    uploadSuccessMessage() {
      if (this.item) {
        if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
          return 'upload_correction_success'
        } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
          return 'upload_translation_success'
        }
      }
      return ''
    },

    shouldDisplayCustomerAddress() {
      return !(this.item.order.options.some( option =>
        option.productOption.name === 'email_delivery_method_standard' ||
        option.productOption.name === 'email_delivery_method_express'  ||
        option.productOption.name === 'delivery_certified_scan_only'))
    },

    showOrderAdditionalFiles() {
      if (this.item && this.item.order.orderAdditionalFiles && this.item.order.orderAdditionalFiles.length) {
        return this.item.order.orderAdditionalFiles
      }
      return [];
    },
    jobIsAccepted() {
      if (this.item && this.item.order ) {
        const jobs = this.item.order.jobs
        for (let key in jobs) {
          if (jobs[key].constructor === Object && jobs[key].status === JobStatus.FINISHED && jobs[key].jobDefinition.type === 'customer-approval')
            return true;
        }
        return false;
      }
      return false;
    },
  },
  created() {
    // retrieve job request data
    this.retrieve(decodeURIComponent(this.$route.params.id))
    this.retrieveLinguist(decodeURIComponent('users/' + this.account.user.id))
  },

  beforeDestroy() {
    bus.$off('postMessage')
    this.chatActive = false;
  },

  data() {
    return {
      digitalFiles: [],
      additionalFilesToUpdate: null,
      accepted: null,
      // TODO step 2: read from assigned pm
      contactBox: {
        profileImage: contactBoxImage,
        headline: 'personal_contact_person',
        name: 'Marlies Behrndt',
        position: 'lingoking Support',
        email: 'marlies.behrndt@lingoking.com',
        phone: '+49 (0)89 416 12 20 0'
      },
      apiUrl: ENTRYPOINT,
      messages: [],
      chatActive: false,
      jobStatus: JobStatus,
      messageLoader: () => {
        if (this.chatActive) {
          this.getMessages()
          setTimeout(this.messageLoader, 300000);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      retrieve: 'job/show/retrieve',
      retrieveLinguist: 'linguist/show/retrieve',
      updateRetrieved: 'job/show/updateRetrieved',
      alertSuccess: 'alert/success',
      uploadFiles: 'job/show/upload',
      updateTrackingCode: 'job/show/updateTrackingCode',
      downloadMediaObject: 'mediaobject/show/download',
      uploadAdditionalFiles: 'additionalCopy/create/upload',
      getChatUsers: 'chatUsers/list/getItems',
    }),

    uploadTranslatedFiles: function () {
      let fileUploadForm = null

      if (this.filesToUpload && this.filesToUpload.length) {
        fileUploadForm = new FormData()
        for (let key in this.filesToUpload) {
          if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_TRANSLATOR) {
            fileUploadForm.append('translationTargetFiles[]', this.filesToUpload[key]['file'], this.filesToUpload[key]['filename'])
          } else if (this.item.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR) {
            fileUploadForm.append('translationCorrectionFiles[]', this.filesToUpload[key]['file'], this.filesToUpload[key]['filename'])
          }
        }

        this.uploadFiles({id: this.item['@id'], fileUploadForm: fileUploadForm})
      }
    },

    handleFileUploadChange: function (fieldName, fileList) {
      if (!fileList.length) return

      this.filesToUpload = []
      //let formData = new FormData()
      // append the files to a FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.filesToUpload.push({
            file: fileList[x].file,
            filename: fileList[x].file.name
          })
        })
    },

    sendTrackingCode: function (trackingCode, shipmentCarrier) {
      this.updateRetrieved({'trackingCode': trackingCode})
      this.updateRetrieved({'shipmentCarrier': shipmentCarrier})
      this.updateTrackingCode()
      this.chatActive = false
    },

    downloadAction: function (downloadItem) {
      if (downloadItem) {
        this.downloadMediaObject(downloadItem)
      }
    },

    getMessages: function () {
      if (this.item && this.item.order) {
        const orderId = this.item.order['@id'].split('/').pop();
        const that = this;
        let url = '/retrieve_chat_messages/' + orderId;
        if (this.messages.length !== 0) {
          url += '/' + this.messages[this.messages.length - 1].id
        }
        fetch(url)
          .then((data) => {
            // ~ update messages only if there was a change
            if (data.messages && (that.messages.length === 0 ||
              that.messages[that.messages.length - 1].id !== data.messages[data.messages.length - 1].id)) {
              that.messages = data.messages;
            }
          })
          .catch((e) => {
            console.error(e)
          });
      }
    },

    postMessage: function (message, receiver) {
      if (!message) return;
      const that = this;
      if (this.item && this.item.order) {

        const orderId = this.item.order['@id'].split('/').pop();
        let url = '/post_chat_message/' + orderId;
        if (receiver) {
          url += '/' + receiver.id
        }
        let formData = new FormData();
        formData.append('message', message);
        fetch(url,
          {method: 'POST', data: formData})
          .then((data) => {
            that.messages = data.messages;
          })
          .catch((e) => {
            console.error(e)
          });
      }
    },

    handleUpdateField: function (field, value) {
      Object.assign(this.additionalFilesToUpdate, { [field]: value });
    },

    additionalFilesChange(fieldName, fileList) {
      if(this.item && this.item.order) {
        this.handleUpdateField('orderAdditionalFile', this.digitalFiles);
        this.additionalFiles = fileList;
      } else {
        this.additionalFiles = [];
        this.handleUpdateField('orderAdditionalFile', null);
      }
    },
    uploadAdditionFilesAction(){
      if(this.additionalFiles && this.additionalFiles.length) {
        this.handleUpdateField('orderAdditionalFile', this.additionalFiles)
        let fileUploadForm = new FormData();
        for (let key in this.additionalFilesToUpdate['orderAdditionalFile']) {
          if (this.additionalFilesToUpdate['orderAdditionalFile'].hasOwnProperty(key)) {
            fileUploadForm.append(
              'orderAdditionalFile[]',
              this.additionalFilesToUpdate['orderAdditionalFile'][key]['file'],
              this.additionalFilesToUpdate['orderAdditionalFile'][key]['filename']
            );
          }
        }
        fileUploadForm.append('order', this.additionalFilesToUpdate['id']);
        this.uploadAdditionalFiles({ fileUploadForm: fileUploadForm});
      } else {
        this.handleUpdateField('orderAdditionalFile', null);
      }
    },
  },

  watch: {
    item: function (item) {
      if (item && item.order) {
        this.getChatUsers(item.order.id);
        this.getMessages();
        // TODO: check if the chat is still active && time
        // this.chatActive = item.status !== JobStatus.FINISHED;
        this.chatActive = item.order.status !== orderStatus.ORDER_DELIVERED.schema && item.jobDefinition.type !== JobTypes.JOB_TYPE_CORRECTOR;
        setTimeout(this.messageLoader, 300000);

        this.additionalFilesToUpdate = {
          id: item.order.id,
          orderName: item.order.orderName,
          '@id': item.order['@id']
        };
      }
    },

    additionFileIsCreated: function (item) {
      this.retrieve(decodeURIComponent(this.$route.params.id))
    },

    chatActive: function (chatActive) {
      if (chatActive) {
        bus.$on('postMessage', this.postMessage);
      }
    },

    error: function (error) {
      if (!error) {
        return
      }

      if (error && error === 'Not Found') {
        this.$router.push({name: 'JobList'})
      }
    },

    uploadUpdated: function (uploadUpdated) {
      if (uploadUpdated) {
        this.$scrollTo('body')
        this.retrieve(decodeURIComponent(this.$route.params.id))
      }
    },

    trackingCode: function (trackingCode) {
      if (trackingCode) {
        // Go to order compelted list view TODO
        this.$router.push({name: 'JobList'})
        setTimeout(() => {
          // display success message after route change completes
          this.alertSuccess('tracking_code_saved', {root: true})
        })
      }
    }
  }
};
</script>
