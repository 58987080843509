import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    retrieved: null,
    fileUploadIsLoading: false,
    uploadError: '',
    uploadViolations: null,
    uploadUpdated: null,
    acceptedOrRejected: null,
    trackingCode: null,
    shipmentCarrier: null,
    projectManager: null,
  },
  actions,
  getters,
  mutations
}
