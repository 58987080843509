import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const retrieve = ({ commit }, id) => {
  commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)
      commit(types.MEDIAOBJECT_SHOW_SET_RETRIEVED, data)
    })
    .catch((e) => {
      commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)
      commit(types.MEDIAOBJECT_SHOW_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.MEDIAOBJECT_SHOW_RESET)
}


export const download = ({ commit }, { resourceId, type, name }) => {
  commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)

  return fetch(resourceId, { responseType: "blob"})
    .then((blob) => {
      commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)
      commit(types.MEDIAOBJECT_SHOW_SET_DOWNLOADED, 'downloaded')

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => {
      commit(types.MEDIAOBJECT_SHOW_TOGGLE_LOADING)
      commit(types.MEDIAOBJECT_SHOW_SET_ERROR, e.message)
    })
}
