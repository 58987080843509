import UserTypes from "lingoking-workbench/src/enums/UserTypes";
import {INTERCOM_APP_ID, SHOP_BACKEND_URL} from '../../../config/entrypoint'

const intercomSettings = {
  app_id: INTERCOM_APP_ID
}
const  actions = {
  registerPolNumber({ commit }, data) {
    commit('setPolNumber', data)
  },
  registerJobName({ commit }, data) {
    commit('setJobName', data)
  },
  registerProductName({ commit }, data) {
    commit('setProductName', data)
  },
  registerOrderNumber({ commit }, data) {
    commit('setOrderNumber', data)
  },
  registerCustomerSettings({ commit }, obj) {
    commit('setCustomerSettings', obj)
  },
  registerJobFee({ commit }, data) {
    commit('setJobFee', data)
  },
  registerOrderAmount({ commit }, data) {
  commit('setOrderAmount', data)
  }
}
 const mutations = {
  setCustomerSettings: (state, obj) => {
    let profileUrl = SHOP_BACKEND_URL;
    profileUrl = (obj.userType === UserTypes.TYPE_LINGUIST) ? (profileUrl + '/linguists/show/%2Flinguists%2F'+ obj.id) : ((obj.userType === null) ? null : (profileUrl + '/customers/show/%2Fcustomers%2F'+ obj.id))
    state.intercomSettings.email = (obj && obj.email) || null;
    state.intercomSettings.user_id = (obj && obj.id) || null;
    state.intercomSettings.name = (obj && (obj.firstName + ' '+ obj.lastName)) || null;
    state.intercomSettings.userTitle = (obj && (obj.userTitle === 'mr' ? 'Herr' : (obj.userTitle === 'mrs' ? 'Frau' : null))) || null;
    state.intercomSettings.userCountry = (obj && obj.billingAddress && obj.billingAddress.addressCountry) || null;
    state.intercomSettings.userType = (obj && obj.userType) || null;
    state.intercomSettings.userProfile = (obj && profileUrl) || null;
    state.intercomSettings.created_at = (obj && obj.created_at) || null;
    state.intercomSettings.companyName = (obj && ((obj.userType === UserTypes.TYPE_CUSTOMER_BUSINESS) && obj.company ? obj.company.name : null)) || null;
    state.intercomSettings.telephoneNumber = (obj && obj.telephone) || null;
    state.intercomSettings.polNumber = (obj && obj.polNumber) || null;
  },
   setPolNumber: (state, polNumber) => state.intercomSettings.polNumber = polNumber || null,
   setOrderNumber: (state, orderNumber) => state.intercomSettings.orderNumber = orderNumber || null,
   setJobName: (state, jobName) => state.intercomSettings.jobName = jobName || null,
   setProductName: (state, productName) => state.intercomSettings.productName = productName || null,
   setJobFee: (state, jobFee) => state.intercomSettings.jobFee = jobFee || null,
   setOrderAmount: (state, orderAmount) => state.intercomSettings.orderAmount = orderAmount || null,
   }
 const getters= {
  allSettings: (state) => state.intercomSettings
 }
export default {
  namespaced: true,
  state: {
    intercomSettings: intercomSettings
  },
  actions,
  mutations,
  getters
};
