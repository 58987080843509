<template>
    <div>
        <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-md-12">
              <h2 class="lk-form__step-headline">{{ $t('application_documents') }}</h2>

              <!-- CV START -->
                <fieldset>
                    <legend>{{ $t('curriculum_vitae') }} *</legend>

                  <div class="bx--row">
                    <div class="lk-form-desc bx--col-md-3">
                      {{ $t('upload_curriculum_vitae') }}
                    </div>
                        <div class="bx--col-md-9">
                            <div class="bx--form-item">
                                <file-upload
                                        :clearOnReselect="false"
                                        :files="cvFiles"
                                        :handle-change="cvChange"
                                        :invalid="$v.item.curriculumVitaes.$error"
                                        :invalid-message="$t('upload_curriculum_vitae')"
                                        :max-file-size="25"
                                        :multiple="true"
                                        :removable="true"
                                        accepted="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                        :button-label="$t('select_files')"
                                        :description="$t('documents_allowed')"
                                        id="lsp_cvs"
                                        invalid-class="bx--form-requirement"
                                        :label="$t('curriculum_vitae')"></file-upload>

                              <UploadFilesList
                                :removeTitle="config.remove_title"
                                :remove="removeCV"
                                :items="itemCurriculumVitaes"
                                :download-action="downloadAction">
                              </UploadFilesList>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- CV END -->


                <!-- REFERENCE START -->
                <fieldset>
                    <legend>{{ $t('references') }} *</legend>

                    <div class="bx--row">
                        <div class="lk-form-desc bx--col-md-3">
                            {{$t('upload_references')}}
                        </div>
                        <div class="bx--col-md-9">
                            <div class="bx--form-item bx--text-input-wrapper">

                                <file-upload
                                        :clearOnReselect="false"
                                        :files="referencesFiles"
                                        :handle-change="referencesChange"
                                        :invalid="$v.item.references.$error"
                                        :max-file-size="25"
                                        :multiple="true"
                                        :removable="true"
                                        accepted="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                        :button-label="$t('select_files')"
                                        :description="$t('documents_allowed')"
                                        id="lsp_references"
                                        invalid-class="bx--form-requirement"
                                        :invalid-message="$t('upload_references')"
                                        :label="$t('references')"></file-upload>
                              <UploadFilesList
                                :removeTitle="config.upload_remove_title"
                                :remove="removeReference"
                                :items="itemReferences"
                                :download-action="downloadAction">
                              </UploadFilesList>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- REFERENCE END -->
            </div>
        </div>
    </div>
</template>

<script>
  import '../form/form.scss'
  import FileUpload from './../upload/FileUpload.vue'
  import UploadFilesList from "./ApplicationDocumentList/UploadFilesList.vue"

  export default {
    name: 'ApplicationDocumentsForm',
    components: {
      FileUpload,
      UploadFilesList
    },
    props: {
      config: {
          type: Object,
          required: true
      },
      $v: {
        type: Object,
        required: true
      },
      cvChange: {
        type: Function,
        required: true
      },

      referencesChange: {
        type: Function,
        required: true
      },
      removeCV: {
        type: Function,
        required: true
      },
      removeReference: {
        type: Function,
        required: true
      },
      cvFiles: {
        type: Array,
        required: true
      },

      referencesFiles: {
        type: Array,
        required: true
      },

      cvs: {
        type: Array,
        required: true
      },

      references: {
        type: Array,
        required: true
      },

      downloadAction: {
        type: Function
      }
    },

    computed: {
      invalidMessageCVs () {
        // TODO
        return '* At least one curriculum vitae document is required.'
      },

      itemCurriculumVitaes () {
        return this.cvs || []
      },

      itemReferences () {
        return this.references || []
      }
    }
  }
</script>
