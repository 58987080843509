import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({ commit }, values) => {
  commit(types.MEDIAOBJECT_CREATE_SET_ERROR, '')
  commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)

  return fetch('translation_files', {
    method: 'POST',
    data: values
  })
    .then((response) => {
      commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)

      return response
    })
    .then((data) => {
      commit(types.MEDIAOBJECT_CREATE_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.MEDIAOBJECT_CREATE_TOGGLE_LOADING)
      commit(types.MEDIAOBJECT_CREATE_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.MEDIAOBJECT_CREATE_RESET)
}
