<template>
    <layout-front>
        <div class="lk-login lk-container--inner">
            <div class="lk-login__header bx--row">
                <div class="bx--col-lg-4">
                </div>
                <div class="bx--col-lg-8 lk-login__header-content lk-alignment--right">
                  <router-link to="register" class="lk-login__button-label">{{$t('no_account')}}</router-link>
                  <router-link to="register" class="bx--btn bx--btn--tertiary bx--btn--sm lk-login__button">{{$t('register_now')}}</router-link>
                    <LanguageSelector></LanguageSelector>
                </div>
            </div>
            <div class="lk-login__content lk-login__content--bigmargintop bx--row">
                <div class="bx--col-md-12 bx--col-lg-12 bx--col-xl-10 bx--col-xxl-5 lk-alignment--center lk-margin--center">
                    <h1 class="lk-login__headline">{{$t('reset_password')}}</h1>
                    <h2 class="lk-login__sub-headline">{{$t('enter_email')}}</h2>

                    <Loading
                        v-if="isLoading"
                        class="alert alert-info"
                        role="status">
                    </Loading>

                    <InlineNotification
                        v-if="error"
                        notificationClass="bx--inline-notification bx--inline-notification--error"
                        kind="error"
                        role="alert"
                        icon="lk-carbon-icon lk-carbon-icon-close--glyph"
                        :title="error"
                        :subtitle="$t('try_again')"
                        :closeButton="true"
                    />

                    <form class="lk-login-form" @submit.prevent="handleSubmit">
                        <div  class="bx--form-item bx--text-input-wrapper">
                            <label for="text-input-email" class="bx--label lk-login-form__label">{{$t('your_email')}}</label>
                            <input
                              id="text-input-email"
                              type="text"
                              :placeholder="$t('email')"
                              class="bx--text-input lk-login-form__field"
                              :class="{ 'is-invalid': isLoading && !email }"
                              :data-invalid="$v.email.$error"
                              v-model="$v.email.$model"
                              name="email">
                            <div class="bx--form-requirement"
                                  v-if="!$v.email.required">
                              {{$t('email_required')}}
                            </div>
                            <div class="bx--form-requirement"
                                  v-if="!$v.email.email">
                              {{$t('email_unvalid')}}
                            </div>
                        </div>
                        <div class="bx--form-item">
                            <button class="bx--btn bx--btn--primary lk-login-form__submit" type="submit" :disabled="isLoading">{{$t('forgot_password')}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </layout-front>
</template>

<script>
  import 'lingoking-workbench/src/components/login/login.scss'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { default as validations } from '../store/modules/password/token/validations';
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import LayoutFront from './layouts/LayoutFront.vue'
  import LanguageSelector from "lingoking-workbench/src/components/languageselector/LanguageSelector.vue";

  export default {
    data () {
      return {
        email: null
      }
    },

    computed: {
      ...mapGetters({
        isLoading: 'password/token/isLoading',
        error: 'password/token/error',
        created: 'password/token/created',
        violations: 'password/token/violations'
      })
    },

    beforeDestroy() {
      this.reset()
    },

    methods: {
      ...mapActions({
        tokenCreate: 'password/token/create',
        tokenReset: 'password/token/reset',
        alertSuccess: 'alert/success'
      }),
      handleSubmit (e) {
        const { email } = this

        // run validation at least once
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.tokenCreate({ email })
        }
      },
      reset() {
        this.tokenReset()
      }
    },

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }

            this.$router.push({ name: 'Login' })

            setTimeout(() => {
                // display success message after route change completes
                this.alertSuccess('password_reset_success', { root: true });
            })
        }
    },

    validations() {
      return validations
    },

    components: {
      LayoutFront,
      Loading,
      InlineNotification,
      LanguageSelector
    }
  };
</script>
