
export default [
  {
    label: 'dashboard',
    name: 'Dashboard',
    menuIconClass: 'lk-be-icon lk-be-icon-dashboard lk-backend-menu__icon',
  },
  {
    label: 'recent_jobs',
    link: '#',
    name: 'JobList',
    menuIconClass: 'lk-be-icon lk-be-icon-jobs lk-backend-menu__icon',
  },
  {
    label: 'completed_jobs',
    link: '#',
    name: 'JobsCompleted',
    menuIconClass: 'lk-be-icon lk-be-icon-archive lk-backend-menu__icon',
  },
  {
    label: 'job_requests',
    link: '#',
    name: 'OfferList',
    menuIconClass: 'lk-be-icon lk-be-icon-requests lk-backend-menu__icon',
  },
  {
    label: 'payments',
    link: '#',
    name: 'CreditNoteList',
    menuIconClass: 'lk-be-icon lk-be-icon-payments lk-backend-menu__icon',
  }
]
