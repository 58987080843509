import update from './update'
import show from './show'
import unsubscribeFeedbackEmail from './unsubscribeFeedbackEmail'

export default {
  namespaced: true,
  modules: {
    update,
    show,
    unsubscribeFeedbackEmail
  }
}
