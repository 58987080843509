<template>
  <div>
    <p class="lk-detailview__subheadline"
       v-html="job.order.product.description"
       v-if="!hideProductDescription && job.order.product.description"></p>

    <Translator
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_TRANSLATOR"
      :job="job"
      :linguist="linguist"
      :handle-file-upload-change="handleFileUploadChange"
      :download-action="downloadAction"
      :upload-action="uploadAction"
      :get-translation-files="getTranslationFiles"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles"
      :product-options-job-notes="productOptionsJobNotes" />

    <Corrector
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_CORRECTOR"
      :job="job"
      :linguist="linguist"
      :handle-file-upload-change="handleFileUploadChange"
      :download-action="downloadAction"
      :upload-action="uploadAction"
      :get-translation-files="getTranslationFiles"
      :get-translation-target-file-downloads="getTranslationTargetFileDownloads"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles"
      :product-options-job-notes="productOptionsJobNotes" />

    <FinalRevision
      v-if="job.jobDefinition.type === jobTypes.JOB_FINAL_REVISION"
      :job="job"
      :linguist="linguist"
      :handle-file-upload-change="handleFileUploadChange"
      :download-action="downloadAction"
      :upload-action="uploadAction"
      :get-translation-files="getTranslationFiles"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles"
      :product-options-job-notes="productOptionsJobNotes" />

    <QM
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_QM"
      :job="job"
      :linguist="linguist"
      :handle-file-upload-change="handleFileUploadChange"
      :download-action="downloadAction"
      :upload-action="uploadAction"
      :remove-file-action="removeAction"
      :get-translation-files="getTranslationFiles"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles" />

    <Printing
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_PRINTING"
      :job="job"
      :download-action="downloadAction"
      :translation-files="getTranslationFiles()"
      :uploaded-files-label="$t('your_uploaded_translation')"
    />

    <FinalRevisionQM
      v-if="job.jobDefinition.type === jobTypes.JOB_FINAL_REVISION_QM"
      :job="job"
      :linguist="linguist"
      :handle-file-upload-change="handleFileUploadChange"
      :download-action="downloadAction"
      :upload-action="uploadAction"
      :get-translation-files="getTranslationFiles"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles"
      :product-options-job-notes="productOptionsJobNotes" />

    <CustomerApproval
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL"
      :job="job"
      :linguist="linguist"
      :download-action="downloadAction"
      :remove-file-action="removeAction"
      :get-translation-files="getTranslationFiles"
      :get-translation-target-file-downloads="getTranslationTargetFileDownloads"
      :delivery-date="deliveryDate"
      :job-order-quantity="jobOrderQuantity"
      :translation-source-files="translationSourceFiles" />

    <Shipment
      v-if="job.jobDefinition.type === jobTypes.JOB_TYPE_SHIPMENT"
      :job="job"
      :linguist="linguist"
      :should-display-customer-address="displayCustomerAddress" />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'

import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import Translator from "./types/Translator.vue";
import Corrector from "./types/Corrector.vue";
import FinalRevision from "./types/FinalRevision.vue";
import QM from "./types/QM.vue";
import FinalRevisionQM from "./types/FinalRevisionQM.vue";
import CustomerApproval from "./types/CustomerApproval.vue";
import Shipment from "./types/Shipment.vue";
import Printing from "./types/Printing.vue";

import {OrderStatus} from "lingoking-workbench/src/enums";

import JobStatus from 'lingoking-workbench/src/enums/JobStatus'
import JobTypes from "lingoking-workbench/src/enums/JobType";
import PriceUnits from "lingoking-workbench/src/enums/PriceUnits";
import ProductOptionFormType from "lingoking-workbench/src/enums/ProductOptionFormType";

export default {
  name: 'JobDetailView',
  components: {
    CustomerApproval,
    FinalRevisionQM,
    QM,
    FinalRevision,
    Corrector,
    Translator,
    Printing,
    Shipment
  },
  props: {
    job: {
      type: Object
    },
    jobRequest: {
      type: Boolean,
      default: false
    },
    actionButtons: {
      type: Object
    },
    linguist: {
      type: Object
    },
    admin: {
      type: Boolean,
      default: false
    },
    customerAddressLabel: {
      type: String,
      default: 'customer_address'
    },
    hideNativeSpeaker: {
      type: Boolean,
      required: false,
      default: false
    },
    hideProductDescription: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldDisplayCustomerAddress: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    deliveryDate() {
      return this.$moment(this.job.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    jobFee() {
      let fee = this.job.fee ? parseFloat(this.job.fee).toFixed(2) : null

      if (fee) {
        return fee.replace('.', ',') + ' €'
      }

      return null
    },

    jobOrderQuantity() {
      let label

      if (this.job.order.product.priceUnitLabel) {
        label = this.job.order.product.priceUnitLabel
      }

      if (this.job.order.priceUnit === PriceUnits.PRICE_UNIT_PAGE) {
        label = this.job.pagesOrWords > 1 ? 'pages' : 'page'
      }

      if (this.job.order.priceUnit === PriceUnits.PRICE_UNIT_WORD) {
        label = this.job.pagesOrWords > 1 ? 'words' : 'word'
      }

      return [
      {'head': 'amount', 'data': this.job.pagesOrWords + ' ' + this.$t(label)}
      ]
    },

    productOptionsJobNotes() {
      /** get all job notes from product options for current job type **/
      let productOptionJobNotes = []

      if (!this.job.order.options && this.job.order.options.length)
      return productOptionJobNotes

      let self = this

      this.job.order.options.forEach((orderOption) => {

        if (!(orderOption.productOption && orderOption.productOption.jobNotes
        && orderOption.productOption.jobNotes.length)) return

        // JUST RENDER IF ITS A RADIO OPTION OR AN OPTION WITH VALUE
        if (orderOption.productOption.type !== ProductOptionFormType.RADIO.type
        && !orderOption.productOptionValue || orderOption.productOptionValue === "false") return


        orderOption.productOption.jobNotes.forEach((jobNote) => {
          if (jobNote.jobType && (jobNote.jobType === 'all' || jobNote.jobType === self.job.jobDefinition.type) && jobNote.description) {
            productOptionJobNotes.push(jobNote.description)
          }
        })

      })

      return productOptionJobNotes
    },

    displayCustomerAddress() {
      return !(this.job.order.options.some(option =>
      option.productOption.name === 'email_delivery_method_standard' ||
      option.productOption.name === 'email_delivery_method_express' ||
      option.productOption.name === 'delivery_certified_scan_only'))
    },

    translationSourceFiles() {
      /** returns null, if no translation source files exist, or an array with all translation source files **/
      let translationSourceFiles = []

      if (this.job) {
        // if there are more files:
        if (this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length) {
          let self = this
          this.job.order.translationSourceFiles.forEach(function (translationSourceFile) {
            translationSourceFiles.push({
              name: translationSourceFile.name,
              link: self.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
              downloadAction: true,
              resourceId: translationSourceFile['@id'],
              type: 'source-file',
              created_at: translationSourceFile.createdAt,
              scheduledDeletionDate: translationSourceFile.scheduledDeletionDate,
              status: translationSourceFile.status,
            })
          })
        } else if (this.job.order.translationSourceFile) { // only one file:
          translationSourceFiles = [{
            name: this.job.order.translationSourceFile.name,
            link: this.$getDownloadURL(this.job.order.translationSourceFile['@id'], this.job.order.translationSourceFile.name),
            downloadAction: true,
            resourceId: this.job.order.translationSourceFile['@id'],
            type: 'source-file',
            created_at: this.job.order.translationSourceFile.createdAt,
            scheduledDeletionDate: this.job.order.translationSourceFile.scheduledDeletionDate,
            status: this.job.order.translationSourceFile.status,
          }]
        }
      }

      return translationSourceFiles
    },
  },

  methods: {
    ...mapActions({
      uploadFiles: 'job/show/upload',
      downloadMediaObject: 'mediaobject/show/download',
      deleteMediaObject: 'mediaobject/del/del',
      uploadAdditionalFiles: 'additionalCopy/create/upload',
    }),

    getTranslationFiles(type, status) {
      if (this.job) {
        let translationFiles = []
        let type = type

        if (status) {
          if (this.job.status !== status) {
            return []
          }
        }

        if (this.job.order.translationTargetFiles && this.job.order.translationTargetFiles.length) {
          translationFiles = JSON.parse(JSON.stringify(this.job.order.translationTargetFiles));

          const version = Math.max(...translationFiles.map(o => o.version), 0);
          let downloadItems = []

          translationFiles
          .sort((t1, t2) => t1.version > t2.version ? -1 : 1)
          .forEach(targetFile => {
            if (this.admin || targetFile.version === version) {
              downloadItems.push({
                name: targetFile.name,
                link: this.$getDownloadURL(targetFile['@id'], targetFile.name),
                downloadAction: true,
                resourceId: targetFile['@id'],
                created_at: targetFile.createdAt,
                scheduledDeletionDate: targetFile.scheduledDeletionDate,
                status: targetFile.status,
                type: type
              })
            }
          })

          return downloadItems
        }
      }

      return []
    },

    getTranslationTargetFileDownloads(downloadLabel) {
      let self = this
      let targetFiles = this.job.order.translationTargetFiles

      let downloadItems = []
      const version = Math.max(...targetFiles.map(o => o.version), 0);

      targetFiles.forEach(function (targetFile, index) {
        if (targetFile.version === version){
          let downloadItem = {
            downloadLink: self.$getDownloadURL(targetFile['@id'], targetFile.name),
            linkText: targetFile.name,
            downloadAction: true,
            resourceId: targetFile['@id'],
            type: 'target-file',
            name: targetFile['name'],
            created_at: targetFile.createdAt,
            scheduledDeletionDate: targetFile.scheduledDeletionDate,
            status: targetFile.status,
          }
          if(!index && downloadLabel) {
            downloadItem.downloadLabel = downloadLabel
          }
          downloadItems.push(downloadItem)
        }

      })

      return downloadItems
    },

    handleFileUploadChange: function (fieldName, fileList) {
      if (!fileList.length) return

      this.filesToUpload = []
      // append the files to a FormData:
      Array
      .from(Array(fileList.length).keys())
      .map(x => {
        this.filesToUpload.push({
          file: fileList[x].file,
          filename: fileList[x].file.name
        })
      })
    },

    // actions
    downloadAction: function (downloadItem) {
      if (downloadItem) {
        this.downloadMediaObject(downloadItem)
      }
    },

    removeAction: function (translationSourceFile) {
      this.showUploadSuccess = false
      this.deleteMediaObject(translationSourceFile)
    },

    uploadAction: function (name) {
      let fileUploadForm = null

      if (this.filesToUpload && this.filesToUpload.length) {
        fileUploadForm = new FormData()

        for (let key in this.filesToUpload) {
          fileUploadForm.append(name, this.filesToUpload[key]['file'], this.filesToUpload[key]['filename'])
        }

        this.uploadFiles({id: this.job['@id'], fileUploadForm: fileUploadForm})
      }
    },
  },

  data() {
    return {
      jobStatus: JobStatus,
      jobTypes: JobTypes,
      orderStatus: OrderStatus
    }
  },
}
</script>
