import AccountSetupLsp from '../components/account/SetupLsp'
import FinanceCreditNotesList from '../components/finance/lsp/creditnotes/List';

export default [
  {
    name: 'AccountSetupLsp',
    path: '/account/setup-lsp',
    component: AccountSetupLsp,
    meta: {
      resource: 'Account',
      action: 'update',
      lspAuth: true,
      authRequired: true
    }
  },
  {
    name: 'CreditNotesListLsp',
    path: '/credit-notes',
    component: FinanceCreditNotesList,
    meta: {
      customerAuth: true,
      authRequired: true
    }
  }
]
