<template>
  <div>

    <Loading v-if="isLoading"/>

    <button
      :class="btnClass"
      :disabled="disabled"
      @click="download()"
      title="Download"
      alt="Download"
    >
      <slot></slot>
    </button>
  </div>
</template>
<script>
    import './jwtdownloadbutton.scss';
    import {jwtDownload} from '../../util/JwtDownload';
    import {ENTRYPOINT} from './../../apps/config/entrypoint';
    import Loading from './../loading/Loading.vue'

    export default {
        name: "JwtDownloadButton",
        components: {
            Loading
        },
        props: {
            btnClass: {
                type: Array,
                required: false
            },
            downloadUrl: {
                type: String,
                required: true
            },
            downloadName: {
                type: String,
                required: true
            },
            disabled: {
              type: Boolean,
              required: false,
              default: false
            }
        },
        data() {
            return {
                apiUrl: ENTRYPOINT,
                isLoading: false,
                error: false
            }
        },
        methods: {
            download() {
                this.isLoading = true;
                let file = this.apiUrl + this.downloadUrl;
                jwtDownload(file, this.downloadName)
                    .then(success => {
                        this.isLoading = false;
                        this.error = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.error = true;
                    });
            }
        }
    }
</script>
