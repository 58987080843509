import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import SubmissionError from "../../../../error/SubmissionError";

export const retrieve = ({ commit }, id) => {
  commit(types.JOB_SHOW_TOGGLE_LOADING)

  return fetch(id)

    .then((data) => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_RETRIEVED, data)
      commit(types.JOB_SHOW_SET_PM, data.order.partOfInvoice.pm)
    })
    .catch((e) => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.JOB_SHOW_RESET)
}


export const updateRetrieved = ({ commit }, updated) => {
  commit(types.JOB_SHOW_UPDATE_RETRIEVED, updated)
}

export const upload = ({ dispatch, commit }, values) => {
  commit(types.JOB_SET_UPLOAD_ERROR, '')
  commit(types.JOB_TOGGLE_FILE_UPLOAD_LOADING)

  return fetch( values.id + '/upload', { method: 'POST', data: values.fileUploadForm })
    .then((response) => {
      commit(types.JOB_TOGGLE_FILE_UPLOAD_LOADING)

      return response
    })
    .then((data) => {
      commit(types.JOB_SET_UPLOAD_UPDATED, data)
    })
    .catch((e) => {
      commit(types.JOB_TOGGLE_FILE_UPLOAD_LOADING)
      if (e instanceof SubmissionError) {
        commit(types.JOB_SET_UPLOAD_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.JOB_SET_UPLOAD_ERROR, e.errors._error)
        return
      }

      commit(types.JOB_SET_UPLOAD_ERROR, e.message)
    })
}

export const acceptOrReject = ({dispatch, commit, state}) => {
  const callAcceptOrReject = (job) => {
    return fetch(job['@id'] + '/accept_or_reject', {
      method: 'PUT',
      headers: {'Content-Type': 'application/ld+json'},
      data: JSON.stringify(job)
    })
  }

  const callUpload = (job, file) => {
    let fileUploadForm = new FormData()
    fileUploadForm.append('translationCorrectionFiles[]', file, file.filename)

    return fetch(job['@id'] + '/upload', {method: 'POST', data: fileUploadForm})
  }

  commit(types.JOB_SHOW_TOGGLE_LOADING)

  let job = Object.assign({}, state.retrieved)

  function executeCalls() {
    return new Promise((resolve, reject) => {
      let file = job.file
      delete job.file

      callAcceptOrReject(job)
        .then(response => {
          if (!file) {
            return resolve(response)
          }
          callUpload(job, file)
            .then(() => resolve(response))
            .catch(reject)
        })
        .catch(reject)
    })
  }

  executeCalls()
    .then((data) => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_ACCEPTED_OR_REJECTED, data)
    })
    .catch(e => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_ERROR, e.message)
    });
}

export const updateTrackingCode = ({ commit, state }) => {
  commit(types.JOB_SHOW_TOGGLE_LOADING)

  return fetch(state.retrieved['@id'] + '/update_tracking_code', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/ld+json' },
    data: JSON.stringify(state.retrieved)
  })

    .then((data) => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_TRACKING_CODE, data)
    })
    .catch((e) => {
      commit(types.JOB_SHOW_TOGGLE_LOADING)
      commit(types.JOB_SHOW_SET_ERROR, e.message)
    })
}
