const Timezones = [
  {
    "label":"Europe/Belfast",
    "timezone":"(GMT) Greenwich Mean Time : Belfast"
  },
  {
    "label":"Europe/Dublin",
    "timezone":"(GMT) Greenwich Mean Time : Dublin"
  },
  {
    "label":"Europe/Lisbon",
    "timezone":"(GMT) Greenwich Mean Time : Lisbon"
  },
  {
    "label":"Europe/London",
    "timezone":"(GMT) Greenwich Mean Time : London"
  },
  {
    "label":"Africa/Abidjan",
    "timezone":"(GMT) Monrovia, Reykjavik"
  },
  {
    "label":"Europe/Amsterdam",
    "timezone":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
  },
  {
    "label":"Europe/Belgrade",
    "timezone":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
  },
  {
    "label":"Europe/Brussels",
    "timezone":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"
  },
  {
    "label":"Africa/Algiers",
    "timezone":"(GMT+01:00) West Central Africa"
  },
  {
    "label":"Africa/Windhoek",
    "timezone":"(GMT+01:00) Windhoek"
  },
  {
    "label":"Asia/Beirut",
    "timezone":"(GMT+02:00) Beirut"
  },
  {
    "label":"Africa/Cairo",
    "timezone":"(GMT+02:00) Cairo"
  },
  {
    "label":"Asia/Gaza",
    "timezone":"(GMT+02:00) Gaza"
  },
  {
    "label":"Africa/Blantyre",
    "timezone":"(GMT+02:00) Harare, Pretoria"
  },
  {
    "label":"Asia/Jerusalem",
    "timezone":"(GMT+02:00) Jerusalem"
  },
  {
    "label":"Europe/Minsk",
    "timezone":"(GMT+02:00) Minsk"
  },
  {
    "label":"Asia/Damascus",
    "timezone":"(GMT+02:00) Syria"
  },
  {
    "label":"Europe/Moscow",
    "timezone":"(GMT+03:00) Moscow, St. Petersburg, Volgograd"
  },
  {
    "label":"Africa/Addis_Ababa",
    "timezone":"(GMT+03:00) Nairobi"
  },
  {
    "label":"Asia/Tehran",
    "timezone":"(GMT+03:30) Tehran"
  },
  {
    "label":"Asia/Dubai",
    "timezone":"(GMT+04:00) Abu Dhabi, Muscat"
  },
  {
    "label":"Asia/Yerevan",
    "timezone":"(GMT+04:00) Yerevan"
  },
  {
    "label":"Asia/Kabul",
    "timezone":"(GMT+04:30) Kabul"
  },
  {
    "label":"Asia/Yekaterinburg",
    "timezone":"(GMT+05:00) Ekaterinburg"
  },
  {
    "label":"Asia/Tashkent",
    "timezone":"(GMT+05:00) Tashkent"
  },
  {
    "label":"Asia/Kolkata",
    "timezone":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
  },
  {
    "label":"Asia/Katmandu",
    "timezone":"(GMT+05:45) Kathmandu"
  },
  {
    "label":"Asia/Dhaka",
    "timezone":"(GMT+06:00) Astana, Dhaka"
  },
  {
    "label":"Asia/Novosibirsk",
    "timezone":"(GMT+06:00) Novosibirsk"
  },
  {
    "label":"Asia/Rangoon",
    "timezone":"(GMT+06:30) Yangon (Rangoon)"
  },
  {
    "label":"Asia/Bangkok",
    "timezone":"(GMT+07:00) Bangkok, Hanoi, Jakarta"
  },
  {
    "label":"Asia/Krasnoyarsk",
    "timezone":"(GMT+07:00) Krasnoyarsk"
  },
  {
    "label":"Asia/Hong_Kong",
    "timezone":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
  },
  {
    "label":"Asia/Irkutsk",
    "timezone":"(GMT+08:00) Irkutsk, Ulaan Bataar"
  },
  {
    "label":"Australia/Perth",
    "timezone":"(GMT+08:00) Perth"
  },
  {
    "label":"Australia/Eucla",
    "timezone":"(GMT+08:45) Eucla"
  },
  {
    "label":"Asia/Tokyo",
    "timezone":"(GMT+09:00) Osaka, Sapporo, Tokyo"
  },
  {
    "label":"Asia/Seoul",
    "timezone":"(GMT+09:00) Seoul"
  },
  {
    "label":"Asia/Yakutsk",
    "timezone":"(GMT+09:00) Yakutsk"
  },
  {
    "label":"Australia/Adelaide",
    "timezone":"(GMT+09:30) Adelaide"
  },
  {
    "label":"Australia/Darwin",
    "timezone":"(GMT+09:30) Darwin"
  },
  {
    "label":"Australia/Brisbane",
    "timezone":"(GMT+10:00) Brisbane"
  },
  {
    "label":"Australia/Hobart",
    "timezone":"(GMT+10:00) Hobart"
  },
  {
    "label":"Asia/Vladivostok",
    "timezone":"(GMT+10:00) Vladivostok"
  },
  {
    "label":"Australia/Lord_Howe",
    "timezone":"(GMT+10:30) Lord Howe Island"
  },
  {
    "label":"Etc/GMT-11",
    "timezone":"(GMT+11:00) Solomon Is., New Caledonia"
  },
  {
    "label":"Asia/Magadan",
    "timezone":"(GMT+11:00) Magadan"
  },
  {
    "label":"Pacific/Norfolk",
    "timezone":"(GMT+11:30) Norfolk Island"
  },
  {
    "label":"Asia/Anadyr",
    "timezone":"(GMT+12:00) Anadyr, Kamchatka"
  },
  {
    "label":"Pacific/Auckland",
    "timezone":"(GMT+12:00) Auckland, Wellington"
  },
  {
    "label":"Etc/GMT-12",
    "timezone":"(GMT+12:00) Fiji, Kamchatka, Marshall Is."
  },
  {
    "label":"Pacific/Chatham",
    "timezone":"(GMT+12:45) Chatham Islands"
  },
  {
    "label":"Pacific/Tongatapu",
    "timezone":"(GMT+13:00) Nuku\\'alofa"
  },
  {
    "label":"Pacific/Kiritimati",
    "timezone":"(GMT+14:00) Kiritimati}"
  },
  {
    "label":"Pacific/Midway",
    "timezone":"(GMT-11:00) Midway Island, Samoa"
  },
  {
    "label":"America/Adak",
    "timezone":"(GMT-10:00) Hawaii-Aleutian"
  },
  {
    "label":"Etc/GMT+10",
    "timezone":"(GMT-10:00) Hawaii"
  },
  {
    "label":"Pacific/Marquesas",
    "timezone":"(GMT-09:30) Marquesas Islands"
  },
  {
    "label":"Pacific/Gambier",
    "timezone":"(GMT-09:00) Gambier Islands"
  },
  {
    "label":"America/Anchorage",
    "timezone":"(GMT-09:00) Alaska"
  },
  {
    "label":"America/Ensenada",
    "timezone":"(GMT-08:00) Tijuana, Baja California"
  },
  {
    "label":"Etc/GMT+8",
    "timezone":"(GMT-08:00) Pitcairn Islands"
  },
  {
    "label":"America/Los_Angeles",
    "timezone":"(GMT-08:00) Pacific Time (US & Canada)"
  },
  {
    "label":"America/Denver",
    "timezone":"(GMT-07:00) Mountain Time (US & Canada)"
  },
  {
    "label":"America/Chihuahua",
    "timezone":"(GMT-07:00) Chihuahua, La Paz, Mazatlan"
  },
  {
    "label":"America/Dawson_Creek",
    "timezone":"(GMT-07:00) Arizona"
  },
  {
    "label":"America/Belize",
    "timezone":"(GMT-06:00) Saskatchewan, Central America"
  },
  {
    "label":"America/Cancun",
    "timezone":"(GMT-06:00) Guadalajara, Mexico City, Monterrey"
  },
  {
    "label":"Chile/EasterIsland",
    "timezone":"(GMT-06:00) Easter Island"
  },
  {
    "label":"America/Chicago",
    "timezone":"(GMT-06:00) Central Time (US & Canada)"
  },
  {
    "label":"America/New_York",
    "timezone":"(GMT-05:00) Eastern Time (US & Canada)"
  },
  {
    "label":"America/Havana",
    "timezone":"(GMT-05:00) Cuba"
  },
  {
    "label":"America/Bogota",
    "timezone":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco"
  },
  {
    "label":"America/Caracas",
    "timezone":"(GMT-04:30) Caracas"
  },
  {
    "label":"America/Santiago",
    "timezone":"(GMT-04:00) Santiago"
  },
  {
    "label":"America/La_Paz",
    "timezone":"(GMT-04:00) La Paz"
  },
  {
    "label":"Atlantic/Stanley",
    "timezone":"(GMT-04:00) Faukland Islands"
  },
  {
    "label":"America/Campo_Grande",
    "timezone":"(GMT-04:00) Brazil"
  },
  {
    "label":"America/Goose_Bay",
    "timezone":"(GMT-04:00) Atlantic Time (Goose Bay)"
  },
  {
    "label":"America/Glace_Bay",
    "timezone":"(GMT-04:00) Atlantic Time (Canada)"
  },
  {
    "label":"America/St_Johns",
    "timezone":"(GMT-03:30) Newfoundland"
  },
  {
    "label":"America/Araguaina",
    "timezone":"(GMT-03:00) UTC-3"
  },
  {
    "label":"America/Montevideo",
    "timezone":"(GMT-03:00) Montevideo"
  },
  {
    "label":"America/Miquelon",
    "timezone":"(GMT-03:00) Miquelon, St. Pierre"
  },
  {
    "label":"America/Godthab",
    "timezone":"(GMT-03:00) Greenland"
  },
  {
    "label":"America/Argentina/Buenos_Aires",
    "timezone":"(GMT-03:00) Buenos Aires"
  },
  {
    "label":"America/Sao_Paulo",
    "timezone":"(GMT-03:00) Brasilia"
  },
  {
    "label":"America/Noronha",
    "timezone":"(GMT-02:00) Mid-Atlantic"
  },
  {
    "label":"Atlantic/Cape_Verde",
    "timezone":"(GMT-01:00) Cape Verde Is."
  },
  {
    "label":"Atlantic/Azores",
    "timezone":"(GMT-01:00) Azores"
  },
]

export default Timezones
