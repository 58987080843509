<template>
  <div class="lk-detailview">
    <h5 class="lk-detailview__subheadline" v-if="job.jobDefinition.type !== jobTypes.JOB_TYPE_CUSTOMER_APPROVAL && (job.name || job.jobName)">{{ job.jobName ? job.jobName : job.name }}</h5>
    <div class="lk-detailview__headline-wrapper">
      <h4 class="lk-detailview__headline">{{ job.order.product.name }}</h4>
      <span class="lk-detailview__details">{{ job.order.partOfInvoice && job.order.partOfInvoice.confirmationNumber && !linguist ? job.order.partOfInvoice.confirmationNumber : this.job.purchaseOrderNumber }}</span>
    </div>
    <p class="lk-detailview__subheadline" v-html="job.order.product.description" v-if="!hideProductDescription && job.order.product.description"></p>

    <!-- Job Messages START -->
    <JobDetailViewSection
      :headline="'Name'"
      :text="job.order.orderName"
      v-if="job.order.orderName">
    </JobDetailViewSection>
    <!-- Job Messages END -->

    <!-- Product Notes START -->
    <JobDetailViewSection
      :headline="$t('notes')"
      :border="!productOptionsJobNotes.length"
      :text="job.order.product.note"
      v-if="!isLinguistJob && job.order.product.note">
    </JobDetailViewSection>
    <!-- Product Notes END -->

    <!-- Product Job Notes START -->
    <JobDetailViewSection
      :headline="$t('notes')"
      :border="!productOptionsJobNotes.length"
      :text="job.order.product.jobNotes[0]['description']"
      v-if="isLinguistJob && job.order.product.jobNotes && job.order.product.jobNotes.length">
    </JobDetailViewSection>
    <!-- Product Job Notes END -->

    <!-- Product Option Job Notes START -->
    <JobDetailViewSection
      :border="index === (productOptionsJobNotes.length-1)"
      :text="productOptionJobNote"
      v-for="(productOptionJobNote, index) in productOptionsJobNotes"
      :key="'option-job-note-' + index"
      v-if="isLinguistJob && productOptionsJobNotes && productOptionsJobNotes.length">
    </JobDetailViewSection>
    <!-- Product Option Job Notes END -->

    <!-- Job Messages START -->
    <JobDetailViewSection
      :headline="$t('customer_notes')"
      :border="true"
      :text="job.order.message"
      v-if="job.order.message">
    </JobDetailViewSection>
    <!-- Job Messages END -->

    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('requirements')"
      :table="jobRequirements">
    </JobDetailViewSection>
    <JobDetailViewSection
      v-if="job.jobDefinition"
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <!-- Kundenanschrift START -->
    <JobDetailViewSection
      :headline="$t('customer_address')"
      :status="{ type: 'warning', text: $t('for_your_information'), subTitle: $t('address_info')}"
      :table="basicCustomerAddress" v-if="jobRequest && shouldDisplayCustomerAddress">
    </JobDetailViewSection>
    <JobDetailViewSection
      :headline="$t('customer_address')"
      :table="customerAddress" v-else-if="((job.jobDefinition.type === jobTypes.JOB_TYPE_TRANSLATOR && job.status === jobStatus.JOB_STATUS_SHIPMENT)
      || (job.jobDefinition.type === jobTypes.JOB_TYPE_TRANSLATOR && job.status === jobStatus.FINISHED) || job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL) && shouldDisplayCustomerAddress">
    </JobDetailViewSection>
    <JobDetailViewSection
      :headline="$t('customer_address')"
      :table="basicCustomerAddress" v-else-if="job.jobDefinition.type === jobTypes.JOB_TYPE_TRANSLATOR && shouldDisplayCustomerAddress">
    </JobDetailViewSection>
    <!--Kundenanschrift END-->

    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD START -->
    <JobDetailViewSection
      :border="!(job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL && job.order.status === orderStatus.ORDER_PROCESSING.schema && job.status !== jobStatus.FINISHED)"
      :headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      :download-action="downloadAction"
      :downloads="translationSourceFiles"
      :remove-action="job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL && job.order.status === orderStatus.ORDER_PROCESSING.schema ? removeFileAction : null"
      :remove-enabled="removeEnabled"
      :admin="admin"
      :table="getJobTranslationSourceFileTable"
      v-if="translationSourceFiles">
    </JobDetailViewSection>
    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD END -->

    <!-- JOB TRANSLATION SOURCE FILES UPLOAD START -->
    <JobDetailViewUploadSection
      :headline="$t('file_upload')"
      :text="$t(uploadTranslationFileLabel)"
      :upload-action="uploadAction"
      :upload-action-label="$t('save')"
      :files="newTranslationSourceFiles"
      :handle-change="handleFileUploadChange"
      handleChangeName="translation-source-files"
      v-if="
      job.jobDefinition.type === jobTypes.JOB_TYPE_CUSTOMER_APPROVAL &&
      job.order.status === orderStatus.ORDER_PROCESSING.schema &&
      job.status !== jobStatus.REALIZATION &&
      job.status !== jobStatus.FINISHED">
    </JobDetailViewUploadSection>
    <!-- JOB TRANSLATION SOURCE FILES UPLOAD END -->

    <!-- JOB TARGET FILES DOWNLOAD START -->
    <JobDetailViewSection
      :download-action="downloadAction"
      :headline="$t(downloadTranslationLabel)"
      :downloads="translationFiles"
      :admin="admin"
      v-if="targetFilesDownload">
    </JobDetailViewSection>
    <!-- JOB TARGET FILES DOWNLOAD END -->

    <!-- JOB FEE START -->
    <JobDetailViewLinguist
        :job="job"
        :admin="admin"
        :linguist="linguist"
        v-if="jobRequest"
    />
  </div>
</template>
<script>
import '../lspbackend/lspbackend.scss'
import '../detailview/detailview.scss'

import JobDetailViewSection from "./JobDetailViewSection.vue";
import JobDetailViewHeaderActions from "./jobdetailviewheader/JobDetailViewHeaderActions.vue";
import JobDetailViewActionButtons from "./jobdetailviewheader/JobDetailViewActionButtons.vue";
import JobDetailViewUploadSection from './JobDetailViewUploadSection.vue'

import { OrderStatus } from "../../enums";
import JobStatus from '../../enums/JobStatus'
import JobTypes from "../../enums/JobType";
import { ENTRYPOINT } from '../../apps/config/entrypoint'
import JobDetailViewLinguist from "./JobDetailViewLinguist";
import JobDetailViewTrackingCodeForm from "./JobDetailViewTrackingCodeForm.vue";
import PriceUnits from "../../enums/PriceUnits";
import ProductOptionFormType from "../../enums/ProductOptionFormType";

export default {
  name: 'JobDetailView',
  components: {
    JobDetailViewLinguist,
    JobDetailViewTrackingCodeForm,
    JobDetailViewActionButtons,
    JobDetailViewHeaderActions,
    JobDetailViewSection,
    JobDetailViewUploadSection
  },
  props: {
    job: {
      type: Object
    },
    jobRequest: {
      type: Boolean,
      default: false
    },
    actionButtons: {
      type: Object
    },
    linguist: {
      type: Object
    },
    uploadAction: {
      type: Function,
      default: null
    },
    admin: {
      type: Boolean,
      default: false
    },
    handleFileUploadChange: {
      type: Function
    },
    uploadTranslationFileLabel: {
      type: String,
      default: ''
    },
    downloadTranslationLabel: {
      type: String,
      default: 'your_uploaded_translation'
    },
    downloadAction: {
      type: Function
    },
    removeFileAction: {
      type: Function,
      default: null
    },
    hideProductDescription: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldDisplayCustomerAddress: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    deliveryDate () {
      let deliveryDate = this.job.deliveryDate

      if (this.job.childJobs && this.job.childJobs.length) {
        this.job.childJobs.forEach(function (childJob) {
          if (childJob.status === JobStatus.REALIZATION) {
            deliveryDate = childJob.deliveryDate
          }
        })
      }

      return this.$moment(deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    translationJobDeliveryDate() {
      if (!this.translationJob)
        return null

      return this.$moment(this.translationJob.deliveryDate)
        .format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    proofreadingDeliveryDate () {
      if (!this.proofreadingJob)
        return null

      return this.$moment(this.proofreadingJob.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    finalRevisionDeliveryDate() {
      if (!this.finalRevisionJob)
        return null

      return this.$moment(this.finalRevisionJob.deliveryDate).format("DD.MM.YYYY, HH:mm") + ' ' + this.$t('cet')
    },

    proofreadingJob() {
      return this.job.order.jobs.filter(job => job.jobDefinition && job.jobDefinition.type === 'corrector')[0]
    },

    finalRevisionJob() {
      return this.job['childJobs'].filter(job => job.jobDefinition && job.jobDefinition.type === 'final-revision')[0]
    },

    translationJob() {
      return this.job.order.jobs.filter(job => job.jobDefinition && job.jobDefinition.type === 'translator')[0]
    },

    jobOrderQuantity () {
      if (this.job.order.product.priceUnitLabel) {
        return this.job.order.product.priceUnitLabel
      }

      if (this.job.order.priceUnit === PriceUnits.PRICE_UNIT_PAGE) {
        return this.job.pagesOrWords > 1 ? 'pages' : 'page'
      }

      if (this.job.order.priceUnit === PriceUnits.PRICE_UNIT_WORD) {
        return this.job.pagesOrWords > 1 ? 'words' : 'word'
      }
    },

    jobFee () {
      let fee = this.job.fee ? parseFloat(this.job.fee).toFixed(2) : null

      if (fee) {
        return fee.replace('.', ',') + ' €'
      }

      return null
    },

    customerAddress () {
      let customerAddress = [];
      let fullName = this.job.order.address['name'] ? this.job.order.address['name'] : this.job.order.address['firstName'] + ' ' + this.job.order.address['lastName']

      if (this.job.order.address['companyName']) {
        customerAddress.push({'head' : 'company_name', 'data': this.job.order.address['companyName'] })
      }

      customerAddress.push(
        {'head' : 'first_and_last_name', 'data': fullName },
        {'head' : 'address_street', 'data': this.job.order.address['streetAddress'] },
        {'head' : 'number', 'data': this.job.order.address['houseNumber'] },
        {'head' : 'address_suffix', 'data': this.job.order.address['addressSuffix']}
      );
      return customerAddress.concat(this.basicCustomerAddress)
    },

    basicCustomerAddress () {
      return[
        {'head' : 'postal_code_and_city', 'data': this.job.order.address['postalCode'] + ' ' + this.job.order.address['addressLocality'] },
        {'head' : 'address_country', 'data': this.job.order.address['addressCountry']['id'] }
      ];
    },

    deadlines () {
      return [
        {'head' : 'due_on', 'data': this.deliveryDate },
        {'head' : 'deadline_proofreading', 'data' : this.proofreadingDeliveryDate, 'hide': this.job.jobDefinition.type === 'customer-approval' || !this.proofreadingJob},
        {'head' : 'deadline_final_revision', 'data' : this.finalRevisionDeliveryDate, 'hide': this.job.jobDefinition.type === 'customer-approval' || !this.proofreadingJob},
        {'head' : 'deadline_translation', 'data' : this.translationJobDeliveryDate, 'hide': this.job.jobDefinition.type === 'customer-approval' || this.job.jobDefinition.type !== 'corrector'}
      ];
    },

    jobRequirements() {
      let jobReqs = [
        {'head' : 'source_language', 'data': this.job.order.sourceLanguage.name },
        {'head' : 'target_language', 'data': this.job.order.targetLanguage.name  }
      ]
      const modalsInfo = !this.linguist ? {} : {
        qm: this.$t('lsp_qm_info')
      };
      if (this.job.order && this.job.order.options) {
        this.job.order.options
          .filter((option) => this.admin || option.productOption.showInJobOverview)
          .forEach((option) => {
            if (
              option.productOption
              && option.productOptionValue
              && option.productOptionValue !== "false"
            ) {
              let data = {
                'head': option.productOption.label,
                'data': option.productOptionValue,
                'modal': modalsInfo[option.productOption.name]
              };
              jobReqs.push(data);
            }
          })
      }

      return jobReqs

    },

    translationFiles () {
      if (this.job) {
        let translationFiles = []

        if (this.job.order.translationTargetFiles && this.job.order.translationTargetFiles.length
          && (this.job.jobDefinition.type !== JobTypes.JOB_TYPE_CORRECTOR || (this.job.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR && this.job.status === JobStatus.REALIZATION))) {

          let type = this.job.jobDefinition.type === JobTypes.JOB_TYPE_CORRECTOR ? 'correction-file' : 'target-file'
          translationFiles = this.job.order.translationTargetFiles
          const version = Math.max(...translationFiles.map(o => o.version), 0);
          let downloadItems = []
          translationFiles
            .sort((t1, t2) => t1.version > t2.version ? -1 : 1)
            .forEach(targetFile => {
              if (this.admin || targetFile.version === version) {
                downloadItems.push({
                  name: targetFile.name,
                  link: this.$getDownloadURL(targetFile['@id'], targetFile.name),
                  downloadAction: true,
                  resourceId: targetFile['@id'],
                  created_at: targetFile.createdAt,
                  type: type,
                  scheduledDeletionDate: targetFile.scheduledDeletionDate,
                  status: targetFile.status,
                })
              }
            })
          return downloadItems
        }
      }

      return []
    },

    /** returns null, if no translation source files exist, or an array with all translation source files **/
    translationSourceFiles () {
      let translationSourceFiles = []
      if (this.job) {

        if (this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length) { // if there are more files
          let self = this
          this.job.order.translationSourceFiles.forEach(function (translationSourceFile) {
            translationSourceFiles.push({
              name: translationSourceFile.name,
              link: self.$getDownloadURL(translationSourceFile['@id'], translationSourceFile.name),
              downloadAction: true,
              resourceId: translationSourceFile['@id'],
              type: 'source-file',
              created_at: translationSourceFile.createdAt,
              scheduledDeletionDate: translationSourceFile.scheduledDeletionDate,
              status: translationSourceFile.status,
            })
          })
        }
        else if (this.job.order.translationSourceFile) { // only one file
          translationSourceFiles = [{
            name: this.job.order.translationSourceFile.name,
            link: this.$getDownloadURL(this.job.order.translationSourceFile['@id'], this.job.order.translationSourceFile.name),
            downloadAction: true,
            resourceId: this.job.order.translationSourceFile['@id'],
            type: 'source-file',
            created_at: this.job.order.translationSourceFile.createdAt,
            scheduledDeletionDate: this.job.order.translationSourceFile.scheduledDeletionDate,
            status: this.job.order.translationSourceFile.status,
          }]
        }
      }

      return translationSourceFiles
    },

    targetFilesDownload () {
      return (this.job.jobDefinition.type === this.jobTypes.JOB_TYPE_CUSTOMER_APPROVAL && this.job.status === this.jobStatus.FINISHED)
        || (this.job.jobDefinition.type === this.jobTypes.JOB_TYPE_TRANSLATOR && this.job.status === this.jobStatus.JOB_STATUS_SHIPMENT)
        || (this.job.jobDefinition.type === this.jobTypes.JOB_TYPE_CORRECTOR && this.job.status === this.jobStatus.REALIZATION)
    },

    jobInProgress () {
      if (this.job) {
        let status = this.job.status
        if (status === JobStatus.REALIZATION) {
          return true
        }

        if (this.job.childJobs && this.job.childJobs.length) {
          let childJobInProgress = this.job.childJobs.find(function (childJob) {
            return childJob['state'] === JobStatus.REALIZATION
          })

          if (childJobInProgress) {
            return true
          }
        }
      }

      return false
    },

    removeEnabled () {
      if (this.job.jobDefinition.type === this.jobTypes.JOB_TYPE_CUSTOMER_APPROVAL
        && this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.status === this.jobStatus.REALIZATION) {
        return false
      }

      return  this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length > 1
    },

    // get all job notes from product options for current job type
    // TODO: NEEDS TO BE UNIT TESTED
    productOptionsJobNotes () {
      let productOptionJobNotes = []

      if (!this.job.order.options && this.job.order.options.length)
        return productOptionJobNotes

      let self = this

      this.job.order.options.forEach((orderOption)  => {

        if (!(orderOption.productOption && orderOption.productOption.jobNotes
          && orderOption.productOption.jobNotes.length)) return

        //JUST RENDER IF ITS A RADIO OPTION OR AN OPTION WITH VALUE
        if (orderOption.productOption.type !== ProductOptionFormType.RADIO.type
          && !orderOption.productOptionValue || orderOption.productOptionValue === "false") return


        orderOption.productOption.jobNotes.forEach((jobNote) => {
          if (jobNote.jobType && (jobNote.jobType === 'all' || jobNote.jobType === self.job.jobDefinition.type) && jobNote.description) {
            productOptionJobNotes.push(jobNote.description)
          }
        })

      })

      return productOptionJobNotes
    },

    isLinguistJob () {
      if (this.job.jobDefinition.sameUserOfJobDefinition) {
        return this.job.jobDefinition.recruitable === true || this.job.jobDefinition.sameUserOfJobDefinition.recruitable === true
      }

      return this.job.jobDefinition.recruitable === true
    },

    getJobTranslationSourceFileTable() {
      return [
        {'head' : 'amount', 'data': this.job.pagesOrWords + ' ' + this.$t(this.jobOrderQuantity)}
      ]
    }
  },

  data () {
    return {
      jobStatus: JobStatus,
      jobTypes: JobTypes,
      allowUploadFiles: false,
      translatedFiles: [],
      newTranslationSourceFiles: [],
      apiUrl: ENTRYPOINT,
      orderStatus: OrderStatus
    }
  },

}
</script>
