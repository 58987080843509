<template>
  <div>
    <!-- Job Details START -->
    <JobDetailViewSection
      :headline="$t('deadline')"
      :table="deadlines">
    </JobDetailViewSection>
    <!-- Job Details END -->

    <!-- Product Job Notes START -->
    <JobDetailViewSection
      :headline="$t('notes')"
      :border="!productOptionsJobNotes().length"
      :text="job.order.product.jobNotes[0]['description']"
      v-if="job.order.product.jobNotes && job.order.product.jobNotes.length">
    </JobDetailViewSection>
    <!-- Product Job Notes END -->

    <!-- Product Option Job Notes START -->
    <div v-if="productOptionsJobNotes && productOptionsJobNotes.length">
      <JobDetailViewSection
        :border="index === (productOptionsJobNotes.length-1)"
        :text="productOptionJobNote"
        v-for="(productOptionJobNote, index) in productOptionsJobNotes" :key="'option-job-note-' + index">
      </JobDetailViewSection>
    </div>
    <!-- Product Option Job Notes END -->

    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD START -->
    <JobDetailViewSection
      :border="false"
      :headline="translationSourceFiles.length > 1 ? $t('documents_to_translate') : $t('document_to_translate')"
      :download-action="downloadAction"
      :downloads="translationSourceFiles"
      :remove-action="null"
      :remove-enabled="removeEnabled"
      :admin="admin"
      :table="jobOrderQuantity"
      v-if="translationSourceFiles">
    </JobDetailViewSection>
    <!-- JOB TRANSLATION SOURCE FILES DOWNLOAD END -->

    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM START -->
    <JobDetailViewLinguist
      :job="job"
      :admin="admin"
      :action-buttons="actionButtons"
      :linguist="linguist"
      :upload-action="uploadTranslatedFiles"
      :handle-file-upload-change="handleFileUploadChange"
      :upload-translation-file-label="'upload_translation_here'"
      :uploaded-files-label="'your_uploaded_translation'"
      :download-action="downloadAction"
      :uploaded-correction-files-label="'corrections_to_download'"
      :translation-files="translationFiles"
      :job-in-progress="job.status === jobStatus.REALIZATION"
      :remove-action="null"/>
    <!-- JOB DETAIL VIEW FOR LINGUISTS / QM END -->
  </div>
</template>
<script>
import 'lingoking-workbench/src/components/lspbackend/lspbackend.scss'
import 'lingoking-workbench/src/components/detailview/detailview.scss'

import JobDetailViewSection from "lingoking-workbench/src/components/jobdetailview/JobDetailViewSection.vue";
import JobDetailViewUploadSection from 'lingoking-workbench/src/components/jobdetailview/JobDetailViewUploadSection.vue'
import JobDetailViewLinguist from "lingoking-workbench/src/components/jobdetailview/JobDetailViewLinguist.vue";

import {OrderStatus} from "lingoking-workbench/src/enums";

import JobStatus from "lingoking-workbench/src/enums/JobStatus";

export default {
  name: 'FinalRevisionQM',
  components: {
    JobDetailViewLinguist,
    JobDetailViewSection,
    JobDetailViewUploadSection
  },

  props: {
    admin: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object
    },
    linguist: {
      type: Object
    },
    actionButtons: {
      type: Object
    },
    handleFileUploadChange: {
      type: Function
    },
    downloadAction: {
      type: Function
    },
    uploadAction: {
      type: Function,
    },
    getTranslationFiles: {
      type: Function
    },
    deliveryDate: {
      type: String
    },
    jobOrderQuantity: {
      type: Array
    },
    translationSourceFiles: {
      type: Array
    },
    productOptionsJobNotes: {
      type: Array
    }
  },

  computed: {
    deadlines() {
      return [
        {'head': 'due_on', 'data': this.deliveryDate}
      ];
    },

    removeEnabled() {
      return this.job.order.status === OrderStatus.ORDER_PROCESSING.schema && this.job.order.translationSourceFiles && this.job.order.translationSourceFiles.length > 1
    },

    targetFilesDownload() {
      return this.job.status === this.jobStatus.FINISHED
    },

    translationFiles() {
      return this.getTranslationFiles('target-file', null)
    }
  },

  methods: {
    uploadTranslatedFiles: function () {
      this.uploadAction('translationTargetFiles[]')
    },
  },

  data() {
    return {
      jobStatus: JobStatus,
      orderStatus: OrderStatus
    }
  },
}
</script>
