<template>
  <div :class="border ? '' : 'lk-detailview__section-wrapper--no-border'" class="lk-detailview__section-wrapper">
    <InlineNotification
      :closeButton="true"
      :title="status.statusText"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :kind="status.type"
      :notificationClass="'bx--inline-notification bx--inline-notification--no-margin-top bx--inline-notification--' + status.type"
      role="alert"
      :subtitle="status.statusSubTitle"
      v-if="status && status.statusText"
    />
    <h5 class="lk-detailview__section-headline" v-if="headline">{{ headline }}</h5>
    <p v-if="text">
      {{ text }}
    </p>

    <slot name="content">
      <div style="margin-top: 1rem; margin-bottom: 1rem;">
        <slot name="extra-content"></slot>
      </div>
    </slot>

    <div class="lk-detailview__table bx--row" v-if="table">
      <div class="lk-detailview__table-row bx--col-lg-10"
           v-for="(tableRow, index) in table" :key="'lk-detailview-table-row-'+index"
           v-if="!tableRow.hide">
        <span class="lk-detailview__table-left">
          <span>{{ $t(tableRow.head) }}</span>
          <span
            v-if="!!tableRow.modal"
            class="lk-carbon-icon lk-carbon-icon-info--glyph lk-icon-modal"
            @click="openModal(tableRow.modal)"
          ></span>
        </span>
        <span v-if="tableRow.data === 'true'" class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
        <span v-else-if="tableRow.data === 'false'" class="lk-carbon-icon lk-carbon-icon-close--glyph"></span>
        <span v-else :class="tableRow.bold ? 'lk-detailview__table-right--bold' : ''" class="lk-detailview__table-right">{{ tableRow.data }}</span>
      </div>
    </div>

    <ul class="lk-detailview__file-download" v-if="downloads && downloads.length">
      <li class="bx--row" v-for="(downloadItem, index) in downloads" :key="'lk-detailview__file-download-item-'+index">
        <div class="bx--col-lg-10">
          <div class="lk-detailview__file-download-item">
            <TranslationFileDownload
              :file="downloadItem"
            />
            <span class="lk-detailview__table-right" v-if="admin">{{ $moment(downloadItem.created_at).format("DD.MM.YYYY HH:mm") }} CET</span>

            <a class="lk-detailview__file-remove-link"
               @click="removeFile(downloadItem)"
               v-if="removeAction && removeEnabled">
              <span class="lk-carbon-icon lk-carbon-icon-close--glyph lk-button-icon--right"></span>
            </a>
          </div>
          <ScheduledDocumentDeletionLabel :file="downloadItem" />
        </div>
        <div class="bx--col-lg-6 lk-detailview__file-download-item" v-if="downloadItem.description">
          {{ downloadItem.description }}
        </div>
      </li>
    </ul>

    <ul class="lk-detailview__list" v-if="rejections && rejections.length > 0">
      <li class="bx--row" v-for="(rejection, index) in rejections">
        <div class="bx--col-lg-12">
          <p style="padding: .3em"> {{ rejection.reason }}</p>
        </div>
        <div v-if="rejection.file" class="bx--col-lg-12">
          <div class="lk-detailview__file-download-item">
            <TranslationFileDownload
              :file="rejection.file"
            />
          </div>
        </div>
        <ScheduledDocumentDeletionLabel :file="rejection.file" />
        <p style="padding: 0.5em; margin-bottom: 1em; font-size: .80em; color: #8897a2"> Rejected on {{ rejection.date }} - {{ rejection.createdBy }} </p>
      </li>
    </ul>

    <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <slot name="extra-content-bottom"></slot>
    </div>

    <LKModal
      ref="modalRef"
      :close-button="true"
      :modal-data="modalData"
      :handle-reject-button-click="closeModal"
      :modal-id="'modal-info'"
    >
      <template slot="content">
        <div class="job-detail-modal" v-html="modalText"></div>
      </template>
    </LKModal>
  </div>
</template>
<script>
  import InlineNotification from '../notification/InlineNotification.vue';
  import LKModal from '../modal/Modal.vue';
  import TranslationFileDownload from '../translationfile/TranslationFileDownload.vue';
  import ScheduledDocumentDeletionLabel from '../translationfile/ScheduledDocumentDeletionLabel.vue';

  export default {
    name: 'JobDetailViewSection',
    components: {
      ScheduledDocumentDeletionLabel,
      TranslationFileDownload,
      InlineNotification,
      LKModal
    },
    props: {
      data: {
        type: Object
      },
      headline: {
        type: String
      },
      text: {
        type: String
      },
      status: {
        type: Object
      },
      table: {
        type: Array
      },
      rejections: {
        type: Array
      },
      downloads: {
        type: Array
      },
      border: {
        type: Boolean,
        default: true
      },
      downloadAction: {
        type: Function
      },
      removeAction: {
        type: Function,
        default: null
      },
      removeEnabled: {
        type: Boolean,
        default: false
      },
      admin: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        modalText: null,
        modalData: {
          rejectButtonLabel: this.$t("close")
        }
      }
    },
    methods: {
      openModal (text) {
        this.modalText = text;
        this.$refs.modalRef.modalInstance.show();
      },
      closeModal () {
        this.$refs.modalRef.modalInstance.hide  ();
      },
      download (downloadItem, e) {
        if(downloadItem.downloadAction) {
          e.preventDefault()
          e.stopPropagation()

          this.downloadAction(downloadItem)

          return false
        }

        return true
      },

      removeFile (downloadItem) {
        if(this.removeAction && this.removeEnabled && window.confirm('Are you sure you want to delete this file?')) {
          this.removeAction(downloadItem)
        }
      }
    }
  }
</script>

<style scoped>
.lk-icon-modal {
  float: right;
  padding-top: 3px;
  padding-left: 4px;
  cursor: pointer;
}
.lk-icon-modal:hover {
  color: #333;
}
.job-detail-modal {
  line-height: 24px;
}
</style>
