<template>
  <ul>
    <li :class="['lk-list-view__row bx--parent-row-v2 lk-container--white lk-container--layer-raised', row.link && !row.expandable ? 'lk-data-table__row--link' : '']"
        @click.prevent="row.link && !row.expandable ? openLink(row.link, '_self') : ''"
        :key="'lk-list-view__row-'+ row.id"
        v-for="(row,rowInd) in dataTableItems">

      <div class="bx--row">
        <div class="bx--col-xs-10">
          <div class="lk-list-view-section">
            <a :href="row.link" class="lk-list-view-section__name" v-if="row.link">
              {{ row.name }}
            </a>
            <span class="lk-list-view-section__name" v-else>{{ row.name }}</span>
            <ul class="lk-list-view-section__content">
              <li
                v-for="dataTableHeadItem in dataTableHeadItems"
                v-if="row[dataTableHeadItem.index] && dataTableHeadItem.index !== 'name'">
                <ul class="lk-data-table__col-list" v-if="Array.isArray(row[dataTableHeadItem.index])">
                  <li class="lk-data-table__col-list__item" :class="'lk-data-table__col-list__item--' + rowItem.type" v-for="(rowItem, index) in row[dataTableHeadItem.index]">
                    <a class="lk-data-table__col-list__link" :href="rowItem.link" @click.prevent="openLink(rowItem.link, '_self')" v-if="rowItem.link">
                      <span :class="rowItem.icon" v-if="rowItem.icon"></span>
                      <span v-if="rowItem.text">{{ rowItem.text }}</span>
                    </a>
                    <span :class="rowItem.icon" v-else-if="rowItem.icon"></span>
                    <span class="lk-data-table__col-list__badge" :class="rowItem.badge" v-if="rowItem.badge">
                      {{ rowItem.badgeText ? rowItem.badgeText : '' }}
                    </span>
                  </li>
                </ul>
                <span v-else>
                {{ row[dataTableHeadItem.index] }}
              </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="bx--col-xs-2" @click.prevent="toggleExpanded(rowInd)" v-if="row.expandable">
          <button class="lk-list-view-expand__button">
            <span :class="['lk-carbon-icon', rowIsExpanded(rowInd) ? 'lk-carbon-icon-chevron--up' : 'lk-carbon-icon-chevron--down']"></span>
          </button>
        </div>
        <div class="bx--col-xs-2" v-if="!row.expandable">
          <a :href="row.link" class="lk-list-view-expand__button" v-if="row.link">
            <span class="lk-carbon-icon lk-carbon-icon-chevron--right"></span>
          </a>
        </div>
      </div>
      <ul :class="['bx--row', rowIsExpanded(rowInd) ? '' : 'lk--expandable-row--hidden-v2']" v-if="row.items && row.items.length && rowIsExpanded(rowInd)">
        <li :class="['bx--col-md-12', dataTableItemChild.status ? 'lk-data-table-section--'+ dataTableItemChild.status : '']" v-for="dataTableItemChild in row.items" :key="'bx--col-md-12-' + dataTableItemChild.id">
          <div class="bx--row">
            <div class="bx--col-md-12" v-if="dataTableItemChild.name">
              <div class="lk-list-view-section lk-list-view-section--border">
                <table class="lk-data-table-section__table">
                  <tr>
                    <th class="lk-data-table-section__headline">
                      {{ dataTableItemChild.name }}
                    </th>
                    <td v-if="dataTableItemChild.price">
                      {{ dataTableItemChild.price }}
                    </td>
                  </tr>
                </table>

                <table class="lk-data-table-section__table" v-if="dataTableItemChild.details && dataTableItemChild.details.length">
                  <tr v-for="detail in dataTableItemChild.details">
                    <th>{{ detail.label }}</th>
                    <td>{{ detail.value }}</td>
                  </tr>
                </table>
                <p :class="'lk-text__state lk-text__state--' + dataTableItemChild.status" v-if="dataTableItemChild.status">
                                            <span
                                              :class="'lk-button-icon lk-button-icon--left ' + dataTableItemChild.statusIcon"
                                              v-if="dataTableItemChild.statusIcon"></span>
                  <span v-if="dataTableItemChild.statusText ">{{ dataTableItemChild.statusText }}</span>
                </p>
              </div>
            </div>
          </div>
        </li>
        <li class="bx--col-md-12" v-if="row.chargedWith || row.sendCopyTo || row.downloadFileUrl">
          <div class="bx--row">
            <div class="bx--col-md-12">
              <div class="lk-list-view-section">
                <div class="lk-text__state" v-if="row.chargedWith">
                  <span class="lk-text__state--pending">{{$t('order_from')}}:</span>
                  {{ row.chargedWith }}
                </div>
                <div class="lk-text__state" v-if="row.sendCopyTo">
                  <span class="lk-text__state--pending">{{$t('after_order_copy_to')}}:</span>
                  {{ row.sendCopyTo }}
                </div>
                <div class="lk-text__state">
                  <a :href="row.downloadFileUrl" v-if="row.downloadFileUrl">
                    {{ $t('download_offer') }}
                    <span class="lk-carbon-icon lk-carbon-icon-download lk-button-icon lk-button-icon--right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'ListView',
    components: {
    },
    props: {
      dataTableHeadItems: {
        type: Array,
        required: true
      },
      dataTableItems: {
        type: Array,
        required: true
      },
      colSize: {
        type: Number,
      },
      rowIsExpanded: {
      },
      toggleExpanded: {

      }
    },
    methods: {
      openLink (link, target) {
        window.open(link, target)
      },
    }
  }
</script>
