<template>
  <layout-default>
    <Loading v-if="isLoading" />
    <InlineNotification
      v-if="alert && alert.message && alert.type === 'alert-danger'"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="$t('error_occurred')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <InlineNotification
      v-if="alert.message && alert.type === 'alert-success'"
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t(alert.message)"
      :closeButton="true"
    />
    <div class="bx--row" v-if="emptyDashboard">
      <div class="bx--col-md-12">
        <div class="lk-empty-page-content-wrapper">
          <div class="lk-empty-page-content">
            <div class="lk-empty-page-content__image-wrapper">
              <img
                src="../../assets/images/lk-empty-dashboard.png"
                alt="Lingoking Empty Dashboard"
                class="lk-empty-page-content__image"
              />
            </div>
            <div class="lk-empty-page-content__teaser">
              <h2 class="lk-empty-page-content__headline">
                {{ $t("empty_jobs") }}
              </h2>
              <p class="lk-empty-page-content__text">
                {{ $t("empty_help_lsp_1") }}
                <a
                  :href="shop_url + $t('help_linkLSP')"
                  class="lk-empty-page-content__link"
                  >FAQ</a
                >
                {{ $t("empty_help_lsp_2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--row" v-else>
      <div class="bx--col-md-12" v-if="!isLoading">
        <h1 class="lk-service-backend__headline">Übersicht Anfragen</h1>

        <ListView
          :data-table-items="dataTableItems"
          :data-table-head-items="dataTableHeadItems"
        ></ListView>
        <pagination
          :cur-page="currentPage"
          :current-page="currentPage"
          :items-length="dataTableItems.length"
          :items-per-page="itemsPerPage"
          :total-items="totalItems"
          :total-pages="totalPages"
          :view="view"
          :get-page="goToPage"
          :current-page-change-parent="changeCurrentPage"
          :items-per-page-change-parent="changeItemsPerPage"
        />
      </div>
    </div>
  </layout-default>
</template>
<script>
import "lingoking-workbench/src/components/emptypagecontent/emptypagecontent.scss";
import { mapState, mapGetters, mapActions } from "vuex";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import InlineLoading from "lingoking-workbench/src/components/loading/LoadingInline.vue";
import Loading from "lingoking-workbench/src/components/loading/Loading.vue";
import ListView from "lingoking-workbench/src/components/listview/ListView.vue";
import InlineNotification from "lingoking-workbench/src/components/notification/InlineNotification.vue";
import PriceUnits from "lingoking-workbench/src/enums/PriceUnits";
import Pagination from "lingoking-workbench/src/components/datatable/Pagination.vue";
import router from "../../router";
import { SHOP_URL } from "../../config/entrypoint";

export default {
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),
    ...mapGetters({
      error: "jobrequest/list/error",
      offers: "jobrequest/list/items",
      isLoading: "jobrequest/list/isLoading",
      view: "jobrequest/list/view",
      totalItems: "jobrequest/list/totalItems",
      currentPage: "jobrequest/list/currentPage",
    }),

    totalPages() {
      return this.totalItems < this.itemsPerPage
        ? 1
        : Math.ceil(this.totalItems / this.itemsPerPage).toFixed(0);
    },

    rejected() {
      return false;
    }
  },
  created() {
    this.fetch();
  },
  components: {
    Loading,
    LayoutDefault,
    InlineLoading,
    ListView,
    InlineNotification,
    Pagination
  },
  data() {
    return {
      curPage: 1,
      itemsPerPage: 10,
      emptyDashboard: true,
      shop_url: SHOP_URL,
      dataTableHeadItems: [
        {
          label: "name",
          sortable: true,
          index: "name"
        },
        {
          label: "order_number",
          sortable: true,
          index: "purchaseOrderNumber"
        },
        {
          label: "amount",
          sortable: true,
          index: "quantity"
        },
        {
          label: "delivery_date",
          sortable: true,
          index: "deliveryDate"
        },
        {
          label: "fee",
          sortable: true,
          index: "fee"
        }
      ],
      dataTableItems: []
    };
  },

  watch: {
    offers: function(items) {
      if (items && items.length) {
        this.emptyDashboard = false;
        this.dataTableItems = this.prepareListView(items);
      } else {
        this.emptyDashboard = true;
      }
    }
  },

  methods: {
    ...mapActions({
      getOffers: "jobrequest/list/default"
    }),

    fetch() {
      const url =
        "/job_requests" +
        "?exists[deletedAt]=false" +
        "&itemsPerPage=" + this.itemsPerPage +
        "&page=" + this.curPage;
      this.getOffers(url);
    },

    goToPage(url) {
      this.getOffers(url);
    },

    changeCurrentPage(page) {
      this.curPage = page;
      this.fetch();
    },

    changeItemsPerPage(itemsPerPage) {
      this.curPage = 1;
      this.itemsPerPage = itemsPerPage;
      this.fetch();
    },

    prepareListView: function(items) {
      let self = this;
      let preparedItems = [];
      items.forEach(function(item) {
        let quantity = item.job.order.pagesOrWords + " ";
        if (item.job.order.priceUnit === PriceUnits.PRICE_UNIT_PAGE) {
          quantity +=
            item.job.order.pagesOrWords > 1
              ? self.$t("pages")
              : self.$t("page");
        } else if (item.job.order.priceUnit === PriceUnits.PRICE_UNIT_WORD) {
          quantity +=
            item.job.order.pagesOrWords > 1
              ? self.$t("words")
              : self.$t("word");
        }
        let productOptionIsEnabled = item.job.order.options.filter(option => {
          return option.productOption.name === 'hide_lsp_fees';
        });
        let preparedItem = {
          id: item.id,
          purchaseOrderNumber: item.job["purchaseOrderNumber"],
          name: item.job.productOptionJobDefinition.jobTitle ? item.job.productOptionJobDefinition.jobTitle : item.job.jobDefinition.name,
          productName: item.job.order.product
            ? item.job.order.product.name
            : "",
          deliveryDate: self
            .$moment(item.job["deliveryDate"])
            .format("DD.MM.YYYY, HH:mm"),
          fee: productOptionIsEnabled.length ? '' :
            parseFloat(item.job["fee"])
              .toFixed(2)
              .replace(".", ",") + " €",
          feeWithVat:
            parseFloat(item.job["feeWithVat"])
              .toFixed(2)
              .replace(".", ",") + " €",
          quantity: quantity,
          expandable: false,
          expanded: false,
          link: router.resolve({
            name: "OfferShow",
            params: { id: encodeURIComponent(item["@id"]) }
          }).href
        };

        preparedItems.push(preparedItem);
      });
      return preparedItems;
    }
  }
};
</script>
