<template>
  <div class="bx--form-item">
    <strong class="bx--label" v-if="label">{{ label }}</strong>
    <p class="bx--label-description" v-if="description">{{ description}}</p>
    <div :class="['bx--file', multiple ? '' : 'bx--file--single']" data-file>
      <label
        :class="buttonClass ? buttonClass : 'bx--file-btn bx--btn bx--btn--primary bx--btn--sm'"
        :for="id"
        role="button"
        tabindex="0">{{ buttonLabel }}</label>
      <input
        :accept="accepted"
        :data-invalid="invalid || sameFileNameError"
        :id="id"
        :multiple="multiple"
        :name="id"
        @change="handleFileChange($event.target.name, $event.target.files)"
        class="bx--file-input bx--select-input"
        data-file-uploader
        data-target="[data-file-container]"
        type="file"
        v-bind="$attrs"/>
      <p class="lk-file-upload__content" style="font-size: 0.7rem;">*{{ maxFileSize }} {{$t('max_file_upload_size_mb')}}</p>
      <div :class="invalidClass && invalidMessage ? invalidClass : ''" v-if="invalid">
        {{ invalidMessage }}
      </div>
      <div :class="invalidClass" v-html="sameFileNameError" v-if="sameFileNameError">
        {{ sameFileNameError }}
      </div>
      <div class="bx--file-container" data-file-container>
        <div
          :key="index"
          class="bx--file-container lk-file-upload-checkmark"
          data-file-container
          v-for="(file, index) in internalFiles">
            <span class="bx--file__selected-file">
                <p class="bx--file-filename">{{ file.file.name }}</p>
                <span
                  :data-for="id"
                  :data-test="file.state"
                  class="bx--file__state-container">
                    <div
                      class="bx--loading"
                      style="width: 1rem; height: 1rem;"
                      tabindex="0"
                      v-if="file.state === 'uploading'">
                        <svg class="bx--loading__svg" viewBox="-42 -42 84 84">
                          <circle cx="0" cy="0" r="37.5"></circle>
                        </svg>
                    </div>
                    <svg
                      alt="Complete"
                      aria-label="Complete"
                      class="bx--file-complete"
                      fill-rule="evenodd"
                      height="16"
                      role="img"
                      v-if="file.state === 'complete'"
                      viewBox="0 0 16 16"
                      width="16">
                        <title>Complete</title>
                        <path
                          d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.293-11.332L6.75 9.21 4.707 7.168 3.293 8.582 6.75 12.04l5.957-5.957-1.414-1.414z"
                        ></path>
                    </svg>
                    <svg
                      @click="remove(index)"
                      @keydown.enter.prevent="remove(index)"
                      @keydown.space.prevent
                      @keyup.space.prevent="remove(index)"
                      alt="Remove file"
                      arial-label="Remove file"
                      class="bx--file-close"
                      fill-rule="evenodd"
                      height="16"
                      role="button"
                      tabindex="0"
                      v-if="removable"
                      viewBox="0 0 16 16"
                      width="16">
                        <title>Remove</title>
                        <path
                          d="M8 6.586L5.879 4.464 4.464 5.88 6.586 8l-2.122 2.121 1.415 1.415L8 9.414l2.121 2.122 1.415-1.415L9.414 8l2.122-2.121-1.415-1.415L8 6.586zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
                    </svg>
                </span>
            </span>
          <span class="lk-carbon-icon lk-carbon-icon-checkmark lk-carbon-icon-checkmark-upload-file"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // we do not use the file uploader any more, but use code from
  // https://github.com/carbon-design-system/carbon-components-vue/blob/master/src/components/cv-file-uploader/cv-file-uploader.vue
  // import { FileUploader } from 'lingoking-workbench/node_modules/carbon-components'
  import './fileupload.scss'

  export default {
    name: 'FileUpload',
    props: {
      clearOnReselect: Boolean,
      files: Array,
      label: String,
      buttonClass: String,
      helperText: String,
      initialStateUploading: Boolean,
      removable: Boolean,
      buttonLabel: {type: String, default: 'Select file'},
      multiple: Boolean,
      id: String,
      description: String,
      handleChange: Function,
      accepted: String,
      invalid: Boolean,
      invalidMessage: String,
      invalidClass: String,
      maxFileSize: {type: Number, default: 2},
      sameFileName: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      handleFileChange: function (name, files) {
        this.sameFileNameError = ''
        if (!files.length) return;
        if (this.clearOnReselect) {
          this.internalFiles = [];
        }

        Array
          .from(Array(files.length).keys())
          .map(x => {
            var FileSize = files[x].size / 1024 / 1024; // in MB
            var file = files[x]
            if (FileSize > this.fileSize) {
              alert('File size exceeds ' + this.fileSize + ' MB') // TODO
            } else {

              if(!this.sameFileName) {
                let fileNameAlreadyExists = this.internalFiles.find(function (internalFile) {
                  return internalFile['file']['name'] === file['name']
                })

                if(!fileNameAlreadyExists) {
                  this.internalFiles.push({
                    state: 'ok',
                    file,
                  })
                }
                else {
                  this.sameFileNameError += '<br />File with the name ' + file['name'] + ' already exists.'
                }
              }

              else {
                this.internalFiles.push({
                  state: 'ok',
                  file,
                })
              }
            }

            if (this.internalFiles.length) {
              this.handleChange(name, this.internalFiles)
            }
          })
      },
      remove(index) {
        this.sameFileNameError = ''
        this.internalFiles.splice(index, 1);
        this.$emit('change', this.internalFiles);
      },
    },
    data() {
      return {
        internalFiles: [],
        fileUploadInstance: null,
        fileSize: this.maxFileSize ? this.maxFileSize : 2,
        sameFileNameError: ''
      }
    },
    mounted: function () {
      // this.fileUploadInstance = FileUploader.create(this.$el)
      this.internalFiles = this.files ? this.files : [];
    },
    watch: {
      files() {
        this.internalFiles = this.files ? this.files : [];
      },
    },
  }
</script>


