<template>
    <div class="lk-main-container">
        <div class="container">
            <header class="lk-header lk-header--top lk-header--small">
                <div class="bx--row">
                    <div class="bx--col-xs-12 bx--col-sm-3">
                        <a :href="shop_url">
                            <img src="../../assets/images/logo_rebranded.svg" alt="Lingoking" class="lk-header__logo" />
                        </a>
                    </div>
                    <div class="bx--col-xs-12 bx--col-sm-9">
                       <MainMenu />
                    </div>
                </div>
                <div class="bx--row lk-service-backend__toggle-mobile-nav-wrapper">
                    <div class="bx--col-xs-12 bx--col-md-12">
                        <button class="lk-subnav-menu-mobile__toggler lk-carbon-icon lk-carbon-icon-menu" @click="openMobileNav"></button>
                    </div>
                </div>
            </header>
            <main>
                <div class="lk-page-content container lk-container lk-container--inner lk-service-backend__container">
                    <aside class="lk-service-backend__aside">
                        <AsideMenu />
                    </aside>
                    <div class="bx--row lk-service-backend__content">
                        <div class="bx--col-md-12">
                            <slot/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
  import 'lingoking-workbench/src/components/header/header.scss'
  import 'lingoking-workbench/src/components/header/mainnav/mainnav.scss'
  import 'lingoking-workbench/src/components/header/subnav/subnav.scss'
  import 'lingoking-workbench/src/components/header/subnav/subnavmobile.scss'
  import {SHOP_SERVICE_LSP_URL} from '../../config/entrypoint'

  import AsideMenu from '../aside/AsideMenu'
  import MainMenu from '../menu/MainMenu'
  import {mapState} from "vuex";

  export default {
    name: 'LayoutDefault',
    components: {
      AsideMenu,
      MainMenu
    },
    data() {
      return {
        mobileNavOpen: false,
        shop_url: SHOP_SERVICE_LSP_URL
      }
    },
    computed: {
      ...mapState({
        account: state => state.account,
      }),
    },
    methods: {
      openMobileNav() {
        if (!this.mobileNavOpen) {
          $('.lk-service-backend__aside').clone().insertAfter($('.lk-subnav-menu-mobile__toggler')).addClass('cloned-mobile-nav').slideDown()
          this.mobileNavOpen = true
        } else {
          $('.cloned-mobile-nav').slideUp("normal", function() { $(this).remove() })
          this.mobileNavOpen = false
        }
      }
    },
  }
</script>
