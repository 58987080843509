<template>
  <div @click="componentClickHandler($event)">
    <fieldset>
      <legend>{{ $t('company_legend') }}</legend>
      <!-- Line of business -->
      <div class="bx--row">
        <div class="lk-form-desc bx--col-md-3"></div>

        <div class="bx--col-md-4 bx--col-sm-3">
          <div class="bx--form-item bx--select">
            <input
              :data-invalid="$v.item.company.name.$error"
              :value="selectedCompanyName"
              @input="companyInputHandler($event)"
              @focus="companyFocusHandler($event)"
              id="text-input-company-name"
              :placeholder="$t('company_name')"
              type="text"
              :class="['bx--text-input', 'bx--text-input--searchable', $v.item.company.lineOfBusiness.isInvalid ? 'is-invalid' : '']"
              :disabled="(this.item.company!=null && this.item.company['@id']!=null) && !companies"
              autocomplete="off"
            >

            <div tabindex="-1" class="multiselect" v-if="showCompanySuggestions && !disableSearchCompanies">
              <div tabindex="-1" class="multiselect__content-wrapper">
                <ul class="autocomplete-results multiselect__content">
                  <li
                    v-for="(company, index) in filteredCompanies"
                    :key="index"
                    :value="company.name"
                    class="multiselect__element"
                    @click="selectCompanyHandler(company)"
                    @mouseover="hoveredCompany = company"
                    @mouseleave="hoveredCompany = null">
                    <span
                      :class="[{'multiselect__option--selected' : (selectedCompany==company)},{'multiselect__option--highlight' : (hoveredCompany==company)}, 'multiselect__option', 'multiselect__option--search']"><span>{{ company.name }}</span></span>
                  </li>
                </ul>
              </div>
            </div>
            <label class="bx--label" for="text-input-company-name">{{ $t('company_name') }} *</label>
            <div class="bx--form-requirement" v-if="!$v.item.company.name.minLength">{{ $t('company_name_too_short') }}</div>
            <div class="bx--form-requirement" v-if="!$v.item.company.name.required">{{ $t('company_name_required') }}</div>
            <div class="bx--form-requirement" v-if="getViolation('companyName')">{{ getViolation('companyName') }}</div>
          </div>
        </div>

        <div class="bx--col-md-5 bx--col-sm-3">
          <div class="bx--form-item bx--select">
            <label class="bx--label" for="line-of-business">{{ $t('industry') }} *</label>
            <multiselect
              :disabled="readonly || (this.item.company!=null && this.item.company['@id']!=null)"
              :readonly="readonly || (this.item.company!=null && this.item.company['@id']!=null)"
              :data-invalid="$v.item.company.lineOfBusiness.$error"
              :class="[$v.item.company.lineOfBusiness.isInvalid ? 'is-invalid' : '']"
              :allow-empty="false"
              :placeholder="$t('industry')"
              :options="lineOfBusiness"
              track-by="@id"
              label="label"
              v-model="selectedLineOfBusiness"
              id="line-of-business"
              v-if="lineOfBusiness && lineOfBusiness.length">
            </multiselect>
            <div class="bx--form-requirement" v-if="!$v.item.company.lineOfBusiness.isInvalid">{{ $t('select_industry') }}</div>
            <div class="bx--form-requirement" v-if="getViolation('lineOfBusiness')">{{ getViolation('lineOfBusiness') }}</div>
          </div>
        </div>
      </div>

      <!-- Company billing period / vatId-->
      <div class="bx--row">

        <div class="bx--offset-md-3 bx--col-md-4">
          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :disabled="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :readonly="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :value="selectedCompany.phone"
              class="bx--text-input"
              id="company-phone"
              :placeholder="$t('company_phone')"
              type="text"
              @change="readonly ? '' : handleUpdateCompanyField('phone', $event.target.value)"
              :data-invalid="readonly ? false : $v.item.company.phone.$error"
            />
            <label class="bx--label" for="company-phone">{{ $t('company_phone') }}</label>
            <div class="bx--form-requirement" v-if="$v && !$v.item.company.phone.minLength">{{ $t('company_phone_too_short') }}</div>
          </div>
        </div>

        <div class="bx--col-md-5">
          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :disabled="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :readonly="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :value="selectedCompany.vatId"
              :class="['bx--text-input', $v.item.company.vatId.isInvalid ? 'is-invalid' : '']"
              id="company-vat-id"
              :placeholder="$t('vat_number')"
              type="text"
              :data-invalid="readonly ? false : $v.item.company.vatId.$error"
              @input="readonly ? '' : handleUpdateCompanyField('vatId', $event.target.value)"/>
            <label class="bx--label" for="company-vat-id">{{ $t('vat_number') }} *</label>
            <div class="bx--form-requirement" v-if="$v && !$v.item.company.vatId.required">{{ $t('vat_required') }}</div>
            <div class="bx--form-requirement" v-if="$v && !$v.item.company.vatId.isValid">{{ $t('vat_id_invalid') }}</div>
          </div>
        </div>
      </div>

      <div class="bx--row">
        <!-- Url -->
        <div class="bx--offset-md-3 bx--col-md-4">
          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :disabled="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :readonly="readonly || (this.item.company!=null && this.item.company['@id']!=null) "
              :value="selectedCompany.url"
              class="bx--text-input"
              id="company-url"
              :placeholder="'Url'"
              type="text"
              :data-invalid="readonly ? false : $v.item.company.url.$error"
              @input="readonly ? '' : handleUpdateCompanyField('url', $event.target.value)"
            />
            <label class="bx--label" for="company-url">Url</label>
            <div class="bx--form-requirement" v-if="$v && !$v.item.company.url.minLength">{{ $t('url_too_short') }}</div>
          </div>
        </div>

        <div class="bx--col-md-5">
          <div class="bx--form-item bx--text-input-wrapper">
            <input
              :data-invalid="$v.item.companyPosition.$error"
              :value="item.companyPosition"
              @input="handleUpdateField('companyPosition', $event.target.value)"
              class="bx--text-input"
              id="text-input-position"
              :placeholder="$t('position_in_company')"
              type="text"/>
            <label class="bx--label" for="text-input-position">{{ $t('position_in_company') }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import {mapActions, mapGetters} from 'vuex';
    import { DEFAULT_BILLING_PERIOD } from "../../../constants/Company"

    export default {
        name: 'CustomerCompanyForm',
        components: {
            Multiselect
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            companies: {
                type: Array,
                required: false
            },
            $v: {
                type: Object,
                required: false
            },
            isInvalid: {
                type: Function,
                required: false
            },
            handleUpdateField: {
                type: Function,
                required: false
            },
            handleUpdateLineOfBusiness: {
                type: Function,
                required: true
            },
            lineOfBusiness: {
                type: Array,
                required: true
            },
            getViolation: {
                type: Function,
                required: false
            },
            readonly: {
                type: Boolean,
                required: false
            },
            disableSearchCompanies: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                lb: null,
                company: null,
                hoveredCompany: null,
                showCompanySuggestions: false,
                suggestedCompanies: null,
                companyName: null,
                scheduleFetchCompany: null
            }
        },
        methods: {
            ...mapActions({
              getCompanies: 'company/list/default',
            }),
            companyFocusHandler($event) {
              this.showCompanySuggestions = true;
            },
            companyInputHandler($event) {
                this.selectedCompanyName = $event.target.value;
                if (this.scheduleFetchCompany && !this.disableSearchCompanies) {
                  clearTimeout(this.scheduleFetchCompany);
                }
                if (!this.disableSearchCompanies) {
                  this.scheduleFetchCompany = setTimeout(() => this.getCompanies({ name: this.selectedCompanyName }), 1000);
                }
                this.handleUpdateCompanyField('name', $event.target.value);
                if (this.companies && this.companies.length) {
                    this.handleUpdateCompanyField('@id', null, false);
                    this.handleUpdateCompanyField('billingAddress', null, false);
                }
            },
            selectCompanyHandler(company) {
                if (this.companies && this.companies.length) {
                    this.selectedCompany = company;
                    this.handleUpdateCompany(this.selectedCompany);
                    this.selectedCompanyName = company.name;
                    this.showCompanySuggestions = false;
                }
            },
            handleUpdateCompany(company) {
                this.handleUpdateField('company', company);
            },
            handleUpdateIntField(field, value) {
                const intValue = isNaN(parseInt(value)) ? null : parseInt(value);
                this.handleUpdateCompanyField(field, intValue);
            },
            handleUpdateCompanyField(field, value, touch = true) {
                let company = Object.assign({}, this.item.company, {[field]: value});
                this.handleUpdateField('company', company);
                if (!this.item.company.billingPeriod) {
                    company = Object.assign({}, company, {'billingPeriod': DEFAULT_BILLING_PERIOD});
                    this.handleUpdateField('company', company);
                }
                if (touch && this.$v.item.company[field]) {
                    this.$v.item.company[field].$touch();
                }
            },
            componentClickHandler($event) {
                // hide search options unless clicked on the searchfield
                const clickedOnSearchField = $event.target.classList.contains('bx--text-input--searchable');
                if (!clickedOnSearchField) {
                    this.showCompanySuggestions = false;
                }
            }
        },
        computed: {
            filteredCompanies() {
              return this.companies;
            },
            selectedCompanyName: {
                get() {
                    if (this.companyName !== null) {
                        return this.companyName;
                    }
                    if (this.selectedCompany.name) {
                        return this.selectedCompany.name;
                    }
                    if (this.item.company && this.item.company.name) {
                        return this.item.company.name;
                    }

                },
                set(companyName) {
                    this.companyName = companyName;
                }
            },
            selectedCompany: {
                get() {
                    if (this.company) {
                        return this.company;
                    }
                    if (this.item.company) {
                        return this.item.company;
                    }
                    return {
                        billingAddress: {
                            houseNumber: null,
                            streetAddress: null,
                            addressSuffix: null,
                            addressCountry: null,
                            addressLocality: null,
                            postalCode: null
                        },
                        billingPeriod: DEFAULT_BILLING_PERIOD,
                        collectiveInvoice: false,
                        description: null,
                        fax: null,
                        lineOfBusiness: null,
                        name: null,
                        phone: null,
                        url: null,
                        vatId: null
                    };
                },
                set(company) {
                    this.company = company;
                    this.handleUpdateField('company', company);
                }
            },
            selectedLineOfBusiness: {
                get() {
                    if (this.lb) {
                        return this.lb;
                    }
                    const selectedLB = this.lineOfBusiness.find(line => line['@id'] == this.selectedCompany.lineOfBusiness);
                    return selectedLB;
                },
                set(lineOfBusiness) {
                    this.lb = lineOfBusiness;
                    this.handleUpdateCompanyField('lineOfBusiness', this.lb['@id']);
                }
            }
        },
        watch: {
            item(item) {
                if (item && item.company) {
                    this.company = item.company;
                    this.lb = this.lineOfBusiness.find(line => line['@id'] == item.company.lineOfBusiness);
                }
            }
        }
    }
</script>
