<template>
  <layout-default>
    <!-- LOADING AND NOTIFICATIONS START -->
    <Loading
      v-if="isLoading || topicIsLoading || mediaObjectIsLoading"
      class="alert alert-info"
      role="status">
    </Loading>
    <InlineNotification
      v-if="error"
      notificationClass="bx--inline-notification bx--inline-notification--error"
      kind="error"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-close--glyph"
      :title="error"
      :subtitle="$t('try_again')"
      :closeButton="true"
    />

    <InlineNotification
      notificationClass="bx--inline-notification bx--inline-notification--success"
      kind="success"
      role="alert"
      icon="lk-carbon-icon lk-carbon-icon-checkmark"
      :title="$t('success')"
      :subtitle="$t('profile_updated')"
      :closeButton="true"
      v-if="updated"
    />
    <!-- LOADING AND NOTIFICATIONS END -->

    <!-- INTRO START -->
    <div
      class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom"
      v-if="account.user && !account.user.active">
      <div class="bx--row">
        <div class="bx--col-xs-12 bx--col-md-12">
          <h1 class="lk-form__onboarding-headline">{{ $t('welcome_title') }}</h1>
          <p class="lk-form__intro-text">{{ $t('welcome_description') }}</p>

          <ul class="lk-form__step-list bx--list--unordered">
            <li class="bx--list__item">
              <span class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
              {{ $t('currenct_contact_details') }}
            </li>
            <li class="bx--list__item">
              <span class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
              {{ $t('skills') }}
            </li>
            <li class="bx--list__item">
              <span class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
              {{ $t('proof_of_qualification') }}
            </li>
            <li class="bx--list__item">
              <span class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
              {{ $t('photo_optional') }}
            </li>
            <li class="bx--list__item">
              <span class="lk-carbon-icon lk-carbon-icon-checkmark--glyph"></span>
              {{ $t('account_details') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- INTRO END -->

    <form class="lk-form" @submit.prevent="handleSubmit" enctype="multipart/form-data" v-if="item">
      <!-- Contact Details START -->
      <ContactDetailsForm
        :countries="countries"
        :get-violation="getViolation"
        :is-invalid="isInvalid"
        :config="config[config.lang].contactDetailsFormConfig"
        :handle-update-field="handleUpdateField"
        :handle-update-address-country-field="handleUpdateAddressCountryField"
        :handle-update-timezone-field="handleUpdateTimezoneField"
        :handle-update-address-field="handleUpdateAddressField"
        :item-timezone="itemTimezone"
        :selected-timezone="selectedTimezone"
        :item="item"
        :timezones="timezones"
        :showAdditionalText="true"
        :search-for-country="searchCountry"
        :is-loading="countriesLoading"
        :$v="$v">
      </ContactDetailsForm>
      <!-- Contact Details END -->

      <div
        class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom">
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-md-12">
            <SkillsForm
              :$v="$v"
              :config="config[config.lang].skillsConfig"
              :getViolation="getViolation"
              :handleUpdateField="handleUpdateField"
              :setNativeLanguage="setNativeLanguage"
              :getNativeLanguage="getNativeLanguage"
              :setItemSkills="setItemSkills"
              :getItemSkills="getItemSkills"
              :skillOptions="skillOptions"
            ></SkillsForm>
            <!-- Skills START -->
            <div
              class="lk-container lk-container--onboarding lk-container--margin-bottom">
              <div class="bx--row">
                <div class="bx--col-xs-12 bx--col-md-12">


                  <div v-if="selectedSkills != null">
                    <div id="tab-panel-1" class="tab-1" role="tabpanel" aria-labelledby="tab-link-1"
                         v-if="selectedSkills.includes(skillOptions[0])">
                      <!-- TEXT TRANSLATION SKILLS START -->
                      <TextTranslationSkillsForm
                        :$v="$v"
                        :topic-items="topicItems"
                        :skill-status-readonly="true"
                        :all-languages-options="languages"
                        :config="config[config.lang].textTranslationSkillsForm"
                        :selected-skills="selectedSkills"
                        :skill-options="skillOptions"
                        :showStatus="showStatus"
                        :get-violation="getViolation"
                        :action-statuses="actionStatuses"
                        :software-skills="itemSoftwareSkills"
                        :text-translation-skills="textTranslationSkills"
                        :remove-text-translation-skill="removeTextTranslationSkill"
                        :update-text-translation-skills="updateSkills"
                        :update-software-skills="updateSoftwareSkills"
                        :add-text-translation="addTextTranslationSkill">
                      </TextTranslationSkillsForm>
                      <!-- TEXT TRANSLATION SKILLS END -->
                    </div>
                    <div id="tab-panel-2" class="tab-2" role="tabpanel" aria-labelledby="tab-link-2"
                         v-if="selectedSkills.includes(skillOptions[1])">
                      <!-- INTERPRETATION SKILLS START -->
                      <InterpretationSkillsForm
                        :$v="$v"
                        :config="config[config.lang].interpretationSkillsFormConfig"
                        :skill-status-readonly="true"
                        :topic-items="topicItems"
                        :showStatus="showStatus"
                        :all-languages-options="languages"
                        :selected-skills="selectedSkills"
                        :skill-options="skillOptions"
                        :action-statuses="actionStatuses"
                        :get-violation="getViolation"
                        :interpretation-skills="interpretationSkills"
                        :remove-interpretation-skill="removeInterpretationSkill"
                        :update-skills="updateSkills"
                        :add-interpretation-skill="addInterpretationSkill">
                      </InterpretationSkillsForm>
                      <!-- INTERPRETATION SKILLS END -->
                    </div>
                    <div id="tab-panel-3" class="tab-3" role="tabpanel" aria-labelledby="tab-link-3"
                         v-if="selectedSkills.includes(skillOptions[2])">
                      <!-- VOICE SKILLS START -->
                      <VoiceSkillsForm
                        :$v="$v"
                        :config="config[config.lang].voiceSkillsForm"
                        :skill-status-readonly="true"
                        :handle-update-field="handleUpdateField"
                        :topic-items="topicItems"
                        :all-languages-options="languages"
                        :showStatus=true
                        :selected-skills="selectedSkills"
                        :is-invalid="isInvalid"
                        :skill-options="skillOptions"
                        :get-violation="getViolation"
                        :action-statuses="actionStatuses"
                        :voice-skills="telephoneInterpretationSkills"
                        :remove-voice-skill="removeVoiceSkill"
                        :update-skills="updateSkills"
                        :add-voice-skill="addVoiceSkill"
                        :voice-phone-number="item.voicePhoneNumber">
                      </VoiceSkillsForm>
                      <!-- VOICE SKILLS END -->
                    </div>
                  </div>
                  <!-- SKILLS PER TYPES END -->

                </div>
              </div>
            </div>
            <!-- Skills END -->

          </div>
        </div>
      </div>
      <div
        class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom">
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-md-12">
            <!-- Application Documents START -->
            <ApplicationDocumentsForm
              :cv-change="cvChange"
              :references-change="referencesChange"
              :download-action="downloadAction"
              :error="uploadError"
              :cvs="itemCurriculumVitaes"
              :references="itemReferences"
              :cv-files="curriculumVitaesFiles"
              :references-files="referencesFiles"
              :remove-c-v="removeCV"
              :remove-reference="removeReference"
              :$v="$v"
              :config="config[config.lang].applicationDocumentsForm">
            </ApplicationDocumentsForm>
            <!-- Application Documents END -->
          </div>
        </div>
      </div>
      <div
        class="lk-container lk-container--onboarding lk-container--inner lk-container--white lk-container--layer-raised lk-container--margin-bottom">
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-md-12">            <!-- Profile Image START -->
            <ProfilePhotoForm
              :profile-image-change="profileImageChange"
              :profile-image="itemProfileImage"
              :profile-image-file="profileImageFile"
              :remove-profile-image="removeProfileImage"
              :$v="$v"
              :config="config[config.lang].profilePhotoForm">
            </ProfilePhotoForm>
            <!-- Profile Image END -->
          </div>
        </div>
      </div>

      <!-- Payment Section START -->
      <PaymentForm
        :default-config="config[config.lang].paymentForm"
        :is-invalid="isInvalid"
        :handle-update-payment-data="handleUpdatePaymentData"
        :handle-update-payment-method="handleUpdatePaymentMethod"
        :get-violation="getViolation"
        :payment-methods="paymentMethods"
        :can-disable-small-company-by-country="true"
        :item="item"
        :$v="$v">
      </PaymentForm>
      <!-- Payment Section END -->

      <!-- Form Submit Section START -->
      <div class="lk-container">
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-md-12">
            <InlineNotification
              v-if="$v.$error"
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              title="Sorry!"
              :subtitle="$t('invalid_data')"
              :closeButton="false"
            />
            <InlineNotification
              v-if="uploadError || createProfileImageError"
              notificationClass="bx--inline-notification bx--inline-notification--error"
              kind="error"
              role="alert"
              icon="lk-carbon-icon lk-carbon-icon-close--glyph"
              :title="uploadError || createProfileImageError"
              :subtitle="$t('try_again')"
              :closeButton="true"
            />

            <!-- Submit Button -->
            <div class="lk-form__actionbar">
              <button class="bx--btn bx--btn--primary" type="submit" :disabled="isLoading">
                {{ account.user && account.user.active ? $t('save') : $t('register_complete')}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Form Submit Section END -->
    </form>
  </layout-default>
</template>

<script>
  import 'lingoking-workbench/src/components/form/form.scss'

  import {mapGetters, mapState, mapActions} from 'vuex'
  import {isEuCountry} from 'eu-country-check'
  import {required, minLength, requiredIf} from 'vuelidate/lib/validators'
  import {inArray} from './../../validators'
  import {PaymentMethodsServiceProvider} from 'lingoking-workbench/src/enums/index';
  import LayoutDefault from './../layouts/LayoutDefault.vue'
  import {
    UserTypes,
    UserTitles,
    Timezones,
    ProductTypes,
    SkillQualities
  } from 'lingoking-workbench/src/enums'
  import InlineNotification from 'lingoking-workbench/src/components/notification/InlineNotification.vue'
  import Loading from 'lingoking-workbench/src/components/loading/Loading.vue'
  import ContactDetailsForm from 'lingoking-workbench/src/components/linguist/ContactDetailsForm'
  import ApplicationDocumentsForm from 'lingoking-workbench/src/components/linguist/ApplicationDocumentsForm'
  import ProfilePhotoForm from 'lingoking-workbench/src/components/linguist/ProfilePhotoForm'
  import PaymentForm from 'lingoking-workbench/src/components/linguist/PaymentForm'
  import SkillsForm from 'lingoking-workbench/src/components/linguist/SkillsForm'
  import TextTranslationSkillsForm from 'lingoking-workbench/src/components/linguist/SkillsForm/TextTranslationSkillsForm'
  import VoiceSkillsForm from 'lingoking-workbench/src/components/linguist/SkillsForm/VoiceSkillsForm'
  import InterpretationSkillsForm from 'lingoking-workbench/src/components/linguist/SkillsForm/InterpretationSkillsForm'

  const ibantools = require('ibantools')

  export default {
    components: {
      InterpretationSkillsForm,
      PaymentForm,
      SkillsForm,
      ProfilePhotoForm,
      ApplicationDocumentsForm,
      ContactDetailsForm,
      LayoutDefault,
      InlineNotification,
      Loading,
      TextTranslationSkillsForm,
      VoiceSkillsForm
    },

    data() {
      const skillForm = {
        en: {
          note: 'Please note that your skill has not yet been reviewed or accredited by lingoking.',
          sourceLabel: 'Source language',
          sourceRequired: 'Please select a source language.',
          targetError: 'Please select a target language.',
          areaLabel: 'Areas of expertise',
          bidirectionalLabel: 'Bidirectional',
          simultaneousLabel: 'Simultaneous',
          swormLabel: 'Sworn',
          actionStatusLabel: 'Action status',
          rejectReasonLabel: "Reject reason"
        },
        de: {}
      };

      return {
        //TODO this should be in each form component!!!!!
        config: {
          en: {
            applicationDocumentsForm: {
              title: '3. Your application documents',
              label: 'Curriculum vitae.*',
              description: 'Please upload your curriculum vitae.*',
              remove_title: 'Remove',
              legend_label: 'Reference / testimonials',
              upload_description: 'Please upload at least one reference or testimonial.*',
              upload_description_message: "Only documents allowed (msword, ms-excel, ms-powerpoint, .txt, .pdf, .jpeg, .png).",
              upload_remove_title: 'Remove'
            },
            paymentForm: {
              showSmallCompanyCheckbox: true
            },
            profilePhotoForm: {
              label: 'Profile photo',
              remove: 'Remove',
              title: "4. Your profile summary",
              subtitle: 'Profile photo',
              description_1: 'You are able to enhance your public profile by uploading a profile picture, however this is\n' +
              '                        optional.\n' +
              '                        Please note that the profile picture you enter will be visible in your public profile.\n' +
              '                    ',
              description_2: 'Important: In order to submit your registration to lingoking please scroll to the bottom of\n' +
              '                            the page and click the \'complete registration\' button. Please also keep in mind the Data\n' +
              '                            Protection Declaration as well as our TOB.\n' +
              '                            Write something about yourself for the customer to see. Please do not enter any data such as\n' +
              '                            your full name, e-mail address or telephone number that could identify you personally.\n' +
              '                        ',
              upload_description: 'only .jpg and .png files. 500kb max file size.'

            },
            voiceSkillsForm: {
              title: 'Telephone interpreting: Skills',
              subtitle: 'Your telephone number for telephone interpreting',
              phoneRequired: "Telephone number is required",
              additionalSkills: 'Additional telephone interpreting skills',
              phoneLabel: 'Telephone number*',
              phonePlaceholder: 'Telephone number',
              skillsForm: skillForm.en
            },
            interpretationSkillsFormConfig: {
              title: "Interpreting: Skills",
              description: 'Bitte geben Sie hier alle Sprachkombinationen an, in denen Sie arbeiten. Setzen Sie, wenn zutreffend, den Haken bei "Bidirektional", "Simulatan" und "Beeidigt".',
              additionalSkills: "Additional interpreting skill",
              skillsForm: skillForm.en
            },
            textTranslationSkillsForm: {
              title: 'Text translation: Skills',
              content: 'Bitte geben Sie hier alle Sprachkombinationen an, in denen Sie arbeiten.\n' +
              '       Setzen Sie, wenn zutreffend, den Haken bei "Bidirektional" und/oder "Beedigt".\n' +
              '       Wenn Sie über hier aufgezählte Software-Kompetenzen verfügen, geben Sie diese bitte auch an.',
              softwareSkills: "Software skills",
              subtitle: 'Please remember to indicate your software skills.',
              catToolslabel: 'CAT tools',
              dptTools: 'DTP tools',
              cmsToolsLabel: 'CMS tools',
              additionalTranslation: 'Additional text translation skill',
              skillsForm: skillForm.en
            },
            skillsConfig: {
              skillsPlaceholder: "Add your skills",
              skillsTitle: "2. Your Skills",
              skillDescription: "As lingoking language service provider, you have the opportunity to work as a translator, an interpreter or a telephone interpreter. Please specify the language services you wish to offer. You can add more details in the next steps. You can specify one or several skills.",
              skillsRequired: 'At least one skill is required.',
              skillsAdditionallNote: 'Please note',
              skillsSubtitle: 'Your skills must first be accredited by lingoking before you are offered suitable jobs.',
              title: "Your skills",
              skillsLabel: 'Skills',
              NaviteLanguage: {
                title: "Native Language",
                selectNativeLanguageDescription: "Please select your native language.",
                nativeLanguageLabel: "Native Languages",
                nativeLanguageError: "At least one native language is required.",
                nativeLanguageDescription: "If you have more than one native language, please specify."
              },
              skills: {
                title: "Skills"
              },
              showAdditionalText: true
            },
            contactDetailsFormConfig: {
              "showSmallCompanyCheckbox": true,
              "smallCompanyLabel": "Kleinunternehmen",
              "title": "1. Your Contact Details",
              "salutationTitle": "Anrede",
              "firstNameLabel": "Vorname",
              "lastName": "Nachname",
              "addressTitle": "Adresse",
              "streetLabel": "Strasse, Hausnummer",
              "postCodeLabel": "Postleitzahl",
              "cityLabel": "Ort *",
              "addressCountryLabel": "Land *",
              "contactLabel": "Contact",
              "phoneNumberLabel": "Telephone number *",
              "mobilePhoneLabel": "Mobile phone number",
              "faxLabel": "Fax number",
              "emailLabel": "E-Mail",
              "emailAddressLabel": "Ihre E-Mail Addresse",
              "timezoneLabel": "Zeitzone",
              "selectTimeZoneLabel": "Select a timezone.",
              "salutationLabel": "Bitte geben sie eine Anredeform an.",
              "salutation_required_error": "Bitte geben sie eine Anredeform an.",
              "mr": "Frau",
              "mis": "Herr",
              "userTitle_required": "Anredeform wird benötigt",
              "firstName_required": "Vorname wird benötigt",
              "lastName_required": "Nachname wird benötigt",
              "addressTitle_description": " Teilen sie uns bitte Ihre Anschrift mit.",
              "streetAddress_required": "Strasse wird benötigt",
              "postalCode_required": "Postleitzahl wird benötigt",
              "addressLocality_required": "Ort wird benötigt",
              "addressCountry_required": "Land wird benötigt",
              "addressCountry_inList": "Land aus der Liste wählen",
              "phoneNumber_description": "Your phone number",
              "telephone_required": "Telephone number is required",
              "email_description": "Ihre E-Mail Addresse",
              "timezone_required": "Zeitzone wird benötigt",
              "timezone_inEnum": "Zeitzone aus der Liste wählen",
              "timezone_description": "Ihre Zeitzone",
              "timezoneLabel_description": "Um für Aufträge den Liefertermin korrekt berechnen zu lassen, geben Sie bitte hier die Zeitzone an, in der Sie leben."
            },
          },
          de: {},
          lang: 'en'
        },
        default: {
          billingAddress: {
            streetAddress: null,
            houseNumber: null,
            addressSuffix: null,
            addressCountry: null,
            addressLocality: null,
            postalCode: null
          },
          voicePhoneNumber: null,
          userType: null,
          userTitle: null,
          firstName: null,
          lastName: null,
          timezone: null,
          email: null,
          paymentMethod: null,
          paymentData: {
            iban: null,
            bic: null,
            taxNumber: null,
            accountHolder: null,
            paypalAddress: null,
            vatId: null,
            smallCompany: false
          },
          telephone: null,
          mobilePhone: null,
          faxNumber: null,
          nativeLanguages: null,
          skillTypes: null,
          skills: null,
          curriculumVitaes: [],
          references: [],
        },
        actionStatuses: ['accredited', 'in review', 'denied'],
        userTypes: UserTypes,
        userTitles: UserTitles,
        timezones: Timezones,
        paymentMethods: PaymentMethodsServiceProvider,
        selectedTimezone: null,
        selectedNativeLanguages: null,
        selectedSkills: null,
        skillOptions: [ProductTypes.PRODUCT_TYPE_TEXT_TRANSLATION, ProductTypes.PRODUCT_TYPE_INTERPRETER, ProductTypes.PRODUCT_TYPE_VOICE],
        profileImageFormData: null,
        cvForms: [],
        referencesForms: [],
        curriculumVitaesFiles: [],
        referencesFiles: [],
        profileImageFile: [],
        uploadedCv: false,
        uploadedReference: false,
        updateUser: false,
        softwareQualities: [SkillQualities.BIDIRECTIONAL, SkillQualities.SIMULTANEOUS, SkillQualities.SWORN],
        textTranslationSkillDefault: {
          sourceLanguage: null,
          targetLanguage: null,
          topics: [],
          bidirectional: false,
          sworn: false,
          skillQualities: [],
          skillType: ProductTypes.PRODUCT_TYPE_TEXT_TRANSLATION
        },
        interpretationSkillDefault: {
          sourceLanguage: null,
          targetLanguage: null,
          topics: [],
          bidirectional: false,
          simultaneous: false,
          sworn: false,
          skillQualities: [],
          skillType: ProductTypes.PRODUCT_TYPE_INTERPRETER
        },
        voiceSkillDefault: {
          sourceLanguage: null,
          targetLanguage: null,
          topics: [],
          bidirectional: true,
          skillQualities: [SkillQualities.BIDIRECTIONAL],
          skillType: ProductTypes.PRODUCT_TYPE_VOICE,
        },
        textTranslationSkills: [],
        interpretationSkills: [],
        telephoneInterpretationSkills: [],
        allSkills: [],
        voicePhoneNumber: null,
        tabsInstance: null,
        selectedAddressCountry: null,
        loading: false,
        activateUser: true
      }
    },

    validations() {
      if (this.item && this.item.paymentMethod && this.item.paymentMethod === PaymentMethodsServiceProvider.PAYMENT_METHOD_PAYPAL) {
        return {
          item: this.paymentMethodPayPalValidations
        }
      }
      else if (this.item && this.item.paymentMethod && this.item.paymentMethod === PaymentMethodsServiceProvider.PAYMENT_METHOD_BANK_TRANSFER) {
        return {
          item: this.paymentMethodBankTransferValidations
        }
      }
      else {
        return {
          item: this.linguistValidations
        }
      }
    },

    computed: {
      ...mapState({
        account: state => state.account
      }),
      ...mapGetters({
        //Linguist
        userLoading: 'linguist/update/isLoading',
        error: 'linguist/update/error',
        retrieved: 'linguist/update/retrieved',
        updated: 'linguist/update/updated',
        violations: 'linguist/update/violations',
        //Relations
        countries: 'country/list/items',
        countriesLoading: 'country/list/isLoading',
        languages: 'language/list/items',
        //Topics
        topicError: 'topic/list/error',
        topicItems: 'topic/list/items',
        topicIsLoading: 'topic/list/isLoading',
        //Profile Image
        createdProfileImage: 'profileimage/create/created',
        isUploadingProfileImage: 'profileimage/create/isLoading',
        createProfileImageError: 'profileimage/create/error',
        //File Upload
        uploadError: 'linguist/update/uploadError',
        uploadViolations: 'linguist/update/uploadViolations',
        uploadUpdated: 'linguist/update/uploadUpdated',
        isUploadingMediaObject: 'linguist/update/fileUploadIsLoading',
        //File Download
        downloadedMediaObject: 'mediaobject/show/downloaded',
        mediaObjectIsLoading: 'mediaobject/show/isLoading',
      }),
      item: {
        get() {
          if (this.retrieved) {
            if (!this.retrieved.billingAddress) {
              this.handleUpdateField('billingAddress', {}, false)
            }
            else if (!this.selectedAddressCountry && this.retrieved.billingAddress && this.retrieved.billingAddress.addressCountry && this.retrieved.billingAddress.addressCountry.id) {
              this.selectedAddressCountry = this.retrieved.billingAddress.addressCountry.id
              this.handleUpdateAddressCountryField(this.selectedAddressCountry, false)
            }

            if (!this.retrieved.paymentData) {
              this.handleUpdateField('paymentData', {}, false)
            }


            if (!this.selectedSkills && this.retrieved.skillTypes && this.retrieved.skillTypes.length) {
              this.selectedSkills = this.retrieved.skillTypes

              if (this.retrieved.skills) {
                this.setSkillsPerType(this.retrieved.skills)
              }

              // get topics
              if (!this.topicItems.length) {
                this.getTopics()
              }
            }
          }

          return this.retrieved || this.default
        }
      },
      isLoading() {
        return this.userLoading || this.countriesLoading || this.isUploadingMediaObject || this.isUploadingProfileImage || this.loading
      },
      itemNativeLanguages: {
        get: function () {
          return this.selectedNativeLanguages || this.item.nativeLanguages
        },
        set: function (newValue) {
          this.selectedNativeLanguages = newValue;
          this.handleUpdateField('nativeLanguages', this.prepareLanguages(newValue))
        }
      },
      itemTimezone: {
        get: function () {
          return this.selectedTimezone || this.item.timezone
        },
        set: function (newValue) {
          this.selectedTimezone = newValue
          this.handleUpdateField('timezone', newValue)
        }
      },


      itemSkills: {
        get: function () {
          return this.selectedSkills || this.item.skillTypes
        },
        set: function (newValue) {
          this.selectedSkills = newValue
          this.handleUpdateField('skillTypes', newValue)
          this.updateSkills()

          // get topics
          if (!this.topicItems.length) {
            this.getTopics()
          }
        }
      },

      itemCurriculumVitaes() {
        return this.item ? this.item.curriculumVitaes : []
      },

      itemReferences() {
        return this.item ? this.item.references : []
      },

      itemProfileImage() {
        return this.item ? this.item.profileImage : null
      },

      itemSoftwareSkills() {
        return this.item ? this.item.softwareSkills : []
      },

      linguistValidations() {
        return {
          userType: {
            required,
            inEnum: inArray(Object.values(UserTypes))
          },
          userTitle: {
            required,
            inEnum: inArray(Object.values(UserTitles))
          },
          firstName: {
            required,
            minLength: minLength(3)
          },
          lastName: {
            required,
            minLength: minLength(1)
          },
          email: {
            required,
            minLength: minLength(3)
          },
          timezone: {
            required
          },
          billingAddress: {
            houseNumber: {
              required
            },
            streetAddress: {
              required,
              minLength: minLength(3)
            },
            postalCode: {
              required,
              minLength: minLength(3)
            },
            addressLocality: {
              required,
              minLength: minLength(3)
            },
            addressCountry: {
              required,
              minLength: minLength(2),
            }
          },
          telephone: {
            required,
            minLength: minLength(3)
          },
          nativeLanguages: {
            required,
            minLength: minLength(1),
            $each: {
              required
            }
          },
          paymentMethod: {
            required,
            inEnum: inArray(Object.values(PaymentMethodsServiceProvider))
          },
          skillTypes: {
            required
          },
          skills: {
            required,
            minLength: minLength(1),
            $each: {
              sourceLanguage: {
                required,
                minLength: minLength(1),
              },
              targetLanguage: {
                required,
                minLength: minLength(1),
              },
              skillType: {
                required,
                minLength: minLength(1),
              }
            }
          },
          curriculumVitaes: {
            requiredIf: requiredIf(() => {
              return !this.curriculumVitaesFiles.length
            }),
          },
          references: {
            requiredIf: requiredIf(() => {
              return !this.referencesFiles.length
            })
          },
          voicePhoneNumber: {
            minLength: minLength(3),
            required: requiredIf(function () {
              return this.item.skillTypes.includes(ProductTypes.PRODUCT_TYPE_VOICE)
            })
          }
        }
      },

      paymentMethodPayPalValidations() {
        return Object.assign({}, this.linguistValidations, {
          paymentData: {
            required,
            paypalAddress: {
              required,
              minLength: minLength(3),
            },
            taxNumber: {
              minLength: minLength(3),
            },
            vatId: {
              requiredIf: requiredIf(() => {
                const addressCountry = this.item.billingAddress.addressCountry
                if (!addressCountry || (addressCountry === 'DE' && this.item.paymentData.smallCompany)) {
                  return false
                }
                return isEuCountry(addressCountry)
              }),
              minLength: minLength(3),
            },
            smallCompany: {

            }
          }
        })
      },

      paymentMethodBankTransferValidations() {
        const isValidIBAN = (value) => ibantools.isValidIBAN(value)
        const isValidBIC = (value) => ibantools.isValidBIC(value)

        return Object.assign({}, this.linguistValidations, {

          paymentData: {
            required,
            iban: {
              required,
              isValidIBAN
            },
            bic: {
              required,
              isValidBIC
            },
            taxNumber: {
              minLength: minLength(3),
            },
            accountHolder: {
              required,
              minLength: minLength(3),
            },
            vatId: {
              requiredIf: requiredIf(() => {
                const addressCountry = this.item.billingAddress.addressCountry
                if (!addressCountry || (addressCountry === 'DE' && this.item.paymentData.smallCompany)) {
                  return false
                }
                return isEuCountry(addressCountry)
              }),
              minLength: minLength(3),
            },
            smallCompany: {

            }
          },
        })
      },

      showStatus () {
        return this.account.user && this.account.user.active || false
      }
    },

    watch: {
      createdProfileImage: function (created) {
        if (!created) {
          return
        }

        if (created['@id']) {
          //reset profile data
          this.profileImageFormData = null;
          this.profileImageFile = []
          //add profile image to user
          this.handleUpdateField('profileImage', created['@id'])
        }
      },

      updated: function (updated) {
        if (!updated) {
          return
        }
        this.$scrollTo('body')
      },
      error: function(){
        this.$scrollTo('body');
      },

      uploadUpdated: function (updated) {
        if (!updated) {
          return
        }

        // Reset the file forms
        this.curriculumVitaesFiles = []
        this.referencesFiles = []
        this.profileImageFile = []

        this.handleUpdateField('curriculumVitaes', updated.curriculumVitaes)
        this.handleUpdateField('references', updated.references)
        this.handleUpdateField('profileImage', updated.profileImage)

        // if all the required data field is filled, then the account will be activated
        if (this.account.user && !this.account.user.active && updated && updated.curriculumVitaes && updated.curriculumVitaes.length && updated.references && updated.references.length) {
          this.activate();

            setTimeout(() => {
            this.registerAlertSuccess();
          });
        } 
      },

      downloadedMediaObject: function (mediaObject) {
        //console.log('downloaded media object', mediaObject)
      }
    },

    mounted() {
      this.getLanguages('/languages?pagination=false&enabled=true')
      this.getCountries()
      this.retrieve(decodeURIComponent('users/' + this.account.user.id))
      // easy debugging
      window.$v = this.$v
    },

    beforeDestroy() {
      this.reset()
    },

    methods: {
      ...mapActions({
        retrieve: 'linguist/update/retrieve',
        updateReset: 'linguist/update/reset',
        update: 'linguist/update/update',
        updateRetrieved: 'linguist/update/updateRetrieved',
        getCountries: 'country/list/default',
        //getTimezones: 'timezone/list/default',
        activate: 'account/activate',
        getLanguages: 'language/list/default',
        createMediaObject: 'mediaobject/create/create',
        createProfileImage: 'profileimage/create/create',
        uploadFiles: 'linguist/update/upload',
        getTopics: 'topic/list/default',
        registerAlertSuccess: 'account/registerAlertSuccess',
        downloadMediaObject: 'mediaobject/show/download'
      }),
      setItemSkills(newValue) {
        return this.itemSkills = newValue;
      },
      getItemSkills() {
        return this.itemSkills;
      },
      handleSubmit(e) {
        // prepare data
        this.prepareItemRelations()

        // be sure to run validation at least once
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.updateUser = false
          this.update().then(() => {
            // if a profile image is available, upload it to the server
            if (this.profileImageFormData) {
              if (this.profileImageFormData.has('user')) {
                this.profileImageFormData.delete('user')
              }
              this.profileImageFormData.append('user', this.item['id'])
              this.createProfileImage(this.profileImageFormData).then(() => this.uploadCvsAndReferences())
            }
            else {
              this.uploadCvsAndReferences()
            }
          })
        }
      },
      setNativeLanguage(newValue) {
        this.itemNativeLanguages = newValue;
      },
      getNativeLanguage() {
        return this.itemNativeLanguages;
      },
      uploadCvsAndReferences() {
        let fileUploadForm = null
        if (this.curriculumVitaesFiles && this.curriculumVitaesFiles.length) {
          //delete this.item.curriculumVitaes

          fileUploadForm = new FormData()
          for (let key in this.curriculumVitaesFiles) {
            fileUploadForm.append('curriculumVitaesFiles[]', this.curriculumVitaesFiles[key]['file'], this.curriculumVitaesFiles[key]['filename'])
          }
          //this.createMediaObject(fileUploadForm)
          fileUploadForm.append('user', this.item['id'])
        }

        if (this.referencesFiles && this.referencesFiles.length) {
          //delete this.item.references
          if (!fileUploadForm) {
            fileUploadForm = new FormData()
          }
          for (let key in this.referencesFiles) {
            fileUploadForm.append('referencesFiles[]', this.referencesFiles[key]['file'], this.referencesFiles[key]['filename'])
          }
        }

        if (fileUploadForm) {
          this.uploadFiles({fileUploadForm: fileUploadForm, userId: this.item['id']})
        }
      },

      getViolation(...path) {
        return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, this.violations || {})
      },
      isInvalid(...path) {
        var error = path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, this.$v.item)
        return error ? error.$error || this.getViolation(path) : null
      },
      reset() {
        this.updateReset()
      },

      handleUpdateField(field, value, touch = true) {
        this.updateRetrieved({[field]: value})
        if (this.getViolation(field))
          if (touch) this.$v.item[field].$touch()
      },
      handleUpdateAddressField(field, value, touch = true) {
        var address = Object.assign({}, this.item.billingAddress, {[field]: value})
        this.updateRetrieved({['billingAddress']: address})
        if (touch) this.$v.item.billingAddress[field].$touch()
      },
      handleUpdateAddressCountryField(value, touch = true) {
        this.handleUpdateAddressField('addressCountry', value, touch)
      },
      handleUpdateTimezoneField(value) {
        this.handleUpdateField('timezone', value)
      },
      handleUpdatePaymentData(field, value) {
        var paymentData = Object.assign({}, this.item.paymentData, {[field]: value})
        this.updateRetrieved({['paymentData']: paymentData})
      },
      handleUpdatePaymentMethod(value) {
        this.handleUpdateField('paymentMethod', value)
      },
      prepareLanguages(options) {
        let preparedLanguages = []
        if (options.length) {
          for (let key in options) {
            let languageOption = JSON.parse(JSON.stringify(options[key]))
            preparedLanguages.push(languageOption['@id'])
          }
        }
        return preparedLanguages
      },
      profileImageChange(fieldName, fileList) {
        let tmpProfileImages = []

        if (fileList.length) {
          this.profileImageFormData = new FormData()
          // handle file changes
          if (!fileList.length) return;
          // append the files to FormData
          Array
            .from(Array(fileList.length).keys())
            .map(x => {
              this.profileImageFormData.append('file', fileList[x].file, fileList[x].file.name)
              tmpProfileImages.push({file: fileList[x].file, filename: fileList[x].file.name})
            })

          this.profileImageFile = tmpProfileImages
        }
        else {
          this.profileImageFormData = null
        }

        // remove the old profile image
        this.handleUpdateField('profileImage', null)

        // this.createProfileImage(this.profileImageFormData)
      },
      cvChange(fieldName, fileList) {
        if (fileList.length) {
          this.curriculumVitaesFiles = fileList
        }
        else {
          this.curriculumVitaesFiles = []
          this.handleUpdateField('curriculumVitaes', [])
        }
      },
      referencesChange(fieldName, fileList) {
        if (fileList.length) {
          this.referencesFiles = fileList
        }
        else {
          this.referencesFiles = []
          this.handleUpdateField('references', [])
        }
      },
      updateSkills() {

        let preparedSkills = []
        if (this.item.skillTypes.includes(ProductTypes.PRODUCT_TYPE_TEXT_TRANSLATION)) {
          if (!this.textTranslationSkills.length) {
            this.textTranslationSkills.push({...this.textTranslationSkillDefault})
          }
          else {
            preparedSkills = this.prepareSkills(preparedSkills, this.textTranslationSkills)
          }
        }

        if (this.item.skillTypes.includes(ProductTypes.PRODUCT_TYPE_INTERPRETER)) {
          if (!this.interpretationSkills.length) {
            this.interpretationSkills.push({...this.interpretationSkillDefault})
          }
          else {
            preparedSkills = this.prepareSkills(preparedSkills, this.interpretationSkills)
          }
        }

        if (this.item.skillTypes.includes(ProductTypes.PRODUCT_TYPE_VOICE)) {
          if (!this.telephoneInterpretationSkills.length) {
            this.telephoneInterpretationSkills.push({...this.voiceSkillDefault})
          }
          else {
            preparedSkills = this.prepareSkills(preparedSkills, this.telephoneInterpretationSkills)
          }
        }

        if (preparedSkills.length) {
          this.handleUpdateField('skills', preparedSkills)
        }
      },
      updateSoftwareSkills(tools) {
        this.handleUpdateField('softwareSkills', tools)
      },
      addTextTranslationSkill() {
        this.textTranslationSkills.push({...this.textTranslationSkillDefault})
        this.updateSkills()
        //this.handleUpdateField('skills', this.textTranslationSkills)
      },
      addInterpretationSkill() {
        this.interpretationSkills.push({...this.interpretationSkillDefault})
        this.updateSkills()
      },
      addVoiceSkill() {
        this.telephoneInterpretationSkills.push({...this.voiceSkillDefault})
        this.updateSkills()
      },
      removeTextTranslationSkill(n) {
        this.textTranslationSkills.splice(n - 1, 1)
        this.updateSkills()
      },
      removeInterpretationSkill(n) {
        this.interpretationSkills.splice(n - 1, 1)
        this.updateSkills()
      },
      removeVoiceSkill(n) {
        this.telephoneInterpretationSkills.splice(n - 1, 1)
        this.updateSkills()
      },
      prepareSkills(preparedSkills, skills) {
        let self = this
        skills.forEach(function (skill) {
          skill = JSON.parse(JSON.stringify(skill))

          if (skill.sourceLanguage && skill.sourceLanguage['@id']) {
            skill.sourceLanguage = skill.sourceLanguage['@id']
          }

          if (skill.targetLanguage && skill.targetLanguage['@id']) {
            skill.targetLanguage = skill.targetLanguage['@id']
          }

          if (skill.topics && skill.topics.length) {
            skill.topics.forEach(function (topic, i) {
              skill.topics[i] = topic['@id']
            })
          }

          if (self.item['@id']) {
            skill.linguist = self.item['@id']
          }

          preparedSkills.push(skill)
        })
        return preparedSkills
      },
      setSkillsPerType(skills) {
        let self = this
        skills.forEach(function (skill) {
          skill = JSON.parse(JSON.stringify(skill))

          skill.skillQualities.forEach(function (skillQuality) {
            skill[skillQuality] = true
          })
          switch (skill.skillType) {
            case ProductTypes.PRODUCT_TYPE_TEXT_TRANSLATION:
              self.textTranslationSkills.push(skill)
              break
            case ProductTypes.PRODUCT_TYPE_INTERPRETER:
              self.interpretationSkills.push(skill)
              break
            case ProductTypes.PRODUCT_TYPE_VOICE:
              self.telephoneInterpretationSkills.push(skill)
            default:
          }
        })
      },
      prepareItemRelations() {
        //this.handleUpdateField('nativeLanguages', this.prepareLanguages(this.itemNativeLanguages))
        this.updateSkills()
      },

      removeCV(index) {
        if (this.item.curriculumVitaes && this.item.curriculumVitaes[index]) {
          let cvs = JSON.parse(JSON.stringify(this.item.curriculumVitaes))
          cvs.splice(index, 1)
          this.handleUpdateField('curriculumVitaes', cvs)
        }
      },

      removeReference(index) {
        if (this.item.references && this.item.references[index]) {
          let refs = JSON.parse(JSON.stringify(this.item.references))
          refs.splice(index, 1)
          this.handleUpdateField('references', refs)
        }
      },

      removeProfileImage() {
        if (this.item.profileImage) {
          this.handleUpdateField('profileImage', null)
          this.profileImageFormData = null
        }
      },

      searchCountry(searchString) {
        if(!this.countriesLoading && searchString.length > 3) {
          let searchFor = encodeURI(searchString.trim())
          this.getCountries('/countries?name=' + searchFor)
        }
      },

      downloadAction (mediaObject) {
        if(mediaObject) {
          this.downloadMediaObject(mediaObject)
        }
      }
    },

  }
</script>
