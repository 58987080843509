<template>
  <fieldset>
    <legend>{{ $t('skills_title') }}</legend>

    <p class="lk-form__desc-text" v-if="config.showAdditionalText">
      {{ $t('skills_desc') }}
    </p>

    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3" v-if="config.showAdditionalText">
      </div>
      <div v-bind:class="{ 'bx--col-md-9' : config.showAdditionalText, 'bx--col-md-12' : !config.showAdditionalText }">
        <div class="form-group bx--form-item bx--select-wrapper bx--select" :data-invalid="$v.item.skillTypes.$error" v-if="skillOptions">
          <multiselect
            :data-invalid="$v.item.skillTypes.$error"
            :class="[$v.item.skillTypes.$error ? 'is-invalid' : '']"
            :hideSelected="true"
            :multiple="true"
            :options="skillOptions"
            :taggable="false"
            id="linguist_skills"
            :placeholder="$t('skills_placeholder')"
            required="true"
            tag-placeholder="Add a skill"
            v-model="itemSkills"></multiselect>
          <label
            class="form-control-label bx--label"
            for="linguist_skills">{{ $t('skills_title') }} *</label>
          <div class="bx--form-requirement" v-if="$v.item.skillTypes.$error">
            {{ $t('skills_title') }} {{ $t('required') }}
          </div>
        </div>

        <div v-if="config.showAdditionalText" data-notification="" class="bx--inline-notification bx--inline-notification--warning" role="alert">
          <div class="bx--inline-notification__details">
            <span class="lk-carbon-icon lk-carbon-icon-warning"></span>
            <div class="bx--inline-notification__text-wrapper">
              <p class="bx--inline-notification__title">{{ $t('please_note') }}</p>
              <p class="bx--inline-notification__subtitle">{{ $t('skill_warning') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "Skills",
  components: {
    Multiselect
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    skillOptions: {
      type: Array,
      required: true
    },
    setItemSkills: {
      type: Function,
      required: true
    },
    getItemSkills: {
      type: Function,
      required: true
    },
    $v: {
      type: Object,
      required: true
    },
    getViolation: {
      type: Function,
      required: true
    }

  },
  computed: {
    itemSkills: {
      get: function () {
        return this.getItemSkills();
      },
      set: function (newValue) {
        this.setItemSkills(newValue);
      }
    },
  },
  methods: {

  }
}
</script>
