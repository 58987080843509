<template>
  <fieldset>
    <legend>
      <span class="lk-product-icon lk-product-icon-telefondolmetscher lk-button-icon--left"></span>
      {{ config.title }}
    </legend>

    <!-- Telephone START -->
    <div class="bx--row">
      <div class="bx--col-md-12">
        <div class="bx--row">
          <div class="lk-form-desc bx--col-md-3">
            {{ config.subtitle }}
          </div>
          <div class="lk-form-desc bx--col-md-9">
            <div class="bx--row">
              <div class="bx--col-md-12">
                <div class="bx--form-item bx--text-input-wrapper" >
                  <input
                    id="linguist_telephone-interpreting-number"
                    :data-invalid="isInvalid('voicePhoneNumber')"
                    :class="['bx--text-input', 'form-control', isInvalid('voicePhoneNumber') ? 'is-invalid' : '']"
                    type="text"
                    :placeholder="config.phonePlaceholder"
                    @input="handleUpdateField('voicePhoneNumber', $event.target.value)"
                    :value="voicePhoneNumber">
                  <label
                    for="linguist_telephone-interpreting-number"
                    class="form-control-label bx--label">{{ config.phoneLabel }}</label>

                  <div class="bx--form-requirement" v-if="$v.item.voicePhoneNumber.$error">
                    {{ config.phoneRequired }}
                  </div>
                  <div class="bx--form-requirement" v-if="getViolation('voicePhoneNumber')">
                    {{ getViolation('voicePhoneNumber') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Telephone END -->
    <SkillForm
      input-id-prefix="linguist_voice_skills"
      label="Telephone interpreting skill"
      :remove-skill="removeVoiceSkill"
      :$v="$v"
      :config="config.skillsForm"
      :showStatus=showStatus
      :skills="voiceSkills"
      :all-languages-options="allLanguagesOptions"
      :action-statuses="actionStatuses"
      :topic-items="topicItems"
      :update-skills="updateSkills"
      :skill-qualities="skillQualities"
      :skill-status-readonly="skillStatusReadonly">
    </SkillForm>
    <div class="bx--row">
      <div class="bx--col bx-col-md-6">
        <br />
        <button class="bx--btn bx--btn--primary lk-form__add-btn" type="button" @click="addVoiceSkill()">
          {{ config.additionalSkills }}
          <svg class="bx--btn__icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M7 7H4v2h3v3h2V9h3V7H9V4H7v3zm1 9A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" fill-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import './../../form/form.scss'
  import Multiselect from 'vue-multiselect'
  import SkillForm from './SkillForm.vue'
  import Tooltip from './../../tooltip/Tooltip.vue'
  import { SkillQualities } from './../../../enums';

  export default {
    name: 'VoiceSkillsForm',
    components: {
      SkillForm,
      Multiselect,
      Tooltip
    },
    props: {
      config: {
        type: Object,
        required: true
      },
      showStatus:{
        type: Boolean,
        required: true
      },
      $v: {
        type: Object,
        required: true
      },
      isInvalid: {
        type: Function,
        required: true
      },
      getViolation: {
        type: Function,
        required: true
      },
      allLanguagesOptions: {
        required: true
      },
      addVoiceSkill: {
        type: Function,
        required: true
      },
      removeVoiceSkill: {
        type: Function,
        required: true
      },
      selectedSkills: {
        required: true
      },
      skillOptions: {
        required: true
      },
      voiceSkills: {
        required: true
      },
      topicItems: {
        required: true
      },
      handleUpdateField: {
        required: true
      },
      voicePhoneNumber: {
        required: true
      },
      actionStatuses: {
        type: Array,
        required: true
      },
      updateSkills: {
        type: Function,
        required: true
      },
      skillStatusReadonly: {
        type: Boolean
      },
      showOnlyExpertiseArea: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      skillQualities() {
        let skillQualities = [
          {
            type: SkillQualities.BIDIRECTIONAL,
            required: true,
            disabled: true
          },
        ]
        if (this.showOnlyExpertiseArea) {
          skillQualities.push(SkillQualities.ONLY_EXPERTISE_AREA)
        }
        return skillQualities
      }
    }
  }
</script>
