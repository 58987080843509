import * as types from './mutation_types'

export default {
  [types.PASSWORD_TOKEN_CREATE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.PASSWORD_TOKEN_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.PASSWORD_TOKEN_CREATE_SET_CREATED] (state, created) {
    Object.assign(state, { created })
  },

  [types.PASSWORD_TOKEN_CREATE_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.PASSWORD_TOKEN_CREATE_RESET] (state) {
    Object.assign(state, {
      created: null,
      error: '',
      isLoading: false,
      violations: null,
      success: true
    })
  },

  [types.PASSWORD_TOKEN_CHECK_SUCCESS] (state, success) {
    Object.assign(state, { success })
  },
}
