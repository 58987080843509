import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const getItems = ({ commit }, orderId) => {
  commit(types.TOGGLE_LOADING)

  fetch('retrieve_chat_users/' + orderId)

    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data)
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}
