<template>
  <fieldset>
    <legend>{{ $t("settings_notifications") }}</legend>
    <div class="bx--row">
      <div class="lk-form-desc bx--col-md-3">
        <div class="lk-tooltip cv-interactive-tooltip">
          <Tooltip
            uid="lk-how-to-tip-timezone"
            direction="right"
            :hover="true"
            :visible="false"
          >
            <template slot="label">{{ $t("notifications") }}</template>
            <template slot="trigger">
              <span
                class="
                  lk-button-icon lk-button-icon--left
                  lk-how-to-tip__icon
                  lk-carbon-icon
                  lk-carbon-icon-info--glyph
                "
              ></span>
            </template>
            <template slot="content">
              <p>{{ $t("settings_notifications_tooltip") }}</p>
            </template>
          </Tooltip>
        </div>
      </div>
      <div class="bx--col-md-9">
        <div class="bx--row">
          <div class="bx--col-md-3 lk-checkbox-wrapper--margin-top">
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-newsletter"
                name="checkbox-newsletter"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.newsletter.isInvalid ? 'is-invalid' : '',
                ]"
                @change="updateField('newsletter', !newsletter)"
                :data-invalid="$v.newsletter.isInvalid"
                :checked="newsletter"
                :value="newsletter"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-newsletter"
              >
                {{ $t("newsletter") }}
              </label>
            </div>
          </div>
          <div class="bx--col-md-3 lk-checkbox-wrapper--margin-top">
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-notifications"
                name="checkbox-notifications"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.notificationEnabled.isInvalid ? 'is-invalid' : '',
                ]"
                @change="
                  updateField('notificationEnabled', !notificationEnabled)
                "
                :data-invalid="$v.notificationEnabled.isInvalid"
                :checked="notificationEnabled"
                :value="notificationEnabled"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-notifications"
              >
                {{ $t("email") }}
              </label>
            </div>
          </div>
          <div
            v-if="subscribeFeedbackEmailEnabled"
            class="bx--col-md-6 lk-checkbox-wrapper--margin-top"
          >
            <div class="form-group bx--form-item bx--checkbox-wrapper">
              <input
                id="checkbox-feedback"
                name="checkbox-feedback"
                type="checkbox"
                :class="[
                  'bx--checkbox',
                  'bx--checkbox-new',
                  'form-control',
                  $v.subscribeFeedbackEmail.isInvalid ? 'is-invalid' : '',
                ]"
                @change="
                  updateField('subscribeFeedbackEmail', !subscribeFeedbackEmail)
                "
                :data-invalid="$v.subscribeFeedbackEmail.isInvalid"
                :checked="subscribeFeedbackEmail"
                :value="subscribeFeedbackEmail"
              />
              <label
                class="form-control-label bx--checkbox-label"
                for="checkbox-feedback"
              >
                {{ $t("subscribe_feedback_email") }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Tooltip from "lingoking-workbench/src/components/tooltip/Tooltip.vue";

export default {
  name: "NotificationSettings",
  components: {
    Multiselect,
    Tooltip,
  },
  props: ["newsletter", "notificationEnabled", "updateRetrieved", "subscribeFeedbackEmail", "subscribeFeedbackEmailEnabled", "$v"],
  data() {
    return {
      currentLanguage: null,
    };
  },
  methods: {
    updateField(field, value) {
      this.updateRetrieved({ [field]: value });
    },
  },
};
</script>
