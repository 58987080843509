<template>
    <nav role="navigation" class="lk-backend-aside-nav">
        <ul class="lk-backend-aside-nav__list">
            <li
              :class="['lk-backend-aside-nav__item', {'lk-backend-aside-nav__item--selected lk-backend-aside-nav__item--active': isActive(routes)}]"
              v-for="routes in links">
                <router-link v-bind:key="routes.id"
                             :to="{name: routes.name}"
                             class="lk-backend-aside-nav__link" v-if="routes.name">
                    <span class="lk-backend-aside-nav__icon" v-bind:class="routes.menuIconClass" v-if="routes.menuIconClass"></span>
                    <span class="lk-backend-aside-nav__label">{{ $t(routes.label) || $t(routes.name) }}</span>
                </router-link>
                <a class="lk-backend-aside-nav__link" :href="routes.link" rel="noopener" :target="routes.target || '_self'" v-else-if="routes.link">
                    <span class="lk-backend-aside-nav__icon" v-bind:class="routes.menuIconClass" v-if="routes.menuIconClass"></span>
                    <span class="lk-backend-aside-nav__label">{{ $t(routes.label) || $t(routes.name) }}</span>
                </a>

                <ul class="lk-backend-submenu lk-backend-aside-nav__submenu" v-if="routes.children">
                    <li :class="['lk-backend-submenu__item', {'lk-backend-submenu__item--active' : isActive(childRoute) }]" v-for="childRoute in routes.children">
                      <router-link v-bind:key="childRoute.id"
                                   :to="{name: childRoute.name}"
                                   class="lk-backend-aside-nav__link" v-if="childRoute.name">
                        <span class="lk-backend-aside-nav__icon" v-bind:class="childRoute.menuIconClass" v-if="childRoute.menuIconClass"></span>
                        <span class="lk-backend-aside-nav__label">{{ $t(childRoute.label) || $t(childRoute.name) }}</span>
                      </router-link>
                      <a :href="childRoute.link" rel="noopener" :target="childRoute.target || '_self'" v-else-if="childRoute.link">
                        <span class="lk-backend-aside-nav__icon" v-bind:class="childRoute.menuIconClass" v-if="childRoute.menuIconClass"></span>
                        <span class="lk-backend-aside-nav__label">{{ $t(childRoute.label) || $t(childRoute.name) }}</span>
                      </a>
                    </li>
                </ul>
            </li>
        </ul>
      <router-view :key="$route.name"></router-view>
    </nav>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import UserRole from '../../enums/UserRoles'
  import customerAsideNav from '../../router/navigation/customerAsideNavigation'
  import lspAsideNav from '../../router/navigation/lspAsideNavigation'
  import {isActive} from '../../router/navigation/util'

  export default {
    name: 'AsideMenu',
    mounted: function() {
    },
    methods: {
      ...mapActions({
        getUser: 'user/show/retrieve',
        registerCustomerSettings: 'intercom/registerCustomerSettings'
      }),
      isActive: isActive,
    },
    computed:{
      ...mapState({
        locale: state => state.locale,
        account: state => state.account,
      }),
      ...mapGetters({
        customer: 'user/show/retrieved',
        intercomSettings: 'intercom/allSettings',
      }),
      links () {
        const user = this.account.user

        let customerMenu = Array.from(customerAsideNav);
        let lspMenu = lspAsideNav;

        // user is undefined or null OR user roles is null or undefined
        if (!user || !user.roles) {
          return [];
        }

        // Putting quick-access menu if user has any customized services:
        if(this.customer) {
          let customerProducts = this.customer.products || [];
          let companyProducts = this.customer.company ? (this.customer.company.products || []) : [];

          if(customerProducts.length > 0 || companyProducts.length > 0 ) {
            customerMenu.shift(); // remove first element from customerMenu
            customerMenu.unshift(
              {
                label: 'quick_access',
                link: '#',
                name: 'QuickAccess',
                menuIconClass: 'lk-be-icon lk-be-icon-quick-access lk-backend-menu__icon',
                children: []
              }
            )
          }
        }

        if(user.roles.includes(UserRole.ROLE_CUSTOMER)) {
          return customerMenu;
        }
        else if (user.roles.includes(UserRole.ROLE_LSP)) {
          return lspMenu;
        }
      },
    },
    created() {
      this.getUser('/users/' + this.account.user.id + '?groups[]=user:read&groups[]=customer:products&groups[]=productmenu:read')
    },
    beforeUpdate() {
      if (this.customer){
        this.registerCustomerSettings(this.customer)
        window.Intercom('boot', this.intercomSettings);
        window.Intercom('update');
      }
    }
  }
</script>
